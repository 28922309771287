export const routingTable = {
    order: 'zamowienie',
    blog: 'blog',
    error: 'strona-nieznaleziona',
    noQueryResult: 'error/querysearch',
    additional: {
        aboutUs: 'o-firmie',
        contact: 'kontakt',
        pos: 'salony-arte',
        posQ: 'salony-arte:q',
        posSingle: 'salony-arte/:shop'
    },
    checkout: {
        base: 'koszyk',
        account: 'uzytkownik',
        login: 'logowanie',
        summary: 'podsumowanie',
        final: 'zakonczenie',
        payment: 'platnosc'
    },
    customer: {
        base: 'klient',
        login: 'logowanie',
        register: 'rejestracja',
        passwordRecovery: 'odzyskiwanie-hasla',
        passwordRecoveryNewPassword: 'odzyskiwanie-hasla/:token',
        account: {
            base: 'konto',
            myData: 'dane',
            myCoupons: 'kupony',
            favourite: 'ulubione',
            reviews: 'opinie',
            lastWatched: 'ostatnio-ogladane',
            orders: 'zamowienia'
        },
    },
    shop: {
        listing: 'produkty',
        compare: 'porownaj',
        product: 'produkt',
        listingFriendly: [],
    },
}