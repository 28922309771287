import
{
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Resolutions } from "@resolutions";
import { CustomerClientService } from "@service/client/customer-client.service";
import { UrlHelperService } from "@service/helpers/url-helper.service";
import { OverlayService } from "@service/overlay/overlay.service";
import { SearchRepositoryService } from "@service/search-repository/search-repository.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { takeUntil } from "rxjs/operators";
import { getImageUrlStatic } from "../../../../utils/utilsFunctions";
import { SearchComponent } from "../search/search.component";
import { PopupService } from "../../../service/popup/popup.service";
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { BlockService } from "@shared/block/shared/block.service";
import { Menu, MenuCategory } from "app/model/interfaces";
import { Subject } from "rxjs";


@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnDestroy
{
  getImageUrlStatic = getImageUrlStatic;
  public activeCategory: string;
  public activeMenuLinks: MenuCategory[] = [];
  public menuLinks: MenuCategory[] = [];
  public hoveredCategory: MenuCategory = this.activeMenuLinks[0];

  @Output() public readonly isHoveredOutput: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("searchComponent") public searchComponent: SearchComponent;
  private destroy$: Subject<void> = new Subject();
  private hoverTimeout: any;
  public isOver500mil: boolean = false;
  private is500milTimeout: any;
  private name: string = "menu";
  public checkIfActiveCategoryIsKonto: boolean

  constructor(
    private searchRepositoryService: SearchRepositoryService,
    private router: Router,
    private cs: CustomerClientService,
    private translate: TranslateService,
    private changeDet: ChangeDetectorRef,
    private overlayService: OverlayService,
    private windowRef: WindowRefService,
    private urlHelper: UrlHelperService,
    private popupService: PopupService,
    public urlTranslateService: UrlTranslateService,
    private blockService: BlockService,
  )
  {
  }

  public ngOnInit(): void
  {
    this.popupService.reserveName(this.name);

    const categories: Menu = this.blockService.getDataSync("menu");
    categories.categories.forEach((category: MenuCategory) =>
    {
      category.urlParams = this.urlHelper.parseQueryStringToObject(category.link);

      if (category.urlParams.hasOwnProperty("kategoria"))
      {
        category.code = category.urlParams["kategoria"];
      }
    });

    this.menuLinks = categories.categories;
    this.activeMenuLinks = categories.categories.slice();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) =>
    {
      if (event instanceof NavigationEnd && this.windowRef.isWindowLoaded())
      {
        this.isHoveredOutput.emit(false);
        this.resetMenuAttributes();
        this.activeCategory = null;
        this.checkIfActiveCategoryIsKonto = false;
        this.onScroll(null);
      }
    });
  }

  public ngOnDestroy(): void
  {
    this.popupService.clearName(this.name);
    this.activeCategory = null;
    this.checkIfActiveCategoryIsKonto = false;
    this.destroy$.next();
    this.destroy$.complete();
  }

  public chooseCategory(name: string, attributes: any[]): void
  {
    this.activeCategory = name;
    this.checkIfActiveCategoryIsKonto = this.activeCategory === this.translate.instant("account.title")
    this.activeMenuLinks = attributes;
    this.onScroll(null);
    if (this.changeDet && !this.changeDet["destroyed"])
    {
      this.changeDet.detectChanges();
    }
  }



  public setAsHovered(elem: MenuCategory): void
  {
    this.changeDet.detectChanges();
    if (
      this.hoveredCategory &&
      !this.hoveredCategory.attributes &&
      elem.attributes &&
      elem.attributes.length &&
      this.isOver500mil
    )
    {
      this.overlayService.show();
    }
    this.hoveredCategory = elem;
    if (!elem || !elem.attributes || !elem.attributes.length)
    {
      this.overlayService.hide();
      this.changeDet.detectChanges();
      return;
    }

    if (!this.isOver500mil)
    {
      clearTimeout(this.hoverTimeout);
      // todo: zachowanie filtra gornego
      if (this.isMoreThanDesktopResolution())
      {
        this.isHoveredOutput.emit(true);
      }
    } else
    {
      clearTimeout(this.hoverTimeout);
      this.overlayService.show();
    }
    this.changeDet.detectChanges();
  }

  public logout(): void
  {
    this.cs.logout();
  }

  public isUserLogged(): boolean
  {
    return this.cs.isUserLogged;
  }

  public isMoreThanDesktopResolution(): boolean
  {
    return (
      this.windowRef.getNativeWindowInnerWidth() >=
      Resolutions.resolutionDesktop
    );
  }

  public isItemMenuSelected(link: string, value: string): boolean
  {
    let filterArray;
    let isItemSelected = false;

    if (link !== "{CODE}")
    {
      link = link.replace("{CODE}", value);
      filterArray = link.split("&");
      if (window.location.search.includes(filterArray[0]) && window.location.search.includes(filterArray[1]))
      {
        isItemSelected = value === "heat-set" ? window.location.search.includes("heat-set_frise") : true;
      }
    }
    else if (window.location.search.includes(value))
    {
      isItemSelected = true;
    }

    return isItemSelected;
  }

  @HostListener("window:scroll", ["$event"])
  public onScroll($event): void
  {
    if ($event && $event.type == "scroll")
    {
      if (this.hoveredCategory || this.overlayService.status)
      {
        this.hoveredCategory = null;
        this.overlayService.hide();
        this.changeDet.detectChanges();
      }
    }
    else
    {
      this.isOver500mil = false;
      clearTimeout(this.is500milTimeout);
      this.hoveredCategory = null;

      if (this.windowRef.getNativeWindowInnerWidth() >= Resolutions.resolutionDesktop)
      {
        this.overlayService.hide();
      }

      this.changeDet.detectChanges();
    }
  }

  private resetMenuAttributes(): void
  {
    this.activeCategory = null;
    this.searchRepositoryService.setSearchVisible(false);
    this.activeMenuLinks = this.menuLinks.slice();
  }

  public getLinkQuery(link: string, code: string): any
  {
    link = link.replace("{CODE}", code);

    if (!link.includes("="))
    {
      return '';
    }
    else
    {
      return this.urlHelper.parseQueryStringToObject(link);
    }
  }

  public getLink(link: string, code: string): string
  {
    link = link.replace("{CODE}", code);

    if (!link.includes("="))
    {
      return '/' + link;
    }
    else
    {
      return '/' + this.urlTranslateService.routingTable.shop.listing;
    }
  }


  public isOver500MilFunc($event): void
  {
    clearTimeout(this.is500milTimeout);

    this.is500milTimeout = setTimeout(() =>
    {
      if (this.changeDet["Destroyed"])
      {
        return;
      }
      this.isOver500mil = true;
      if (this.hoveredCategory && this.hoveredCategory.attributes.length)
      {
        this.overlayService.show();
        this.changeDet.detectChanges();
      }
    }, 500);
  }

  public getLinkToProduct(link: any): any
  {
    return this.urlHelper.encodeParams(
      link.includes(`/${this.urlTranslateService.routingTable.shop.listing}?`)
        ? link.split(`/${this.urlTranslateService.routingTable.shop.listing}?`)[1]
        : link
    );
  }
}
