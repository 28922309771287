import { Injectable, OnDestroy } from '@angular/core';
import { Wishlist, WishlistResultDTO } from '@dto';
import { AnalyticsService } from "@service/analytics/abstract/analytics.service";
import { getCamelCaseVariableName } from 'app/utils/utilsFunctions';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { AuthHttpService } from '../http/auth-http/auth-http.service';
import { TimestampService } from '../timestamp/timestamp.service';

@Injectable({
  providedIn: 'root'
})
export class WishlistService implements OnDestroy
{
  private isVisibleSubject: Subject<null> = new Subject();
  public readonly isVisible$: Observable<boolean> = this.isVisibleSubject.asObservable();
  private apiUrl: string;

  private wishlistSubject: BehaviorSubject<Wishlist> = new BehaviorSubject<Wishlist>(null);
  public widgetVisibility: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly wishlist$: Observable<Wishlist> = this.wishlistSubject.asObservable();

  constructor(
    private analyticsService: AnalyticsService,
    private configService: ConfigService,
    private timestampService: TimestampService,
    private authHttp: AuthHttpService
  )
  {

    this.apiUrl = configService.ApiUrl + 'wishlist/swiatdywanowshop/wishlist';
  }

  public updateWishlistSubject(wishlist): void
  {
    wishlist.products.forEach((item) =>
    {
      if (item.lowestPromotionPricePerSquareMeters != 0)
      {
        item.promotionPrice = item.lowestPromotionPricePerSquareMeters;
      }
      else
      {
        item.promotionPrice = item.lowsetPromotionPrice;
      }

      if (item.lowestPromotionPricePerSquareMeters != 0)
      {
        item.standardPrice = item.lowestStandardPricePerSquareMeters;
      }
      else
      {
        item.standardPrice = item.lowsetStandardPrice;
      }
    });

    this.wishlistSubject.next(wishlist);
    this.timestampService.update();
  }

  public addProductToWishList(profileFamilySku: string): Observable<Wishlist>
  {

    return this.authHttp.post(this.apiUrl + '/' + profileFamilySku, null).pipe(
      map((res: WishlistResultDTO) => getCamelCaseVariableName(res, true).wishlist),
      tap((wishlistData: Wishlist) =>
      {
        this.updateWishlistSubject(wishlistData);
        this.analyticsService.registerWishlistAdd(wishlistData, profileFamilySku);
      })
    );
  }

  public removeProductFromWishList(profileFamilySku: string): Observable<Wishlist>
  {
    return this.authHttp.delete(this.apiUrl + '/' + profileFamilySku).pipe(
      map((res: WishlistResultDTO) => getCamelCaseVariableName(res, true).wishlist),
      tap((wishlistData: Wishlist) => this.updateWishlistSubject(wishlistData))
    );
  }

  public clearWishList(): Observable<Wishlist>
  {
    return this.authHttp.delete(this.apiUrl).pipe(
      map((res: WishlistResultDTO) => getCamelCaseVariableName(res, true).wishlist),
      tap((wishlistData: Wishlist) => this.updateWishlistSubject(wishlistData))
    );
  }

  public ngOnDestroy(): void
  {
    this.wishlistSubject.complete();
    this.widgetVisibility.complete();
    this.isVisibleSubject.complete();
  }

  public closeTopNavbar(): void
  {
    this.isVisibleSubject.next(null);
  }

  public getProduct(product: any): any
  {
    if (this.wishlistSubject.value)
    {
      const tempProduct = this.wishlistSubject.value.products.filter(el => el.name == product.name);
      if (tempProduct.length)
      {
        return tempProduct[0];
      }
    }
    return;
  }
}
