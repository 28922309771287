import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BreadcrumbsLoggerService } from '@service/breadcrumbs/breadcrumbs-logger.service';
import { AppPreloadingStrategy } from './utils/app_preloading_strategy';
import { routingTable } from 'environments/routing-table';
import { TranslationLoaderResolver } from './module/resolver/translate-resolver.service';

export const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('app/module/shop/shop.module').then(m => m.ShopModule),
    resolve: { tempTranslation: TranslationLoaderResolver }
  },
  {
    path: routingTable.customer.base,
    loadChildren: () => import('app/module/customer/customer.module').then(m => m.CustomerModule),
    canActivate: [BreadcrumbsLoggerService],
    data: { preload: true, delay: true },
    resolve: { tempTranslation: TranslationLoaderResolver }
  },
  {
    path: routingTable.checkout.base,
    loadChildren: () => import('app/module/checkout/checkout.module').then(m => m.CheckoutModule),
    canActivate: [BreadcrumbsLoggerService],
    data: { preload: true, delay: true },
    resolve: { tempTranslation: TranslationLoaderResolver }
  },
  {
    path: routingTable.error,
    loadChildren: () => import('app/module/error-page/error-page.module').then(m => m.ErrorPageModule),
    resolve: { tempTranslation: TranslationLoaderResolver }
  },
  {
    path: routingTable.noQueryResult,
    loadChildren: () => import('app/module/error-page/error-page.module').then(m => m.ErrorPageModule),
    resolve: { tempTranslation: TranslationLoaderResolver }
  },
  {
    path: routingTable.order,
    loadChildren: () => import('app/module/guest/guest.module').then(m => m.GuestModule),
    canActivate: [BreadcrumbsLoggerService],
    data: { preload: false, delay: false },
    resolve: { tempTranslation: TranslationLoaderResolver }
  },
  {
    path: routingTable.blog,
    loadChildren: () => import('app/module/blog/blog.module').then(m => m.BlogModule),
    canActivate: [BreadcrumbsLoggerService],
    data: { preload: false, delay: false },
    resolve: { tempTranslation: TranslationLoaderResolver }
  },
  {
    path: '',
    loadChildren: () => import('app/module/additional/additional.module').then(m => m.AdditionalModule),
    canActivate: [BreadcrumbsLoggerService],
    data: { preload: false, delay: false },
    resolve: { tempTranslation: TranslationLoaderResolver }
  },
  {
    path: '**',
    loadChildren: () => import('app/module/error-page/error-page.module').then(m => m.ErrorPageModule),
    resolve: { tempTranslation: TranslationLoaderResolver }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: AppPreloadingStrategy,
    //scrollPositionRestoration: 'enabled',
    // onSameUrlNavigation: 'reload',
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule
{
}
