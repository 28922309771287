import
{
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ShopProduct, ShopProductFamily } from "@dto";
import { PopupService } from "@service/popup/popup.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { getImageUrl } from "app/utils/utilsFunctions";
import { ProductStatusPopupComponent } from "../product-status-popup/product-status-popup.component";

interface CalendarDates
{
  date: Date;
  color: string;
}

@Component({
  selector: "app-tooltip-popup",
  templateUrl: "./tooltip-popup.component.html",
  styleUrls: ["./tooltip-popup.component.scss"],
})
export class TooltipPopupComponent implements OnInit, OnDestroy
{
  public showForm = false;
  @Input() public webpPictureSrcSet: string;
  @Input() public jpgPictureSrcSet: string;
  @Input() public mainTitle: string;
  @Input() public buttonType: string;
  @Input() public defaultButtonText: string;
  @Input() public tooltip: boolean;
  @Input() name: string = "";
  @ViewChild(ProductStatusPopupComponent, { static: true })
  private productStatusPopup: ProductStatusPopupComponent;
  @Input() public product: any;
  private productLocal: any;
  public readonly dateNow: Date = new Date();
  public readonly dateNowISO = this.dateNow.toISOString();
  public loaded: boolean = false;
  @Output()
  public readonly emitAsClose: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public readonly successEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private windowRef: WindowRefService,
    private elementRef: ElementRef,
    private changeDet: ChangeDetectorRef,
    private renderer: Renderer2,
    private popupService: PopupService,
    private activeRoute: ActivatedRoute
  ) { }

  public goToInfoAlert(product): void
  {
    this.productStatusPopup.openModal(null, getImageUrl(product.familyCode, "0", null, [40, 40, 40], null));
  }

  public openSecondStepModal($event: ShopProductFamily, imageString: string): void
  {
    this.product = $event;
    this.productStatusPopup.openModal($event, imageString);
  }

  public getShippingLabel(): string
  {
    return this.product.shippingTime.daysTimeLabelShort || "";
  }

  public getDates(product: ShopProduct): CalendarDates[]
  {
    if (product && product.shippingTime && product.shippingTime.shippingtDate)
    {
      return [
        {
          date: product.shippingTime.shippingtDate,
          color: "rgb(218,218,218)",
        },
        {
          date: product.shippingTime.deliveryDate,
          color: "#ffffff",
        },
      ];
    }
  }

  public openModal(productVariant?: any, imageThumb?: string, disableReload?: boolean): void
  {
    if (imageThumb)
    {
      this.webpPictureSrcSet = imageThumb;
      this.jpgPictureSrcSet = imageThumb;
    }

    this.popupService.openPopup(this.name, !!disableReload);
    this.showForm = true;
    this.product = productVariant;

    if (productVariant)
    {
      this.productLocal = { ...productVariant };
    }

    this.windowRef.blockScroll();
    this.loaded = true;
    this.changeDet.detectChanges();
  }

  public closeModal(event?: any, disableReload?: boolean): void
  {
    if (!this.popupService.isActivePopup(this.name))
    {
      return;
    }

    if (this.windowRef.getDocumentBody().querySelector(".contact-popup-abstract"))
    {
      this.renderer.removeClass(this.windowRef.getDocumentBody().querySelector(".contact-popup-abstract"), "first-stage");
    }

    this.showForm = false;
    this.loaded = false;
    this.popupService.closePopup(this.name, !!disableReload);
    this.emitAsClose.emit();
    this.changeDet.detectChanges();
    // We need to unblock scroll after popup close
    setTimeout(() =>
    {
      this.windowRef.unBlockScroll();
    }, 100);
  }

  public emitSuccess(value)
  {
    this.successEmitter.emit(value);
  }

  @HostListener("window:keyup", ["$event"])
  private onKeyUp(event): void
  {
    if (this.showForm && event.key === "Escape")
    {
      this.closeModal(null);
    }
  }

  @HostListener("window:popstate", ["$event"])
  private backButtonHandler(): void
  {
    if (!this.popupService.isActiveParam("m", this.name) && this.popupService.isActivePopup(this.name))
    {
      this.closeModal(null, true);
    }
  }

  ngOnInit(): void
  {
    this.popupService.reserveName(this.name);
  }

  ngOnDestroy()
  {
    this.popupService.clearName(this.name);
  }
}
