import { Injectable } from "@angular/core";
import { routingTable } from "environments/routing-table";

@Injectable({
  providedIn: "root",
})
export class UrlTranslateService
{
  public routingTable = routingTable;

  public urlMapping = {
    checkout: {
      account: `${this.routingTable.checkout.base}/${this.routingTable.checkout.account}`,
      login: `${this.routingTable.checkout.base}/${this.routingTable.checkout.login}`,
      summary: `${this.routingTable.checkout.base}/${this.routingTable.checkout.summary}`,
      final: `${this.routingTable.checkout.base}/${this.routingTable.checkout.final}`,
      payment: `${this.routingTable.checkout.base}/${this.routingTable.checkout.payment}`,
    },
    customer: {
      login: `${this.routingTable.customer.base}/${this.routingTable.customer.login}`,
      register: `${this.routingTable.customer.base}/${this.routingTable.customer.register}`,
      passwordRecovery: `${this.routingTable.customer.base}/${this.routingTable.customer.passwordRecovery}`,
      passwordRecoveryNewPassword: `${this.routingTable.customer.base}/${this.routingTable.customer.passwordRecoveryNewPassword}`,
      account: {
        base: `${this.routingTable.customer.base}/${this.routingTable.customer.account.base}`,
        myData: `${this.routingTable.customer.base}/${this.routingTable.customer.account.base}/${this.routingTable.customer.account.myData}`,
        myCoupons: `${this.routingTable.customer.base}/${this.routingTable.customer.account.base}/${this.routingTable.customer.account.myCoupons}`,
        favourite: `${this.routingTable.customer.base}/${this.routingTable.customer.account.base}/${this.routingTable.customer.account.favourite}`,
        reviews: `${this.routingTable.customer.base}/${this.routingTable.customer.account.base}/${this.routingTable.customer.account.reviews}`,
        lastWatched: `${this.routingTable.customer.base}/${this.routingTable.customer.account.base}/${this.routingTable.customer.account.lastWatched}`,
        orders: `${this.routingTable.customer.base}/${this.routingTable.customer.account.base}/${this.routingTable.customer.account.orders}`
      },
    }
  }
}
