<ng-container *ngIf="sliderData$ | async as sliderData">
  <div class="slider-wrapper" [ngClass]="{ isWithBanner: sliderData }">
      <swiper [config]="swiperOpt" class="swiper slider-main" #swiperComponent>
        <ng-template *ngFor="let banner of sliderData; let i = index" swiperSlide>
          <div class="slider-wrapper-elem swiper-slide" [ngClass]="{ first: i == 0, 'tile-rwd-slide': isTileRWD }">
            <ng-container [ngSwitch]="banner.type">
              <!-- <app-stay-home-code-banner
                *ngSwitchCase="'StayHomeCode'"
                [banner]="banner"
                [width]="width"
                [height]="height"
                [isMainSlider]="isMainSlider"
              ></app-stay-home-code-banner> -->
              <app-standard-banner
                *ngSwitchDefault
                [banner]="banner"
                [width]="width"
                [height]="height"
                [isMainSlider]="true"
              ></app-standard-banner>
            </ng-container>
          </div>
        </ng-template>
      </swiper>
  </div>
</ng-container>