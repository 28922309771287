import { PreloadingStrategy, Route } from '@angular/router';
import { of, timer } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable()
export class AppPreloadingStrategy implements PreloadingStrategy 
{
  preload(route: Route, load: Function): Observable<any> 
  {
    const loadRoute = (delay) => delay ? timer(2500).pipe(mergeMap(() => load())) : load();
    return route.data && route.data.preload ? loadRoute(route.data.delay) : of(null);
  }
}
