<div
  class="cloud-wrapper"
  [ngClass]="{ 'small-card': !isBigCardSize }"
  *ngIf="product && product.productLink"
>
  <div class="image-wrapper">
    <a
      (click)="$event.preventDefault(); $event.stopPropagation()"
      [routerLink]="[
        '/' + urlTranslateService.routingTable.shop.product,
        product.productLink
      ]"
      [queryParams]="{ recommendationID: recommendationID }"
    >
      <app-img
        [src]="product.productGroupID"
        [index]="product.previewImageIndex"
        [width]="width"
        [height]="height"
        [options]="{ product: true, fillCrop: product.isCovering || product.isCarpet || product.isWalkway }"
        [placeholder]="false"
        [placeholderGray]="true"
      ></app-img>
    </a>
    <ng-container *ngIf="isBigCardSize">
      <div
        class="button tertiary-button middle-small circle no-border"
        [ngClass]="{ green: isInWishlist(product.productGroupID) }"
        (click)="toggleWishlist($event, product)"
      ></div>
    </ng-container>
  </div>
  <div
    class="bottom-wrapper"
    #variantsContainer
    [ngClass]="{ wykladziny: product.isCovering }"
  >
    <app-product-variants
      [product]="product"
      [onlyTableView]="true"
      [addVariantsHeader]="false"
      [deliveryTooltip]="isListing"
      (statusProductTooltipInvoked)="showTooltipPopup($event)"
    ></app-product-variants>
    <a
      class="success-link tiny-text"
      *ngIf="product.variants.length > 5"
      (click)="
        $event.preventDefault();
        $event.stopPropagation();
        goToProductPage(product)
      "
      >{{ "productHover.showMoreSizes" | translate }}</a
    >
  </div>
</div>
