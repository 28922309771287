import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { OverlayService } from '@service/overlay/overlay.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit
{

  @ViewChild('mainDiv') public overlayDiv: ElementRef;

  public isLoading$: Observable<boolean>;

  constructor(public overlayService: OverlayService, private windowRef: WindowRefService, private renderer: Renderer2)
  {
  }

  public ngOnInit(): void
  {
    this.isLoading$ = this.overlayService.isOverlayVisible$.pipe(map(res =>
    {
      return res;
    }));
  }
}
