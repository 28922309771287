import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

declare var OpenPayU: any;
declare var openpayu: any;

@Component({
  selector: 'app-raty',
  templateUrl: './raty.component.html',
  styleUrls: ['./raty.component.scss']
})
export class RatyComponent implements OnInit
{
  @ViewChild('creditAmountInput', { static: true }) public creditAmountInput: ElementRef;
  @ViewChild('installmentAmoutInput', { static: true }) public installmentAmoutInput: ElementRef;
  installmentAmountValue = 12;
  creditAmountValue: number;

  ngOnInit()
  {
    if (this.setupPayU() == false)
    {
      if (environment.instalments)
      {
        let a = new Observable(observer =>
        {
          if (typeof openpayu === 'undefined' && typeof OpenPayU === 'undefined')
          {
            (window as any).openpayu = {};
            (window as any).openpayu.options = { creditAmount: 1000, creditMinAmount: 1, posId: "1518454", key: "b9" };

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://static.payu.com/res/v2/widget-calculator-installments.min.js';
            script.async = true;
            script.defer = true;
            script.onload = () =>
            {
              observer.next(true);
              observer.complete();
            };
            script.onerror = (error: any) =>
            {
              observer.error(error);
            };
            document.body.appendChild(script);
          }
          else
          {
            observer.next(true);
            observer.complete();
          }
        });

        setTimeout(() =>
        {
          a.subscribe(() =>
          {
            this.setupPayU();
          });
        }, environment.instalmentsDeley);
      }
    }
  }

  public setupPayU()
  {
    if (typeof openpayu !== 'undefined' && typeof OpenPayU !== 'undefined')
    {
      openpayu.options.creditAmount = this.creditAmountInput.nativeElement.value && this.creditAmountInput.nativeElement.value !== "" ? this.creditAmountInput.nativeElement.value : 1000;
      openpayu.options.element = "#installment-calc"
      OpenPayU.Installments.calculator('#installment-calc');
      document.querySelector("#installment-calc").remove();
      openpayu.options.element = "#arte-installment-calc";
      OpenPayU.Installments.onReady();
      OpenPayU.Installments.prepareView();
      return true;
    }
    else
    {
      return false;
    }
  }

  constructor(public translate: TranslateService, private renderer: Renderer2)
  {
  }

  setCreditAmount(value)
  {
    this.creditAmountInput.nativeElement.value = value;
    this.creditAmountValue = value;
    this.refreshCreditAmount();
  }

  refreshCreditAmount()
  {
    if (typeof openpayu !== 'undefined' &&
      typeof OpenPayU !== 'undefined')
    {
      if (isNaN(this.creditAmountInput.nativeElement.value))
      {
        this.creditAmountInput.nativeElement.value = this.creditAmountValue;
        return;
      }

      this.creditAmountValue = this.creditAmountInput.nativeElement.value;
      OpenPayU.Installments.validateCreditAmount();
      OpenPayU.Installments.refreshCreditAmount();
    }
  }

  refreshInstallmentAmount()
  {
    if (typeof openpayu !== 'undefined' &&
      typeof OpenPayU !== 'undefined')
    {
      if (isNaN(this.installmentAmoutInput.nativeElement.value))
      {
        this.installmentAmoutInput.nativeElement.value = this.installmentAmountValue;
        return;
      }

      this.installmentAmountValue = this.installmentAmoutInput.nativeElement.value;
      OpenPayU.Installments.validateInstallmentAmount();
      OpenPayU.Installments.refreshInstallmentAmount();
    }
  }

  subtractionValue()
  {
    this.installmentAmoutInput.nativeElement.value--;
    this.refreshInstallmentAmount();
    this.installmentAmountValue = this.installmentAmoutInput.nativeElement.value;
  }

  additionValue()
  {
    this.installmentAmoutInput.nativeElement.value++;
    this.refreshInstallmentAmount();
    this.installmentAmountValue = this.installmentAmoutInput.nativeElement.value;
  }

}
