<section class="tile-wrapper" *ngIf="tiles$ | async as Tiles">
  <h2 class="title">{{ "main.floorStyleText" | translate }}</h2>
  <div class="description">{{ "main.floorStyleText2" | translate }}</div>
  <div class="tile-box separator4" *ngIf="isMoreThanMobileResolution">

    <a [routerLink]="'/' + urlTranslateService.routingTable.shop.listing"
      [queryParams]="tile.linkQueryParams"
      class="grid-item"
      *ngFor="let tile of Tiles; let i = index"
      [ngStyle]="{ color: tile.captionForeColor }"
      [ngClass]="tile.className">

      <div class="image-wrapper" *ngIf="tile.image">
        <app-img
          [lazy]="i > 3"
          [src]="tile.image.url"
          [newOptions]="tile.image"
          [placeholder]="false">
        </app-img>
      </div>

      <div class="text-wrapper">
        <p class="heading7 block-name body-text font-style-light" *ngIf="tile.text">
          {{ tile.text }}
        </p>

        <p class="heading7 block-caption" *ngIf="tile.caption">
          {{ tile.caption }}
        </p>

        <p class="text-link heading5" *ngIf="tile.callToActionText"
          [ngClass]="{ 'white-after': tile.captionForeColor === '#FFFFFF' }">
          <a [style.color]="tile.captionForeColor"
            [routerLink]="'/' + urlTranslateService.routingTable.shop.listing"
            [queryParams]="tile.linkQueryParams">
            {{ tile.callToActionText }}
          </a>
        </p>
      </div>

    </a>
  </div>
  <app-tile-slider *ngIf="!isMoreThanMobileResolution"
    class="tile-rwd"
    [sliderData$]="tiles$"
    [width]="[242, 269, 400]"
    [height]="[242, 269, 400]"
    [singleSlideMovement]="true"
  ></app-tile-slider>
</section>