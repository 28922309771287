import { ChangeDetectorRef, ElementRef, Renderer2 } from '@angular/core';
import { Record } from '@idto';
import { Resolutions } from '@resolutions';
import { ConfigService } from '@service/config/config.service';

export function getImageUrl(familyCode: string, index?: string, width?: number[], height?: number[], dpi?: string, webp?: boolean, fillCrop?: boolean, suffix?: boolean, fillCover?: boolean): string
{
  if (!window.document.querySelector('html').className.includes('webp'))
  {
    return;
  }

  let webpSupport = webp != undefined ? webp : window.document.querySelector('html').className.includes('webp-lossless');
  let url = '?index=' + index;
  let suffixData = '';

  if (width)
  {
    if (width.length == 3)
    {
      if (window.innerWidth < Resolutions.resolutionDesktop)
      {
        if (window.innerWidth < Resolutions.resolutionTablet)
        {
          url += '&width=' + width[0];
        }
        else
        {
          url += '&width=' + width[1];
        }
      }
      else
      {
        url += '&width=' + width[2];
      }
    }

    if (width.length == 4)
    {
      if (window.innerWidth < Resolutions.resolutionDesktop)
      {
        if (window.innerWidth < Resolutions.resolutionTablet)
        {
          if (window.innerWidth < 450)
          {
            url += '&width=' + width[0];
          }
          else
          {
            url += '&width=' + width[1];
          }
        }
        else
        {
          url += '&width=' + width[2];
        }
      }
      else
      {
        url += '&width=' + width[3];
      }
    }
  }

  if (height)
  {
    if (window.innerWidth < Resolutions.resolutionDesktop)
    {
      if (window.innerWidth < Resolutions.resolutionTablet)
      {
        url += '&height=' + height[0];
      }
      else
      {
        url += '&height=' + height[1];
      }
    }
    else
    {
      url += '&height=' + height[2];
    }
  }

  if (fillCrop)
  {
    url += '&resizeType=Fill_Crop';
  }

  if (fillCover)
  {
    url += '&resizeType=Fill_Cover';
  }

  if (!dpi)
  {
    let dpiData = getDpi(dpi);
    dpi = dpiData.dpi;
    suffixData = dpiData.suffix;
  }

  url += '&dpi=' + dpi;

  url += '&format='.concat(webpSupport ? 'webp' : 'jpg');
  if (suffix == undefined || suffix == true || suffix == null)
  {
    url = url.concat(suffixData);
  }

  return ConfigService.staticImageUrl + '/productimages/' + familyCode + url;
}

export function getImageUrlStatic(imagePath: string, width?: number[], height?: number[], dpi?: string, webp?: boolean, suffix?: boolean, fillCrop?: boolean): string
{
  if (!window.document.querySelector('html').className.includes('webp'))
  {
    return;
  }

  let suffixData = '';

  let webpSupport = webp != undefined ? webp : window.document.querySelector('html').className.includes('webp-lossless');
  let url = '';

  if (imagePath.indexOf('?') > 0)
  {
    url = imagePath;
  }
  else
  {
    url = imagePath + '?';
  }

  if (height)
  {
    if (window.innerWidth < Resolutions.resolutionTablet)
    {
      url += '&height=' + height[0];
    } else if (window.innerWidth < Resolutions.resolutionDesktop)
    {
      url += '&height=' + height[1];
    } else
    {
      url += '&height=' + height[2];
    }
  }
  if (width)
  {
    if (window.innerWidth < Resolutions.resolutionTablet)
    {
      url += '&width=' + width[0];
    } else if (window.innerWidth < Resolutions.resolutionDesktop)
    {
      url += '&width=' + width[1];
    } else
    {
      url += '&width=' + width[2];
    }
  }

  if (fillCrop)
  {
    url += '&resizeType=Fill_Crop';
  }

  if (!dpi)
  {
    let dpiData = getDpi(dpi);

    dpi = dpiData.dpi;
    suffixData = dpiData.suffix;
  }

  url += '&dpi=' + dpi;

  if (suffix == undefined || suffix == true || suffix == null)
  {
    url = url.concat(suffixData);
  }
  return url;
}

export function getDpi(dpiInput: string): { dpi: string, suffix: string }
{
  let suffixData = '';

  if (!dpiInput)
  {
    dpiInput = '100';

    if (window.devicePixelRatio > 1.76)
    {
      dpiInput = '200';
      suffixData = ' 2x';
    }
    else if (window.devicePixelRatio > 1.51)
    {
      dpiInput = '175';
      suffixData = ' 1.75x';
    }
    else if (window.devicePixelRatio > 1.26)
    {
      dpiInput = '150';
      suffixData = ' 1.5x';
    }
    else if (window.devicePixelRatio > 1.11)
    {
      dpiInput = '125';
      suffixData = ' 1.25x';
    }
    else if (window.devicePixelRatio > 1.01)
    {
      dpiInput = '110';
      suffixData = ' 1.10x';
    }
  }

  return { dpi: dpiInput, suffix: suffixData };
}

export function animateButton(buttonName: string, nativeLoginElement: ElementRef, renderer: Renderer2, changeDet: ChangeDetectorRef): void
{
  renderer.addClass(nativeLoginElement.nativeElement, buttonName);
  changeDet.detectChanges();
  if (buttonName != 'loading')
  {
    setTimeout(() =>
    {
      renderer.removeClass(nativeLoginElement.nativeElement, buttonName);
      if (changeDet && !changeDet['destroyed'])
      {
        changeDet.detectChanges();
      }
    }, 3000);
  }
}

export function removeAnimateButton(buttonName: string, nativeLoginElement: ElementRef, renderer: Renderer2): void
{
  renderer.removeClass(nativeLoginElement.nativeElement, buttonName);

}

export function getTextWidth(text, font): number
{
  // if given, use cached canvas for better performance
  // else, create new canvas
  // @ts-ignore
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export function markAsTouchedAndDirty(formControls: object): void
{
  Object.keys(formControls).forEach(el =>
  {
    formControls[el].markAsDirty();
    formControls[el].markAsTouched();
  });
}

export function showAfterSecond(time: number, loaded, changeDet): void
{
  setTimeout(function ()
  {
    loaded['loaded'] = true;
    changeDet.detectChanges();
  }, time);
}

const isDate: RegExp = /^\d{4}-(\d{2})-(\d{2})[T]{1}(\d{2}):(\d{2}):(\d{2})$/;

export function getCamelCaseVariableName(myVal, camel: boolean): any
{
  const val = JSON.parse((JSON.stringify(myVal)));

  if (val === null)
  {
    return val;
  }
  else if (Array.isArray(val))
  {
    const array = [];
    val.forEach(el =>
    {
      JSON.stringify(el, function (key, value)
      {
        if (value && typeof value === 'object')
        {
          const replacement = {};
          for (let k in value)
          {
            if (Object.hasOwnProperty.call(value, k))
            {
              if (camel)
              {
                replacement[k && k.charAt(0).toLowerCase() + k.substring(1)] = getCamelCaseVariableName(value[k], camel);
              }
              else
              {
                replacement[k && k.charAt(0).toUpperCase() + k.substring(1)] = getCamelCaseVariableName(value[k], camel);
              }
            }
          }
          array.push(replacement);
        } else
        {
          array.push(value);
        }
      });
    });
    return array;
  }
  else if (typeof val === 'object')
  {
    const replacement = {};

    Object.keys(val).forEach(el =>
    {
      if (camel)
      {
        replacement[el.charAt(0).toLowerCase() + el.substring(1)] = getCamelCaseVariableName(val[el], camel);
      }
      else
      {
        replacement[el.charAt(0).toUpperCase() + el.substring(1)] = getCamelCaseVariableName(val[el], camel);
      }
    });

    return replacement;
  }
  else
  {
    if (isDate.test(val))
    {
      return new Date(val)
    }

    return val;
  }
}


export function transformColorStatic(hexColor: string, opacity: number): any
{
  if (hexColor)
  {
    const rgb = hexColor.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16));

    return { 'background-color': `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})` };
  }
}