<div #shorterText class="shorter">
  <span [innerHTML]="content.collapsedText"></span>
  <a class="expand"> {{ 'block.expand' |translate }} &raquo; </a>
</div>
<div #longerText class="longer" style="display: none;">
  <ng-scrollbar autoHeightDisabled="false">
    <span [innerHTML]="content.expandedText"></span>
    <a class="collapse"> &laquo; {{ 'block.fold' |translate }} </a>
  </ng-scrollbar>
</div>
