<div
  class="tooltip-wrapper"
  [style.height]="height"
  [style.width]="width"
  [style.top]="top"
  [style.left]="left"
  [style.bottom]="bottom"
  [style.right]="right"
>
  <div class="main-title">{{ title }}</div>
  <ng-container *ngIf="!block"
    ><p class="description" [innerHTML]="description"></p
  ></ng-container>  
  <!-- Chyba nie używane -->
  <!-- <ng-container *ngIf="block"
    ><p class="description" sd-block [block]="block"></p
  ></ng-container> -->
</div>
