<div class="error-overlay">
  <div class="content">
    <nav>
      <div class="flex-row">
        <img src="./assets/arte.svg" />
        <div class="small-text phones">
          Potrzebujesz pomocy? <a href="tel:801090905">801 09 09 05</a> /
          <a href="tel:221002727">22 100 27 27</a>
        </div>
      </div>
    </nav>

    <div>
      <div class="error-wrapper">
        <div class="wrapper">
          <p class="header">
            Przepraszamy! <br />
            Trwa przerwa techniczna
          </p>
          <p class="description">
            Nasi specjaliści pracują już nad usunięciem problemu, a Ty możesz
            odpocząć.
          </p>
        </div>
        <div class="image-wrapper"></div>
      </div>
    </div>
    <div class="bottom-footer">
      <div>© Arte. Wszelkie prawa zastrzeżone. Powered by ENP</div>
    </div>
  </div>
</div>
