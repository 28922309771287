import { Injectable } from "@angular/core";
import { environment } from '../../../../../environments/environment';
import
{
  ShopProductFamily,
  ShopProduct,
  Order,
  Cart,
  Wishlist,
  Customer
} from "@dto";
import
{
  SearchResult,
  Record,
} from "@idto";
import { NavigationEnd } from "@angular/router";
import { AnalyticsInterface } from './analytics.interface';
import { EMPTY } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsBaseService implements AnalyticsInterface
{
  public analyticsServices = [];
  constructor()
  {

  }

  public getName(): string
  {
    return 'AnalyticsBaseService';
  }

  public isEnabled(): boolean
  {
    return false;
  }

  public async initialize(): Promise<void>
  {
  }

  //#region Generic events
  public async registerEvent(eventType: string, eventParameters: { [key: string]: any }, eventNonInteraction: boolean = false): Promise<void>
  {
  }

  public async registerPageView(event: NavigationEnd): Promise<void>
  {
  }
  //#endregion

  //#region Shop flow events
  public async registerApplicationStart(): Promise<void>
  {
    return Promise.resolve();
  }

  public async registerProductListView(result: SearchResult, list: string): Promise<void>
  {
  }

  public async registerProductPageView(product: ShopProductFamily): Promise<void>
  {
  }

  public async registerSelectItem(product: Record, list: string): Promise<void>
  {
  }

  public async registerWishlistAdd(wishlist: Wishlist, profileFamilySku: string): Promise<void>
  {
  }
  //#endregion

  //#region Transaction
  public async registerCartAdd(product: ShopProduct, quantity: number, cart: Cart): Promise<void>
  {
  }

  public async registerCartRemove(product: ShopProduct, quantity: number, cart: Cart): Promise<void>
  {
  }

  public async registerCartView(cart: Cart): Promise<void>
  {
  }
  public async registerCoupon(coupon: string): Promise<void>
  {
  }
  public async registerBeginCheckout(cart: Cart): Promise<void>
  {
  }

  public async registerTransaction(order: Order): Promise<void>
  {
  }
  //#endregion

  //#region Basket steps flow
  public async registerCheckoutRegistrationEnter(): Promise<void>
  {
  }

  public async registerCheckoutCustomerDataEnter(): Promise<void>
  {
  }

  public async registerCheckoutReviewEnter(): Promise<void>
  {
  }

  public async registerCheckoutOrderPlacedEnter(): Promise<void>
  {
  }
  //#endregion

  //#region Subscription
  public async registerCustomerNewsletterSubscription(customer: Customer, email: string): Promise<void>
  {
  }
  public async registerCustomerNewsletterUnsubscription(customer: Customer, email: string): Promise<void>
  {
  }
  //#endregion

  //#region Customer
  public async registerCustomerRegistration(): Promise<void>
  {
  }
  public async registerCustomerContactRequest(): Promise<void>
  {
  }
  public async registerPosSearch(query: string): Promise<void>
  {
  }
  public async registerUserID(userID: string): Promise<void>
  {
  }
  public async registerUserData(email: string, phone: string): Promise<void>
  {
  }
  //#endregion
}
