import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@shared/directives/directives.module';
import { DropdownComponent } from './dropdown/dropdown.component';
import { RatingComponent } from './rating/rating.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ImgComponent } from './img/img.component';
import { DiscountComponent } from './discount/discount.component';
import { GetImageUrlPipe } from './img/get-image-url.pipe';

@NgModule({
  declarations: [DropdownComponent, RatingComponent, ImgComponent, DiscountComponent, GetImageUrlPipe],
  imports: [
    CommonModule,
    TranslateModule,
    NgScrollbarModule,
    DirectivesModule,
  ],
  providers: [
    GetImageUrlPipe
  ],
  exports: [
    DropdownComponent, RatingComponent, ImgComponent, DiscountComponent
  ]
})
export class ExtraModule
{
}
