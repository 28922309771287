import { Component } from "@angular/core";
import { ContactPopupAbstractComponent } from "app/module/sharedModules/shared/modals/contact-popup-abstract/contact-popup-abstract.component";

@Component({
  selector: "app-advisor-popup",
  templateUrl:
    "../contact-popup-abstract/contact-popup-abstract.component.html",
  styleUrls: ["./advisor-popup.component.scss"],
})
export class AdvisorPopupComponent extends ContactPopupAbstractComponent { }
