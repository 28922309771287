import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TimestampService implements OnDestroy
{
  private timestampSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public readonly timestamp$: Observable<string> = this.timestampSubject.asObservable();

  constructor(private storageService: StorageService)
  {
  }

  public updateTimeStampOnRequest(): void
  {
    this.storageService.get('timestamp').subscribe((timestamp: string) =>
    {
      this.timestampSubject.next(timestamp);
    });
  }

  public update(): void
  {
    this.storageService.set('timestamp', new Date().toJSON());
  }

  public ngOnDestroy(): void
  {
    this.timestampSubject.complete();
  }
}
