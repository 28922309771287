import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { BehaviorSubject, Observable } from 'rxjs';

interface ElementHeight
{
  top: number;
  bottom: number;
  linkElement: any;
}

interface Links
{
  descriptionProduct?: ElementHeight;
  reviews?: ElementHeight;
  payments?: ElementHeight;
  complementary?: ElementHeight;
}

export interface NotificationBar
{
  alertStatus: string;
  errorText: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavbarService
{
  public links: Links;
  private renderer: Renderer2;
  private notificationSubject: BehaviorSubject<NotificationBar> = new BehaviorSubject<NotificationBar>(null);
  public readonly notification$: Observable<NotificationBar> = this.notificationSubject.asObservable();
  private navbarDomSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly navbarDom$: Observable<boolean> = this.navbarDomSubject.asObservable();

  constructor(private windowRef: WindowRefService,
    private _renderer: RendererFactory2)
  {
    this.renderer = _renderer.createRenderer(null, null);

  }

  public updateNavbarDomSubject(): void
  {
    this.navbarDomSubject.next(true);
  }

  public updateNotificationSubject(notification: NotificationBar): void
  {
    this.notificationSubject.next(notification);
  }

  public highlightElementInViewport(): void
  {
    if (!this.windowRef.isServer && !this.windowRef.scrollIsBlocked)
    {
      if (!this.links || Object.keys(this.links).length === 0)
      {
        this.initializeLinks();
      }

      if (this.links)
      {
        const scrollTop = this.windowRef.getNativeWindow().scrollY;
        const hrLine = this.windowRef.getDocument().getElementById('hr-navigation-line');


        if (this.links.descriptionProduct && scrollTop < this.links.descriptionProduct.bottom && scrollTop >= this.links.descriptionProduct.top - 175)
        {
          const productWrapperLink = this.links.descriptionProduct.linkElement;
          hrLine.style.transform = `translateX(${productWrapperLink.offsetLeft}px)`;
          hrLine.style.width = `${productWrapperLink.clientWidth}px`;
        }

        if (this.links.reviews && scrollTop < this.links.reviews.bottom - 150 && scrollTop >= this.links.reviews.top)
        {
          const reviewsLink = this.links.reviews.linkElement;
          hrLine.style.transform = `translateX(${reviewsLink.offsetLeft}px)`;
          hrLine.style.width = `${reviewsLink.clientWidth}px`;
        }

        if (this.links.payments && scrollTop < this.links.payments.bottom - 150 && scrollTop >= this.links.payments.top - 175)
        {
          const paymentsLink = this.links.payments.linkElement;
          hrLine.style.transform = `translateX(${paymentsLink.offsetLeft}px)`;
          hrLine.style.width = `${paymentsLink.clientWidth}px`;
        }

        if (this.links.complementary)
        {
          const complementaryLink = this.windowRef.getDocument().getElementById('complementaryLink');

          if (scrollTop < this.links.complementary.bottom - 150 && scrollTop >= this.links.complementary.top - 225)
          {
            hrLine.style.transform = `translateX(${complementaryLink.offsetLeft}px)`;
            hrLine.style.width = `${complementaryLink.clientWidth}px`;
          }

          if (scrollTop >= this.links.complementary.bottom - 150)
          {
            this.renderer.addClass(hrLine, 'hidden');
          } else
          {
            this.renderer.removeClass(hrLine, 'hidden');
          }
        }
      }
    }
  }

  private initializeLinks(): void
  {
    const descriptionProductEl = this.windowRef.getDocument().getElementById('descriptionProduct');

    if (!descriptionProductEl)
    {
      return;
    }

    this.links = this.links || {};
    const reviewsEl = this.windowRef.getDocument().getElementById('reviews');
    const paymentsEl = this.windowRef.getDocument().getElementById('payments');
    const complementaryEl = this.windowRef.getDocument().getElementById('complementary');

    if (descriptionProductEl)
    {
      const attributesTop = 150;
      const attributesBottom = reviewsEl ? reviewsEl.offsetTop + 50 : 0;
      this.links.descriptionProduct = {
        top: attributesTop,
        bottom: attributesBottom,
        linkElement: this.windowRef.getDocument().getElementById('productWrapperLink')
      };
    }

    if (reviewsEl)
    {

      this.links.reviews = {
        top: reviewsEl.offsetTop + 50,
        bottom: reviewsEl.offsetTop + reviewsEl.offsetHeight + 266,
        linkElement: this.windowRef.getDocument().getElementById('reviewsLink')
      };
    }

    if (paymentsEl)
    {
      this.links.payments = {
        top: paymentsEl.offsetTop + 200,
        bottom: paymentsEl.offsetTop + paymentsEl.offsetHeight + 380,
        linkElement: this.windowRef.getDocument().getElementById('paymentsLink')
      };
    }

    if (complementaryEl)
    {
      this.links.complementary = {
        top: complementaryEl.offsetTop - 64 + 80 + 300,
        bottom: complementaryEl.offsetTop + complementaryEl.offsetHeight + 450,
        linkElement: this.windowRef.getDocument().getElementById('complementaryLink')
      };
    }

    this.updateNavbarDomSubject();
  }
}
