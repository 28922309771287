import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalendarService
{
  get weeksMatrix(): any
  {
    return this._weeksMatrix;
  }

  get daysMatrix(): any
  {
    return this._daysMatrix;
  }

  private monthsMatrix: any = {};
  private _daysMatrix: any = {};
  private _weeksMatrix: any = {};

  get year(): number
  {
    return this._year;
  }

  private date: Date;
  private _year: number;
  private month: number;
  private daysinMonth: number;
  private day: number;
  private dayOfWeek: number;
  private defaultYear;

  constructor()
  {
    this.defaultYear = this.generateMatrix(new Date().getFullYear());
    this.setCurrentDate();
    this.setDateMatrices();
  }

  private setCurrentDate(): void
  {
    this.date = new Date();

    this._year = this.date.getFullYear();

    this.month = this.date.getMonth();
    this.daysinMonth = new Date(this._year, this.month, 0).getDate();

    this.day = this.date.getDate();
    this.dayOfWeek = this.date.getDay();
  }

  public generateMatrix(year): any
  {
    if (this.defaultYear && year == new Date().getFullYear())
    {
      return this.defaultYear;
    }
    const months = [];

    // Ostatni miesiąc poprzedniego roku

    let week = 1;

    months[0] = {};

    for (let day = 1; day <= new Date(year - 1, 12, 0).getDate(); day++)
    {
      if (!months[0][week])
      {
        months[0][week] = [];
      }
      months[0][week].push({
        date: new Date(year - 1, 11, day),
        year: year - 1,
        month: 12,
        day: day
      });
      if (new Date(year - 1, 11, day).getDay() === 0)
      {
        week++;
      }
    }

    // Cały rok

    let weekOfYear = 1;

    for (let month = 1; month <= 12; month++)
    {
      week = 1;
      months[month] = {};
      for (let day = 1; day <= new Date(year, month, 0).getDate(); day++)
      {
        if (!months[month][week])
        {
          months[month][week] = [];
        }
        months[month][week].push({
          date: new Date(year, month - 1, day),
          year: year,
          month: month,
          day: day,
          week: week,
          weekOfYear: weekOfYear
        });
        if (new Date(year, month - 1, day).getDay() === 0)
        {
          weekOfYear++;
          week++;
        }
      }
    }

    // Pierwszy miesiąc kolejnego roku

    week = 1;

    months[13] = {};

    for (let day = 1; day <= new Date(year + 1, 0, 0).getDate(); day++)
    {
      if (!months[13][week])
      {
        months[13][week] = [];
      }
      months[13][week].push({
        date: new Date(year + 1, 0, day),
        year: year + 1,
        month: 1,
        day: day
      });
      if (new Date(year + 1, 0, day).getDay() === 0)
      {
        week++;
      }
    }

    // Przejście na tablice

    const monthsArray = [];

    for (let month = 0; month <= 13; month++)
    {
      const monthArray = [];
      for (let i = 1; i <= Object.keys(months[month]).length; i++)
      {
        const weekArray = [];
        for (let day = 0; day < months[month][i].length; day++)
        {
          if (months[month][i][day])
          {
            weekArray.push(months[month][i][day]);
          }
        }
        monthArray.push(weekArray);
      }
      monthsArray.push(monthArray);
    }

    // Uzupełnienie tygodni

    for (let month = 1; month <= 13; month++)
    {

      const monthArray = monthsArray[month].slice(0);

      if (monthsArray[month][0].length < 7)
      {

        const disabledDays = [];

        monthsArray[month - 1][monthsArray[month - 1].length - 1].forEach(day =>
        {
          disabledDays.push({
            date: day.date,
            year: day._year,
            month: day.month,
            day: day.day,
            week: day.week,
            weekOfYear: day.weekOfYear,
            disabled: true
          });
        });

        monthsArray[month][0] = disabledDays.concat(monthArray[0]);

      }

      if (monthsArray[month - 1][monthsArray[month - 1].length - 1].length < 7)
      {

        const disabledDays = [];

        monthArray[0].forEach(day =>
        {
          disabledDays.push({
            date: day.date,
            year: day._year,
            month: day.month,
            day: day.day,
            week: day.week,
            weekOfYear: day.weekOfYear,
            disabled: true
          });
        });

        monthsArray[month - 1][monthsArray[month - 1].length - 1] =
          monthsArray[month - 1][monthsArray[month - 1].length - 1].concat(disabledDays);
      }

    }

    // Obiekty

    return monthsArray;

  }

  private setDateMatrices(): void
  {
    let weekOfYear = 1;
    for (let month = 0; month <= 11; month++)
    {
      this.monthsMatrix[month + 1] = {};
      this.daysMatrix[month + 1] = {};
      const days = new Date(this.year, month + 1, 0).getDate();
      let week = 1;
      for (let day = 1; day <= days; day++)
      {
        if (!this.monthsMatrix[month + 1][week])
        {
          this.monthsMatrix[month + 1][week] = {};
        }
        if (!this.weeksMatrix[weekOfYear])
        {
          this.weeksMatrix[weekOfYear] = [];
        }
        const date = new Date(this.year, month, day);
        const dayObject = {
          date: date,
          year: this.year,
          month: month + 1,
          day: day,
          weekOfMonth: week,
          weekOfYear: weekOfYear
        };
        this.monthsMatrix[month + 1][week][day] = dayObject;
        this.daysMatrix[month + 1][day] = dayObject;
        this.weeksMatrix[weekOfYear].push(dayObject);
        if (date.getDay() === 0)
        {
          week++;
          weekOfYear++;
        }
      }
    }
  }
}
