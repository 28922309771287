import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShopProduct } from '@dto';
import { Variant } from '@idto';
import { Resolutions } from '@resolutions';
import { WindowRefService } from '@service/window-service/window-ref.service';

interface CalendarDates
{
  date: Date;
  color: string;
}

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent
{
  // create set get for product
  private _product: any;
  public get product(): any
  {
    return this._product;
  }
  @Input() public set product(value: any)
  {
    this._product = value;

    if (this.product.shippingTime && this.product.shippingTime.daysTimeLabelShort)
    {
      this.unknownDate = this.product.shippingTime.daysTimeLabelShort == 'tel.';
    }
    else
    {
      this.unknownDate = this.product.shipmentTime == 'tel.';
    }
  }
  public readonly dateNow: Date = new Date();
  public unknownDate: boolean;
  public readonly dateNowISO = this.dateNow.toISOString();
  @Output() private readonly statusProductTooltipInvoked: EventEmitter<ShopProduct> = new EventEmitter<ShopProduct>();

  constructor(private windowRef: WindowRefService)
  {
  }

  public goToProductStatusPopup($event): void
  {
    $event.stopPropagation();
    $event.preventDefault();
    this.statusProductTooltipInvoked.emit(this.product);
  }

  public getShippingTime(product): string
  {
    if (this.product.shippingTime)
    {
      return this.product.shippingTime.shippingtDate;
    }
    return this.product.shipmentDate;
  }

  public getDates(product: ShopProduct | Variant | any): CalendarDates[]
  {
    if (product && product.shippingTime && product.shippingTime.shippingtDate)
    {
      return [
        {
          date: product.shippingTime.shippingtDate,
          color: 'rgb(218,218,218)'
        },
        {
          date: product.shippingTime.deliveryDate,
          color: '#ffffff'
        }
      ];
    } else if (product && product.shipmentTime)
    {
      return [
        {
          date: new Date(product.shipmentDate),
          color: 'rgb(218,218,218)'
        },
        {
          date: new Date(product.deliveryDate),
          color: '#ffffff'
        }
      ];
    }
  }

  public getClassNameByResolution(className1: string, className2: string): string
  {
    if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
    {
      return className1;
    }
    return className2;
    //  todo:on resize
  }

}
