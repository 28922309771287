export enum Resolutions
{
  resolutionMidMobile = 550,
  resolutionTablet = 768,
  resolutionTabletMinus = 767,
  resolutionDesktopMinus = 1279,
  resolutionDesktop = 1280,
  resolutionMinLaptop = 1366,
  resolutionMidLaptop = 1536,
  resolutionHalfHd = 1680,
  resolutionHalfHdMinus = 1679,
  resolutionFullHd = 1880
}

// when want to change, change values in _variables.scss also !important
