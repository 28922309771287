<swiper id="main-product-gallery" [config]="mainSliderConfig" (slideChange)="onSlideChange($event)" #swiperComponent>
  <ng-template *ngFor="let img of imgArray; let i = index" swiperSlide>
    <a
      class="gallery-item"
      [ngClass]="{'cover': product.isCovering}"
      [href]="img.src"
      [attr.data-pswp-width]="img.width"
      [attr.data-pswp-height]="img.height"
      [attr.data-cropped]="product.isCovering"
      target="_blank"
      (click)="initPhotoSwipe($event)"
      >
      <app-img
        [lazy]="i > 1"
        [src]="product.familyCode"
        [index]="product.images[i].index"
        [width]="width"
        [height]="height ? height : [1200, 1200, 1200]"
        [options]="{
          product: true
        }"
        [alt]="product.name + ' - ' + product.images[i].name"
      >
      </app-img>
    </a>
  </ng-template>
</swiper>
