import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ShopProduct } from "@dto";
import { Variant } from "@idto";

@Pipe({ name: "getShortTime" })
export class ShortTimePipe implements PipeTransform
{
    constructor(
        private translate: TranslateService,
    ) { }

    transform(productVariant: ShopProduct | Variant, selectedShop: any, isMobile: boolean, popupReservation: boolean = false): string
    {
        if (selectedShop && productVariant['availableInStorages'].filter((el) => selectedShop.code == el).length > 0)
        {
            let translatedText;
            translatedText = this.translate.instant("cart.availableAtOnce");

            return ('<span class="font-style-semi color-body-text" >' + translatedText + "</span>");
        }

        if (isMobile)
        {
            if ('shippingTime' in (productVariant as any))
            {
                return this.isOneDayOnPopupReservation(popupReservation, (productVariant as ShopProduct).shippingTime.daysTimeLabelShort);
            }
            else
            {
                return this.isOneDayOnPopupReservation(popupReservation, (productVariant as Variant).shipmentTimeShort);
            }
        }
        else
        {
            if ('shippingTime' in (productVariant as any))
            {
                return this.isOneDayOnPopupReservation(popupReservation, (productVariant as ShopProduct).shippingTime.daysTimeLabel);
            }
            else
            {
                return this.isOneDayOnPopupReservation(popupReservation, (productVariant as Variant).shipmentTime);
            }
        }
    }

    private isOneDayOnPopupReservation(popupReservation, value)
    {
        const ONE_DAY = "24h";
        const TWO_DAYS = "48h";

        return popupReservation && value === ONE_DAY ? TWO_DAYS : value;
    }
}
