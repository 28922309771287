<section class="menu" (mouseleave)="onScroll($event)">
  <div class="above-list-wrapper" *ngIf="!checkIfActiveCategoryIsKonto" (mouseleave)="onScroll($event)" (mouseenter)="isOver500MilFunc($event)">

    <div class="list-wrapper list-filter" *ngFor="let elem of activeMenuLinks" (mouseenter)="setAsHovered(elem)" [ngClass]="{ 'bigger-cursor-space': hoveredCategory }" (click)="isOver500mil = false">
      <span class="heading5" [ngClass]="{ 'font-style-light': !elem.filters && activeCategory }">
        <img class="filter-image" *ngIf="elem.image" [src]="elem.image" (click)="chooseCategory(elem.name, null)"/>
        <a [ngClass]="{ 'main-color': elem.promotion, 'text-link': hoveredCategory == elem }"
          [routerLink]="getLink(elem.link, null)" [queryParams]="getLinkQuery(elem.link,null)">
          {{ elem.name }}
        </a>
      </span>
    </div>

    <div class="filters-list-desktop hidden" [ngClass]="{ visible: isOver500mil && isMoreThanDesktopResolution() && hoveredCategory && hoveredCategory.attributes.length }">
      <div class="container">

        <div class="category-wrapper" *ngIf="hoveredCategory">
          <div class="category" *ngFor="let attribute of hoveredCategory.attributes">
            <div class="heading5 separator-one-half">
              {{ attribute.name }}
            </div>
            <ul class="sub-categories">
              <li *ngFor="let value of attribute.values"
                class="font-style-light sub-category-elem"
                [ngClass]="{ selected: isItemMenuSelected(attribute.linkTemplate, value.code)}">
                <a
                  class="flex"
                  [routerLink]="getLink(attribute.linkTemplate, value.code)"
                  [queryParams]="getLinkQuery(attribute.linkTemplate, value.code)"
                  (click)="onScroll(true)">
                  <span class="image" *ngIf="value.imageUrl">
                    <img [src]="value.imageUrl"/>
                  </span>
                  <span>
                    {{ value.value }}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="banner-wrapper">
          <div class="grid-item" *ngIf="hoveredCategory && hoveredCategory.banner">

            <div class="image-wrapper" *ngIf="hoveredCategory.banner['image']">
              <app-img
                [src]="hoveredCategory.banner['image']"
                [width]="[544, 544, 544]"
                [height]="[340, 340, 340]"
                [alt]="'Menu banner'"
                [lazy]="false"
                [placeholder]="false"
                [placeholderGray]="true"
              ></app-img>
            </div>

            <div class="text-wrapper">
              <p class="heading7 block-name body-text font-style-light" *ngIf="hoveredCategory.banner.text">
                {{ hoveredCategory.banner.text }}
              </p>
              <p class="heading7 block-caption" *ngIf="hoveredCategory.banner.caption">
                {{ hoveredCategory.banner.caption }}
              </p>
              <p class="text-link heading5" *ngIf="hoveredCategory.banner.callToActionText" [ngClass]="{ 'white-after': hoveredCategory.banner.captionForeColor === '#FFFFFF' }">
                <a [style.color]="hoveredCategory.banner.captionForeColor"
                  [routerLink]="'/' + urlTranslateService.routingTable.shop.listing"
                  [queryParams]="getLinkToProduct(hoveredCategory.banner.callToActionLink)">
                  {{ hoveredCategory.banner.callToActionText }}
                </a>
              </p>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</section>
