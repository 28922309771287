<ng-container *ngIf="sliderData$ | async as sliderData">
  <div class="title-section" *ngIf="title">
    <div class="title-wrapper">
      <div class="font-style-semi">{{ title }}</div>
      <div class="secondary-title">{{ secondaryTitle }}</div>
    </div>
    <div *ngIf="linkToMoreText" class="link-to-more text-link" [routerLink]="linkToMoreUrl">
      {{ linkToMoreText }}
    </div>
  </div>
  <div class="slider-wrapper" [ngClass]="{ isWithBanner: sliderData && isMainSlider }">
      <swiper [config]="swiperOpt" class="swiper slider-main" #swiperComponent>
        <ng-template *ngFor="let banner of sliderData; let i = index" swiperSlide>
          <div class="slider-wrapper-elem swiper-slide" [ngClass]="{ first: i == 0, 'tile-rwd-slide': true }">
            <ng-container [ngSwitch]="banner.type">
              <app-tile-banner
                *ngSwitchDefault
                [lazy]="i > 4"
                [banner]="banner"
                [width]="width"
                [height]="height"
                [isMainSlider]="isMainSlider"
              ></app-tile-banner>
            </ng-container>
          </div>
        </ng-template>
      </swiper>
  </div>
</ng-container>