import { Component, Input } from '@angular/core';
import { ContactPopupAbstractComponent } from 'app/module/sharedModules/shared/modals/contact-popup-abstract/contact-popup-abstract.component';
import { animateButton, getImageUrl } from '../../../../../utils/utilsFunctions';

@Component({
  selector: 'app-free-sample-popup',
  templateUrl: './free-sample-popup.component.html',
  styleUrls: ['./free-sample-popup.component.scss']
})
export class FreeSamplePopupComponent extends ContactPopupAbstractComponent
{
  public getImageUrl = getImageUrl;
  @Input() public product: any;

  public addToCart(): void
  {
    this.isLoading = true;
    animateButton('loading', this.addButton, this.renderer, this.changeDetector);
    const sample = this.getSample();
    if (sample)
    {
      this.cartService.addElementToCart('null', sample[0], 1).subscribe(this.onSuccess.bind(this), this.onError.bind(this));
    }
  }

  private getSample(): any
  {
    return this.product && this.product.products.filter(el => el.isSample == true);
  }
}
