<div [hidden]="!shouldShow" class="popup-wrapper">
  <div [ngClass]="{ wrapper: wishlist.products.length > 0 }" *ngIf="wishlist$ | async as wishlist; else emptyState">
    <ng-scrollbar class="list-wrapper" autoHeightDisabled="false">
      <div class="single-element" *ngFor="let item of wishlist.products">

        <div class="flex-wrapper-checkbox" (click)="setAsActive(item, $event)">
          <input type="checkbox" class="primary-checkbox" [checked]="isSelected(item)" (change)="changeSelected()"/>
          <span class="checkmark"></span>
        </div>

        <a [routerLink]="'/' + urlTranslateService.routingTable.shop.product + '/' + item.url">
          <app-img [src]="item.products[0].familyCode" [height]="[54, 54, 54]" [width]="[54, 54, 54]" [index]="0" [lazy]="false" [options]="{product: true}"></app-img>
        </a>

        <a [routerLink]="'/' + urlTranslateService.routingTable.shop.product + '/' + item.url">
          <div class="column name-column">
            <p class="font-style-semi">{{ item.name }}</p>
          </div>
        </a>

        <ng-container *ngIf="item.standardPrice != item.promotionPrice">
          <div class="column prices">
            <p class="promotion-price">
              {{ "singleProduct.from" | translate}} {{ item.promotionPrice | valueWithComma }} {{ "general.currency" | translate }}<ng-container *ngIf="item.volumeUnit != '' && item.volumeUnit">/</ng-container><span [innerHtml]="item.volumeUnit | removeSpaces"></span>
            </p>
            <p class="standard-price">
              {{ "singleProduct.from" | translate}} {{ item.standardPrice | valueWithComma }} {{ "general.currency" | translate }}<ng-container *ngIf="item.volumeUnit != '' && item.volumeUnit">/</ng-container><span [innerHtml]="item.volumeUnit | removeSpaces"></span>
            </p>
          </div>
        </ng-container>

        <!--todo: sprawdzic czy tutaj to dziala dobrze na jakims przykladzie-->
        <ng-container *ngIf="item.standardPrice == item.promotionPrice">
          <p class="standard-price-without-promotion">
            {{ "singleProduct.from" | translate}} {{ item.standardPrice | valueWithComma }}{{ "general.currency" | translate }}<ng-container *ngIf="item.volumeUnit != '' && item.volumeUnit">/</ng-container><span [innerHtml]="item.volumeUnit | removeSpaces"></span>
          </p>
        </ng-container>

        <div class="cancel-wrapper" (click)="removeElementFromCart(item)">
          <i class="ai ai-cancel"></i>
        </div>

        <div class="separator-big"></div>
      </div>
    </ng-scrollbar>

    <div class="button-wrapper" *ngIf="wishlist.products.length > 0">
      <div class="button secondary-button" (click)="compareItems()">
        {{ "favourite.compareSelected" | translate }}
      </div>
    </div>

    <p *ngIf="selectedItems.length < 2 && wishlist.products.length && compareTrigger" class="main-color compare-error">
      {{ "favourite.selectToCompareError" | translate }}
    </p>

    <ng-container *ngIf="wishlist.products.length == 0">
      <div class="wrapper-empty-state">
        <div class="image-wrapper">
          <i class="ai ai-heart"></i>
        </div>
        <p class="empty-state">{{ "favourite.noFavItems" | translate }}</p>
      </div>
    </ng-container>

  </div>

  <ng-template #emptyState>
    <div class="wrapper-empty-state">
      <div class="image-wrapper">
        <i class="ai ai-heart"></i>
      </div>
      <p class="empty-state">{{ "favourite.noFavItems" | translate }}</p>
    </div>
  </ng-template>
</div>
