import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NewsletterPopupComponent } from '@shared/shared/modals/newsletter-popup/newsletter-popup.component';
import { BlockService } from '../../block/shared/block.service';
import { FooterColumnElement } from 'app/model/interfaces';
import { ChatService } from '@service/chat/chat-service.service';

interface Links
{
  Link: string;
  Name: string;
}


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit
{

  @ViewChild(NewsletterPopupComponent, { static: true }) private newsletterPopup: NewsletterPopupComponent;
  @Input() public showFooterLinks: boolean;
  @Input() public isCheckout: boolean;
  @Input() public isFinalStepOfCheckout: boolean;

  public columns: FooterColumnElement[];
  public phoneNumbers: string[] = [];

  constructor(
    private chatService: ChatService,
    private blockService: BlockService)
  {
  }

  public ngOnInit(): void
  {
    const contact = this.blockService.getDataSync('contact').contact;
    const footer = this.blockService.getDataSync('footer');

    if (contact.ecommerce.primaryPhoneNumber)
    {
      this.phoneNumbers.push(contact.ecommerce.primaryPhoneNumber);
    }

    if (contact.ecommerce.secondaryPhoneNumber)
    {
      this.phoneNumbers.push(contact.ecommerce.secondaryPhoneNumber);
    }

    footer.columns.forEach(column => column.isExpanded = false);
    this.columns = footer.columns;
  }

  public showIntercome($event: MouseEvent): void
  {
    $event.stopPropagation();
    $event.preventDefault();
    this.chatService.open();
  }

  public openNewsletterPopup($event: any): void
  {
    $event.stopPropagation();
    this.newsletterPopup.openModal();
  }

}
