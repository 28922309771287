import { Injectable, OnDestroy } from '@angular/core';
import { ContentResultDTO, Newsletter } from '@dto';
import { Content, Aggregate, Messages, MessageItem } from 'app/model/interfaces';
import { ConfigService } from '@service/config/config.service';
import { HttpService } from '@service/http/http.service';
import { RollbarErrorHandler } from '@service/rollbar/rollbar';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BlockService implements OnDestroy
{
  private aggregateBaseUrl: string = this.configService.BlogUrl + '/Aggregate/' + this.configService.ShopID;

  private aggregate: BehaviorSubject<Aggregate> = new BehaviorSubject<Aggregate>(null);
  private blocks: BehaviorSubject<Content[]> = new BehaviorSubject<Content[]>(null);
  private errors: MessageItem[];
  private obs = new BehaviorSubject<boolean>(false);
  private aggregateDownloaded = new Subject<boolean>();

  constructor(private httpService: HttpService, private configService: ConfigService)
  {
    this.obs.next(false);
  }

  public getDataSync(idendifier: string): any
  {
    return this.aggregate.value[idendifier];
  }

  public get initialized(): boolean
  {
    return this.aggregate.value != null;
  }

  public getData(idendifier: string): Observable<any>
  {
    return this.aggregate.pipe(mergeMap((aggrega) =>
    {
      const data = aggrega[idendifier];

      if (data)  
      {
        return of(data);
      }
      else 
      {
        return of(null);
      }
    }));
  }

  public initialize(): Observable<any>
  {
    if (this.initialized)
    {
      return of(true);
    }
    else
    {
      if (this.obs.value == false)
      {
        this.obs.next(true);

        this.httpService.get(this.aggregateBaseUrl).subscribe((aggregate: Aggregate) => 
        {
          this.aggregate.next(aggregate);
          this.errors = this.getDataSync('messages');

          this.aggregateDownloaded.next(true);
          this.aggregateDownloaded.complete();
          this.aggregateDownloaded = null;
        });
      }

      if (this.aggregateDownloaded == null)
      {
        return of(true);
      }
      else
      {
        return this.aggregateDownloaded;
      }
    }
  }

  public ngOnDestroy(): void
  {
    this.blocks.complete();
  }

  public getError(idendifier: string): MessageItem
  {
    if (this.errors)
    {
      const tempError: MessageItem = this.errors.filter(o => o.code == idendifier)[0];
      tempError['message'] = tempError['Message'];
      tempError['type'] = tempError['Type'];
      return tempError;
    }
  }
}
