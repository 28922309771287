<picture  *ngIf="url"  #picture  [ngClass]="{ notLoadedImage: picNotFound, loaded: isImageLoaded }">
  <source [srcset]="url + '&format=webp' + (suffix ? suffix : '')" type="image/webp"/>
  <source [srcset]="url + '&format=jpg' + (suffix ? suffix : '')" type="image/jpg"/>

  <img
      [ngClass]="{ hidden: !isImageLoaded, superHidden: !isImageLoaded && _placeholderGray}"
      [src]="url + '&format=jpg'"
      (load)="onImageLoaded()"
      (error)="onError()"
      [alt]="alt ? alt : ''"
      [loading]="lazy ? 'lazy' : 'eager'"/>
</picture>

<div class="placeholder" *ngIf="_placeholderIcon" [ngStyle]="{'padding.px': placeholderPadding, 'height.px': pHeight, 'width.px': pWidth}">
  <img [src]="'/assets/images/empty-pic.svg'" />
</div>

<div class="placeholderGray" *ngIf="_placeholderGray" [ngStyle]="{'height.px': pHeight, 'width.px': pWidth}">
</div>

<div class="placeholder" *ngIf="picNotFound" [ngStyle]="{'height.px': pHeight, 'width.px': pWidth}">
  <img [src]="'/assets/icons/no-image.svg'" />
</div>
