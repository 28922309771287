import { ElementSchemaRegistry } from "@angular/compiler";
import { Injectable } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CustomerClientService } from "@service/client/customer-client.service";
import { ConfigService } from "@service/config/config.service";
import { BlockService } from "@shared/block/shared/block.service";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { subscriptionLogsToBeFn } from "rxjs/internal/testing/TestScheduler";

@Injectable()
export class TranslationLoaderResolver implements Resolve<any>
{

    constructor(private translate: TranslateService, private blockService: BlockService, private configService: ConfigService, private customerService: CustomerClientService)
    {
    }

    resolve(): Observable<any>
    {
        let firstObs: any;
        let secondObs: any;

        if (this.customerService.customerSubject.value)
        {
            firstObs = of(true);
        }
        else
        {
            firstObs = new Subject<boolean>();

            this.customerService.customerSubject.subscribe({
                next: o => 
                {
                    if (o)
                    {
                        firstObs.next(true);
                        firstObs.complete();
                    }
                },
                error: err =>
                {
                    window.alert(err);
                }
            });

            this.customerService.getCustomer().subscribe();
        }


        if (this.blockService.initialized)
        {
            secondObs = of(true);
        }
        else
        {
            secondObs = new Subject<boolean>();

            this.blockService.initialize().subscribe({
                next: () =>
                {
                    this.translate.setDefaultLang(this.configService.LowDomain);
                    this.translate.use(this.configService.LowDomain);
                    secondObs.next(true);
                    secondObs.complete();
                },
                error: err =>
                {
                    window.alert(err);
                }
            });
        }

        return combineLatest([firstObs, secondObs]);
    }

}