import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Banner } from '../../../../../model/banner/banner';

@Component({
  selector: 'app-standard-banner',
  templateUrl: './standard-banner.component.html',
  styleUrls: ['./standard-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardBannerComponent extends Banner
{
  @Input() public isMainSlider: any;
  @Input() public height: number[];
  @Input() public width: number[];
}
