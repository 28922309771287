import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { UrlHelperService } from "@service/helpers/url-helper.service";
import { ScrollPositionService } from "@service/scrollPosition.service";
import { WindowRefService } from "@service/window-service/window-ref.service";

@Injectable({
  providedIn: "root",
})
export class PopupService
{
  private activePopups: Set<string> = new Set<string>();
  private reservedNames: Set<string> = new Set<string>();
  private params: any;

  constructor(private router: Router, private location: Location, private urlHelper: UrlHelperService, private windowRef: WindowRefService, private scrollPositionService: ScrollPositionService)
  {

  }

  public isActivePopup(popup?: string): boolean
  {
    if (popup)
    {
      return this.activePopups.has(popup);
    }

    return this.activePopups.size > 0;
  }

  public hasReservedNames(): boolean
  {
    return this.reservedNames.size > 0;
  }

  public openPopup(active: string, disableReload = false): void
  {
    if (!this.isActivePopup(active) && active !== "")
    {
      this.activePopups.add(encodeURIComponent(active));

      if (!disableReload)
      {
        this.changeState();
      }
    }
  }

  public closePopup(name: string, disableReload: boolean = false, replaceUrl: boolean = false): void
  {
    this.activePopups.delete(encodeURIComponent(name));

    if (!disableReload)
    {
      this.location.back();
    }
    else if (replaceUrl)
    {
      this.changeState(true, true);
    }
  }

  public clear()
  {
    this.activePopups.clear();
    this.changeState();
  }

  public isActiveParam(type: string, name: string): boolean
  {
    const search = window.location.search.substr(1).split("&");

    for (let query of search)
    {
      let q = query.split("=");

      if (q[0] === type && q[1] === name) return true;
    }

    return false;
  }

  public reserveName(name: string): void
  {
    const modalName = encodeURIComponent(name);

    if (this.reservedNames.has(modalName))
    {
      console.warn(`Duplicated modal name: ${modalName}`);
    }
    else
    {
      this.reservedNames.add(modalName);
    }
  }

  public clearName(name: string): void
  {
    const modalName = encodeURIComponent(name);

    if (this.reservedNames.has(modalName))
    {
      this.reservedNames.delete(modalName);
    }
  }

  private changeState(getQueryParamsFromUrl: boolean = true, replaceUrl: boolean = false): void
  {
    if (getQueryParamsFromUrl)
    {
      this.params = this.getQueryParams();
    }

    const url = this.router
      .createUrlTree([], {
        queryParams: {
          ...this.params,
          m: this.activePopups.size ? [...this.activePopups] : null,
        },
        queryParamsHandling: "merge",
        preserveFragment: true,
      })
      .toString();

    if (replaceUrl)
    {
      this.location.replaceState(url);
    }
    else
    {
      this.scrollPositionService.addPosition(url);
      this.location.go(url);
    }
  }

  private getQueryParams(): Params
  {
    return this.urlHelper.encodeParams(this.windowRef.getNativeWindow().location.search);
  }
}
