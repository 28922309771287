import { NgModule } from "@angular/core";
import { DirectivesModule } from "@shared/directives/directives.module";
import { ExtraModule } from "@shared/extraModuleShared/extraModule.module";
import { SharedModule } from "../sharedModules/shared/shared.module";
import { ShopRoutingModule } from "@shop/shop-routing.module";
import { SwiperModule } from "swiper/angular";
import { CompareComponent } from "./compare/compare.component";
import { MainComponent } from "./main/main.component";
import { TileRwdBoxComponent } from "./main/tile-rwd-box/tile-rwd-box.component";
import { ShopComponent } from "./shop.component";
import { PayUScriptResolver } from "../resolver/payu-script-resolver.service";

@NgModule({
  declarations: [
    ShopComponent,
    MainComponent,
    CompareComponent,
    TileRwdBoxComponent,
  ],
  imports: [
    SharedModule,
    SwiperModule,
    ShopRoutingModule,
    ExtraModule,
    DirectivesModule,

  ],
  providers: [PayUScriptResolver],
})
export class ShopModule { }
