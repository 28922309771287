import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductComponent } from '@shop/product/product.component';

const routes: Routes = [
  {
    path: '', component: ProductComponent // todo: niewybrany przedmiot wroc do listy czy inne zachowanie ?
  },
  {
    path: ':product', component: ProductComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule
{
}
