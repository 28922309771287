import
{
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { ShopProductFamily } from "@dto";
import { CartService } from "@service/cart/cart.service";
import { ShopModeService } from "@service/helpers/shop-mode.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import
{
  getImageUrl,
  showAfterSecond,
} from "../../../../../utils/utilsFunctions";
import { SwiperOptions } from "swiper";
import { PopupService } from "@service/popup/popup.service";
import { Resolutions } from "@resolutions";
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { SwiperConfigService } from "@service/swiper-config.service";


@Component({
  selector: "app-product-add-item-popup",
  templateUrl: "./product-add-item-popup.component.html",
  styleUrls: ["./product-add-item-popup.component.scss"],
})
export class ProductAddItemPopupComponent implements OnInit, OnDestroy
{
  @Input() public product: ShopProductFamily[];
  @Input() private name: string = "";

  public showForm: boolean = false;
  public hideAccessories: boolean = false;
  public boughtItems: any[];
  public tempBoughtItems: any[];
  public accessoryProduct: any[];
  public getImageUrl = getImageUrl;
  public loaded = [];
  public isLoadingIndex: number;
  public swiperOpt: SwiperOptions;

  constructor(
    private router: Router,
    public windowRef: WindowRefService,
    private changeDet: ChangeDetectorRef,
    private shopModeService: ShopModeService,
    private cartService: CartService,
    private popupService: PopupService,
    public urlTranslateService: UrlTranslateService,
    private swiperCo: SwiperConfigService
  )
  {
    this.swiperOpt = this.swiperCo.getConfig(
      [0, Resolutions.resolutionTablet],
      [16, 0],
      [46, 46],
      null,
      [1, 3]
    );

    this.swiperOpt.observeParents = true;
    this.swiperOpt.navigation = true;
  }

  public openModal(boughtItems: any[], accessoryProduct: any[], disableReload?: boolean, hideAccessories?: boolean): void
  {
    this.boughtItems = boughtItems;
    this.boughtItems.forEach(el =>
    {
      if (el.selectedSize)
      {
        el.summaryPrice = el.promotionPrice * el.selectedSize;
      } else
      {
        el.summaryPrice = el.promotionPrice;
      }
    });
    this.tempBoughtItems = [];
    this.accessoryProduct = accessoryProduct;
    this.showForm = true;
    this.hideAccessories = hideAccessories;
    this.windowRef.blockScroll();
    this.popupService.openPopup(this.name, !!disableReload);

    showAfterSecond(100, this.loaded, this.changeDet);
    this.changeDet.detectChanges();
  }

  public closeModal($event?: any, disableReload?: boolean): void
  {
    this.showForm = false;
    this.windowRef.unBlockScroll();
    this.loaded['loaded'] = false;
    this.popupService.closePopup(this.name, !!disableReload);

    this.changeDet.detectChanges();
  }

  public isSelected(product: any): boolean
  {
    return this.tempBoughtItems.filter(el => el.name == product.name).length > 0;
  }

  public goToCheckout(): void
  {
    this.closeModal(null, true);
    this.router.navigateByUrl(`/${this.urlTranslateService.routingTable.checkout.base}`);
  }

  public addToCart(product, isSelected: boolean, index: number): void
  {
    this.isLoadingIndex = index;
    if (isSelected)
    {
      this.cartService.removeProductFromCart('null', product).subscribe(res =>
      {
        this.tempBoughtItems = this.tempBoughtItems.filter(el => el != product);
        this.isLoadingIndex = null;
        this.changeDet.detectChanges();
      });
    } else
    {
      this.cartService.addElementToCart('null', product, 1).subscribe(res =>
      {
        this.tempBoughtItems.push(product);
        this.isLoadingIndex = null;
        this.changeDet.detectChanges();
      });
    }
  }

  public openInNewCard(virtualTourLink: string, $event): void
  {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.windowRef.isWindowLoaded())
    {
      this.windowRef.getNativeWindow().open(`/${this.urlTranslateService.routingTable.shop.product}/` + virtualTourLink, this.shopModeService.isKioskMode() ? '_self' : '_blank');
    }
  }

  @HostListener("window:popstate", ['$event'])
  private backButtonHandler(): void
  {
    if (
      !this.popupService.isActiveParam("m", this.name) &&
      this.popupService.isActivePopup(this.name)
    )
    {
      this.closeModal(null, true);
    }
  }

  ngOnInit()
  {
    this.popupService.reserveName(this.name);
  }

  ngOnDestroy()
  {
    this.popupService.clearName(this.name);
  }
}
