import { Component, Input, OnInit } from "@angular/core";
import { Params } from "@angular/router";
import { UrlHelperService } from "@service/helpers/url-helper.service";
import { UrlTranslateService } from '@service/helpers/url-translate.service';

@Component({
  selector: "app-chips-item",
  templateUrl: "./chips-item.component.html",
  styleUrls: ["./chips-item.component.scss"],
})
export class ChipsItemComponent implements OnInit
{
  @Input() name: string;
  @Input() url: string;
  queryParams: Params;

  constructor(
    private urlHelper: UrlHelperService,
    public urlTranslateService: UrlTranslateService
  ) { }

  ngOnInit(): void
  {
    this.queryParams = this.urlHelper.encodeParams(this.url);
  }
}
