import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, } from "@angular/core";
import { Autoplay, SwiperOptions } from "swiper";
import { AutoplayOptions } from "swiper/types/modules/autoplay";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Observable, of } from "rxjs";
import { Banners, ResponsiveBanner, SliderOptions } from "app/model/interfaces";
import { Resolutions } from "@resolutions";
import { SwiperConfigService } from "@service/swiper-config.service";
import { BlockService } from "@shared/block/shared/block.service";

SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
  selector: "app-main-slider",
  templateUrl: "./main-slider.component.html",
  styleUrls: ["./../slider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSliderComponent
{
  public swiperOpt: SwiperOptions;
  public sliderData$: Observable<ResponsiveBanner[]>;

  @Input() public height: number[];
  @Input() public width: number[];

  constructor(private swiperCo: SwiperConfigService, private blockService: BlockService)
  {
    const banners: Banners = this.blockService.getDataSync('banner').banners;
    const bannerOptions: SliderOptions = this.blockService.getDataSync('banner').options;

    this.swiperOpt = this.swiperCo.getConfig(
      [0, Resolutions.resolutionDesktop],
      null,
      [30, 30],
      null,
      [1, 1],
      [1, 1]
    );
    delete this.swiperOpt.grid;
    delete this.swiperOpt.slidesPerView;
    this.swiperOpt.loop = false;
    this.swiperOpt.speed = bannerOptions.transitionSpeed;
    this.swiperOpt.freeMode = false;
    this.swiperOpt.autoplay = {
      delay: bannerOptions.autoPlayDelay,
      stopOnLastSlide: bannerOptions.autoPlayStopOnLastSlide,
      disableOnInteraction: bannerOptions.autoPlayDisableOnInteraction,
      reverseDirection: bannerOptions.autoPlayReverseDirection,
      waitForTransition: bannerOptions.autoPlayWaitForTransition,
    };;

    this.sliderData$ = of(banners.items.filter((item) => item.enabled));
  }
}
