export abstract class GeneralError extends Error
{

  private readonly messageId: string;

  protected constructor(message: string, private cause: Error = null, messageId: string = null)
  {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.messageId = messageId;
  }

  public getMessage(): string
  {
    return this.message;
  }

  public getMessageId(): string
  {
    return this.messageId;
  }

  public getCause(): Error
  {
    return this.cause;
  }

}
