import { Injectable } from '@angular/core';
import { RollbarErrorHandler } from '@service/rollbar/rollbar';
import { LocalStorageService } from '@service/storage/local-storage/local-storage.service';
import { Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageService
{
  private localStorage: Storage;
  private sessionStorage: Storage;
  private defaultStorage: Storage;
  private listingViewSubject: Subject<string> = new Subject();
  public readonly listingView$: Observable<string> = this.listingViewSubject.asObservable();
  private storageInitialized: Subject<boolean> = new ReplaySubject(1);
  public readonly storageInit$: Observable<boolean> = this.storageInitialized.asObservable();

  constructor(
    private storageService: LocalStorageService,
    private rollbarService: RollbarErrorHandler,
  )
  {
    this.localStorage = this.storageService.getLocalStorage();
    this.sessionStorage = this.storageService.getSessionStorage();
    this.defaultStorage = this.localStorage;
    if (this.defaultStorage)
    {
      this.storageInitialized.next(true);
    }
  }

  public setStorageSystem(storage: string): void
  {
    if (storage == 'local')
    {
      this.defaultStorage = this.localStorage;
    }
    if (storage == 'session')
    {
      this.defaultStorage = this.sessionStorage;
    }
  }

  public updateListingViewSubject(s: string): void
  {
    this.listingViewSubject.next(s);
  }

  public getSync(key: string): any
  {
    if (!this.defaultStorage)
    {
      return null;
    }

    return this.parse(this.defaultStorage.getItem(key));
  }

  public get<T>(key: string): Observable<T>
  {
    if (!this.defaultStorage)
    {
      return of();
    }
    try
    {

      const value = this.defaultStorage.getItem(key);

      if (!value)
      {
        return of(null);
      }

      return of(this.parse(value));

    }
    catch (e)
    {
      this.rollbarService.handleWarning('get item error', { key: key, cause: e.toString() });
      return throwError(null);
    }
  }

  public set<T>(key: string, value: T): void
  {
    if (!this.defaultStorage)
    {
      return;
    }
    try
    {
      this.defaultStorage.setItem(key, JSON.stringify(value));
    }
    catch (e)
    {
      this.rollbarService.handleWarning('set item error, key:' + key + ' value:' + value, {
        statusCode: null,
        url: null,
        method: null,
        params: null,
        headers: null,
        body: value,
        message: 'set item error, key:' + key,
        trace: null
      });
    }
  }

  public remove(key: string): void
  {
    if (!this.defaultStorage)
    {
      return;
    }

    if (this.defaultStorage == this.sessionStorage)
    {
      this.defaultStorage.clear();
    }

    this.defaultStorage.removeItem(key);
  }

  public reset(): void
  {
    this.localStorage.clear();
    this.sessionStorage.clear();
  }

  private parse<T>(value: string): T
  {
    try
    {
      return JSON.parse(value);
    }
    catch (e)
    {
      return null;
    }
  }

}
