import { Component, Input } from "@angular/core";

@Component({
  selector: "app-tooltip-custom",
  templateUrl: "./tooltip-custom.component.html",
  styleUrls: ["./tooltip-custom.component.scss"],
})
export class TooltipCustomComponent
{
  @Input() public title: string;
  @Input() public description: string = "";
  @Input() public width: string = "300px";
  @Input() public height: string = "200px";
  @Input() public show: boolean = false;
  @Input() public block;
  @Input() top: string = "36px";
  @Input() bottom: string;
  @Input() right: string;
  @Input() left: string = "30px";
}
