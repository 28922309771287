<section>
  <h2 class="title" *ngIf="title">
    {{ "tileBox.collection" | translate }} {{ title }}
  </h2>
  <div class="description" *ngIf="description">
    {{ description }}
  </div>
  <div *ngIf="videoLink && (!isMobileView || !videoMobileLink)" class="video" style="padding:56.25% 0 0 0;position:relative;">
    <iframe [src]="videoLink" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="ARTE - ASCOT #1">
    </iframe>
    <!-- <script src="https://player.vimeo.com/api/player.js"></script> -->
  </div>
  <div *ngIf="videoMobileLink && isMobileView" class="video" style="padding:125% 0 0 0;position:relative;">
    <iframe [src]="videoMobileLink" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="ARTE - ASCOT #1">
    </iframe>
    <!-- <script src="https://player.vimeo.com/api/player.js"></script> -->
  </div>
  <div class="block-wrapper" #dropdownSection>
    <div class="row-wrapper">
      <div class="main-wrapper" *ngFor="let elem of allBlocks" [ngClass]="columns == 2 ? 'two-columns' : 'three-columns'">
        <div class="header-wrapper">
          <div class="picture-wrapper">
            <img [src]="isProduct? configService.ImageUrl + elem?.imageUrl : elem?.imageUrl"/>
          </div>
          <h3 class="heading5">
            {{ elem?.caption }}
          </h3>
        </div>
        <div class="text-wrapper">
          {{ isProduct ? elem?.description : elem?.text }}
        </div>
        <div class="link-wrapper" *ngIf="isProduct ? elem?.articleUrl : elem?.link">
          <a
            *ngIf="isProduct"
            [routerLink]="elem?.articleUrl"
            [fragment]="elem?.articleSection"
            >{{ "tileBox.getMore" | translate }}
          </a>
          <a *ngIf="!isProduct" [routerLink]="elem?.link">
            {{ "tileBox.getMore" | translate }}
          </a>
        </div>
      </div>
    </div>
    <div
      *ngIf="allBlocks.length > 4 && showMore"
      [ngClass]="{ 'expanded': rowExpanded }"
      [appExpand]="dropdownSection"
      [elements]="allBlocks.length"
      [perPage]="4"
      (toggleSection)="toggleBlocks($event)"
    >
      <button *ngIf="!rowExpanded" class="button secondary-button show-more-opinions">
        {{ "showMore" | translate }}
      </button>
    </div>
  </div>
</section>
