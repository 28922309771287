  <div class="slider-wrapper" #sliderWrapper>
    <div class="swiper-container bigger-container" [ngClass]="{ hidden: !showSlider }" *ngIf="imgArray.length > 0">
      <swiper [config]="config ? config : defaultConfigForGallery" [pagination]="pagination" class="swiper" #swiperComponent>
        <ng-template *ngFor="let img of imgArray; let i = index" class="swiper-slide" swiperSlide>
          <div class="image-wrapper" [ngClass]="{ selected: selectedIndex == i }">
            <ng-container *ngIf="!showThumbs && img.name && product && height.length > 1 && width.length > 1">
              <div [ngClass]="{ wykladzina: product.isCovering, 'two-image-wrapper': !img.tempSrc, 'no-image': img['noImage'] }">
                <img [style.visibility]="!isImageLoaded ? 'hidden' : 'visible'"
                  [src]="
                    img.tempSrc
                      ? img.tempSrc
                      : getImageUrl(
                          product.familyCode,
                          product.images[i].index
                            .toString()
                            .concat(
                              product.isCovering ||
                                (fillCrop && i > 0)
                                ? '&resizeType=Fill_Crop'
                                : ''
                            ),
                          width.length > 2
                            ? [width[0], width[1], width[2], width[3]]
                            : [width[0], width[1], width[2]],
                          img.index == 0 &&
                            !product.isCovering
                            ? [height[0] - 8, height[1] - 8, height[2] - 8]
                            : [height[0], height[1], height[2]],
                          null,
                          null,
                          null,
                          false
                        )
                  "
                  (error)="onError(img)"
                  (click)="setAsMainImage(i)"
                  (load)="setImageAsLoaded()"
                  [alt]="getImageAlt(img)"
                />
                <div class="main-image-placeholder" *ngIf="!isImageLoaded && placeholder">
                  <img [src]="'/assets/images/empty-pic.svg'" />
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="showThumbs">
              <div class="thumbs-images-wrapper" (error)="onError(img)" (click)="setAsMainImage(i)">
                <app-img [src]="img.thumb" [lazy]="i > 3" [width]="width" [height]="height" [alt]="getImageAlt(img)"></app-img>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </swiper>
      <button *ngIf="notAllImagesVisible()"
        class="slider-button-prev"
        (click)="previousSlide()"
        [ngClass]="{ inactive: isFirstSlide() }"
      ></button>
      <button *ngIf="notAllImagesVisible()"
        class="slider-button-next"
        (click)="nextSlide()"
        [ngClass]="{ inactive: isLastSlide() }"
      ></button>
    </div>
  </div>