import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import
{
  Customer,
  CustomerConnectDTO,
  CustomerContactRequestDTO,
  CustomerContactRequestVO,
  CustomerPasswordResetDTO,
  CustomerProductReviewDTO,
  CustomerUpdateDTO,
  Session,
  SessionDTO,
  StatusResultDTO,
  CustomerPasswordResetRequestValidateDTO,
  CustomerPasswordChangeDTO,
  CustomerRegisterDTO
} from '@dto';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CartService } from '../cart/cart.service';
import { ConfigService } from '../config/config.service';
import { AuthHttpService } from '../http/auth-http/auth-http.service';
import { HttpService } from '../http/http.service';
import { AuthService } from '../session/auth/auth.service';
import { TimestampService } from '../timestamp/timestamp.service';
import { WishlistService } from '../wishlist/wishlist.service';
import { UrlHelperService } from '@service/helpers/url-helper.service';
import { AnalyticsService } from '@service/analytics/abstract/analytics.service';
import { getCamelCaseVariableName } from 'app/utils/utilsFunctions';
import { ConsentType } from 'app/model/interfaces';

enum Paths
{
  CONNECT = '/connect',
  REGISTER = '/register',
  PASSWORD = '/password',
  LOGIN = '/login'
}

@Injectable({
  providedIn: 'root'
})
export class CustomerClientService implements OnDestroy
{
  public customerSubject: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);
  public readonly customer$: Observable<Customer> = this.customerSubject.asObservable();
  private readonly baseUrl: string;
  private readonly apiUrl: string;
  public get isUserLogged(): boolean
  {
    return this.customerSubject.value && this.customerSubject.value.isRegistered;
  }
  public loadingApp: boolean = true;
  public loadingAppSub: Subscription;

  constructor(
    private configService: ConfigService,
    private httpService: HttpService,
    private router: Router,
    private cartService: CartService,
    private windowRef: WindowRefService,
    private analyticsService: AnalyticsService,
    private urlHelper: UrlHelperService,
    private wishlistService: WishlistService,
    private timestampService: TimestampService,
    private authService: AuthService,
    private authHttpService: AuthHttpService
  )
  {
    this.baseUrl = configService.ApiUrl;
    this.apiUrl = configService.ApiUrl + 'customer/swiatdywanowshop/customer';
    this.loadingAppSub = this.windowRef.loadingAppSubject.subscribe(res =>
    {
      this.loadingApp = res;
    });

    this.timestampService.timestamp$.subscribe(() =>
    {
      if (this.loadingApp)
      {
        return false;
      }
      if (this.windowRef.getDocument().visibilityState == 'visible')
      {
        this.getCustomer().subscribe((success) =>
        {
        }, (error) =>
        {
          if (error.status === 404)
          {
            this.logout();
          }
        });
      }
    });
  }

  public updateCustomerSubject(customerData): void
  {
    this.customerSubject.next(customerData);
    this.cartService.updateCartSubject(customerData.cart);
  }

  public ngOnDestroy(): void
  {
    this.customerSubject.complete();
    this.loadingAppSub.unsubscribe();
  }

  private handleSession(session: any): void
  {
    this.authService.setGuid(session.customer.guid);
    this.authService.setToken(session.token);
    this.wishlistService.updateWishlistSubject(session.customer.wishlist);
    this.updateCustomerSubject(session.customer);
  }

  public getCustomer(attempt: number = 0): Observable<any>
  {
    const queryObject = this.urlHelper.getQueryParameters(window.location.href, "ads_");
    const adsQuery = this.urlHelper.parseQueryObjectToString(queryObject, true, '&');
    const totalQuery = adsQuery;

    return this.authHttpService.get(`${this.apiUrl}?${totalQuery}`).pipe(
      map((res: any) =>
      {
        return res ? getCamelCaseVariableName(res, true).session : null;
      }),
      tap((session: Session) =>
      {
        if (session)
        {
          if (!this.customerSubject.value || !this.customerSubject.value.timestamp || !session.customer.timestamp || (session.customer.timestamp != this.customerSubject.value.timestamp))
          {
            this.handleSession(session);
          }
        }
        else
        {
          if (attempt < 3)
          {
            this.getCustomer(attempt + 1).subscribe();
          }
          else
          {
            throw new Error('Error while getting customer');
          }
        }
      }));
  }

  public logout(): void
  {
    this.authService.removeToken();
    this.authService.removeGuid();
    this.getCustomer().subscribe();
    this.router.navigateByUrl('/', { replaceUrl: true });
  }

  public login(dto: CustomerUpdateDTO): Observable<string>
  {
    if (!dto.rememberPassword)
    {
      this.authService.setStorage('session');
    }

    return this.authHttpService.post(this.apiUrl + Paths.LOGIN, dto).pipe(
      map((res: SessionDTO) => getCamelCaseVariableName(res, true).session),
      tap((session: any) => this.handleSession(session))
    );
  }

  public register(dto: CustomerRegisterDTO): Observable<Customer>
  {
    this.analyticsService.registerCustomerRegistration();

    return this.authHttpService.post(this.apiUrl + Paths.REGISTER, dto).pipe(
      map(res => getCamelCaseVariableName(res, true).session.customer),
      tap(customer => this.updateCustomerSubject(customer)));
  }

  public externalAuthenticatorConnect(dto: CustomerConnectDTO): Observable<Customer>
  {
    return this.authHttpService.post(this.apiUrl + Paths.CONNECT, dto).pipe(
      map(res => getCamelCaseVariableName(res, true).session),
      tap((session: any) => this.handleSession(session))
    );
  }

  public externalAuthenticatorConnectionDelete(providerName: string): Observable<Customer>
  {
    return this.authHttpService.delete(this.apiUrl + Paths.CONNECT + '/' + providerName).pipe(
      map(res => getCamelCaseVariableName(res, true).session),
      tap((session: any) => this.handleSession(session))
    );
  }

  public changePassword(dto: CustomerPasswordChangeDTO): Observable<Customer>
  {
    return this.authHttpService.post(this.apiUrl + Paths.PASSWORD + '/change', dto).pipe(
      map((session: SessionDTO) => getCamelCaseVariableName(session, true).session.customer));
  }

  public recoverPassword(dto: CustomerUpdateDTO): Observable<boolean>
  {
    return this.authHttpService.post(this.apiUrl + Paths.PASSWORD + '/reset/request', dto).pipe(map((status: StatusResultDTO) =>
    {
      return getCamelCaseVariableName(status, true).status;
    }));
  }

  public checkPasswordRecoveryToken(dto: CustomerPasswordResetRequestValidateDTO): Observable<StatusResultDTO>
  {
    return this.authHttpService.post(this.apiUrl + Paths.PASSWORD + '/reset/request/validate', dto).pipe(
      map(res => getCamelCaseVariableName(res, true)));
  }

  public sendContactRequest(dto: CustomerContactRequestVO): Observable<StatusResultDTO>
  {
    const dtoWrapper: CustomerContactRequestDTO = {
      customerContactRequest: dto
    };

    return this.authHttpService.put(this.apiUrl + '/contact/request', dtoWrapper).pipe(map(res => getCamelCaseVariableName(res, true)));
  }

  public createPasswordRecoveryNewPassword(dto: CustomerPasswordResetDTO): Observable<string>
  {
    return this.authHttpService.post(this.apiUrl + Paths.PASSWORD + '/reset', dto).pipe(
      map(res => getCamelCaseVariableName(res, true).session.token),
      tap(token => this.authService.setToken(token)));
  }

  public update(dto: CustomerUpdateDTO): Observable<Customer>
  {
    if (dto.addConsents && dto.addConsents.filter(o => o.toString() == ConsentType.Newsletter).length > 0)
    {
      this.analyticsService.registerCustomerNewsletterSubscription(this.customerSubject.value, dto.email);
    }
    if (dto.endConsents && dto.endConsents.filter(o => o.toString() == ConsentType.Newsletter).length > 0)
    {
      this.analyticsService.registerCustomerNewsletterUnsubscription(this.customerSubject.value, dto.email);
    }

    return this.authHttpService.post(this.apiUrl, dto).pipe(
      map(res => getCamelCaseVariableName(res, true).session.customer),
      tap((customer: any) => this.updateCustomerSubject(customer)));
  }

  public customerProductReviewAdd(dto: CustomerProductReviewDTO): Observable<Customer>
  {
    return this.authHttpService.post(this.apiUrl + '/product/review', dto).pipe(
      map((session: Session) => getCamelCaseVariableName(session, true).session.customer),
      tap((customer: any) => this.customerSubject.next(customer)));
  }

  public customerProductReviewDelete(familyCode: string): Observable<Customer>
  {
    return this.authHttpService.delete(this.apiUrl + '/product/review/' + familyCode).pipe(
      map((session: Session) => getCamelCaseVariableName(session, true).session.customer));
  }

  public registerByFacebook(dto: CustomerUpdateDTO): Observable<Customer>
  {
    return this.httpService.post(this.apiUrl + Paths.CONNECT, dto).pipe(map(res => getCamelCaseVariableName(res, true)));
  }

  public loginByFacebook(dto: CustomerUpdateDTO): Observable<Customer>
  {
    return this.httpService.post(this.baseUrl + Paths.CONNECT, dto).pipe(map(res => getCamelCaseVariableName(res, true)));
  }

  public assignFacebookAccount(dto: CustomerUpdateDTO): Observable<Customer>
  {
    return this.authHttpService.post(this.apiUrl + Paths.CONNECT, dto).pipe(map(res => getCamelCaseVariableName(res, true)));
  }

  public removeFacebookAccount(): Observable<boolean>
  {
    return this.authHttpService.delete(this.apiUrl + '/me/facebook');
  }

  public registerByGoogle(dto: CustomerUpdateDTO): Observable<Customer>
  {
    return this.authHttpService.post(this.apiUrl + Paths.CONNECT, dto).pipe(map(res => getCamelCaseVariableName(res, true)));
  }

  public loginByGoogle(dto: CustomerUpdateDTO): Observable<Customer>
  {
    return this.httpService.post(this.apiUrl + Paths.CONNECT, dto).pipe(map(res => getCamelCaseVariableName(res, true)));
  }

  public assignGoogleAccount(dto: CustomerConnectDTO): Observable<Customer>
  {
    return this.authHttpService.post(this.apiUrl + Paths.CONNECT, dto).pipe(map(res => getCamelCaseVariableName(res, true)));
  }

  public removeGoogleAccount(): Observable<boolean>
  {
    return this.authHttpService.delete(this.apiUrl + '/me/google');
  }
}
