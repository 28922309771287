<div class="description-inline-wrapper" (click)="goToLink(banner)">
  <div class="description-wrapper" [ngStyle]="transformColor(banner.options.selected.backgroundColor, banner.options.selected.backgroundOpacity)" *ngIf="banner.options?.selected?.captionBoxEnabled">
    <p [ngStyle]="{ 'color': banner.options.selected.fontColor }" class="heading4 block-name">
      {{ banner.options.selected.name }}
    </p>
    <p [ngClass]="{ 'main-slider': isMainSlider }" class="block-caption font-style-semi" [ngStyle]="{ 'color': banner.options.selected.fontColor }">
      {{ banner.options.selected.caption }}
    </p>
    <p class="text-link heading5" [ngClass]="{ 'white-after': banner.options.selected.captionForeColor === '#FFFFFF' }">
      <a (click)="goToLink(banner)" [ngStyle]="{ 'color': banner.options.selected.fontColor }">
        {{ banner.options.selected.callToActionText }}
      </a>
    </p>
  </div>
  <div class="tooltip-banner" *ngIf="showCaptionClipboard">
    {{ banner.options.selected.callToActionClipboardSuccessMessage }}
  </div>
  <ng-container *ngIf="isMainSlider">
    <div class="image-wrapper image-main-slider minus-index">
      <app-img [src]="banner.image.url" [newOptions]="banner.image" [placeholder]="false"></app-img>
    </div>
  </ng-container>
  <ng-container *ngIf="!isMainSlider">
    <div class="image-wrapper" [ngClass]="{ insparanz: !isMainSlider }">
         <app-img *ngIf="banner.type !== 'Text'" [ngClass]="{ 'double-width': false }" [src]="banner.image.url" [newOptions]="banner.image" [placeholder]="false"></app-img>
      <div class="text-slider-wrapper">
        <p class="heading7 block-caption" [ngClass]="{ 'white-after': banner?.captionForeColor === '#FFFFFF' }" *ngIf="banner?.caption && !banner?.text">
          {{ banner?.caption }}
        </p>
        <p class="text-link heading5" *ngIf="banner?.callToActionText" [ngClass]="{ 'white-after': banner?.captionForeColor === '#FFFFFF' }">
          <a [style.color]="banner?.captionForeColor" [routerLink]="'/' + urlTranslateService.routingTable.shop.listing" [queryParams]="getLinkToProduct(banner?.callToActionLink)">
            {{ banner?.callToActionText }}
          </a>
        </p>
      </div>

      <p *ngIf="banner.type === 'Text'" class="heading7 block-name body-text font-style-light">
        {{ banner?.text }}
      </p>
    </div>
  </ng-container>
</div>
