import
{
  ChangeDetectionStrategy,
  Component,
  Input,

} from "@angular/core";
import { Resolutions } from "@resolutions";
import { SwiperOptions } from "swiper";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Observable } from "rxjs";
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { TileContent } from "app/model/interfaces";
import { SwiperConfigService } from "@service/swiper-config.service";

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: "app-tile-slider",
  templateUrl: "./tile-slider.component.html",
  styleUrls: ["./../slider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileSliderComponent
{
  public swiperOpt: SwiperOptions;
  @Input() public config: SwiperOptions;
  @Input() public sliderData$: Observable<TileContent[]>;
  @Input() public singleSlideMovement: boolean = false;
  @Input() public height: number[];
  @Input() public width: number[];

  constructor(public urlTranslateService: UrlTranslateService, private swiperCo: SwiperConfigService)
  {
    this.swiperOpt = swiperCo.getConfig(
      [0, Resolutions.resolutionTablet, Resolutions.resolutionDesktop, Resolutions.resolutionHalfHd, Resolutions.resolutionFullHd],
      [16, 16, 24, 48, 128],
      [32, 16, 16, 32, 32],
      [16, 16, 24, 48, 128],
      [1, 1, 1, 1, 1],
      [2, 2, 1, 1, 1]
    );
  }

  //#region Tiles
  public isDoubleWidth(banner: TileContent): boolean
  {
    return banner.position && ((banner.position.right - banner.position.left) >= 1);
  }
  //#endregion

  //#region Product images
  public getImageAlt(img): string
  {
    return "";
  }
  //#endregion
}
