<section class="rating-product">

  <h2 class="font-style-semi title">
    {{ "reviews.opinionAboutProduct" | translate }}
  </h2>

  <ng-container *ngIf="hasReview">
    <div class="wrapper separator-one-half">
      <div class="opinions-details">

        <div class="left-side">
          <div class="review-rating" id="review">
            <img width="110" src="{{ calculateAverageSVG() }}" />
          </div>

          <app-rating
            [activeElements]="getActiveElements(ratingAverage ? ratingAverage : 4.5)"
            [showRating]="false">
          </app-rating>

          <p class="numbers-of-rate">{{ getNumberOfElementsDescription() }}</p>
        </div>

        <div class="right-side">
          <div class="rate-details-wrapper">
            <div class="single-rate-detail" *ngFor="let elem of [].constructor(5); let i = index">
              <i class="ai ai-star"></i>
              <span>{{ 5 - i }}</span>
              <app-progress-bar
                [value]="getFilteredValue(5 - i)"
                [max]="getNumberOfElements"
              ></app-progress-bar>
            </div>
          </div>
        </div>

      </div>

      <div class="button-wrapper">

        <div class="heading3 no-opinions separator-one-half" *ngIf="!ratingAverage">
          {{ "reviews.noOpinionsAboutProduct" | translate }}
        </div>

        <p class="heading6">{{ "reviews.didYouBuyIt" | translate }}</p>
        <p class="heading6">{{ "reviews.shareReview" | translate }}</p>

        <button class="button secondary-button" (click)="openReviewPopup($event, product)">
          {{ "reviews.addOpinion" | translate }}
        </button>

      </div>
    </div>

    <div class="separator-gray"></div>

    <app-opineo
      [noHeader]="true"
      [reviews]="productReviews"
      [noClick]="true"
      [showMore]="true">
    </app-opineo>

  </ng-container>

  <ng-container *ngIf="!hasReview">
    <p class="heading6">{{ "reviews.noOpinionAddFirst" | translate }}</p>

    <div class="button-wrapper no-review">
      <div class="button secondary-button" (click)="openReviewPopup($event, product)">
        {{ "reviews.addOpinion" | translate }}
      </div>
    </div>

    <app-alert-bar
      *ngIf="ratingSuccess"
      [alertStatus]="'Success'"
      [errorText]="'error.reviewAddedThankYou' | translate"
      (publicCloseBar)="ratingSuccess = false">
    </app-alert-bar>

    <div class="separator-gray no-review"></div>
  </ng-container>
</section>

<app-review-popup
  name="review"
  [mainTitle]="'reviews.addOpinionForProduct' | translate"
  buttonType="secondary-button"
  [defaultButtonText]="'reviews.addOpinion' | translate"
  [buttonTextSuccess]="'reviews.addedOpinion' | translate"
  [navigationBarTitle]="'reviews.addOpinion' | translate"
  (ratingSuccessMsg)="ratingSuccess = true"
  [showEmail]="true"
  [emailPlaceholder]="'E-mail'"
  [showMessage]="true"
  [messagePlaceholder]="'reviews.writeYourOpinion' | translate"
  [showName]="true"
  [isReviewPopup]="true"
  [namePlaceholder]="'reviews.nick' | translate"
  [showRating]="true"
  #reviewPopup
>
</app-review-popup>
