<div
    class="icons"
    *ngIf="mediaLinks"
>
    <a
        [href]="mediaLinks.instagramLink"
        rel="noopener noreferrer"
        (click)="openInNewCard(mediaLinks.instagramLink, $event)"
    >
        <i class="ai ai-instagram"></i>
    </a>
    <a
        [href]="mediaLinks.facebookLink"
        rel="noopener noreferrer"
        (click)="openInNewCard(mediaLinks.facebookLink, $event)"
    >
        <i class="ai ai-facebook"></i>
    </a>
    <a
        [href]="mediaLinks.pinterestLink"
        rel="noopener noreferrer"
        (click)="openInNewCard(mediaLinks.pinterestLink, $event)"
    >
        <i class="ai ai-pinterest"></i>
    </a>
</div>
