import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { CartItem } from '@dto';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@service/storage/storage.service';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnChanges
{
  public valueOrPlaceholder: any;

  get activeOption(): string
  {
    return this._activeOption;
  }

  @Input() set activeOption(value: string)
  {
    if (value)
    {
      this._activeOption = value;
      this.setValueOrPlaceholderFunc();
    }
    // @ts-ignore
    if (value === 0 || value === null)
    {
      setTimeout(() =>
      {
        this.setValueOrPlaceholderFunc();
      }, 64);
    }
  }

  private stopSetAsActive: boolean;

  get getFirstOptionActive(): any
  {
    return this._getFirstOptionActive;
  }

  @Input() set getFirstOptionActive(value: any)
  {
    this.storageService.get('selectedPickupPlace').subscribe(res =>
    {
      this.optionFieldName = 'name';
      if (!res)
      {
        this._getFirstOptionActive = value;
      } else
      {
        this._getFirstOptionActive = res;
      }
      this.setAsActive(this._getFirstOptionActive, 'description');
    });
  }

  @Input() public isWykladzina: boolean;

  @Input() set getFirstOptionActiveCart(value: any)
  {
    if (this._getFirstOptionActiveCart)
    {
      return;
    } else
    {
      this.stopSetAsActive = true;
    }
    this.storageService.get('selectedPickupPlace').subscribe((res: any) =>
    {
      if (!this.options.length)
      {
        return;
      }
      if (!res)
      {
        this._getFirstOptionActiveCart = value;
      } else
      {
        this._getFirstOptionActiveCart = this.options.filter(el => el.stocks == res.code)[0];
      }
      this.stopSetAsActive = false;
      this.setAsActive(this._getFirstOptionActiveCart, this.additionalInfo);
    });
  }

  @Input() public options: any[];
  @Input() public placeholder;
  @Input() public disabled: boolean;
  @Input() public label: string;
  @Input() public optionFieldName: string;
  @Input() public productCalc: boolean;
  private _activeOption: string;
  @Input() private name: string;
  @Input() public unit: string;
  private _getFirstOptionActive: any;
  private _getFirstOptionActiveCart: any;
  @Output() private readonly selectedItem: EventEmitter<string> = new EventEmitter<string>();
  public isExpanded: boolean = false;
  public currency: string;

  @Input() public additionalInfo: string;
  @Input() public setToRightBold: boolean = false;
  @Input() public dropdownPrice: boolean = false;

  constructor(private windowRef: WindowRefService,
    private translate: TranslateService,
    private storageService: StorageService,
    private changeDet: ChangeDetectorRef)
  {
    this.currency = this.translate.instant('general.currency');
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (this.productCalc)
    {
      if (changes.options)
      {
        if (JSON.stringify(changes.options.currentValue) !== JSON.stringify(changes.options.previousValue))
        {
          this._activeOption = null;
          this.setValueOrPlaceholderFunc();
        }
      }
    }
  }

  public close($event): void
  {
    if ($event && $event.target.className.includes instanceof Function && !$event.target.className.includes('primary-checkbox') && $event.target.className != 'form-label is-Empty' && !$event.target.className.includes('button secondary-button')
      && !$event.target.className.includes('button primary-button'))
    {
      $event.stopPropagation();
      $event.preventDefault();
    }
    if (this.isExpanded)
    {
      this.isExpanded = false;
    }
    this.changeDet.detectChanges();
  }

  public trackByIndex(index, item: CartItem): number
  {
    return item.iD;
  }

  public setAsActive(option, additional?: string, $event?: any): void
  {
    if (option && !this.stopSetAsActive)
    {
      this._activeOption = this.optionFieldName ? option[this.optionFieldName] : option;
      if (additional)
      {
        if (this.setToRightBold)
        {
          this._activeOption = this._activeOption + `<span class="additional-info bold to-right">` + this.showValueWithComma(option[additional]) + `<span class="font-style-semi" [innerHtml]="this.unit">` + ' ' + this.unit + `</span></span>`;
        } else
        {
          if (this.dropdownPrice)
          {
            this._activeOption = `${this._activeOption} (${this.showValueWithComma(option[additional])} ${this.unit})`;
          } else
          {
            this._activeOption = this._activeOption + ', ' + option[additional];
            this.changeDet.detectChanges();
          }
        }
      }
      if (option.hasOwnProperty('link') && option.link)
      {
        option.link = option.link.replace('a\=0', 'a=1');
      }
      this.selectedItem.emit(option);
      this.setValueOrPlaceholderFunc();
      this.close($event);
      this.changeDet.detectChanges();
    }
  }

  public showValueWithComma(value: any): string
  {
    let temp = parseFloat(value).toFixed(2).replace(/\./gi, ',');
    if (temp.includes(',00'))
    {
      temp = temp.split(',00')[0];
    }
    return temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  public toggleDropdown($event): void
  {
    if ($event.target.className == 'ps__thumb-y' || $event.target.className == 'ps-content' || $event.target.className == 'list-element')
    {
      return;
    }
    this.isExpanded = !this.isExpanded;
    this.changeDet.detectChanges();
  }

  private setValueOrPlaceholderFunc(): void
  {
    if (this._activeOption && this._activeOption['name'] && this._activeOption['code'])
    {
      setTimeout(() =>
      {
        if (this.additionalInfo)
        {
          this.valueOrPlaceholder = this._activeOption ? this._activeOption : this.placeholder ? this.placeholder : this.options && this.options.length > 0 ? this.setAsActive(this.options[0], this.additionalInfo) : '';
        } else
        {
          this.valueOrPlaceholder = this._activeOption ? this._activeOption : this.placeholder ? this.placeholder : this.options && this.options.length > 0 ? this.setAsActive(this.options[0]) : '';
          this.changeDet.detectChanges();
        }
        this.setAsActive(this.options.filter(el => el.code == this._activeOption['code'])[0], this.additionalInfo);
      }, 64);
    } else
    {
      if (this.additionalInfo)
      {
        if (this.isWykladzina)
        {
          let selectedOption = null;
          if (this.options.filter(option => option.width == 400).length)
          {
            selectedOption = this.options.filter(option => option.width == 400)[0];
          } else if (this.options.filter(option => option.width == 500).length)
          {
            selectedOption = this.options.filter(option => option.width == 500)[0];
          } else if (this.options.filter(option => option.width == 300).length)
          {
            selectedOption = this.options.filter(option => option.width == 300)[0];
          } else if (this.options.filter(option => option.width == 200).length)
          {
            selectedOption = this.options.filter(option => option.width == 200)[0];
          } else if (this.options.filter(option => option.width == 100).length)
          {
            selectedOption = this.options.filter(option => option.width == 100)[0];
          }
          this.valueOrPlaceholder = this._activeOption ? this._activeOption : this.placeholder ? this.placeholder : this.options && this.options.length > 0 ? this.setAsActive(selectedOption, this.additionalInfo) : '';
        } else
        {
          this.valueOrPlaceholder = this._activeOption ? this._activeOption : this.placeholder ? this.placeholder : this.options && this.options.length > 0 ? this.setAsActive(this.options[0], this.additionalInfo) : '';
        }
      }
      this.valueOrPlaceholder = this._activeOption ? this._activeOption : this.placeholder ? this.placeholder : this.options && this.options.length > 0 ? this.setAsActive(this.options[0]) : '';
      if (typeof this.valueOrPlaceholder != 'string')
      {
        this.valueOrPlaceholder = '';
      }
      this.changeDet.detectChanges();
    }
  }
}
