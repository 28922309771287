import
{
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { ShopProduct } from "@dto";
import { Record } from "@idto";
import { WishlistService } from "@service/wishlist/wishlist.service";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { ProductsService } from "@service/products/products.service";
import { routingTable } from "environments/routing-table";
import { UrlTranslateService } from '@service/helpers/url-translate.service';

@Component({
  selector: "app-product-hover",
  templateUrl: "./product-hover.component.html",
  styleUrls: ["./product-hover.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductHoverComponent implements OnDestroy, AfterViewInit
{
  @Input() public product: Record;
  @Input() public recommendationID: string;
  @Input() public isListing = false;
  @Input() public cardSize: "big" | "small" = "big";
  @Input() public width: number[] = [];
  @Input() public height: number[] = [];
  @Output() private readonly showProductStatusPopup: EventEmitter<ShopProduct> =
    new EventEmitter();

  private wishlistList: any[];
  private destroy$: Subject<void> = new Subject();

  get isBigCardSize(): boolean
  {
    return this.cardSize === "big";
  }

  constructor(
    private router: Router,
    private changeDet: ChangeDetectorRef,
    private wishlistService: WishlistService,
    public productsService: ProductsService,
    public urlTranslateService: UrlTranslateService
  ) { }

  public goToProductPage(product: Record): void
  {
    this.router.navigate([routingTable.shop.product, product.productLink]);
  }

  public ngAfterViewInit(): void
  {
    if (this.product && this.product.variants)
    {
      this.changeDet.detectChanges();
      this.wishlistService.wishlist$
        .pipe(
          filter((v) => !!v),
          takeUntil(this.destroy$)
        )
        .subscribe((res) =>
        {
          this.wishlistList = res.products;
          this.changeDet.detectChanges();
        });
    }
  }

  public ngOnDestroy(): void
  {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isInWishlist(productGroupID: string): boolean
  {
    return (
      this.wishlistList &&
      this.wishlistList.filter(
        (el) => el.products[0].familyCode == productGroupID
      ).length > 0
    );
  }

  public toggleWishlist($event, product): void
  {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.isInWishlist(product.productGroupID))
    {
      this.wishlistService
        .removeProductFromWishList(this.product.productGroupID)
        .subscribe();
    } else
    {
      this.wishlistService
        .addProductToWishList(this.product.productGroupID)
        .subscribe();
    }
    this.changeDet.detectChanges();
  }

  public showTooltipPopup($event: any): void
  {
    this.showProductStatusPopup.emit($event);
  }
}
