import { Directive, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appInputClick]'
})
export class InputClickDirective
{
  @Input() public focusOut: boolean = true;

  constructor(private renderer2: Renderer2)
  {
  }

  @HostListener('focusout', ['$event'])
  public onBlur($event): void
  {
    if (this.focusOut && $event.target.value == '' || $event.target.value == null)
    {
      this.renderer2.removeClass($event.target, 'label-up');
    }
  }

  @HostListener('focusin', ['$event'])
  public onFocus($event): void
  {
    if ($event.target.value == '' || $event.target.value == null)
    {
      this.renderer2.addClass($event.target, 'label-up');
    }
  }
}
