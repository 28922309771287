import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
//import { StayAtHomePopupComponent } from '@shared/shared/modals/stay-at-home-popup/stay-at-home-popup.component';
import { StayHomeCodeBannerComponent } from '@shared/shared/slider/stay-home-code-banner/stay-home-code-banner.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SwiperModule } from 'swiper/angular';
import { BlockModule } from '../block/block.module';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { ExtraModule } from '../extraModuleShared/extraModule.module';
import { FiltersModule } from '../filters/filters.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { AccountPopupComponent } from './account-popup/account-popup.component';
import { AlertBarComponent } from './alert-bar/alert-bar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ContactPopupComponent } from './contact-popup/contact-popup.component';
import { ElementsPerPageComponent } from './elements-per-page/elements-per-page.component';
import { ErrorsModule } from './errors/errors.module';
import { FooterComponent } from './footer/footer.component';
import { MainImageZoomThumbsComponent } from './main-image-zoom-thumbs/main-image-zoom-thumbs.component';
import { InputCalculatorComponent } from './input-calculator/input-calculator.component';
import { MenuComponent } from './menu/menu.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { AddressPopupComponent } from './modals/address-popup/address-popup.component';
import { AdvisorPopupComponent } from './modals/advisor-popup/advisor-popup.component';
import { ProductAvailabilityPopupComponent } from './modals/availability/product-availability-popup/product-availability-popup.component';
import { CartPopupComponent } from './modals/cart-popup/cart-popup.component';
import { ChangePersonalDataPopupComponent } from './modals/change-personal-data-popup/change-personal-data-popup.component';
import { ConfirmationPopupComponent } from './modals/confirmation-popup/confirmation-popup.component';
import { ContactFormPopupComponent } from './modals/contact-form-popup/contact-form-popup.component';
import { ContactPopupAbstractComponent } from './modals/contact-popup-abstract/contact-popup-abstract.component';
import { FavPopupComponent } from './modals/fav-popup/fav-popup.component';
import { NewsletterPopupComponent } from './modals/newsletter-popup/newsletter-popup.component';
import { ProductAddItemPopupComponent } from './modals/product-add-item-popup/product-add-item-popup.component';
import { RecoveryPasswordPopupComponent } from './modals/recovery-password-popup/recovery-password-popup.component';
import { ReviewPopupComponent } from './modals/review-popup/review-popup.component';
import { SubItemPopupComponent } from './modals/sub-item-popup/sub-item-popup.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { OpineoComponent } from './opineo/opineo.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProductSliderComponent } from './product-slider/product-slider.component';
import { ProductVariantsComponent } from './product-variants/product-variants.component';
import { RoundProgressBarComponent } from './round-progress-bar/round-progress-bar.component';
import { SearchComponent } from './search/search.component';
import { ProductHoverComponent } from './single-product/product-hover/product-hover.component';
import { SingleProductComponent } from './single-product/single-product.component';
import { SliderComponent } from './slider/slider.component';
import { MainSliderComponent } from './slider/main-slider/main-slider.component';
import { StandardBannerComponent } from './slider/standard-banner/standard-banner.component';
import { TileBoxComponent } from './tile-box/tile-box.component';
import { obsWithStatus } from "../../../utils/observable-with-loading.pipe";
import { RegisterClickEventDirective } from '@shared/directives/register-click-event.directive';
import { ShortTimePipe } from './product-variants/short-time.pipe';
import { NavigationComponent } from './navigation/navigation.component';
import { MediaLinksComponent } from './media-links/media-links.component';
import { MainImageComponent } from './main-image/main-image.component';
import { TileSliderComponent } from './slider/tile-slider/tile-slider.component';
import { TileBannerComponent } from './slider/tile-slider/tile-banner/tile-banner.component';
import { SliderBaseComponent } from './slider/slider-base/slider-base.component';

@NgModule({
  declarations: [
    SingleProductComponent,
    ShortTimePipe,
    MenuComponent,
    MobileMenuComponent,
    BreadcrumbsComponent,
    FooterComponent,
    SliderComponent,
    MainSliderComponent,
    TileSliderComponent,
    StandardBannerComponent,
    TileBannerComponent,
    StayHomeCodeBannerComponent,
    SearchComponent,
    NewsletterComponent,
    OpineoComponent,
    ContactPopupComponent,
    AccountPopupComponent,
    AccountPopupComponent,
    AdvisorPopupComponent,
    ContactFormPopupComponent,
    NewsletterPopupComponent,
    ContactPopupAbstractComponent,
    TileBoxComponent,
    ProductAvailabilityPopupComponent,
    ProductVariantsComponent,
    InputCalculatorComponent,
    SingleProductComponent,
    ProductSliderComponent,
    ReviewPopupComponent,
    MainImageZoomThumbsComponent,
    ProductHoverComponent,
    SubItemPopupComponent,
    CartPopupComponent,
    ChangePersonalDataPopupComponent,
    ConfirmationPopupComponent,
    FavPopupComponent,
    PaginationComponent,
    AlertBarComponent,
    RoundProgressBarComponent,
    AddressPopupComponent,
    ElementsPerPageComponent,
    ProductAddItemPopupComponent,
    RecoveryPasswordPopupComponent,
    //StayAtHomePopupComponent,
    obsWithStatus,
    NavigationComponent,
    MediaLinksComponent,
    MainImageComponent,
    SliderBaseComponent
  ],
  providers: [
    obsWithStatus,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ErrorsModule,
    SwiperModule,
    BlockModule,
    NgScrollbarModule,
    FiltersModule,
    TooltipModule,
    TranslateModule,
    ExtraModule,
    DirectivesModule,
    PipesModule
  ],
  exports: [
    SingleProductComponent,
    RegisterClickEventDirective,
    CommonModule,
    TranslateModule,
    FiltersModule,
    NewsletterPopupComponent,
    MenuComponent,
    MobileMenuComponent,
    BreadcrumbsComponent,
    FooterComponent,
    SliderComponent,
    MainSliderComponent,
    TileSliderComponent,
    SearchComponent,
    NewsletterComponent,
    ContactPopupComponent,
    OpineoComponent,
    AccountPopupComponent,
    AdvisorPopupComponent,
    ElementsPerPageComponent,
    ProductVariantsComponent,
    TileBoxComponent,
    ProductAvailabilityPopupComponent,
    InputCalculatorComponent,
    MainImageZoomThumbsComponent,
    SingleProductComponent,
    ProductSliderComponent,
    ReviewPopupComponent,
    ProductHoverComponent,
    SubItemPopupComponent,
    CartPopupComponent,
    ChangePersonalDataPopupComponent,
    ConfirmationPopupComponent,
    ContactFormPopupComponent,
    FavPopupComponent,
    PaginationComponent,
    AlertBarComponent,
    RoundProgressBarComponent,
    AddressPopupComponent,
    TileBannerComponent,
    ProductAddItemPopupComponent,
    RecoveryPasswordPopupComponent,
    //StayAtHomePopupComponent,
    NavigationComponent,
    MediaLinksComponent,
    PipesModule,
    MainImageComponent,
    NgScrollbarModule
  ],
})
export class SharedModule
{
}
