import { Injectable } from '@angular/core';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { StorageService } from '../storage/storage.service';
import { UrlTranslateService } from '@service/helpers/url-translate.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService
{

  private baseUrl;

  private _steps = [
    `/${this.urlTranslateService.routingTable.checkout.base}`,
    `/${this.urlTranslateService.urlMapping.checkout.login}`,
    `/${this.urlTranslateService.urlMapping.checkout.account}`,
    `/${this.urlTranslateService.urlMapping.checkout.summary}`,
    `/${this.urlTranslateService.urlMapping.checkout.final}`,
  ];

  public currentStep = `/${this.urlTranslateService.routingTable.checkout.base}`;
  public stepChange: BehaviorSubject<string> = new BehaviorSubject(this.currentStep);

  constructor(
    private configService: ConfigService,
    private windowService: WindowRefService,
    private storageService: StorageService,
    public urlTranslateService: UrlTranslateService
  )
  {
    this.baseUrl = this.configService.ApiUrl;

    if (!this.windowService.isServer)
    {
      this.storageService.get('cartStep').pipe(filter(v => !!v)).subscribe((data: string) =>
      {
        this.currentStep = data;
        this.stepChange.next(this.currentStep);
      });
    }
  }

  public clear(): void
  {
    this.setCurrentStep(`/${this.urlTranslateService.routingTable.checkout.base}`);
  }

  public setCurrentStep(step): boolean
  {
    if (this._steps.indexOf(step) !== -1)
    {
      this.currentStep = step;
      this.storageService.set('cartStep', step);
      this.stepChange.next(this.currentStep);
      return true;
    }
    return false;
  }

  public isStepChangeAllowed(step): boolean
  {
    if (step === `/${this.urlTranslateService.routingTable.checkout.base}`)
    {
      return true;
    }
    // Jak zamowienie jest juz zlozone, to nie mozna wrocic
    if (this.currentStep === `/${this.urlTranslateService.urlMapping.checkout.final}` && !step.includes(`/${this.urlTranslateService.urlMapping.checkout.final}`))
    {
      return false;
    }
    // Jesli chce wrocic do poprzedniego kroku
    if (this._steps.indexOf(step) <= this._steps.indexOf(this.currentStep))
    {
      return true;
    }

    return this.currentStep === step;
  }

}
