<ng-container>
  <nav>
    <app-navbar #navbar [isFinalStepOfCheckout]="isFinalStepOfCheckout" (mobileMenuOpened)="openMobileMenu($event)"></app-navbar>
    <app-mobile-menu
      #mobileMenu
      sdClickOutside
      (mobileMenuClosed)="mobileMenuClosed()"
      (clickOutside)="mobileMenu.closeMenu($event)"
      *ngIf="isMobile"
    ></app-mobile-menu>

  </nav>
  <app-overlay *ngIf="!isCheckoutModule"></app-overlay>
</ng-container>

<div class="container">
  <div class="notification-bar" *ngIf="notification$ | async as notification">
    <app-alert-bar
      [alertStatus]="notification.alertStatus"
      [errorText]="notification.errorText"
      (publicCloseBar)="clearError()"
    ></app-alert-bar>
  </div>
  <app-technical-error *ngIf="showErrorOverlay"></app-technical-error>
  <router-outlet></router-outlet>
  <app-navigation *ngIf="shopModeService.isKiosk"></app-navigation>
</div>

<ng-container *ngIf="isCheckoutModule">
  <app-footer
    [isCheckout]="true"
    [isFinalStepOfCheckout]="isFinalStepOfCheckout"
  ></app-footer>
</ng-container>

<ng-container *ngIf="!isCheckoutModule">
  <app-footer
    [showFooterLinks]="errorsService.isNotServerBreak | async"
  ></app-footer>
</ng-container>

<app-chat></app-chat>
