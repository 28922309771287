import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { Resolutions } from "@resolutions";
import { SwiperConfigService } from "@service/swiper-config.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { SliderBaseComponent } from "@shared/shared/slider/slider-base/slider-base.component";
import { SwiperOptions } from "swiper";
import { ChipsInterface } from "./chips.interface";

@Component({
  selector: "app-chips",
  templateUrl: "./chips.component.html",
  styleUrls: ["./chips.component.scss"],
})
export class ChipsComponent extends SliderBaseComponent
{
  public minHeight: number = 114;
  @Input() items: ChipsInterface[];
  public swiperOpt: SwiperOptions;

  constructor(sS: SwiperConfigService, protected windowRef: WindowRefService, protected changeDet: ChangeDetectorRef)
  {
    super(windowRef, changeDet);

    this.resolutionbreakpoints = [0, Resolutions.resolutionTablet, Resolutions.resolutionDesktop];
    this.pagination = [1, 2, 3];

    this.swiperOpt = sS.getConfig(
      this.resolutionbreakpoints,
      [16, 0, 0]);

    this.swiperOpt.watchOverflow = false;
    let x = this.swiperOpt.breakpoints[Resolutions.resolutionDesktop];
    x.grid = { rows: 2 };
    x.touchRatio = 0;
    x.freeMode = false;

    this.setResolutionStatus();
  }

  protected hideSwiper(): void
  {
    if (this.currentResolutionStatus < 3)
    {
      this.minHeight = 28;
    }
    else
    {
      this.minHeight = 114;
    }

    super.hideSwiper();

  }
}
