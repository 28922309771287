import { Injectable } from '@angular/core';
import { SwiperOptions } from 'swiper';

@Injectable({
  providedIn: 'root'
})
export class SwiperConfigService
{

  // Employee list
  public readonly defaultConfig: SwiperOptions = {
    init: true,
    centeredSlides: false,
    navigation: false,
    a11y: { enabled: true },
    speed: 800,
    direction: "horizontal",
    grid: { fill: "row" },
    observeParents: false,
    slidesPerView: "auto",
    freeMode: true,
  };

  constructor() { }

  public setBreakepoints(breakpoint: any, index: number, offsetsBefore: number[] = null,
    spaceBetween: number[] = null,
    offsetsAfter: number[] = null,
    slidesPerGroup: number[] = null,
    pagination: number[] = null)
  {
    if (slidesPerGroup)
    {
      breakpoint.slidesPerGroup = slidesPerGroup[index];
    }

    if (spaceBetween)
    {
      breakpoint.spaceBetween = spaceBetween[index];
    }

    if (offsetsAfter)
    {
      breakpoint.slidesOffsetAfter = offsetsAfter[index];
    }

    if (offsetsBefore)
    {
      breakpoint.slidesOffsetBefore = offsetsBefore[index];
    }

    if (pagination)
    {
      switch (pagination[index])
      {
        case 0: breakpoint.pagination = false; break;
        case 1: breakpoint.pagination = { clickable: true, dynamicBullets: false }; break;
        case 2: breakpoint.pagination = { clickable: true, dynamicBullets: true }; break;
      }
    }
  }

  public getConfig(breakpoints: number[] = null,
    offsetsBefore: number[] = null,
    spaceBetween: number[] = null,
    offsetsAfter: number[] = null,
    slidesPerGroup: number[] = null,
    pagination: number[] = null): SwiperOptions
  {
    let config = { ... this.defaultConfig };

    if (breakpoints && breakpoints.length > 0)
    {
      this.setBreakepoints(config, 0, offsetsBefore, spaceBetween, offsetsAfter, slidesPerGroup, pagination);
    }

    if (breakpoints && breakpoints.length > 1)
    {
      config.breakpoints = {};

      for (let i = 1; i < breakpoints.length; i++)
      {
        config.breakpoints[breakpoints[i]] = {};
        this.setBreakepoints(config.breakpoints[breakpoints[i]], i, offsetsBefore, spaceBetween, offsetsAfter, slidesPerGroup, pagination);
      }
    }

    return config;
  }
}
