import { Customer } from '@dto';
import { CookiesService } from '@shared/shared/cookies/cookies.service';
import { Consent, ConsentContent } from 'app/model/interfaces';

export function parseContent(agreement: Consent, text: string): void
{
  const regexp = new RegExp('<.*?collapsed.*?>(.*)<\/.*?>');
  if (!text)
  {
    return;
  }

  const matches = text.match(regexp);

  if (matches)
  {
    setCollapsingText(agreement, matches, text);
  }
  else
  {
    setSimpleText(agreement, text);
  }
}

export function setCollapsingText(agreement: Consent, matches: string[], text: string): void
{
  const collapsedText = text
    .replace('<p>', '')
    .replace('</p>', '')
    .replace(matches[0], '')
    .trim();
  const expandedText = collapsedText + ' ' + String(matches[1]).trim();

  agreement.collapsingContent = { collapsedText: "", expandedText: "" } as ConsentContent;
  agreement.collapsingContent.collapsedText = collapsedText;
  agreement.collapsingContent.expandedText = expandedText;
  agreement.showCollapsingContent = true;
}

export function setSimpleText(agreement: Consent, text: string): void
{
  agreement.simpleContent = text;
  agreement.showSimpleContent = true;
}

export function isCollapsingContentShown(agreement: Consent): boolean
{
  return agreement.showCollapsingContent;
}

export function getCollapsingContent(agreement: Consent): ConsentContent
{
  return agreement.collapsingContent;
}

export function isSimpleContentShown(agreement: Consent): boolean
{
  return agreement.showSimpleContent;
}

export function getSimpleContent(agreement: Consent): string
{
  return agreement.simpleContent;
}

export function isGlobalAccepted(agreement: Consent, customer: Customer): boolean
{
  return agreement.global == true && customer.consents.filter(agr => agr.code == agreement.code && agr.active == agreement.accepted).length > 0;
}

export function allRequiredPoliciesAreAccepted(agreements: Consent[]): boolean
{
  let policesAccepted = true;
  agreements.forEach(agr =>
  {
    if (agr.required == true && agr.accepted == false)
    {
      policesAccepted = false;
    }
  });
  return policesAccepted;
}

export function toggleCheckbox(agreements: Consent[], policyName: string, $event: any, changeDet): void
{


  if ($event.target.className == 'expand' || $event.target.className == 'collapse')
  {
    return;
  }
  let index = agreements.findIndex(agr => agr.code === policyName);
  agreements[index].accepted = !agreements[index].accepted;
  changeDet.detectChanges();
}

export function setAgreementTextExpand($event, agreement: any, changeDet): void
{
  agreement.expand = $event;
  changeDet.detectChanges();
}
