import
{
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import { ShopProduct, ShopProductFamily } from "@dto";
import { TranslateService } from "@ngx-translate/core";
import { Resolutions } from "@resolutions";
import { AuthService } from "@service/session/auth/auth.service";
import { CartService } from "@service/cart/cart.service";
import { CompareService } from "@service/compare/compare.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { ProductAddItemPopupComponent } from "@shared/shared/modals/product-add-item-popup/product-add-item-popup.component";
import { Observable, Subject, Subscription } from "rxjs";
import { map, takeUntil, filter } from "rxjs/operators";
import SwiperCore, { Navigation, Grid, SwiperOptions } from "swiper";
import { UrlTranslateService } from "@service/helpers/url-translate.service";
import { SeoService } from "@service/seo/seo.service";
import { SwiperConfigService } from "@service/swiper-config.service";

SwiperCore.use([Navigation, Grid]);

@Component({
  selector: "app-compare",
  templateUrl: "./compare.component.html",
  styleUrls: ["./compare.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareComponent implements OnDestroy, AfterViewInit
{
  @ViewChild("gallery") public gallery;
  public selectedProduct: ShopProductFamily;
  public showSortPopup: boolean = false;
  public show: boolean = true;
  public swiperOpt: SwiperOptions;
  @ViewChild(ProductAddItemPopupComponent, { static: true })
  private productAddItemPopupComponent: ProductAddItemPopupComponent;
  @ViewChild("swiperComponent") private swiperComponent;
  @ViewChildren("addButton") private addButton: ElementRef;

  public error: string;
  public isLoading: boolean;
  public mainImageIndex: number = 0;
  public isImageLoaded: boolean = false;
  public parametersArray: string[];
  public hidePicture: boolean;
  private chosenId: any;
  private destroy$: Subject<void> = new Subject();
  public chosenSortPopupOptionsProduct: any;
  public chosenProduct: any;
  public isWykladzina: boolean;
  public compareProducts$: Observable<ShopProductFamily[]>;
  private compareProductsLocal: ShopProductFamily[] = [];
  private isFirstRun: any = true;
  private lastResLength: any;
  private counterLoadedImages: number = 0;
  private guidSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private windowRef: WindowRefService,
    private compareService: CompareService,
    private seoService: SeoService,
    private cartService: CartService,
    private renderer2: Renderer2,
    private router: Router,
    private elementRef: ElementRef,
    private changeDet: ChangeDetectorRef,
    private translateService: TranslateService,
    public urlTranslateService: UrlTranslateService,
    private swiperCo: SwiperConfigService
  )
  {
    this.seoService.setTitle(this.translateService.instant('favourite.comparePageTitle') + " - " + this.translateService.instant("pageTitleSufix"));
    this.seoService.setCanonical(this.windowRef.getNativeWindow().location.host + `/${this.urlTranslateService.routingTable.shop.compare}`);
    this.seoService.setNofollow(true);

    this.swiperOpt = this.swiperCo.getConfig(
      [0, Resolutions.resolutionTablet, Resolutions.resolutionDesktop, Resolutions.resolutionHalfHd],
      [24, 24, 0, 0],
      [30, 30, 30, 60]);
    this.swiperOpt.centerInsufficientSlides = true;
    this.swiperOpt.watchOverflow = false;
    //this.swiperOpt.edgeSwipeDetection = "prevent";

    this.compareProducts$ = this.compareService.compare$.pipe(takeUntil(this.destroy$), map(res =>
    {
      if (res && res.length)
      {
        res.forEach(el => el.mainImageIndex = 0);
        if (this.isFirstRun || this.lastResLength != res.length)
        {
          this.lastResLength = res.length;
        }
        this.compareProductsLocal = Array.from(res);
        this.isFirstRun = false;
        this.isWykladzina = res[0].isCovering;
        this.parametersArray = this.getParametersName(res);
        if (!this.selectedProduct)
        {
          this.selectedProduct = res[0];
        }
        if (res.length && !this.changeDet['destroyed'])
        {
          this.compareProductsLocal = Array.from(res);
          if (this.swiperComponent?.swiperRef)
          {
            setTimeout(() =>
            {
              this.changeDet.detectChanges();
              this.swiperComponent.swiperRef.update();
            }, 144);
          }
          if (res.length && !this.changeDet["destroyed"])
          {
            this.compareProductsLocal = Array.from(res);
            if (this.swiperComponent?.swiperRef)
            {
              setTimeout(() =>
              {
                this.changeDet.detectChanges();
                this.swiperComponent.swiperRef.update();
              }, 144);
            }
          }
        } else
        {
          if (res == null)
          {
            this.isFirstRun = true;
          }
          setTimeout(() =>
          {
            this.changeDet.detectChanges();
          }, 60);
        }
      } else
      {
        if (res == null)
        {
          this.isFirstRun = true;

        }
        setTimeout(() =>
        {
          this.changeDet.detectChanges();
        }, 60);
      }
      return res;
    }));
  }

  public ngAfterViewInit(): void
  {
    if (this.swiperComponent?.swiperRef)
    {
      this.changeDet.detectChanges();
    }
  }

  public ngOnDestroy(): void
  {
    if (this.guidSubscription)
    {
      this.guidSubscription.unsubscribe();
    }
    if (!this.changeDet['destroyed'])
    {
      this.destroy$.next();
      this.destroy$.complete();
    }
  }

  public chosenOptionFunc(product: ShopProduct, $event: ShopProduct): void
  {
    product['chosenOption'] = $event.shippingTime.daysTimeLabel;
    product['chosenOptionSize'] = $event.option;
    product['chosenPrice'] = $event.promotionPrice;
    product['chosenId'] = $event.iD;
    this.closeSortPopup();
    this.changeDet.detectChanges();
  }

  public getParametersName(compareProducts): string[]
  {
    if (!compareProducts.length)
    {
      return [''];
    }
    const tempParametersNameArray: any[] = [];
    for (let i = 0; i < compareProducts.length; i++)
    {
      for (let j = 0; j < compareProducts[i].parameters.length; j++)
      {
        if (tempParametersNameArray.indexOf(compareProducts[i].parameters[j].name) == -1)
        {
          tempParametersNameArray.push(compareProducts[i].parameters[j].name);
        }
      }
    }
    return tempParametersNameArray;
  }

  @HostListener('window:resize', ['$event'])
  public getStyleHeight(i): string
  {
    if (this.windowRef.getNativeWindowInnerWidth() >= Resolutions.resolutionTablet)
    {
      return i * 120 + 'px';
    }
    return i * 80 + 'px';
  }

  public openGallery(product, index: number, $event): void
  {
    $event.stopPropagation();
    $event.preventDefault();
    this.selectedProduct = product;
    this.changeDet.detectChanges();
    setTimeout(() =>
    {
      this.gallery.open(index.toString());
    });
  }

  public changeMainImage(imageIndex: number): void
  {
    this.hidePicture = true;
    this.isImageLoaded = false;
    this.changeDet.detectChanges();
    let changes = 0;

    this.compareProductsLocal.forEach(el =>
    {
      if (el.images.length - 1 >= imageIndex)
      {
        if (el.mainImageIndex != el.images[imageIndex].index)
        {
          el.mainImageIndex = el.images[imageIndex].index;
          changes++;
        }
      } else
      {
        el.mainImageIndex = el.images[el.images.length - 1].index;
      }
    });

    this.counterLoadedImages = this.compareProductsLocal.length - changes;

    if (this.counterLoadedImages == this.compareProductsLocal.length)
    {
      this.hidePicture = false;
    }

    this.changeDet.detectChanges();
  }

  public removeFromCompared(product: any): void
  {
    this.compareService.saveElementToCompare(
      this.compareProductsLocal.filter(
        (el) => el.familyCode != product.familyCode
      )
    );
    if (this.swiperComponent?.swiperRef)
    {
      this.swiperComponent.swiperRef.update();
    }
    this.changeDet.detectChanges();
  }

  public setImageAsLoaded(): void
  {
    this.counterLoadedImages++;
    if (this.counterLoadedImages == this.compareProductsLocal.length)
    {
      this.isImageLoaded = true;
      this.hidePicture = false;
      this.changeDet.detectChanges();
    }
  }

  public getValueOfParameter(parameter: string, product: any): string
  {
    for (let i = 0; i < product.parameters.length; i++)
    {
      if (parameter == product.parameters[i].name)
      {
        return product.parameters[i].value;
      }
    }
    return "---";
  }

  public addProductToCart(productFamily: any): void
  {
    this.chosenProduct = productFamily;
    this.isLoading = true;
    this.animateButton("loading", productFamily.chosenId);
    this.chosenId = productFamily.chosenId;

    let productFamilyCasted = <ShopProductFamily>productFamily;
    let productToAdd = productFamilyCasted.products.find(
      (o) => o.iD == this.chosenId
    );

    this.cartService.addElementToCart(null, productToAdd, 1).subscribe(() =>
    {
      this.error = null;
      this.isLoading = false;
      this.productAddItemPopupComponent.openModal(
        [productFamily.products.filter(el => el.iD == productFamily.chosenId)[0]],
        productFamily.accessoryProducts,
        false,
        !(productFamily.accessoryProducts && productFamily.accessoryProducts.length > 0)
      );
      this.changeDet.detectChanges();
      this.chosenId = null;
      this.removeAnimateButton('error', productFamily.chosenId);
      this.removeAnimateButton('loading', productFamily.chosenId);
      this.animateButton('success', productFamily.chosenId);
    }, error =>
    {
      this.error = error;
      this.isLoading = false;
      this.chosenId = null;
      this.removeAnimateButton('success', productFamily.chosenId);
      this.removeAnimateButton('loading', productFamily.chosenId);
      this.animateButton('error', productFamily.chosenId);
    });
  }

  private animateButton(s: string, productId: number): void
  {
    const addbutton = this.elementRef.nativeElement.querySelector('#add-button-' + productId);
    this.renderer2.addClass(addbutton, s);
    this.changeDet.detectChanges();
    if (s != 'loading')
    {
      setTimeout(() =>
      {
        this.renderer2.removeClass(addbutton, s);
        this.changeDet.detectChanges();
      }, 3000);
    }
  }

  private removeAnimateButton(s: string, productId: number): void
  {
    this.renderer2.removeClass(this.elementRef.nativeElement.querySelector('#add-button-' + productId), s);
  }

  public isChosenProduct(chosenId: any): boolean
  {
    return this.chosenId == chosenId;
  }

  public greyColorWhenSameValues(compareProducts, parameter): boolean
  {
    let result = true;
    let firstValue = undefined;

    compareProducts.forEach(cp =>
    {
      const parameterObj = cp.parameters.filter(el => el.name == parameter);
      let currentValue = null;

      if (parameterObj.length)
      {
        currentValue = parameterObj[0].value;
      }

      if (firstValue == undefined)
      {
        firstValue = currentValue;
      }
      else
      {
        if (firstValue != currentValue)
        {
          result = false;
        }
      }

    });

    return result;
  }

  public closeSortPopup(): void
  {
    this.showSortPopup = false;
    this.changeDet.detectChanges();
  }

  public toggleSortBottomMenu(product): void
  {
    this.showSortPopup = !this.showSortPopup;
    this.chosenSortPopupOptionsProduct = product;
    this.changeDet.detectChanges();
  }

  public getPrepared(products): string[]
  {
    return products.filter(el => el.isSample == false);
  }

  public goToSimilarProducts(product: ShopProduct): void
  {
    this.guidSubscription = this.authService.guid$.pipe(
      filter((v) => !!v)
    ).subscribe((cg) =>
    {
      this.router.navigate([`/${this.urlTranslateService.routingTable.shop.listing}`], { queryParams: { cg, sti: product.familyCode } });
    });
  }
}
