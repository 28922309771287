import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { AnalyticsService } from "@service/analytics/abstract/analytics.service";
import { Router } from '@angular/router';

@Directive({
  selector: '[registerClickEvent]'
})
export class RegisterClickEventDirective
{
  @Input() registerClickEvent;

  constructor(private analyticsService: AnalyticsService,
    private router: Router)
  { }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent)
  {
    let tag = (<Element>event.currentTarget).tagName.toLowerCase();

    if (tag == "a" || tag == "span" || tag == "p")
    {
      let anchor = <HTMLAnchorElement>event.currentTarget;
      let eventParameters: { [key: string]: string } = {};
      eventParameters.page_location = this.router.url;

      let eventType = undefined;
      let eventPresetParameters: { [key: string]: string } = undefined;
      let nonInteractive = false;

      if (this.registerClickEvent)
      {
        [eventType, eventPresetParameters, nonInteractive] = this.registerClickEvent;
      }

      if (eventPresetParameters)
      {
        eventParameters = { ...eventParameters, ...eventPresetParameters };
      }

      if (eventType == undefined)
      {
        if (anchor.href.indexOf("tel:") >= 0)
        {
          eventType = "contact_call";
          eventParameters.phone_number = anchor.pathname.replace(/\s/g, "");
        }
        else if (anchor.href.indexOf("mailto:") >= 0)
        {
          eventType = "contact_mail";
          eventParameters.email_address = anchor.pathname.replace(/\s/g, "");
        }
        else
        {
          eventType = "click";
          eventParameters.button_link = anchor.pathname.replace(/\s/g, "");
        }

        eventParameters.button_text = anchor.text;
      }

      if (nonInteractive == undefined)
      {
        nonInteractive = false;
      }

      this.analyticsService.registerEvent(eventType, eventParameters, nonInteractive);
    }
  }

  public generateContextEvent(): void
  {

  }
}
