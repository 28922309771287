import
{
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { ShopProduct, Wishlist } from "@dto";
import { CompareService } from "@service/compare/compare.service";
import { WishlistService } from "@service/wishlist/wishlist.service";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { UrlTranslateService } from '@service/helpers/url-translate.service';

@Component({
  selector: "app-fav-popup",
  templateUrl: "./fav-popup.component.html",
  styleUrls: ["./fav-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavPopupComponent implements OnInit, OnDestroy
{

  public wishlist$: Observable<Wishlist>;
  public selectedItems: any[] = [];
  private destroy$: Subject<void> = new Subject();
  compareTrigger: boolean = false;
  public shouldShow: boolean = false;

  constructor(
    private router: Router,
    private wishlistService: WishlistService,
    private compareService: CompareService,
    private changeDet: ChangeDetectorRef,
    public urlTranslateService: UrlTranslateService
  )
  {
    this.wishlist$ = this.wishlistService.wishlist$.pipe(filter((v) => !!v), takeUntil(this.destroy$));
  }

  public ngOnInit(): void
  {
    this.compareService.compare$
      .pipe(
        filter((v) => !!v),
        takeUntil(this.destroy$)
      )
      .subscribe((res) =>
      {
        this.selectedItems = res;
      });
  }

  public ngOnDestroy(): void
  {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public show(): void
  {
    this.shouldShow = true;
    this.changeDet.detectChanges();
  }

  public hide(): void
  {
    this.shouldShow = false;
    this.changeDet.detectChanges();
  }

  public compareItems(): void
  {
    this.compareTrigger = true;

    if (this.selectedItems.length >= 2)
    {
      this.compareService.saveElementToCompare(this.selectedItems);
      this.router.navigateByUrl(`/${this.urlTranslateService.routingTable.shop.compare}`);
    }

    this.changeDet.detectChanges();
  }

  public changeSelected()
  {
    this.changeDet.detectChanges();
  }

  public isSelected(product: any): boolean
  {
    const x = this.selectedItems.filter(
      (el) => (el.Url && el.Url == product.url) || el.url == product.url
    );

    return x.length > 0;
  }

  public removeElementFromCart(product: ShopProduct): void
  {
    this.wishlistService
      .removeProductFromWishList(product.familyCode)
      .subscribe(() =>
      {
        this.selectedItems = this.selectedItems.filter(
          (el) => el.familyCode != product.familyCode
        );
        this.compareService.saveElementToCompare(this.selectedItems);
      });
  }

  public trackByIndex(index, item): number
  {
    return item.familyCode;
  }

  public setAsActive(product: ShopProduct, $event): void
  {
    if (this.isSelected(product))
    {
      this.selectedItems = this.selectedItems.filter(
        (el) => el.familyCode != product.familyCode
      );
    } else
    {
      this.selectedItems.push(product);
    }
    this.compareService.saveElementToCompare(this.selectedItems);
  }
}
