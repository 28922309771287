import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

interface ConditionalRequired
{
  field: string;
  value: string;
  required: string[];
}

export class CustomValidators
{

  public static equal(fields: string[]): ValidatorFn
  {
    return (control: AbstractControl) =>
    {

      const result = fields.map(field => control.value[field])
        .reduce((acc: string[], curr: string) =>
        {
          if (acc.indexOf(curr) < 0)
          {
            acc.push(curr);
          }

          return acc;
        }, []);

      if (result.length === 1)
      {
        return null;
      }

      return { notEqual: true };
    };
  }

  public static conditionalRequired(conditions: ConditionalRequired[]): ValidatorFn
  {
    return (control: AbstractControl) =>
    {

      const results = conditions.filter(condition => control.value[condition.field] === condition.value)
        .reduce((acc, curr) =>
        {

          curr.required.map(required => Validators.required(control.get(required)))
            .filter(result => result !== null)
            .forEach(result => acc.push(result));

          return acc;
        }, []);

      if (results.length === 0)
      {
        return null;
      }

      return { required: true };
    };
  }

  public static postCode(control: AbstractControl): ValidationErrors
  {

    if (control.value === '')
    {
      return null;
    }

    const regexp = /^[0-9]{2}\-[0-9]{3}$/;

    if (regexp.test(control.value))
    {
      return null;
    }

    return { postCode: true };
  }

  public static telephone(control: AbstractControl): ValidationErrors
  {

    if (control.value === '')
    {
      return null;
    }

    const regexp = /^(\+48)* ?([0-9]{9}|[0-9]{3}\-[0-9]{3}\-[0-9]{3})$/;

    if (regexp.test(control.value))
    {
      return null;
    }

    return { telephone: true };
  }

  public static email(control: AbstractControl): ValidationErrors
  {

    if (control.value === '')
    {
      return null;
    }

    const regexp = /^\S+@\S+\.\S+$/;

    if (regexp.test(control.value))
    {
      return null;
    }

    return { email: true };
  }

  public static vat(control: AbstractControl): ValidationErrors
  {

    if (control.value === '')
    {
      return null;
    }

    const betterRegexp = /^([0-9]{10,11}|(AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})|(EU)[0-9]{9}|(IE)?[0-9]{7}V$/;
    // const shitRegexp = /^(PL)*([0-9]{10}|[0-9]{3}\-[0-9]{3}\-[0-9]{2}\-[0-9]{2})$/;

    if (betterRegexp.test(control.value))
    {
      return null;
    }

    return { vat: true };
  }

}
