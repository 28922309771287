import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ValueWithCommaPipe } from "./value-with-comma.pipe"
import { TrimPipe } from "./trim.pipe"
import { RemoveSpacesPipe } from './remove-spaces.pipe';

@NgModule({
    declarations: [
        ValueWithCommaPipe,
        RemoveSpacesPipe,
        TrimPipe
    ],
    imports: [
        CommonModule,
    ],
    providers: [
        ValueWithCommaPipe,
        RemoveSpacesPipe,
        TrimPipe
    ],
    exports: [
        ValueWithCommaPipe,
        RemoveSpacesPipe,
        TrimPipe
    ]
})
export class PipesModule
{
}
