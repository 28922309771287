import { Injectable } from '@angular/core';
import { Filter, FilterOptions, RecordsPerPage, SearchResult, SearchResultDTO, Sort, Record } from '@idto';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParsedSearchResult } from '../../../model/ParsedSearchResult.model';
import { getCamelCaseVariableName } from '../../../utils/utilsFunctions';
import { SearchRepositoryService } from '../search-repository/search-repository.service';
import { StringVariable } from 'app/model/StringVariable.model'

export const GROUP_IDS = {
  WYKLADZINA_DYWANOWA: "WD",
  WYKLADZINA_ELASTYCZNA: "WV",
  WYKLADZINA_TRAWA: "WT",
  AKCESORIA_WYCIERACZKI: "AW",
  DYWANY: "DY",
  CHODNIKI: "CH",
  PODUSZKI: "PO"
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService
{

  public activeFiltersSubject = new BehaviorSubject<Filter[]>([]);
  public activeFilters$ = this.activeFiltersSubject.asObservable();

  constructor(protected searchRepositoryService: SearchRepositoryService)
  {
  }

  public fetch(): Observable<ParsedSearchResult>
  {
    return this.searchRepositoryService.fetch().pipe(map((result: SearchResultDTO) => this.parseResult(result.searchResult)));
  }

  public fetchByUrlQuery(urlQuery: string): Observable<ParsedSearchResult>
  {
    if (!urlQuery)
    {
      return this.searchRepositoryService.fetch().pipe(map((result: SearchResultDTO) => this.parseResult(result.searchResult)));
    }
    else
    {
      return this.searchRepositoryService.fetchByQuery(urlQuery).pipe(map((result: SearchResultDTO) => this.parseResult(result.searchResult)));
    }
  }

  protected parseResult(result: SearchResult): ParsedSearchResult
  {
    //this.activeFiltersSubject.next(this.buildActiveFilters(getCamelCaseVariableName(result.filters, true)));
    // todo: do analizy pozniej co tak naprawde bedzie potrzebne, podmienic z backendu jak mozliwe

    result.records.forEach(record =>
    {
      record.isCarpet = this.isCarpetGroup(record);
      record.isWalkway = this.isWalkwayGroup(record);
      record.isCovering = this.isCoveringGroup(record);
      record.isWiper = this.isWiperGroup(record);
    });

    let newResult: ParsedSearchResult = {
      description: result.description,
      descriptionExtension: result.descriptionExtension,
      //advanced: result.filters['advanced'],
      records: result.records,
      paging: result.paging,
      filters: result.filters,
      header: result.header,
      recommendationID: result.recommendationID,
      activeFilters: this.buildActiveFilters(result.filters),
      filterOptions: this.buildFilterOptions(result.filterOptions),
      sorts: result.sorts,
      recordsPerPage: result.recordsPerPage,
      searchQuery: result.searchQuery,
      title: result.title
    };

    this.activeFiltersSubject.next(newResult.activeFilters);

    return newResult;
  }

  protected buildFilterOptions(filterOptions: FilterOptions): FilterOptions
  {
    //filterOptions.type = StringVariable.FILTER;
    return filterOptions;
  }

  public buildActiveFilters(filters: Filter[]): Filter[]
  {
    const deepCopy = JSON.parse(JSON.stringify(filters));

    let activeFilters = [];
    deepCopy.forEach(filter =>
    {
      if (filter.type === 'Select' || filter.type === 'Multiselect')
      {
        activeFilters = activeFilters.concat(this.getActiveSelectTypeFilter(filter));
      }
      if (filter.type === StringVariable.INTERVAL)
      {
        activeFilters = activeFilters.concat(this.getActiveIntervalTypeFilter(filter));
      }
    });
    return activeFilters;
  }

  private isCoveringGroup(record: Record): boolean
  {
    const groupId = record.productGroupID.substring(0, 2);
    return !!(groupId && (groupId === GROUP_IDS.WYKLADZINA_DYWANOWA ||
      groupId === GROUP_IDS.WYKLADZINA_ELASTYCZNA ||
      groupId === GROUP_IDS.WYKLADZINA_TRAWA));
  }

  private isWiperGroup(record: Record): boolean
  {
    const groupId = record.productGroupID.substring(0, 2);
    return !!(groupId && groupId === GROUP_IDS.AKCESORIA_WYCIERACZKI);
  }

  private isCarpetGroup(record: Record): boolean
  {
    const groupId = record.productGroupID.substring(0, 2);
    return !!(groupId && groupId === GROUP_IDS.DYWANY);
  }

  private isWalkwayGroup(record: Record): boolean
  {
    const groupId = record.productGroupID.substring(0, 2);
    return !!(groupId && groupId === GROUP_IDS.CHODNIKI);
  }

  private getActiveSelectTypeFilter(filter: Filter): Filter[]
  {
    const activeFilters = [];
    filter.values = getCamelCaseVariableName(filter.values, true);
    let selectedFilter = filter.values.filter(value => value.selected);
    if (selectedFilter.length > 0)
    {
      activeFilters.push(filter);
      activeFilters.filter(localFilter => localFilter === filter).forEach(localFilter => localFilter.values = selectedFilter);
    }
    return activeFilters;
  }

  private getActiveIntervalTypeFilter(filter: Filter): Filter[]
  {
    const activeFilters = [];
    filter.selectedInterval = getCamelCaseVariableName(filter.selectedInterval, true);
    filter.local = getCamelCaseVariableName(filter.local, true);
    if (filter.selectedInterval.min !== filter.local.min || filter.selectedInterval.max !== filter.local.max)
    {
      activeFilters.push(filter);
      activeFilters.filter(filterLocal => filterLocal === filter).forEach(filterLocal =>
      {
        filterLocal.values = [];
        if (filter.selectedInterval.min !== filter.local.min)
        {
          filterLocal.values.push(
            {
              value: 'od ' + filter.selectedInterval.min + ' ' + filter.unit,
              link: this.parseLink(filter.linkTemplate, [filter.selectedInterval.min, filter.selectedInterval.max])
            });
        }
        if (filter.selectedInterval.max !== filter.local.max)
        {
          filterLocal.values.push(
            {
              value: 'do ' + filter.selectedInterval.max + ' ' + filter.unit,
              link: this.parseLink(filter.linkTemplate, [filter.selectedInterval.min, filter.selectedInterval.max])
            });
        }
      });
    }
    if (filter.innerFilters)
    {
      filter.innerFilters = getCamelCaseVariableName(filter.innerFilters, true);
      filter.innerFilters.forEach(singleFilter =>
      {
        let selectedFilter = getCamelCaseVariableName(singleFilter.values, true).filter(value => value.selected);
        if (selectedFilter.length > 0)
        {
          activeFilters.push(singleFilter);
          activeFilters.filter(localFilter => localFilter == singleFilter).forEach(localFilter =>
          {
            localFilter.values = selectedFilter;
          });
        }
      });
    }
    return activeFilters;
  }

  private buildSorting(sorting: Sort[]): Sort[]
  {
    return sorting.filter((sort) => { return sort.field !== 'punkty_polecenia_pierwsza_strona'; }).map((sort) =>
    {
      //sort.type = 'Sorting';
      return sort;
    });
  }

  private parseLink(linkTemplate: string, values: number[], remove: boolean = false): string
  {
    return linkTemplate.replace('{MIN}', String(values[0])).replace('{MAX}', String(values[1]));
  }
}
