import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProductReview, ShopProductFamily } from '@dto';
import { TranslateService } from '@ngx-translate/core';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { ReviewPopupComponent } from '../../../sharedModules/shared/modals/review-popup/review-popup.component';

@Component({
  selector: 'app-product-rating',
  templateUrl: './product-rating.component.html',
  styleUrls: ['./product-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductRatingComponent
{
  @ViewChild(ReviewPopupComponent, { static: true }) private reviewPopup: ReviewPopupComponent;

  get product(): ShopProductFamily
  {
    return this._product;
  }

  get productReviews(): ProductReview[]
  {
    return this.hasReview ? this._product.reviews : [];
  }

  @Input() set product(value: ShopProductFamily)
  {
    if (value)
    {
      this._product = value;
      this.ratingAverage = this.calculateAverage(value.reviews);
      this.changeDetector.detectChanges();
    }

    this.hasReview = this._product && this._product.reviews && this._product.reviews.length > 0;
    this.getNumberOfElements = this.hasReview ? this._product.reviews.length : 0;
  }

  public getNumberOfElements: number;
  public hasReview: boolean
  private _product: ShopProductFamily;
  public ratingAverage: number = 0;
  public ratingSuccess: boolean = false;

  constructor(private windowRef: WindowRefService,
    private router: Router,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef)
  {
  }

  public calculateAverageSVG(): string
  {
    if (this.ratingAverage)
    {
      let ratingNumberDecimal = (this.ratingAverage * 10);
      let ratingNumberFixed = ratingNumberDecimal.toFixed(0).toString();

      if (ratingNumberDecimal >= 10 && ratingNumberDecimal <= 50)
      {
        return "/assets/icons/review/rev" + ratingNumberFixed + ".svg";
      }
    }

    return "/assets/icons/review/rev44.svg";
  }

  public calculateAverage(value: any[]): number
  {
    if (!value)
    {
      return;
    }
    let sum = value.length ? 0 : 1;
    value.forEach(el => sum += el.rating);
    return sum / value.length;

  }

  public getActiveElements(ratingAverage: number): number
  {
    return Math.floor(ratingAverage);
  }

  public getFilteredValue(i: number): number
  {
    return this.hasReview ? this._product.reviews.filter(el => el.rating === i).length : 0;
  }

  public getNumberOfElementsDescription(): string
  {
    if (this.hasReview)
    {
      if (this._product.reviews.length === 1)
      {
        return this.translate.instant('reviews.1opinion');
      }
      if (this._product.reviews.length < 5 && this._product.reviews.length > 1)
      {
        return this._product.reviews.length + ' ' + this.translate.instant('reviews.to4Opinions');
      }
      return this._product.reviews.length + ' ' + this.translate.instant('reviews.fewOpinions');
    }
    return '';
  }

  public openReviewPopup($event, product: ShopProductFamily): void
  {
    $event.stopPropagation();
    // todo: tutaj przychodzi product: ShopProduct
    this.reviewPopup.openModal(product, null);
  }
}
