import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsService } from '@service/breadcrumbs/breadcrumbs.service';
import { Observable } from 'rxjs';
import { Breadcrumbs } from '../../../../model/Breadcrumbs.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit
{
  public breadcrumbs$: Observable<Breadcrumbs[]>;
  @Input() public doubleHeight: boolean;

  constructor(private breadcrumbService: BreadcrumbsService,
    private router: Router,
    private location: Location)
  {
  }

  public ngOnInit(): void
  {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
  }

  public goToPage(url): void
  {
    this.router.navigateByUrl(url);
  }

  public goBack(): void
  {
    this.location.back();
  }
}
