import { HttpErrorResponse } from '@angular/common/http';
import { GeneralError } from '../utils/general.error';
import { InstrumentationEvent } from './LogEvent.model';

export interface ErrorEvent extends InstrumentationEvent
{
  message: string;
  stack: string[];
}

export class HttpError extends GeneralError
{

  private readonly status: number;
  private readonly trace: string;
  private method: string;
  private url: string;

  constructor(cause: HttpErrorResponse, message: string, messageId: string, method: string, url: string)
  {
    super(`Request ${method} ${url} cause error: ${message}`, cause, messageId);
    Object.setPrototypeOf(this, new.target.prototype);

    this.status = cause.status;
    this.method = method;
    this.url = url;
  }

  public getStatus(): number
  {
    return this.status;
  }

  public getTrace(): string
  {
    return this.trace;
  }
}
