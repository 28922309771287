<div class="heading3 font-style-semi separator1 title">
  {{ "productCalculator.techSpec" | translate }}
</div>
<div class="product-data separator4" [ngClass]="isEven">
  <div class="row cell" *ngFor="let param of productParameters">
    <a
      class="param-a"
      *ngIf="param.link"
      [href]="sanitize('/' + urlTranslateService.routingTable.shop.listing + '?' + param.link)"
      (click)="goToListing('/' + urlTranslateService.routingTable.shop.listing + '?' + param.link)"
    >
      <div class="param-name">{{ param.name }}</div>
      <div class="param-value" [innerHTML]="param.value"></div>
    </a>
    <div class="param-a" *ngIf="!param.link">
      <div class="param-name">{{ param.name }}</div>
      <div class="param-value" [innerHTML]="param.value"></div>
    </div>
  </div>
</div>
