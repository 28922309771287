import { Component, Input } from "@angular/core";
import { ContactPopupAbstractComponent } from "../../contact-popup-abstract/contact-popup-abstract.component";

@Component({
  selector: "app-product-availability-popup",
  templateUrl: "./product-availability-popup.component.html",
  styleUrls: ["./product-availability-popup.component.scss"],
})
export class ProductAvailabilityPopupComponent extends ContactPopupAbstractComponent
{
  @Input() public product: any;

  public userCords: any;
  public isLocationDisabled: boolean = false;

  public turnOnGeolocation(): void
  {
    if (this.windowRef.getNativeWindow().navigator.geolocation)
    {
      navigator.geolocation.getCurrentPosition(
        (position) =>
        {
          this.toggleLocationTooltip(false);
          this.getClosestPoint(position);
        },
        () =>
        {
          this.toggleLocationTooltip(true);
          // handleLocationError(true, infoWindow, map.getCenter());
        }
      );
    } else
    {
      this.toggleLocationTooltip(true);
      // Browser doesn't support Geolocation
      // handleLocationError(false, infoWindow, map.getCenter());
    }
  }

  toggleLocationTooltip(status?: boolean): void
  {
    if (status !== undefined && status !== this.isLocationDisabled)
    {
      this.isLocationDisabled = status ? status : !this.isLocationDisabled;
      this.changeDetector.detectChanges();
    }
  }

  private getClosestPoint(originPosition: any): void
  {
    this.userCords = originPosition.coords;
    const closestElem = [];

    for (const mm of this.dropdownOptions)
    {
      closestElem.push({
        sum:
          Math.abs(this.userCords.latitude - +mm.coordinates.Latitude) +
          Math.abs(this.userCords.longitude - +mm.coordinates.Longitude),
        marker: mm,
      });
    }

    let smallest = closestElem[0];
    for (let i = 0; i < closestElem.length; i++)
    {
      if (smallest.sum > closestElem[i].sum)
      {
        smallest = closestElem[i];
      }
    }

    this.storedPickupPlace = smallest.marker;
    this.changeDetector.detectChanges();
  }
}
