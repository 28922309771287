import { Component, HostListener } from '@angular/core';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { ShopModeService } from "@service/helpers/shop-mode.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent
{
  constructor(
    private windowRef: WindowRefService,
    private shopModeService: ShopModeService
  ) { }

  prev(): void
  {
    this.windowRef.getNativeWindow().history.back();
  }

  next(): void
  {
    this.windowRef.getNativeWindow().history.forward();
  }

  isPrev(): boolean
  {
    return window.history.length > 2;
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(e: Event)
  {
    if (this.shopModeService.isKiosk)
    {
      e.returnValue = false;
    }
  }
}
