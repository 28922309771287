import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';
import { getDpi } from '../../../../utils/utilsFunctions';
import { PictureOptionsInterface } from './picture-options.interface';
import { ConfigService } from '@service/config/config.service';
import { ImageOptionElement } from 'app/model/interfaces';

@Pipe({
    name: 'getImageUrl'
})
export class GetImageUrlPipe implements PipeTransform
{
    constructor(public configService: ConfigService)
    {

    }

    public newTransform(value: string, options?: ImageOptionElement): any
    {
        let url = value + '?';

        if (options?.width)
        {
            url += '&width=' + options.width;
        }

        if (options?.height)
        {
            url += '&height=' + options.height;
        }

        if (options?.cropType)
        {
            url += '&resizeType=' + options.cropType.trim();
        }

        if (options?.xOffset)
        {
            url += '&cropXOffsetPercent=' + options.xOffset;
        }
        if (options?.yOffset)
        {
            url += '&cropYOffsetPercent=' + options.yOffset;
        }

        if (options?.zoom)
        {
            url += '&zoom=' + options.zoom;
        }

        url += '&dpi=' + getDpi(null).dpi;

        url = encodeURI(url);

        return url.includes("http") ? url : this.configService.ImageUrl + url;
    }

    public transform(value: string, width: number, height: number, index?: string, options?: PictureOptionsInterface): any
    {
        const config = options ?? {} as PictureOptionsInterface;

        if (!window.document.querySelector('html').className.includes('webp'))
        {
            return;
        }

        let url = index != null ? '?index=' + index : '';


        if (value?.includes('?') || url?.includes('?'))
        {
            url = value + url;
        } else
        {
            url += value + '?';
        }

        if (width)
        {
            url += '&width=' + width;
        }

        if (height)
        {
            url += '&height=' + height;
        }

        if (config.fillCrop)
        {
            url += '&resizeType=Fill_Crop';
        }

        if (config.fillCover)
        {
            url += '&resizeType=Fill_Cover';
        }

        if (!config?.dpi)
        {
            let dpiData = getDpi(options?.dpi);
            let localDpi = dpiData.dpi;
            url += '&dpi=' + localDpi;
        }
        else
        {
            url += '&dpi=' + config.dpi;
        }

        if (options?.product)
        {
            return this.configService.ImageUrl + '/productimages/' + url;
        }

        if (options?.xOffset)
        {
            url += '&cropXOffsetPercent=' + options.xOffset;
        }
        if (options?.yOffset)
        {
            url += '&cropYOffsetPercent=' + options.yOffset;
        }

        if (options?.zoom)
        {
            url += '&zoom=' + options.zoom;
        }

        url = encodeURI(url);

        return options?.prefix ? this.configService.ImageUrl + url : url;
    }
}
