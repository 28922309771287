import
{
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Record } from "@idto";
import { TranslateService } from "@ngx-translate/core";
import { Resolutions } from "@resolutions";
import { UrlHelperService } from "@service/helpers/url-helper.service";
import { ProductsService } from "@service/products/products.service";
import { AuthService } from "@service/session/auth/auth.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { BehaviorSubject, filter, map, of, Subject, timeout } from "rxjs";
import { PaginationOptions } from "swiper/types/modules/pagination";
import SwiperCore, { Pagination, Navigation, SwiperOptions, FreeMode, Swiper, } from "swiper";
import { NavigationOptions } from "swiper/types/modules/navigation";
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { ParsedSearchResult } from "app/model/ParsedSearchResult.model";
import { SwiperConfigService } from "@service/swiper-config.service";
import { SliderBaseComponent } from "../slider/slider-base/slider-base.component";

SwiperCore.use([Pagination, Navigation, FreeMode]);

@Component({
  selector: "app-product-slider",
  templateUrl: "./product-slider.component.html",
  styleUrls: ["./product-slider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSliderComponent extends SliderBaseComponent 
{
  private lastSearchedQueryProducts: string;
  private _products = "a=1&p=1&c=16&s=polecane";
  public productList$: any;

  private slideNumber: number;
  public allCoverings: boolean;
  public loading: Subject<boolean> = new BehaviorSubject(false);
  public swiperIsLoaded: boolean = false;
  public canRoute: boolean = false;
  public recommendationID: string;
  public linkToMoreUrl: UrlTree;
  public linkToMoreUrlNoC: object;
  public forceLoad: number = 5;

  public get products(): any { return this._products; }
  @Input() public set products(value: any) { this.setProducts(value); }
  @Input() public hideMoreLink: boolean;
  @Input() public moveMoreDown: boolean;
  @Input() public title: string;
  @Input() public secondaryTitle: string;
  @Input() public secondaryTitleHref: string;
  @Input() public linkToMoreText: string;
  @Input() public noInit = false;
  @Input() public readyProducts: any[];
  @Input() public height: number[] = [242, 242, 405];
  @Input() public width: number[] = [180, 180, 285];
  @Input() public cardSize: "big" | "small" | "none" = "none";

  @Output() public readonly loaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() public readonly routeActive: EventEmitter<any> = new EventEmitter<any>();
  @Output() public readonly sliderIsEmpty: EventEmitter<any> = new EventEmitter<any>();
  @Output() public readonly pageCount: EventEmitter<number> = new EventEmitter();

  @ViewChild("swiperComponent") private swiperComponent;
  public swiperOpt: SwiperOptions
  //@Input() public pagination: number[] = [2, 2, 1, 1]; // extended 
  @Input() public navigation: number[] = [0, 0, 1, 1];
  @Input() public sliderAfter: number[] = [-8, 8, 8, 64];
  @Input() public sliderBefore: number[] = [16, 24, 48, 128];
  @Input() public spaceBetween: number[] = [50, 50, 32, 32]
  @Input() public slidesPerView: number[] = [1, 2, 3, 3];

  //#region Init/Destroy
  constructor(
    private productService: ProductsService,
    private changeDet: ChangeDetectorRef,
    private authService: AuthService,
    private windowRef: WindowRefService,
    private urlHelper: UrlHelperService,
    private translate: TranslateService,
    private router: Router,
    public urlTranslateService: UrlTranslateService,
    private swiperCo: SwiperConfigService
  )
  {
    super(windowRef, changeDet);
  }
  //#endregion

  private setProducts(value): void
  {
    if (!value.link || (value.link && value.link.length == 0))
    {
      return;
    }

    if (this.lastSearchedQueryProducts == value.link)
    {
      return;
    }

    this._products = value.link;

    if (value.link.includes("customer_guid"))
    {
      this.loading.next(false);
      this.authService.guid$.pipe(filter((v) => !!v)).subscribe((res) =>
      {
        if (res)
        {
          let temp = value.link.replace("{customer_guid}", res);

          if (temp.includes("product_family_code"))
          {
            temp = temp.replace("{product_family_code}", value.productFamilyCode);
          }

          this._products = temp;
          this.linkToMoreUrl = this.router.parseUrl(`/${this.urlTranslateService.routingTable.shop.listing}?` + temp);
          this.linkToMoreUrlNoC = this.removeCParameter(this.linkToMoreUrl.queryParams);
          this.initializeProductListFromData();
          this.loading.next(true);
          this.changeDet.detectChanges();
        }
      });
    }
    else
    {
      this.initializeProductListFromData();
      this.linkToMoreUrl = this.router.parseUrl(`/${this.urlTranslateService.routingTable.shop.listing}?` + value.link);
      this.linkToMoreUrlNoC = this.removeCParameter(this.linkToMoreUrl.queryParams);
      this.loading.next(true);
    }
  }

  public activeIndexChange(event: any): void
  {
    for (let i = event[0].slides.length - 1; i >= 0; i--)
    {
      if (event[0].slides[i].classList.value.includes("visible"))
      {
        this.forceLoad = Math.max(this.forceLoad, i + 2);
        break;
      }
    }
  }

  private initializeProductListFromData(): void
  {
    this.productList$ = this.productService
      .fetchByUrlQuery(this._products)
      .pipe(map((el: ParsedSearchResult) =>
      {
        this.recommendationID = el.recommendationID;
        this.slideNumber = el.records.length;
        this.setResolutionStatus();

        if (this.slideNumber >= 8)
        {
          el.records.push({
            productName: this.translate.instant("showMore"),
            productLink: this.urlHelper.parseQueryObjectToString(this.removeCParameter(this.urlHelper.parseQueryStringToObject(this._products))),
            imageLink: el.records.length ? el.records[0].imageLink : null,
          });
        }

        this.sliderIsEmpty.emit(this.slideNumber <= 1);
        this.pageCount.emit(el.paging.pageCount ?? 0);

        this.swiperOpt = this.swiperCo.getConfig(
          [0, Resolutions.resolutionTablet, Resolutions.resolutionDesktop, Resolutions.resolutionHalfHd],
          this.sliderBefore,
          this.spaceBetween,
          this.sliderAfter,
          this.slidesPerView,
          this.pagination,
        );

        this.swiperOpt.watchSlidesProgress = true;
        this.swiperOpt.observeParents = true;
        this.swiperOpt.navigation = true;
        this.swiperOpt.watchOverflow = false;

        this.allCoverings = el.records.every(o => o.isCovering || !o.productGroupID);
        this.allCoverings = false;
        this.lastSearchedQueryProducts = this._products;

        return el;
      })
      );
  }

  public goToProductPage(product: Record): void
  {
    this.router.navigate([this.urlTranslateService.routingTable.shop.product, product.productLink]);
  }

  public routerGuard($event, record?: any): void
  {
    if (this.windowRef.getNativeWindow().location.pathname.includes(record.productLink))
    {
      $event.stopPropagation();
      $event.preventDefault();
      this.scrollToTop();
      return;
    }

    if (Math.abs(this.swiperComponent.swiperRef.touches.startX - this.swiperComponent.swiperRef.touches.currentX) > 50)
    {
      $event.stopPropagation();
      $event.preventDefault();
    }
    else
    {
      this.routeActive.emit(true);
      this.canRoute = true;
      let query;

      if (this.recommendationID)
      {
        query = { queryParams: { recommendationID: this.recommendationID } };
      }

      this.router.navigate([this.urlTranslateService.routingTable.shop.product, record.productLink], query);
      this.canRoute = false;
    }
  }

  public getRouteLink(record): any
  {
    if (this.windowRef.getNativeWindow().location.pathname.includes("querysearch"))
    {
      return this.canRoute ? [`/${this.urlTranslateService.routingTable.shop.product}/` + record.productLink] : ["/error/querysearch"];
    }

    return this.canRoute ? [`/${this.urlTranslateService.routingTable.shop.product}/` + record.productLink] : null;
  }

  public getRouterLinkQueryParams(record): any
  {
    if (this.windowRef.getNativeWindow().location.pathname.includes("querysearch"))
    {
      const paramDecoded = decodeURIComponent(this.router.url.split("=")[1]);
      return this.canRoute ? null : { querySearch: paramDecoded };
    }
  }

  private scrollToTop(): void
  {
    this.windowRef.getNativeWindowToScroll().scrollTo({ top: 80, left: 0, behavior: "smooth", });
  }

  public setAsConstSortStyle(): void
  {
    this.urlHelper.isLinkConst = true;
    this.changeDet.detectChanges();
  }

  public setAsConstSortStyleLast(link): void
  {
    this.router.navigate([`/${this.urlTranslateService.routingTable.shop.listing}`], { queryParams: this.urlHelper.parseQueryStringToObject(link) });
    this.urlHelper.isLinkConst = true;
    this.changeDet.detectChanges();
  }

  public removeCParameter(object: object): object
  {
    delete object["c"];
    return object;
  }

  @HostListener("mouseup", ["$event"])
  public onClick($event): void
  {
    if ($event.which === 2)
    {
      this.canRoute = true;
      this.changeDet.detectChanges();
    }
    setTimeout(() =>
    {
      this.canRoute = false;
      this.changeDet.detectChanges();
    }, 100);
  }

  public noNavigation(length: number = 0): boolean
  {
    if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionTablet)
    {
      return length < 2;
    }

    return length < 4;
  }
}
