import
{
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from "@angular/core";
import { MediaInfo, ShopProductFamily } from "@dto";
import { getImageUrl } from "../../../../utils/utilsFunctions";

@Component({
  selector: "app-product-picture",
  templateUrl: "./product-picture.component.html",
  styleUrls: ["./product-picture.component.scss"],
})
export class ProductPictureComponent implements AfterViewInit
{
  public mainImageIndex: number = 0;

  get product(): ShopProductFamily
  {
    return this._product;
  }

  @Input() set product(value: ShopProductFamily)
  {
    this._product = Object.assign({}, value);
    this._product.images.sort((a, b) => a.productPageIndex - b.productPageIndex);
    this._product.images.map((image, index) =>
    {
      image.src = getImageUrl(this._product.familyCode, this._product.images[index].index.toString(), [2000, 2000, 2000], [2000, 2000, 2000], '100', null, null, null, true);
      image.width = image.aspectRatio > 1 ? 2000 * image.aspectRatio : 2000;
      image.height = image.aspectRatio > 1 ? 2000 : 2000 / image.aspectRatio;
    });
  }

  @ViewChild("gallerySlider") public gallerySlider;
  @Input() public width: number[] = [];
  @Input() public swiperHeight: number[] = [];
  @Input() public swiperWidth: number[] = [];
  public getImageUrl = getImageUrl;
  private _product: ShopProductFamily;
  public mainImage: MediaInfo;

  constructor(
    private changeDet: ChangeDetectorRef
  ) { }

  public changeMainImage(index: number): void
  {
    if (!this.mainImage || (this.mainImage && this.mainImage.index !== this.product.images[index].index))
    {
      this.mainImage = this._product.images[index];
      this.mainImageIndex = index;

      if (this.changeDet)
      {
        this.changeDet.detectChanges();
      }
    }
  }

  public onSlideChange(activeIndex)
  {
    this.gallerySlider?.setAsMainImage(activeIndex);
  }

  public ngAfterViewInit(): void
  {
    this.changeMainImage(0);
  }
}