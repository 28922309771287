<div class="main-wrapper">
  <div class="main-slider full-width-section">
    <app-main-slider [height]="[446, 446, 600]" [width]="[768, 1280, 1400]" class="top-slider"></app-main-slider>
  </div>

   <app-tile-rwd-box class="separator-big-up"></app-tile-rwd-box>

  <app-product-slider
    *ngIf="productSliders"
    [title]="'main.lastWatched' | translate"
    class="slider-template-small-products"
    [ngClass]="{ hidden: lastWatchedSliderIsEmpty }"
    [spaceBetween]="[12, 50, 32, 32]"
    [hideMoreLink]="lastWatchedMoreLinkHidden"
    [linkToMoreText]="'showMore' | translate"
    [products]="{ link: productSliders.recentlyWatchedProducts }"
    (sliderIsEmpty)="isSliderEmpty($event)"
    (pageCount)="isShowMoreHidden($event)"
  ></app-product-slider>

  <app-product-slider
    *ngIf="productSliders"
    [title]="'main.newThing' | translate"
    class="slider-template-big-products"
    [height]="[242, 339, 607]"
    [width]="[180, 250, 435]"
    [slidesPerView]="[1, 1, 2, 2]"
    [spaceBetween]="[12, 50, 32, 32]"
    [linkToMoreText]="'showMore' | translate"
    [products]="{ link: productSliders.newProducts }"
  ></app-product-slider>

  <div class="insparanz-slider">
    <div class="title-section">
      <div class="title-wrapper">
        <h2 class="title">{{ "main.arrangement" | translate }}</h2>
        <p class="secondary-title">{{ "arteMotto" | translate }}</p>
      </div>
      <a class="link-to-more" [href]="mediaLinks?.instagramLink" (click)="openInNewCard(mediaLinks?.instagramLink, $event)">
        {{ "moreOnInstagram" | translate }}
      </a>
    </div>

    <swiper [config]="swiperOpt" class="swiper slider-main" #swiperComponent>
      <ng-template *ngFor="let product of inspAranz.list; let i = index" swiperSlide>
        <div class="compared-product" [ngClass]="{ first: i == 0 }">
          <div class="image-wrapper">
            <a [href]="mediaLinks?.instagramLink" (click)="openInNewCard(mediaLinks?.instagramLink, $event)">
              <app-img
                [lazy]="i > 4"
                [placeholderGray]="true"
                [placeholder]="false"
                [src]="product.image.url"
                [width]="[242, 260, 400]"
                [height]="[242, 260, 400]"
              ></app-img>
            </a>
          </div>
        </div>
      </ng-template>
    </swiper>

  </div>

  <section class="infos-wrapper">
    <h2>{{ "main.howWeCanHelp" | translate }}</h2>
    <app-tile-box
      [blockName]="mainPageServices"
      [columns]="3"
      [title]="'main.howWeCanHelp' | translate"
    ></app-tile-box>
  </section>

  <app-product-slider
    *ngIf="productSliders"
    [title]="'main.selectedProducts' | translate"
    class="slider-template-small-products last-slider"
    [products]="{ link: productSliders.chosenProducts }"
    [spaceBetween]="[12, 50, 32, 32]"
    [secondaryTitle]="'main.discoverProducts' | translate"
    [linkToMoreText]="'showMore' | translate">
  </app-product-slider>

  <app-opineo [reviews]="opineoReviews"></app-opineo>

</div>
