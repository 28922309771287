import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AdvisorPopupComponent } from '../modals/advisor-popup/advisor-popup.component';
import { ContactFormPopupComponent } from '../modals/contact-form-popup/contact-form-popup.component';
import { Contact } from 'app/model/interfaces';
import { BlockService } from '@shared/block/shared/block.service';
import { UrlTranslateService } from "@service/helpers/url-translate.service";
import { ChatService } from '@service/chat/chat-service.service';

@Component({
  selector: 'app-contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactPopupComponent implements OnInit
{
  public shouldShow: boolean = false;
  @ViewChild(ContactFormPopupComponent, { static: true }) private contactFormPopup: ContactFormPopupComponent;
  @ViewChild(AdvisorPopupComponent, { static: true }) private advisorComponent: AdvisorPopupComponent;
  public contact: Contact;
  public workdayHoursSameAsSaturday: boolean;

  constructor(
    private blockService: BlockService,
    private chatService: ChatService,
    private changeDet: ChangeDetectorRef,
    public urlTranslateService: UrlTranslateService,
  )
  {
    this.contact = this.blockService.getDataSync('contact').contact;

    if (this.contact && this.contact.openHours && this.contact.openHours.weekday)
    {
      this.workdayHoursSameAsSaturday = this.contact.openHours.weekday.from == this.contact.openHours.saturday.from &&
        this.contact.openHours.weekday.to == this.contact.openHours.saturday.to;
    }
  }

  public ngOnInit(): void
  {
  }

  public show(): void
  {
    this.shouldShow = true;
    this.changeDet.detectChanges();
  }

  public hide(): void
  {
    this.shouldShow = false;
    this.changeDet.detectChanges();
  }

  public openContactForm($event): void
  {
    $event.stopPropagation();
    this.contactFormPopup.openModal();
    this.hide();
  }

  public openAdvisorPopup($event): void
  {
    $event.stopPropagation();
    this.advisorComponent.openModal();
    this.hide();
  }

  public showIntercom(): void
  {
    this.chatService.open();
    this.hide();
  }
}
