<div class="calendar">
  <ul>
    <li *ngFor="let month of months">
      <h3 *ngIf="months.length >= 1">{{ month.name }}</h3>
      <ul class="horizontal">
        <li *ngFor="let day of dayNamesMin">{{day}}</li>
      </ul>
      <ul>
        <li *ngFor="let week of month.weeks;let i=index" class="week">
          <ul [ngClass]="{'no-border': i>=month.weeks.length-1}">
            <li *ngFor="let day of week" class="day"
                [ngClass]="{'highlighted': day?.highlighted, 'disabled': day?.disabled, 'with-icon': day?.icon}">
              <span class="day-text">{{ day?.day}}</span>
              <i *ngIf="day?.icon" [ngClass]="['day-icon', 'aif', day.icon]"></i>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>
