<div class="modal-wrapper" *ngIf="showForm" [ngClass]="{'loaded': loaded && loaded['loaded']}">
  <div class="wrapper">
    <div class="under-wrapper" [ngClass]="{'loaded': loaded && loaded['loaded']}" sdClickOutside (clickOutside)="closeModal($event)" #parent
         [parentRef]="parent">
      <div (click)="closeModal($event)" class="close-button">
        <i class="ai ai-cancel"></i>
      </div>

      <div class="font-style-semi main-title separator1" [ngClass]="getClassNameByResolution('heading5','heading3')">
        {{mainTitle}}
      </div>
      <div class="main-info-wrapper">
        <div class="image-wrapper">
          <app-img
            [src]="product.familyCode"
            [index]="0"
            [height]="[72, 72, 72]"
            [options]="{
              product: true
            }"
            [alt]="product?.name"
          ></app-img>
        </div>
        <div>
          <p class="color-body-text font-style-light">{{ 'modalsTexts.orderFreeSample' |translate }}</p>
          <p class="font-style-semi">{{product?.name}}</p>
        </div>
      </div>
      <div class="button-wrapper">
        <span class="success-link" (click)="closeModal($event)">{{ 'cancel' |translate }}</span>
        <div class="button primary-button" #addButton (click)="addToCart()">{{ 'favourite.addToCart2' |translate }}</div>
      </div>
    </div>
  </div>
</div>
