import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BlockService } from '@shared/block/shared/block.service';
import { map } from 'rxjs/operators';


export class CustomTranslateLoader implements TranslateLoader
{
    constructor(private blockService: BlockService)
    {
    }

    getTranslation(lang: string): Observable<any>
    {
        return this.blockService.getData("translation").pipe(map((data) => JSON.parse(data)));
    }
}