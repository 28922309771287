<div [hidden]="!shouldShow" class="popup-wrapper">
  <div class="wrapper" *ngIf="cart$|async as cart; else emptyState" [ngClass]="{'empty':cart.items.length==0}">
    <ng-scrollbar class="list-wrapper horizontal-scrollbar" autoHeightDisabled="false">
      <div class="single-element" *ngFor="let item of cart.items">
        <div class="item-wrapper">
          <div class="image-wrapper">
            <app-img [src]="item.product.familyCode" [index]="0" [lazy]="false" [height]="[54, 54, 54]" [width]="[54, 54, 54]" [options]="{ product: true }"></app-img>
          </div>
          <div class="column name-column" (click)="goToProductPage(item)">
            <p class="font-style-semi">{{item.product.name}}</p>
            <p class="item-size">{{ item.optionName }}: {{item.option}}</p>
          </div>
          <ng-container *ngIf="item.totalPromotionPriceBrutto != item.totalStandardPriceBrutto">
            <div class="column prices">
              <p class="promotion-price">{{item.totalPromotionPriceBrutto| valueWithComma}} {{'general.currency' |translate}}</p>
              <p class="standard-price">{{item.totalStandardPriceBrutto| valueWithComma}} {{'general.currency' |translate}}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="item.totalStandardPriceBrutto == item.totalPromotionPriceBrutto">
            <p class="standard-price-without-promotion">{{item.totalStandardPriceBrutto| valueWithComma}} {{'general.currency'
              |translate}}</p>
          </ng-container>
          <div class="image-wrapper cancel-wrapper" (click)="removeElementFromCart(item)">
            <i class="ai ai-cancel"></i>
          </div>
        </div>
        <div class="sub-item-wrapper" *ngFor="let subItem of item.activeSubItems">
          <img class="sub-item-pic" [src]="'/assets/icons/addition.svg'">
          <p class="sub-item-name ">{{subItem.product.name}}</p>
          <ng-container *ngIf="subItem.totalStandardPriceBrutto != subItem.totalPromotionPriceBrutto">
            <div class="column prices">
              <p class="promotion-price">{{subItem.totalPromotionPriceBrutto| valueWithComma }} {{'general.currency' |translate}}</p>
              <p class="standard-price">{{subItem.totalStandardPriceBrutto| valueWithComma }} {{'general.currency' |translate}}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="subItem.totalStandardPriceBrutto == subItem.totalPromotionPriceBrutto">
            <p class="standard-price-without-promotion">{{subItem.totalStandardPriceBrutto| valueWithComma }} {{'general.currency'
              |translate}}</p>
          </ng-container>
        </div>
      </div>
    </ng-scrollbar>
    <div class="summary-cost" *ngIf="cart.items.length>0">
      <span>{{ 'productVariants.summaryValueFull' |translate }}</span><span
        class="font-style-semi">{{ cart.subPromotionTotalBrutto | valueWithComma }} {{'general.currency' |translate}}</span>
    </div>
    <div class="button-wrapper" *ngIf="cart.items.length>0">
      <div class="button primary-button" (click)="goToCheckout()">{{ 'cart.goToCheckout' |translate }}</div>
    </div>
    <ng-container *ngIf="cart.items.length==0">
      <p class="empty-state-title">{{ 'cart.emptyCart' |translate }}</p>
      <p class="empty-state-desc">{{ 'cart.addOurProducts' |translate }}</p>
      <div class="separator-big"></div>
      <p class="invite">{{ 'cart.cannotDecide' |translate }}</p>
      <div class="button-wrapper">
        <div class="button secondary-button" (click)="goToBestsellers($event)">{{ 'cart.showBestsellers' |translate }}
        </div>
      </div>
    </ng-container>

  </div>

  <ng-template #emptyState>
    <div class="wrapper">
      <p class="empty-state-title">{{ 'cart.emptyCart' |translate }}</p>
      <p class="empty-state-desc">{{ 'cart.addOurProducts' |translate }}</p>
      <div class="separator-big"></div>
      <p class="invite">{{ 'cart.cannotDecide' |translate }}</p>
      <div class="button-wrapper">
        <div class="button secondary-button" (click)="goToBestsellers($event)">{{ 'cart.showBestsellers' |translate }}
        </div>
      </div>
    </div>
  </ng-template>
</div>