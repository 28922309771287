import { AfterViewInit, Directive, ElementRef, HostListener, NgZone, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAutofillLabelup]'
})
export class AutofillLabelupDirective implements AfterViewInit
{

  constructor(private renderer2: Renderer2,
    private zone: NgZone,
    private elementRef: ElementRef)
  {
  }

  public ngAfterViewInit(): void
  {
    this.initListeners();
  }

  public initListeners(): void
  {
    if (this.elementRef && this.elementRef.nativeElement)
    {
      this.zone.runOutsideAngular(() =>
      {
        this.elementRef.nativeElement.addEventListener('animationstart', this.onAnimationStart.bind(this), {
          passive: true,
          useCapture: true
        });
      });
    }
  }

  private onAnimationStart({ target, animationName }): void
  {
    if (animationName == 'onAutoFillStart')
    {
      onAutoFillStart(target, this.renderer2);
    }
    if (animationName == 'onAutoFillCancel' && !(window.navigator.userAgent.indexOf('Edge') > -1))
    {
      onAutoFillCancel(target, this.renderer2);
    }

  }

  @HostListener('blur', ['$event'])
  public onBlur($event): void
  {
    if (this.elementRef.nativeElement.value == '')
    {
      this.renderer2.removeClass(this.elementRef.nativeElement, 'label-up');
    }
  }
}

function onAutoFillStart(el, renderer): void
{
  renderer.removeClass(el, 'ng-untouched');
  renderer.removeClass(el, 'ng-pristine');
  renderer.addClass(el, 'label-up');
  renderer.addClass(el, 'ng-dirty');
}

function onAutoFillCancel(el, renderer): void
{
  renderer.removeClass(el, 'label-up');
}
