import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Breadcrumbs } from "../../../model/Breadcrumbs.model";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbsService
{
  public breadcrumbs$: BehaviorSubject<Breadcrumbs[]> = new BehaviorSubject<Breadcrumbs[]>(null);
  public urlLinkProductDetails$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public lastVisitedListingUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public updateBreadcrumb(route: Breadcrumbs[]): void
  {
    this.breadcrumbs$.next(route);
  }
}
