import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeSpaces'
})
export class RemoveSpacesPipe implements PipeTransform
{
    transform(value: string): string
    {
        return value?.replace(/\s/g, '');
    }
}