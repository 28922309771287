import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ShopModeService } from '@service/helpers/shop-mode.service';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { BlockService } from '@shared/block/shared/block.service';
import { Socalmedia } from 'app/model/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-media-links',
  templateUrl: './media-links.component.html',
  styleUrls: ['./media-links.component.scss']
})
export class MediaLinksComponent  
{
  mediaLinks: Socalmedia;

  constructor(
    private blockService: BlockService,
    private windowRef: WindowRefService,
    private shopModeService: ShopModeService,
    private changeDet: ChangeDetectorRef
  )
  {
    this.mediaLinks = this.blockService.getDataSync('socalmedia');
  }

  openInNewCard(virtualTourLink: string, $event): void
  {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.windowRef.isWindowLoaded())
    {
      this.windowRef.getNativeWindow().open(
        virtualTourLink,
        this.shopModeService.isKioskMode() ? '_self' : '_blank',
        this.shopModeService.isKioskMode() ? '' : 'noopener, noreferrer'
      );
    }
  }
}
