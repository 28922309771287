export enum StringVariable
{
  STORAGE_STRING_FOR_FILTER_POSITION = 'filterPosition',
  PRODUCTS_PER_PAGE = 'productsPerPage',
  SORTING = 'SortingNew',
  PAGING = 'Paging',
  INTERVAL = 'Interval',
  SHOW_ADVANCE_FILTERS = 'ShowAdvancedFilters',
  FILTER = 'Filter',
  SELECTED = 'Selected',
  COLUMN_VIEW_STYLE = 'ColumnViewStyle',
  OPEN_FILTERS = 'OpenFilters',
  COMPARE = 'compare',
  CONSENT_ACCOUNT = 'consent-account',
  CATEGORY_CODE = 'kategoria'
}
