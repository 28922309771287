import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { BlockService } from '@shared/block/shared/block.service';
import { ConfigService } from '@service/config/config.service';
import { Resolutions } from '@resolutions';
import { filter } from 'rxjs/operators';
import { SeoService } from '@service/seo/seo.service';
import { ErrorPage, ErrorPages } from 'app/model/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService
{
  private notFound: ErrorPage;
  private notAvailable: ErrorPage;
  private serverBreak: ErrorPage;
  private queryString: string = '';
  public lastPage: string;
  public image: string;
  public imageOptions: Object;
  public errorChosen: BehaviorSubject<ErrorPage> = new BehaviorSubject<ErrorPage>(null);
  public isNotServerBreak: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);


  constructor(
    private activatedRoute: ActivatedRoute,
    private blockService: BlockService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private seoService: SeoService,
    private router: Router,
    private windowRef: WindowRefService,
  )
  {
  }

  public setPageInfo()
  {
    this.activatedRoute.queryParams.subscribe(res =>
    {
      if (res.querySearch)
      {
        this.queryString = res.querySearch.split('q=')[1];
      }
    });

    this.activatedRoute.params.pipe(filter(v => v && v.id)).subscribe(res =>
    {
      this.lastPage = res.id;
    });
  }

  public getErrorPages()
  {
    const errors: ErrorPages = this.blockService.getDataSync('errorPages');
    this.notFound = errors.pages.filter(el => el.code === 'NoQueryResult')[0];
    this.notAvailable = errors.pages.filter(el => el.code === 'Error404')[0];
    this.serverBreak = errors.pages.filter(el => el.code === 'Error503')[0];

    if (this.router.url.includes('querysearch'))
    {
      this.errorChosen.next(this.notFound);
      this.seoService.setTitle(this.translateService.instant('error.cannotFindElementPageTitle') + " - " + this.translateService.instant("pageTitleSufix"));
    }
    else if (this.router.url.includes('server-break'))
    {
      this.errorChosen.next(this.serverBreak);
      this.seoService.setTitle(this.translateService.instant('error.technicalBreak') + " - " + this.translateService.instant("pageTitleSufix"));
    }
    else
    {
      this.informBackendAbout404();
      this.errorChosen.next(this.notAvailable);
      this.seoService.setTitle(this.translateService.instant('error.pageNotFoundPageTitle') + " - " + this.translateService.instant("pageTitleSufix"));
    }

    this.getImageByErrorCode();

    this.isNotServerBreak.next(this.errorChosen.value != this.serverBreak);
  }

  public getImageByErrorCode(): void
  {
    if (this.errorChosen.value && this.errorChosen.value.image)
    {
      this.image = this.errorChosen.value.image;

      if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionTablet)
      {
        this.image = this.errorChosen.value.imageMobile;
      }
      if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
      {
        this.image = this.errorChosen.value.imageTablet;
      }
    }

    if (!this.router.url.includes('querysearch') && !this.router.url.includes('server-break'))
    {
      this.imageOptions = { yOffset: -100, zoom: 10 };
    } else
    {
      this.imageOptions = {};
    }
  }

  public getHeaderByErrorCode(): string
  {
    if (this.queryString)
    {
      return this.errorChosen.value.caption + ' "' + this.queryString + '"';
    }
    return this.errorChosen.value.caption;
  }

  public getDescriptionByErrorCode(): string
  {
    return this.errorChosen.value.text;
  }

  private informBackendAbout404(): void
  {
    const referrer = encodeURIComponent(document.referrer) || 'brak';
    const nextUri = encodeURIComponent(this.lastPage ? this.lastPage : window.location.pathname);
    this.httpClient.get(`${this.configService.ApiUrl}seo/report/${encodeURIComponent(referrer)}/${encodeURIComponent(nextUri)}`, { responseType: 'text' }).subscribe();
  }

}
