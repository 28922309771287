import { Injectable } from "@angular/core";
import { Params } from "@angular/router";

import { getCamelCaseVariableName } from "../../../utils/utilsFunctions";
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { Filter, FilterElement } from "app/model/idto";

@Injectable({
  providedIn: "root",
})
export class UrlHelperService
{
  private filtersFields = ["a", "q", "p", "c", "s"];
  public isLinkConst: boolean;

  constructor(
    public urlTranslateService: UrlTranslateService
  ) { }

  public getQueryParameters(
    urlString: String,
    onlyStartingWith?: string
  ): object
  {
    const parts = urlString.split("/");
    const queryParameters = parts[parts.length - 1];
    const queryObject = this.parseQueryStringToObject(queryParameters);

    if (onlyStartingWith != null && onlyStartingWith != undefined)
    {
      for (let key in queryObject)
      {
        if (key.startsWith(onlyStartingWith) == false)
        {
          delete queryObject[key];
        }
      }
    }

    return queryObject;
  }

  public parseQueryStringToObject(
    queryString: string,
    skipFilters: boolean = false
  ): { [key: string]: string }
  {
    if (!queryString)
    {
      return {};
    }

    const elements = {};

    queryString
      .replace(/(^\?)/, "")
      .split("&")
      .forEach((element) =>
      {
        const parts = element.split("=");

        if (parts[0] !== undefined && parts[0].indexOf("[]") !== -1)
        {
          parts[0] = parts[0].replace(/\[]/, "");
          if (typeof elements[parts[0]] === "undefined")
          {
            elements[parts[0]] = [];
          }
          elements[parts[0]].push(parts[1]);
        } else
        {
          elements[parts[0]] = parts[1];
        }
      });

    if (skipFilters)
    {
      for (const key of Object.keys(elements))
      {
        if (this.filtersFields.indexOf(key) === -1)
        {
          delete elements[key];
        }
      }
      elements["p"] = 1;
    }

    return elements;
  }

  encodeParams(url: string): Params
  {
    if (!url) return {};

    const result: Params = {};

    let key: string, param: string[];

    url
      .replace(/(^\?)/, "")
      .split("&")
      .forEach((item) =>
      {
        param = item.split("=");

        if (param[0] !== undefined && param[0].includes("[]"))
        {
          key = encodeURIComponent(param[0].replace(/\[]/, ""));

          if (typeof result[key] === "undefined") result[key] = [];

          result[key].push(encodeURIComponent(param[1]));
        } else
        {
          key = encodeURIComponent(param[0]);

          result[key] = encodeURIComponent(param[1]);
        }
      });

    return result;
  }

  public parseQueryObjectToString(queryParams: object, showStartSymbol: boolean = false, symbol: string = "?"): string
  {
    const elements = [];

    for (const key of Object.keys(queryParams))
    {
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(queryParams[key]);
      elements.push(encodeURIComponent(decodedKey) + "=" + encodeURIComponent(decodedValue));
    }

    return ((elements.length && showStartSymbol ? symbol : "") + elements.join("&"));
  }

  public removeParameterFromQuery(query: string, parameter: string): string
  {
    const queryObject = this.parseQueryStringToObject(query);
    delete queryObject[parameter];
    return this.parseQueryObjectToString(queryObject);
  }

  public removeActiveFilterFromQuery(filter: Filter, index: number): string
  {
    if (filter.type === "Interval")
    {
      if (filter.selectedInterval.min == filter.local.min || filter.selectedInterval.max == filter.local.max)
      {
        return filter.linkTemplate.replace(`&${filter.code}={MIN}~-~{MAX}`, '');
      }
      else
      {
        return index === 0 ?
          filter.linkTemplate.replace('{MIN}', filter.local.min.toString()).replace('{MAX}', filter.selectedInterval.max.toString()) :
          filter.linkTemplate.replace('{MIN}', filter.selectedInterval.min.toString()).replace('{MAX}', filter.local.max.toString());
      }
    }

    let query = this.prepareQueryIfMultiselect(filter, "", index);

    if (query)
    {
      return filter.linkTemplate.replace("{CODE}", query);
    }
    else
    {
      return filter.linkTemplate.replace("&" + filter.code + "={CODE}", "").replace(filter.code + '={CODE}', '');;
    }
  }

  public parseFilterObjectToQuery(filter: Filter): string
  {
    let link = "";
    let query = "";

    if (filter.type === "Interval")
    {
      link = filter.linkTemplate.replace('{MIN}', filter.selectedInterval.min.toString());
      link = link.replace('{MAX}', filter.selectedInterval.max.toString());
      return link;
    }

    if (filter.type === "Multiselect")
    {
      query = this.prepareQueryIfMultiselect(filter, query);
    }
    else
    {
      query = this.prepareQueryIfSelect(filter);
    }

    if (query)
    {
      return filter.linkTemplate.replace('{CODE}', query);
    }
    else
    {
      return filter.linkTemplate.replace('&' + filter.code + '={CODE}', '').replace(filter.code + '={CODE}', '');
    }
  }

  private prepareQueryIfMultiselect(filter: Filter, query: string, removeIndexValue?: number): string
  {
    filter.values = getCamelCaseVariableName(filter.values, true);
    filter.values.filter((v) => v.selected === true).forEach((v: FilterElement, index) =>
    {
      if (index != removeIndexValue)
      {
        query += v.code;
        query += filter.multiselectConjunction ? filter.multiselectConjunction : "~~~";
      }
    });

    return query.slice(0, query.length - 3);
  }

  private prepareQueryIfSelect(filter: Filter): string
  {
    if (filter && filter.values && filter.values.length)
    {
      const selectedValue = filter.values.filter((v) => v.selected === true);

      if (selectedValue.length === 1)
      {
        return selectedValue[0].code;
      }
    }
  }

  public removeSlashFromSearchQuery(query: string): string
  {
    return query && query.split("c=")[0].replace(/[^a-zA-Z ]/g, "");
  }
}
