import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[sdClickOutside]'
})
export class ClickOutsideDirective
{

  @Output() public readonly clickOutside = new EventEmitter();
  @Input() public parentRef?: any;

  private localEvent;

  @HostListener('click', ['$event'])
  public trackEvent($event: any): void
  {
    this.localEvent = $event;
  }

  @HostListener('document: click', ['$event'])
  public compareEvent($event: any): void
  {
    if (this.parentRef)
    {
      return;
    }

    if ($event !== this.localEvent)
    {
      if (!$event.button || $event.button <= 2)
      {
        this.clickOutside.emit($event);
      }
    }
    this.localEvent = null;
  }

  @HostListener('document:mousedown', ['$event'])
  public mouseDownAction($event): void
  {
    if (this.parentRef)
    {
      if ($event)
      {
        if (!$event.button || $event.button <= 2)
        {
          const path = ($event.composedPath && $event.composedPath()) || $event.path;
          if (path && path.filter(el => el.className === this.parentRef.className).length == 0)
          {
            this.clickOutside.emit($event);
          }
        }
      }

    }
  }
}
