<div *ngIf="banner.type === 'Text'; else sliderImageLink" class="description-inline-wrapper">
  <p class="heading7 block-name body-text font-style-light">
    {{ banner?.text }}
  </p>
</div>

<ng-template #sliderImageLink>
  <a class="description-inline-wrapper" [href]="banner.callToActionLink">
    <div class="image-wrapper" [ngClass]="{ insparanz: true }">
      <app-img *ngIf="banner.type !== 'Text'"
        [lazy]="lazy"
        [ngClass]="{ 'double-width': isDoubleWidth(banner) }"
        [src]="banner.image.url"
        [newOptions]="banner.image"
        [placeholder]="false"
        [placeholderGray]="true">
      </app-img>

      <div class="text-slider-wrapper">
        <p class="heading7 block-caption" [ngClass]="{ 'white-after': banner?.captionForeColor === '#FFFFFF' }" *ngIf="banner?.caption && !banner?.text">
          {{ banner?.caption }}
        </p>

        <p class="text-link heading5" *ngIf="banner?.callToActionText" [ngClass]="{ 'white-after': banner?.captionForeColor === '#FFFFFF' }" [style.color]="banner?.captionForeColor">
          {{ banner?.callToActionText }}
        </p>
      </div>
    </div>
  </a>
</ng-template>
