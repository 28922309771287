import { Component, EventEmitter, HostListener, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { ConsentContent } from 'app/model/interfaces';

@Component({
  selector: 'app-collapsing-block',
  templateUrl: './collapsing-block.component.html',
  styles: []
})
export class CollapsingBlockComponent
{

  @Input() public content: ConsentContent;
  @Output() public readonly onExpandClick = new EventEmitter<boolean>();

  @ViewChild('shorterText', { read: ElementRef }) private shorterText: ElementRef;
  @ViewChild('longerText', { read: ElementRef }) private longerText: ElementRef;

  constructor()
  {
  }

  @HostListener('click', ['$event'])
  public onClick(event): void
  {
    event.preventDefault();
    const target = event.target;
    const expand = target.classList.contains('expand');
    const collapse = target.classList.contains('collapse');

    if (expand)
    {
      this.shorterText.nativeElement.style.display = 'none';
      this.longerText.nativeElement.style.display = 'inline';
      this.onExpandClick.emit(true);
      return;
    }
    if (collapse)
    {
      this.longerText.nativeElement.style.display = 'none';
      this.shorterText.nativeElement.style.display = 'inline';
      this.onExpandClick.emit(false);
    }
  }

}
