import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss']
})
export class AlertBarComponent
{
  @Input() public errorText: string;
  @Input() private alertStatus: string;
  @Input() public cannotClose: boolean = false;
  @Output() public readonly publicCloseBar: EventEmitter<any> = new EventEmitter();

  constructor()
  {
  }

  public getAlertStatus(): string
  {
    return this.alertStatus;
  }

  public closeBar(): void
  {
    this.publicCloseBar.emit();
  }
}
