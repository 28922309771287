import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@service/storage/storage.service';
//import { StayAtHomePopupComponent } from '@shared/shared/modals/stay-at-home-popup/stay-at-home-popup.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BlockService } from '../sharedModules/block/shared/block.service';
import { NewsletterPopupComponent } from '../sharedModules/shared/modals/newsletter-popup/newsletter-popup.component';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit
{

  @ViewChild(NewsletterPopupComponent, { static: true }) private newsletterPopup: NewsletterPopupComponent;
  //@ViewChild(StayAtHomePopupComponent) private stayAtHomePopup: StayAtHomePopupComponent;
  public popupDescription: string = '';
  public popupDiscount: string = '';
  public popupCode: string = '';
  public popupCallToAction: string = '';
  private popupEnabled: boolean = false;
  private popupDelay: number = 5000;
  public popupImageJpg: string = '';
  public popupImageWebp: string = '';
  private popupImageOptions: string = '?width=593&height=525&resizeType=Fill_Crop&cropXOffsetPercent=-100&dpi=100';

  constructor(private router: Router,
    private changeDet: ChangeDetectorRef,
    private blockService: BlockService,
    private storageService: StorageService)
  {
  }

  public ngOnInit(): void
  {

    // let res = this.blockService.getDataSync('autoShowPopup');

    //   if (res)
    //   {
    // this.popupDescription = res.resolvedJson.Popup.Description1;
    // this.popupDiscount = res.resolvedJson.Popup.DiscountValue;
    // this.popupCode = res.resolvedJson.Popup.PromotionCode;
    // this.popupCallToAction = res.resolvedJson.Popup.CallToAction;
    // this.popupEnabled = res.resolvedJson.Popup.Enabled;
    // this.popupDelay = res.resolvedJson.Popup.ShowDelay;
    // this.popupImageJpg = res.resolvedJson.Popup.ImageUrl + this.popupImageOptions + '&format=jpg';
    // this.popupImageWebp = res.resolvedJson.Popup.ImageUrl + this.popupImageOptions + '&format=webp';

    // if (this.router.url === '/')
    // {
    //   sessionStorage.setItem('stayAtHomePopupShown', 'true');
    // }

    // setTimeout(() =>
    // {
    //   if (this.popupEnabled)
    //   {
    //     this.storageService.get('promotionTimeVisiblity').subscribe(time =>
    //     {
    //       const now = Date.now();
    //       if (now > time && this.router.url !== '/' && !sessionStorage.getItem('stayAtHomePopupShown'))
    //       {
    //         this.stayAtHomePopup.openModal();
    //       }
    //     });
    //   }
    // }, this.popupDelay);
    //   }

  }
}
