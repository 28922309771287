import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, OnChanges, SimpleChanges, Output, ViewChild } from '@angular/core';
import { Resolutions } from '@resolutions';
import { GetImageUrlPipe } from './get-image-url.pipe';
import { PictureOptionsInterface } from './picture-options.interface';
import { getDpi } from '../../../../utils/utilsFunctions';
import { ResponsiveImageElement } from 'app/model/interfaces';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgComponent implements OnInit, OnChanges
{
  //#region Backfields
  private _src: string;
  private _index: string;
  public _placeholderIcon: boolean = true;
  public _placeholderGray: boolean = false;
  private _placeholderIconOriginal: boolean = true;
  private _placeholderGrayOriginal: boolean = false;
  //#endregion

  @Output() public readonly loaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() newOptions: ResponsiveImageElement;
  @Input() options: PictureOptionsInterface;
  @Input() height: Array<number>;
  @Input() width: Array<number>;
  @Input() public lazy: boolean = true;
  @Input() public alt: string;
  @Input() public placeholderPadding = 0;
  @Input() public set placeholder(value: boolean)
  {
    this._placeholderIconOriginal = value;
    this._placeholderIcon = value;
  }
  @Input() public set placeholderGray(value: boolean)
  {
    this._placeholderGrayOriginal = value;
    this._placeholderGray = value;
  }
  @Input() public set src(value: string)
  {
    if (this._src != value)
    {
      this._src = value;
      this.lastRatioStatus = 0;
      this.resolutionStatus = -1;
      this.checkResolutionStatus();
    }
  }
  @Input() public set index(value: string)
  {
    if (this._index != value)
    {
      this._index = value;
      this.lastRatioStatus = 0;
      this.resolutionStatus = -1;
      this.checkResolutionStatus();
    }
  }

  public get src(): string
  {
    return this._src;
  }

  static mobileStatus: number = 0;
  static desktopStatus: number = 2;
  static tabletStatus: number = 1;

  private afterNgOnInit: boolean = false;
  public isImageLoaded: boolean = false;
  public picNotFound: boolean = false;
  public resolutionStatus: number;
  public lastRatioStatus: number;
  public url: string;
  public suffix: string;

  public pWidth: number = 0;
  public pHeight: number = 0;


  constructor(private changeDet: ChangeDetectorRef, private getImagetUrlPipe: GetImageUrlPipe)
  {
  }

  private restoreOriginalPlaceholder(): void
  {
    this._placeholderIcon = this._placeholderIconOriginal;
    this._placeholderGray = this._placeholderGrayOriginal;
    this.isImageLoaded = false;
  }

  //#region Init/Destroy
  ngOnInit(): void
  {
    this.afterNgOnInit = true;
    this.checkResolutionStatus();
  }
  //#endregion

  //#region New input
  ngOnChanges(changes: SimpleChanges): void
  {
    if (changes.src && !changes.src.firstChange)
    {
      this.checkResolutionStatus();
    }
  }
  //#endregion

  //#region Image events
  public onImageLoaded(): void
  {
    if (this.src != 'assets/images/empty-pic.svg' && this.src != '/assets/images/empty-pic.svg')
    {
      this._placeholderGray = false;
      this._placeholderIcon = false;
      this.isImageLoaded = true;
      this.loaded.emit(true);
    }

    this.changeDet.detectChanges();
  }

  public onError(): void
  {
    this.picNotFound = true;
    this.changeDet.detectChanges();
  }
  //#endregion

  //#region UI Update (resize etc.)
  @HostListener('window:resize', [])
  onResize(): void
  {
    this.checkResolutionStatus();
  }

  private checkResolutionStatus(): void
  {
    if (this.afterNgOnInit)
    {
      const lastStatus = this.resolutionStatus;

      if (window.innerWidth < Resolutions.resolutionTablet)
      {
        this.resolutionStatus = ImgComponent.mobileStatus;
      }
      else if (window.innerWidth < Resolutions.resolutionDesktop)
      {
        this.resolutionStatus = ImgComponent.tabletStatus;
      }
      else
      {
        this.resolutionStatus = ImgComponent.desktopStatus;
      }

      if (this.src && (this.resolutionStatus !== lastStatus || window.devicePixelRatio !== this.lastRatioStatus))
      {
        let currentUrl = this.url;
        this.suffix = getDpi(null).suffix;

        if (this.newOptions)
        {
          let currentOptions = this.newOptions.mobile;

          if (this.resolutionStatus == ImgComponent.desktopStatus)
          {
            currentOptions = this.newOptions.desktop;
          }
          else if (this.resolutionStatus == ImgComponent.tabletStatus)
          {
            currentOptions = this.newOptions.tablet;
          }

          if (currentOptions.url && currentOptions.url.length > 0)
          {
            this.url = this.getImagetUrlPipe.newTransform(currentOptions.url, currentOptions);
          }
          else
          {
            this.url = this.getImagetUrlPipe.newTransform(this.src, currentOptions);
          }

          this.pWidth = currentOptions.width;
          this.pHeight = currentOptions.height;
        }
        else
        {
          this.pWidth = this.width?.length ? this.width[this.resolutionStatus] : null;
          this.pHeight = this.height?.length ? this.height[this.resolutionStatus] : null;

          this.url = this.getImagetUrlPipe.transform(
            this.src,
            this.width?.length ? this.width[this.resolutionStatus] : null,
            this.height?.length ? this.height[this.resolutionStatus] : null,
            this._index,
            this.options
          );
        }

        this.lastRatioStatus = window.devicePixelRatio;

        if (this.url != currentUrl)
        {
          this.restoreOriginalPlaceholder();
        }

        if (this.afterNgOnInit)
        {
          this.changeDet.detectChanges();
        }
      }
    }
  }
  //#endregion
}
