import
{
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  SimpleChanges
} from "@angular/core";
import
{
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import
{
  Customer,
  CustomerContactRequestVO,
  CustomerUpdateDTO,
  ShopProductFamily,
} from "@dto";
import { TranslateService } from "@ngx-translate/core";
import { Resolutions } from "@resolutions";
import { CartService } from "@service/cart/cart.service";
import { CustomerClientService } from "@service/client/customer-client.service";
import { PopupService } from "@service/popup/popup.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import
{
  allRequiredPoliciesAreAccepted,
  getCollapsingContent,
  getSimpleContent,
  isCollapsingContentShown,
  isSimpleContentShown,
  parseContent,
  setAgreementTextExpand,
  toggleCheckbox,
} from "app/utils/utilsFunctionsContent";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import
{
  animateButton,
  getImageUrl,
  removeAnimateButton,
  showAfterSecond,
} from "../../../../utils/utilsFunctions";
import { BlockService } from "../../block/shared/block.service";
import { CustomValidators } from "../../../../utils/custom.validators";
import { Consent, Consents, ConsentType, MessageItem } from "app/model/interfaces";

enum ControlNames
{
  RATING = "productStatusRating",
  EMAIL = "productStatusEmail",
  NAME = "productStatusName",
  PHONE = "productStatusPhone",
  WHEN = "productStatusWhen",
  MESSAGE = "productStatusMessage",
  TIME = "productStatusTime",
}

@Component({
  selector: "app-product-status-popup",
  templateUrl: "./product-status-popup.component.html",
  styleUrls: [
    "../../shared/modals/contact-popup-abstract/contact-popup-abstract.component.scss",
    "./product-status-popup.component.scss",
  ],
})
export class ProductStatusPopupComponent implements OnInit, OnChanges, OnDestroy
{
  public isCollapsingContentShown = isCollapsingContentShown;
  public isSimpleContentShown = isSimpleContentShown;
  public getSimpleContent = getSimpleContent;
  public getCollapsingContent = getCollapsingContent;
  public setAgreementTextExpand = setAgreementTextExpand;
  public toggleCheckbox(agreements, code, $event, changeDetector)
  {
    toggleCheckbox(agreements, code, $event, changeDetector);
    this.checkAndRemoveButtonErrorState();
  }

  @ViewChild("addButton") public addButton: ElementRef;
  public isSubmitted: boolean = false;
  public readonly ControlNames = ControlNames;
  public allCheckboxIsSetAsActive: boolean = false;
  public whenAsap = "Jak najszybciej";
  public whenAfterTime = "Po godzinie: ";
  public customer: Customer;
  public showForm = false;
  public form: UntypedFormGroup;
  public formMessage = null;
  public error: MessageItem;
  public isLoading = false;
  public submission = false;
  public hideCheckbox = false;
  public buttonText: string;
  public isForNewsletter: boolean = false;
  public formMessageColor: string = "green";
  @Input() public defaultButtonText = this.translate.instant("sendMessage");
  @Input() public buttonTextSuccess = this.translate.instant("sent2");
  @Input() public mainTitle: string;
  @Input() public secondaryTitle: string;
  @Input() public phoneCaption: string;
  @Input() public productName: string;
  @Input() public productCode: string;
  @Input() public showLink: boolean = false;
  @Input() public showPhone: boolean = false;
  @Input() public phoneRequired: boolean = false;
  @Input() public showName: boolean = false;
  @Input() public showEmail: boolean = false;
  @Input() public showMessage: boolean = false;
  @Input() public showWhen: boolean = false;
  @Input() public showRating: boolean = false;
  @Input() public showOptionalDataCaption: boolean = false;
  @Input() public webpPictureSrcSet: string;
  @Input() public pictureExtension: string;
  @Input() public jpgPictureSrcSet: string;
  public defaultPictureSrcSet: string;
  @Input() public pictureAltName: string;
  @Input() public withImage: boolean = false;
  @Input() public product: ShopProductFamily;
  @Input() public buttonType: string = "secondary-button";
  @Input() public dropdownOptions: any[] = [];
  @Input() public verticalSeparatorGrey: boolean = false;
  @Input() public tooltip: boolean = false;
  @Input() public navigationBarTitle: string;
  @Input() public emailPlaceholder: string = this.translate.instant("cart.addressField.email");
  @Input() public messagePlaceholder: string = this.translate.instant("message");
  @Input() public namePlaceholder: string = this.translate.instant("fullName");
  @Input() public phonePlaceholder: string = this.translate.instant("cart.addressField.phone");
  @Input() private name: string = "";

  @Output()
  public readonly ratingSuccessMsg: EventEmitter<any> = new EventEmitter();
  @Output()
  public readonly successEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public readonly closeParentEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  private destroy$: Subject<void> = new Subject();
  private window: Window | null;
  public ratingError: boolean = false;
  public agreements: Consent[];
  public loaded = [];

  constructor(
    public renderer: Renderer2,
    public cartService: CartService,
    public windowRef: WindowRefService,
    public elementRef: ElementRef,
    public changeDetector: ChangeDetectorRef,
    private blockService: BlockService,
    private translate: TranslateService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private customerClientService: CustomerClientService,
    private popupService: PopupService
  )
  {
    this.window = this.windowRef.getNativeWindow();
  }

  public ngOnInit(): void
  {
    this.popupService.reserveName(this.name);

    this.initForm();
    this.buttonText = this.defaultButtonText;
    this.customerClientService.customer$
      .pipe(
        filter((v) => !!v),
        takeUntil(this.destroy$)
      )
      .subscribe((newCustomer: Customer) =>
      {
        this.customer = newCustomer;
        this.fillUpFormContact();
        if (this.agreements)
        {
          return;
        }
        this.getDataForAgreements();
      });
    this.translatePage();
  }

  private checkAndRemoveButtonErrorState()
  {
    if (this.addButton && this.agreements && allRequiredPoliciesAreAccepted(this.agreements) && this.form.valid)
    {
      removeAnimateButton("error", this.addButton, this.renderer);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (changes.defaultButtonText)
    {
      this.buttonText = this.defaultButtonText;
    }
  }

  private getDataForAgreements(): void
  {
    const agreements = this.blockService.getDataSync("consents").items.filter(o => o.code == "Contact")[0].consents;
    this.agreements = [];
    agreements.forEach((agreement: Consent) =>
    {
      this.agreements.push({ ...agreement });
    });
    this.agreements.forEach((agr) => (agr.expand = false));
    this.agreements = this.agreements.filter(
      (agr) => !(agr.global == true && agr.accepted)
    );
    this.agreements.filter((agr) => (agr.accepted = false));
    this.agreements.forEach(
      (agreement) =>
        (parseContent(agreement, agreement.text))
    );
    this.allCheckboxIsSetAsActive =
      this.agreements.filter((agr) => agr.accepted == true).length ==
      this.agreements.length;
    this.agreements
      .filter((agr) => agr.defaultChecked == true)
      .forEach((agr) => (agr.accepted = true));
    if (!this.changeDetector["destroyed"])
    {
      this.changeDetector.detectChanges();
    }
  }

  public ngOnDestroy(): void
  {
    this.popupService.clearName(this.name);
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit(): void
  {
    this.isLoading = true;
    this.isSubmitted = true;
    animateButton(
      "loading",
      this.addButton,
      this.renderer,
      this.changeDetector
    );
    this.formMessageColor = "green";
    this.submission = true;
    if (!allRequiredPoliciesAreAccepted(this.agreements) || this.form.invalid)
    {
      removeAnimateButton("loading", this.addButton, this.renderer);
      animateButton(
        "error",
        this.addButton,
        this.renderer,
        this.changeDetector
      );
      this.isLoading = false;
      this.markAsTouchedAndDirtyForms();
      this.changeDetector.detectChanges();

      return;
    }

    this.formMessage = null;
    this.saveForContactRequest();
    this.changeDetector.detectChanges();
  }

  private markAsTouchedAndDirtyForms(): void
  {
    if (!this.form.valid)
    {
      Object.keys(this.form.controls).forEach((el) =>
      {
        if (this.form.controls[el].value || this.form.controls[el].errors)
        {
          if (el == ControlNames.RATING && this.form.controls[el].value == "")
          {
            this.ratingError = true;
          }
          this.form.controls[el].markAsDirty();
          this.form.controls[el].markAsTouched();
        }
      });
    }
  }

  public clear(): void
  {
    this.submission = false;
    this.isLoading = false;
    this.form.reset();
    Object.keys(this.form.controls).forEach((key) =>
    {
      this.form.controls[key].setErrors(null);
    });
  }

  public open(email: string): void
  {
    if (email)
    {
      this.form.get(ControlNames.EMAIL).setValue(email);
      this.isForNewsletter = true;
    }
    this.showForm = true;
    this.formMessage = null;
  }

  public openModal(productVariant?: any, imageThumb?: string, disableReload?: boolean): void
  {
    this.windowRef.blockScroll();
    if (imageThumb)
    {
      if (imageThumb.includes("https"))
      {
        this.webpPictureSrcSet = imageThumb;
        this.jpgPictureSrcSet = imageThumb;
      } else
      {
        //only for hover avaiable popup
        this.webpPictureSrcSet = getImageUrl(
          imageThumb,
          "0",
          null,
          [40, 40, 40],
          null,
          true
        );
        this.jpgPictureSrcSet = getImageUrl(
          imageThumb,
          "0",
          null,
          [40, 40, 40],
          null,
          false
        );
        this.defaultPictureSrcSet = getImageUrl(
          imageThumb,
          "0",
          null,
          [40, 40, 40],
          "100",
          false
        );
      }
    }
    this.showForm = true;
    if (this.form.controls[this.ControlNames.WHEN])
    {
      this.form.controls[ControlNames.WHEN].setValue(this.whenAsap);
    }
    if (this.tooltip)
    {
      this.product = productVariant;
      if (
        this.windowRef
          .getDocumentBody()
          .querySelector(".contact-popup-abstract")
      )
      {
        this.renderer.addClass(
          this.windowRef
            .getDocumentBody()
            .querySelector(".contact-popup-abstract"),
          "first-stage"
        );
      }
    }
    showAfterSecond(100, this.loaded, this.changeDetector);
    this.changeDetector.detectChanges();

    this.popupService.openPopup(this.name, !!disableReload);
  }

  public closeModal(event?: any, closeParent?: boolean, disableReload?: boolean): void
  {
    if (event)
    {
      event.stopPropagation();
    }
    this.showForm = false;
    this.popupService.closePopup(this.name, !!disableReload);
    this.error = null;
    this.formMessage = null;
    this.changeDetector.detectChanges();
    this.clear();
    this.loaded["loaded"] = false;

    if (closeParent)
    {
      this.closeParentEventEmitter.emit();
      // We need to unblock scroll after popup close
      setTimeout(() =>
      {
        this.windowRef.unBlockScroll();
      }, 100);
    } else if (!this.popupService.isActivePopup())
    {
      // We need to unblock scroll after popup close
      setTimeout(() =>
      {
        this.windowRef.unBlockScroll();
      }, 100);
    }
  }

  public getClassNameByResolution(
    className1: string,
    className2: string
  ): string
  {
    if (
      this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop
    )
    {
      return className1;
    }
    return className2;
    //  todo:on resize
  }

  public getWhenValue(): number
  {
    return this.form.controls[ControlNames.WHEN].value;
  }

  public setWhenValue(when: string): void
  {
    this.form.controls[ControlNames.WHEN].setValue(when);
  }

  private prepareDataForWhenControl(): string
  {
    return this.form.controls[ControlNames.WHEN].value === this.whenAsap
      ? this.form.controls[ControlNames.WHEN].value
      : this.form.controls[ControlNames.WHEN].value +
      " " +
      this.form.controls[ControlNames.TIME].value;
  }

  private addControl(
    controlName,
    controlValidator = [Validators.required]
  ): void
  {
    this.form.addControl(controlName, new UntypedFormControl("", controlValidator));
  }

  private fillUpFormContact(): void
  {
    if (this.customer && this.form.controls)
    {
      if (this.customer.defaultDeliveryAddress)
      {
        if (this.form.get(ControlNames.NAME))
        {
          this.form.controls[ControlNames.NAME].setValue(
            this.customer.defaultDeliveryAddress.firstName &&
              this.customer.defaultDeliveryAddress.secondName
              ? this.customer.defaultDeliveryAddress.firstName +
              " " +
              this.customer.defaultDeliveryAddress.secondName
              : ""
          );
        }
        if (this.form.get(ControlNames.PHONE))
        {
          this.form.controls[ControlNames.PHONE].setValue(
            this.customer.defaultDeliveryAddress
              ? this.customer.defaultDeliveryAddress.phone
              : null
          );
        }
      }

      if (this.form.get(ControlNames.EMAIL))
      {
        this.form.controls[ControlNames.EMAIL].setValue(this.customer.email);
      }
    }
  }

  private saveForContactRequest(): void
  {
    const requestDTO: CustomerContactRequestVO = {
      contactFormCode: this.mainTitle,
      customerEmail: !this.form.controls[ControlNames.EMAIL] ? null : this.form.controls[ControlNames.EMAIL].value,
      customerMessage: !this.form.controls[ControlNames.MESSAGE] ? null : this.form.controls[ControlNames.MESSAGE].value,
      customerName: !this.form.controls[ControlNames.NAME] ? null : this.form.controls[ControlNames.NAME].value,
      customerPhone: !this.form.controls[ControlNames.PHONE] ? null : this.form.controls[ControlNames.PHONE].value,
      whenToContact: !this.form.controls[ControlNames.WHEN] ? null : this.prepareDataForWhenControl(),
      productName: this.product["name"]
    };

    if (!this.productCode)
    {
      requestDTO.productCode = this.product["code"];
    }

    this.customerClientService.sendContactRequest(requestDTO).subscribe(
      () =>
      {
        this.successEmitter.emit({ type: "Success", message: this.translate.instant("error.formSend"), });

        this.isLoading = false;
        this.error = null;
        this.hideCheckbox = true;
        this.formMessage = this.translate.instant("error.thanksForMessage");

        removeAnimateButton("error", this.addButton, this.renderer);
        removeAnimateButton("loading", this.addButton, this.renderer);
        animateButton("success", this.addButton, this.renderer, this.changeDetector);
        this.changeDetector.detectChanges();
        this.closeModal(null, true);

        if (this.customerClientService.isUserLogged)
        {
          const dto: CustomerUpdateDTO = { addConsents: [] }

          this.agreements.forEach((agr) =>
          {
            if (agr.accepted)
            {
              dto.addConsents.push(agr.code);
            }
          });
        }
      },
      (error) =>
      {
        this.isLoading = false;
        this.error = error;
        this.submission = false;
        this.formMessageColor = "red";
        this.formMessage = this.translate.instant("error.messageSendUnsuccessful");
        removeAnimateButton("success", this.addButton, this.renderer);
        removeAnimateButton("loading", this.addButton, this.renderer);
        animateButton("error", this.addButton, this.renderer, this.changeDetector);
      }
    );
  }

  protected onSuccess(success: boolean): void
  {
    this.error = null;
    this.isLoading = false;
    this.isSubmitted = false;
    this.ratingSuccessMsg.emit(true);
    removeAnimateButton("error", this.addButton, this.renderer);
    removeAnimateButton("loading", this.addButton, this.renderer);
    animateButton(
      "success",
      this.addButton,
      this.renderer,
      this.changeDetector
    );
    if (success)
    {
      this.form.reset();
      this.form.controls[ControlNames.MESSAGE].markAsUntouched();
      this.form.controls[ControlNames.MESSAGE].markAsPending();
      this.form.controls[ControlNames.MESSAGE].markAsPristine();
      this.changeDetector.detectChanges();
      this.closeModal(null, true);
      return;
    }
  }

  protected onError(error: any): void
  {
    this.isLoading = false;
    this.error = error;
    removeAnimateButton("success", this.addButton, this.renderer);
    removeAnimateButton("loading", this.addButton, this.renderer);
    animateButton("error", this.addButton, this.renderer, this.changeDetector);
    return;
  }

  public setRatingNumber($event): void
  {
    this.form.controls[ControlNames.RATING].setValue($event);
    this.ratingError = false;
  }

  private initForm(): void
  {
    this.form = this.formBuilder.group({});
    if (this.showPhone)
    {
      this.addControl(
        ControlNames.PHONE,
        this.phoneRequired ? [Validators.required] : null
      );
    }

    if (this.showName)
    {
      this.addControl(ControlNames.NAME);
    }

    if (this.showEmail)
    {
      this.addControl(ControlNames.EMAIL, [
        Validators.required,
        CustomValidators.email,
      ]);
    }

    if (this.showLink)
    {
      this.addControl("link");
    }

    this.form.addControl(
      ConsentType.ContactRequestProcessing,
      new UntypedFormControl(false)
    );
    this.form.addControl(
      ConsentType.PrivacyPolicy,
      new UntypedFormControl(false)
    );
    this.form = new UntypedFormGroup(this.form.controls);
    this.form.valueChanges.subscribe(() =>
    {
      this.checkAndRemoveButtonErrorState();
    });
  }

  @HostListener("window:keyup", ["$event"])
  private onKeyUp(event): void
  {
    if (this.showForm && event.key === "Escape")
    {
      this.closeModal(null);
    } else if (this.showForm && event.keyCode === 13)
    {
      this.onSubmit();
    }
  }

  @HostListener("window:popstate", ["$event"])
  private backButtonHandler(): void
  {
    if (
      !this.popupService.isActiveParam("m", this.name) &&
      this.popupService.isActivePopup(this.name)
    )
    {
      this.closeModal(null, null, true);
    }
  }

  private translatePage(): void
  {
    this.whenAsap = this.translate.instant("modalsTexts.rightNow");
    this.whenAfterTime = this.translate.instant("modalsTexts.afterHour").concat(": ");
    this.defaultButtonText = this.translate.instant("sendMessage");
    this.emailPlaceholder = this.translate.instant("login.inputEmail");
    this.messagePlaceholder = this.translate.instant("message");
    this.namePlaceholder = this.translate.instant("fullName");
    this.phonePlaceholder = this.translate.instant("typePhoneNumber");
  }

  public hasError(agreement: Consent): boolean
  {
    if (agreement.required == true && agreement.accepted == false)
    {
      return true;
    }
  }
}
