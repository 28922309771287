import { Location, NumberSymbol } from "@angular/common";
import
{
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ShopProduct, ShopProductFamily } from "@dto";
import { ChatService } from "@service/chat/chat-service.service";
import { SearchRepositoryService } from "@service/search-repository/search-repository.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { ValueWithCommaPipe } from "@shared/pipes/value-with-comma.pipe";
import { transcode } from "buffer";
import { ShopProductPack } from "../../../sharedModules/shared/product-variants/product-variants.component";
import { TooltipPopupComponent } from "../../../sharedModules/tooltip/tooltip-popup/tooltip-popup.component";

@Component({
  selector: "app-product-size-calculator",
  templateUrl: "./product-size-calculator.component.html",
  styleUrls: ["./product-size-calculator.component.scss"],
})
export class ProductSizeCalculatorComponent implements AfterViewInit
{
  //#region Backfields
  private _valueBinding: number = 0;
  private _productFamily: ShopProductFamily;
  //#endregion

  @ViewChild(TooltipPopupComponent) private tooltipPopup: TooltipPopupComponent;
  @ViewChild("valueBindingInput") private readonly valueBindingInput: ElementRef;
  @Output() private readonly tooltipInvoked: EventEmitter<ShopProductPack> = new EventEmitter<ShopProductPack>();
  @Output() private readonly statusProductTooltipInvoked: EventEmitter<ShopProductFamily> = new EventEmitter<ShopProductFamily>();
  @Output() private readonly selectElement: EventEmitter<ShopProduct> = new EventEmitter();
  @Output() private readonly resetList: EventEmitter<void> = new EventEmitter();
  @Output() private readonly binding: EventEmitter<boolean> = new EventEmitter();
  @Output() private readonly bindingAll: EventEmitter<boolean> = new EventEmitter();
  @Output() private readonly summaryPrice: EventEmitter<number> = new EventEmitter();

  public bindingProductFamily: ShopProductFamily;

  @Input() set productFamily(value: ShopProductFamily)
  {
    this._productFamily = value;

    //#region Binding
    const bindingData = this._productFamily.complementaryProductFamilies.filter((el) => el.familyType == "Obszycie");
    if (bindingData.length)
    {
      this.showBinding = true;
      this.bindingProductFamily = bindingData[0];
    }
    else
    {
      this.showBinding = false;
    }
    //#endregion

    if (this._productFamily.isCovering)
    {
      this.productsInDropdown = this._productFamily.products.filter((el) => el.isSample == false && el.isUnique == false).sort((a, b) => b.width - a.width);
    }
    else
    {
      this.productsInDropdown = this._productFamily.products.filter((el) => el.isSample == false && el.isUnique == false);
    }

    this.getDeliveryDays();
  }
  get productFamily(): ShopProductFamily
  {
    return this._productFamily;
  }

  public set valueBinding(value: number)
  {
    if (this.showBinding)
    {
      this._valueBinding = value;
    }
    else
    {
      this._valueBinding = 0;
    }

    let pipe = new ValueWithCommaPipe();
    this.valueBindingFormated = pipe.transform(value, true) + " " + this.bindingProductFamily.products[0].quantityUnit;
  }
  public get valueBinding(): number
  {
    return this._valueBinding;
  }

  public selectedItem: ShopProduct;
  public activeProduct: ShopProduct;

  public productsInDropdown: ShopProduct[];
  public activeTab: string = "calculator";

  public showBinding: boolean;
  public orderAllBindingActive: boolean = false;
  public orderBindingActive: boolean = false;
  public valueBindingFormated: string;

  public returnDeliveryDay: string;
  public selectedHeight: number = 1.0;
  private selectedWidth: number = 0;

  constructor(private searchRepo: SearchRepositoryService,
    private location: Location,
    private windowRef: WindowRefService,
    private changeDet: ChangeDetectorRef,
    private chatService: ChatService,
    private router: Router,
    private activeRoute: ActivatedRoute,
  )
  {

  }

  //#region Init/Destroy
  public ngAfterViewInit(): void
  {
    this.activeRoute.queryParams.subscribe((param) =>
    {
      if (param.tab)
      {
        this.setActiveTab(param.tab);

        if (param.tab == "calculator")
        {
          this.selectElement.emit(this.productFamily.products[0]);
        }
      }

      if (param.Option)
      {
        this.activeProduct = this.productFamily.products.find(element => element.code === param.Option);

        if (this.activeProduct && this.productFamily.isWalkway == false)
        {
          this.updateSelectedHeight(100 / this.activeProduct.width)
        }
      }
    });
  }
  //#endregion

  private getDeliveryDays(): void
  {
    // todo zmuiana jak bedzie rest wystawiony
    this.searchRepo.getProductDeliveryDate(this.productFamily, this.getQuantity()).subscribe((res) => { this.returnDeliveryDay = res; });
  }

  public setActiveTab(s: string): void
  {
    this.activeTab = s;
    this.resetList.emit();
    this.location.replaceState(this.router.createUrlTree([], { queryParams: { tab: s } }).toString());

    if (s == "calculator")
    {
      this.selectElement.emit(this.productFamily.products[0]);
      this.toggleOrderBinding();
      this.changeDet.detectChanges();
    }
  }

  public setSelectedItem($event): void
  {
    this.selectedItem = $event;
    this.selectedWidth = $event.width / 100;

    this.emitSelection();
  }

  public emitSelection(): void
  {
    this.selectedItem["selectedSize"] = this.getQuantity();
    this.calculateBindingQuantity();
    this.selectedItem["selectedBindingSize"] = this.valueBinding;

    this.selectElement.emit(this.selectedItem);
    this.summaryPrice.emit(this.getSummaryPrice());
  }


  public updateSelectedHeight($event: number): void
  {
    this.selectedHeight = $event;
    this.emitSelection();
  }

  public getQuantity(): number
  {
    if (this.productFamily.isWalkway)
    {
      return this.selectedHeight;
    }
    else
    {
      return this.selectedWidth * this.selectedHeight;
    }
  }

  public getBindingPrice(): number
  {
    if (this.orderBindingActive && this.bindingProductFamily)
    {
      return this.valueBinding * this.bindingProductFamily.lowsetPromotionPrice;
    }
    else
    {
      return 0;
    }
  }

  public getSummaryPrice(): number
  {
    return this.getBindingPrice() + this.getQuantity() * this.selectedItem.promotionPrice;
  }

  //#region Binding
  public updateValueBinding($event: string): void
  {
    if (this.showBinding)
    {
      const value = parseFloat($event.replace(/,/gi, "."));
      if (isNaN(value) || value === 0 || this.valueBinding === value)
      {
        this.valueBindingInput.nativeElement.value = this.valueBindingFormated;
        return;
      }
      this.valueBinding = value
      this.emitSelection();
      this.changeDet.detectChanges();
    }
  }

  public updateValueBindingByEnter(value, $event: KeyboardEvent): void
  {
    if ($event.keyCode == 13)
    {
      this.updateValueBinding(value);
    }
  }

  public toggleOrderBinding(): void
  {
    if (this.showBinding)
    {
      this.orderBindingActive = !this.orderBindingActive;

      if (this.orderBindingActive)
      {
        this.toggleOrderAllBinding(true);
        this.calculateBindingQuantity();
      }
      else
      {
        this.valueBinding = 0;
      }

      this.binding.emit(this.orderBindingActive);
      this.selectElement.emit(this.selectedItem);
      this.summaryPrice.emit(this.getSummaryPrice());
    }
  }

  public toggleOrderAllBinding(enable?: boolean): void
  {
    if (this.showBinding)
    {
      this.orderBindingActive = true;
      this.orderAllBindingActive = enable ? enable : !this.orderAllBindingActive;

      this.bindingAll.emit(this.orderAllBindingActive);
      this.emitSelection();
    }
  }

  private calculateBindingQuantity(): void
  {
    if (this.showBinding)
    {
      if (this.orderAllBindingActive)
      {
        if (this.productFamily.isWalkway)
        {
          this.valueBinding = 2 * this.selectedWidth;
        }
        else
        {
          this.valueBinding = 2 * this.selectedWidth + 2 * this.selectedHeight;
        }
      }
    }
  }
  //#endregion

  //#region Extra
  public goToInstalmentsSection(): void
  {
    const element = this.windowRef.getDocument().getElementById("payments");
    this.windowRef.getNativeWindowToScroll().scrollTo({ top: element.offsetTop + 100, behavior: "smooth" });
    this.emitSelection();
  }
  //#endregion

  //#region Popups
  public showTooltipPopup(productPack: ShopProductPack): void
  {
    this.tooltipInvoked.emit(productPack);
  }

  public showProductStatusPopup(productVariant: ShopProductFamily): void
  {
    this.statusProductTooltipInvoked.emit(productVariant);
  }

  public showIntercom(): void
  {
    this.chatService.open();
  }
  //#endregion

  //#region Selection
  public addToSelectedElements(productVariant: ShopProduct): void
  {
    this.selectElement.emit(productVariant);
  }
  //#endregion

  //#region ShopProduct counting (Static)
  public hasAllElements(products: ShopProduct[]): boolean
  {
    const uniqueElementArrays = products.filter((el) => el.isUnique == true);
    const notUniqueElementArrays = products.filter((el) => el.isUnique == false);

    let answer = uniqueElementArrays.length > 0 && notUniqueElementArrays.length > 0;

    if (!answer)
    {
      this.activeTab = null;
    }

    return answer;
  }

  public hasOnlyUniqeElements(products: ShopProduct[]): boolean
  {
    const uniqueElementArrays = products.filter((el) => el.isUnique == true);
    return (products.length == uniqueElementArrays.length && products.length != 0);
  }

  public hasOnlyNotUniqeElements(products: ShopProduct[]): boolean
  {
    const notUniqueElementArrays = products.filter((el) => el.isUnique == false);
    return (products.length == notUniqueElementArrays.length && products.length != 0);
  }
  //#endregion
}