<div class="product-pictures">
  <!-- Big Image -->
  <div class="product-pictures-big">
    <app-main-image
      [imgArray]="product.images"
      [product]="product"
      [width]="width"
      [mainImageIndex]="mainImageIndex"
      (slideChange)="onSlideChange($event)"
    ></app-main-image>
  </div>

  <app-slider
    *ngIf="mainImage && product.images"
    [imgArray]="product.images"
    [gallery]="gallery"
    class="product-pictures-gallery"
    [ngClass]="{ wykladziny: product.isCovering }"
    [product]="product"
    [sliderAfter]="[0, 0, product.images.length > 8 ? -120 : -30]"
    [width]="
      swiperWidth.length > 2
        ? [swiperWidth[0], swiperWidth[1], swiperWidth[2], swiperWidth[3]]
        : [swiperWidth[0], swiperWidth[1], swiperWidth[2]]
    "
    [height]="[swiperHeight[0], swiperHeight[1], swiperHeight[2]]"
    [fillCrop]="true"
    [placeholder]="true"
    [singleSlideMovement]="true"
    (selectMainImage)="changeMainImage($event)"
    #gallerySlider
  >
  </app-slider>
</div>
