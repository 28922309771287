import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ShopProductParameter } from '@dto';
import { UrlTranslateService } from "@service/helpers/url-translate.service";

@Component({
  selector: 'app-product-parameters',
  templateUrl: './product-parameters.component.html',
  styleUrls: ['./product-parameters.component.scss']
})
export class ProductParametersComponent implements OnChanges
{

  public isEven: string = 'even';

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private changeDet: ChangeDetectorRef,
    public urlTranslateService: UrlTranslateService,
  )
  {
  }

  private _productParameters: ShopProductParameter[];
  @Input() public set productParameters(value: ShopProductParameter[])
  {
    this._productParameters = value;
    this.isEven = (this._productParameters.length % 2 == 0 ? 'even' : 'odd');
  }
  public get productParameters(): ShopProductParameter[]
  {
    return this._productParameters;
  }

  public sanitize(url: string): SafeUrl
  {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    this.changeDet.detectChanges();
  }

  public goToListing(url): void
  {
    this.router.navigateByUrl(url);
  }
}
