import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BlockModule } from '../block/block.module';
import { DirectivesModule } from '../directives/directives.module';
import { ExtraModule } from '../extraModuleShared/extraModule.module';
import { ErrorsModule } from '../shared/errors/errors.module';
import { CalendarWidgetComponent } from './calendar/calendar.component';
import { ProductStatusPopupComponent } from './product-status-popup/product-status-popup.component';
import { TooltipCustomComponent } from './tooltip-custom/tooltip-custom.component';
import { TooltipPopupComponent } from './tooltip-popup/tooltip-popup.component';
import { TooltipComponent } from './tooltip.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [TooltipComponent, TooltipCustomComponent, CalendarWidgetComponent, TooltipPopupComponent, ProductStatusPopupComponent],
  imports: [
    CommonModule,
    TranslateModule,
    BlockModule,
    DirectivesModule,
    ErrorsModule,
    ExtraModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule
  ],
  exports: [
    TooltipComponent, TooltipCustomComponent, TooltipPopupComponent, ProductStatusPopupComponent
  ]
})
export class TooltipModule
{
}
