import { KeyValue } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { RollbarErrorHandler } from '@service/rollbar/rollbar';
import { AuthService } from '@service/session/auth/auth.service';
import { MessageItem } from 'app/model/interfaces';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpError } from '../../../model/error.model';

// todo check if is correct services
interface HttpContext<I>
{
  method: string;
  url: string;
  path: string;
  params: Params;
  headers: HttpHeaders;
  body: I;
  startTime: number;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService
{

  private showTechnicalErrorOverlaySubject: Subject<boolean> = new BehaviorSubject(false);
  public showTechnicalErrorOverlay$: Observable<boolean> = this.showTechnicalErrorOverlaySubject.asObservable();

  constructor(private httpClient: HttpClient, private router: Router,
    private authService: AuthService,
    private rollbarService: RollbarErrorHandler)
  {
  }

  public get<O>(path: string, params: Params = null, headers: HttpHeaders = null): Observable<O>
  {
    const context = this.createContext<void>('GET', path, params, headers);
    return this.httpClient.get<O>(context.url, { headers: context.headers, observe: 'response' }).pipe(
      catchError(error => this.handleError<void>(error, context)),
      mergeMap(response => this.handleResponse<void, O>(response, context)));
  }

  public post<I, O>(path: string, body: I, params: Params = null, headers: HttpHeaders = null): Observable<O>
  {
    const context = this.createContext<I>('POST', path, params, headers, body);
    return this.httpClient.post<O>(context.url, body, { headers: headers, observe: 'response' }).pipe(
      catchError(error => this.handleError<I>(error, context)),
      mergeMap(response => this.handleResponse<I, O>(response, context)));
  }

  public put<I, O>(path: string, body: I, params: Params = null, headers: HttpHeaders = null): Observable<O>
  {
    const context = this.createContext<I>('PUT', path, params, headers, body);

    return this.httpClient.put<O>(context.url, body, { headers: headers, observe: 'response' }).pipe(
      catchError(error => this.handleError<I>(error, context)),
      mergeMap(response => this.handleResponse<I, O>(response, context)));
  }

  public delete<O>(path: string, params: Params = null, headers: HttpHeaders = null): Observable<O>
  {

    const context = this.createContext<void>('DELETE', path, params, headers);

    return this.httpClient.delete<O>(context.url, { headers: headers, observe: 'response' }).pipe(
      catchError(error => this.handleError<void>(error, context)),
      mergeMap(response => this.handleResponse<void, O>(response, context)));
  }

  private createContext<I>(method: string, path: string, params: Params, headers: HttpHeaders, body?: I): HttpContext<I>
  {
    return {
      method: method,
      url: this.createUrl(path, params),
      path: path,
      params: params,
      headers: headers,
      body: body,
      startTime: Date.now()
    };
  }

  private createUrl(path: string, params?: Params): string
  {

    if (params == null)
    {
      return `${path}`;
    }

    const paramsString = Object.keys(params)
      .filter(key => params.hasOwnProperty(key))
      .map(key => [key, params[key]])
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return `${path}?${paramsString}`;
  }

  private handleResponse<I, O>(response: HttpResponse<O>, context: HttpContext<I>): Observable<O>
  {
    return of(response.body);
  }

  private handleError<I>(response: HttpErrorResponse, context: HttpContext<I>): Observable<any>
  {
    const errorContext = {
      httpErrorResponse: response,
      httpContext: context,
    };

    this.rollbarService.handleError(errorContext);

    // to delete after ugrpading backend to cammelCase
    if (response.error.Message)
    {
      response.error.message = response.error.Message;
      response.error.type = response.error.Type;
    }

    // if (response.status === 0)
    // {
    //   this.showTechnicalErrorOverlaySubject.next(true);
    // }

    // todo: sprawdzic czy działa
    // if (response.error.status === 503)
    // {
    //   this.router.navigateByUrl('/error/server-break');
    // }

    const serverErrorMessage = this.getMessage(response);

    if (response.error['message'] == 'token')
    {
      const err = new HttpError(response, serverErrorMessage.message, 'messageId?', context.method, context.url);
      this.authService.removeToken();
      this.authService.removeGuid();
      return of(err);
    }

    // this.rollbarService.handleWarning(serverErrorMessage.message, {
    //   statusCode: response.status,
    //   url: context.url,
    //   method: context.method,
    //   params: context.params,
    //   headers: this.getHeadersArray(context.headers),
    //   body: context.body,
    //   message: serverErrorMessage,
    //   trace: this.getTrace(response)
    // });

    return throwError(serverErrorMessage);
  }

  private getHeadersArray(headers: HttpHeaders): KeyValue<string, string>[]
  {
    if (headers === null)
    {
      return [];
    }

    const array: KeyValue<string, string>[] = [];

    headers.keys()
      .forEach(key => headers
        .getAll(key).forEach(value => array.push({ key: key, value: value }))
      );

    return array;
  }

  private getMessage(error: HttpErrorResponse): MessageItem
  {
    return {
      message: error.error.message ? error.error.message : error.message,
      type: error.error.type
    };
  }

  private getTrace(error: HttpErrorResponse): string
  {
    return error.error.trace ? error.error.trace : null;
  }
}
