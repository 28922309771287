import { ChangeDetectorRef, Component, Input, Output, OnInit, ViewChild, EventEmitter } from "@angular/core";
import { ShopProductFamily } from "@dto";
import { Resolutions } from "@resolutions";
import
{
  getImageUrl,
} from "../../../../utils/utilsFunctions";
import SwiperCore, { Navigation, SwiperOptions } from "swiper";
import { SwiperConfigService } from "@service/swiper-config.service";

SwiperCore.use([Navigation]);

@Component({
  selector: "app-main-image-zoom-thumbs",
  templateUrl: "./main-image-zoom-thumbs.component.html",
  styleUrls: ["./main-image-zoom-thumbs.component.scss"],
})
export class MainImageZoomThumbsComponent implements OnInit
{
  public swiperOpt: SwiperOptions;

  public itemsThumbs = [];
  public _product: ShopProductFamily;
  public _activeIndex: number;

  @Input() set product(product: ShopProductFamily)
  {
    this._product = product;
    this.updateItems(product);
  }
  @Input() set activeIndex(index: number)
  {
    this._activeIndex = index;
    this.changeDet.detectChanges();
  }
  @Output() public readonly activeIndexChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild("swiperComponent") private swiperComponent;

  constructor(
    private changeDet: ChangeDetectorRef,
    private swiperCo: SwiperConfigService
  )
  {
    this.swiperOpt = this.swiperCo.getConfig(
      [0, Resolutions.resolutionDesktop],
      [0, 0],
      [32, 24],
      [0, 0]);

    this.swiperOpt.centerInsufficientSlides = true;
  }

  private updateItems(product: ShopProductFamily)
  {
    this.itemsThumbs = [];
    const images = product.images;
    if (images && images.length)
    {
      images.forEach((image, index) =>
      {
        const noCropIndexes = [0, 10, 11, 12];

        this.itemsThumbs.push({
          index: images[index].index,
          src: getImageUrl(
            product.familyCode,
            images[index].index.toString(),
            [224, 224, 224],
            images[index].index == 0 ? [154, 154, 154] : [174, 174, 174],
            "100",
            null,
            !noCropIndexes.includes(images[index].index)
          ),
        });
      });
    }
  }


  public goToImage(i: number): void
  {
    this._activeIndex = i;
    this.activeIndexChange.emit(this._activeIndex);
  }

  public nextSlide(): void
  {
    if (this._activeIndex < this.itemsThumbs.length - 1)
    {
      if (this._activeIndex > 3)
      {
        this.swiperComponent.swiperRef.slideNext();
      }
      this._activeIndex++;
    } else
    {
      this.swiperComponent.swiperRef.slideTo(0, 800);
      this._activeIndex = 0;
    }
    this.activeIndexChange.emit(this._activeIndex);
  }

  public previousSlide(): void
  {
    if (this._activeIndex > 0)
    {
      this.swiperComponent.swiperRef.slidePrev();
      this._activeIndex--;
    } else
    {
      this.swiperComponent.swiperRef.slideTo(this.itemsThumbs.length - 1, 800);
      this._activeIndex = this.itemsThumbs.length - 1;
    }
    this.activeIndexChange.emit(this._activeIndex);
  }

  public setThumbAsLoaded(thumb: any): void
  {
    thumb["loaded"] = true;
    this.changeDet.detectChanges();
  }

  public onError(img): void
  {
    img['src'] = '/assets/icons/no-image.svg';
    this.changeDet.detectChanges();
  }

  ngOnInit(): void
  {
  }
}
