<div class="modal-wrapper" *ngIf="showForm" [ngClass]="{ loaded: loaded }">
  <div class="wrapper">
    <div
      class="under-wrapper"
      sdClickOutside
      (clickOutside)="closeModal($event)"
      [ngClass]="{
        calendar: getShippingLabel() != 'tel.',
        loaded: loaded
      }"
      #parent
      [parentRef]="parent"
    >
      <div class="navigation-bar">
        <span class="arrow-back ai ai-back" (click)="closeModal($event)"></span>
        <span class="heading5">{{
          "productVariants.availbility" | translate
        }}</span>
        <div class="img-wrapper cancel" (click)="closeModal($event)">
           <i class="ai ai-cancel"></i>
        </div>
      </div>
      <div class="separator-gray full-height separator-one-half"></div>

      <div (click)="closeModal($event)" class="close-button">
         <i class="ai ai-cancel"></i>
      </div>

      <ng-container *ngIf="getShippingLabel() == 'tel.'">
        <div class="font-style-semi main-title">
          {{ "tooltip.unknownDate" | translate }}
        </div>
        <ul class="special-bullet-list">
          <li class="font-style-light color-body-text">
            {{ "tooltip.phoneRequest" | translate }}
          </li>
          <li class="font-style-light color-body-text">
            {{ "tooltip.phoneRequest2" | translate }}
          </li>
        </ul>
        <div class="button-wrapper">
          <button
            class="button primary-button"
            (click)="goToInfoAlert(product, $event)"
          >
            {{ "tooltip.callMeWhenAvailable" | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="getShippingLabel() != 'tel.'">
        <div class="font-style-semi main-title separator1">
          {{ "tooltip.shippingDate" | translate }}
        </div>
        <p class="color-body-text description-under-title font-style-light">
          {{ "tooltip.forOrdersBeforeDate" | translate }}
          <span class="font-style-semi no-wrap"
            >{{ dateNowISO | date : "dd.MM.yyyy" }} r.</span
          >
          {{ "tooltip.today" | translate }}
          {{ "tooltip.predictedShippingDate" | translate }}
          <span class="font-style-semi no-wrap"
            >{{
              product.shippingTime?.shippingtDate | date : "dd.MM.yyyy"
            }} r.</span
          >
        </p>
        <ng-scrollbar class="list-wrapper horizontal-scrollbar" autoHeightDisabled="false">
          <app-calendar-widget
            [dates]="getDates(product)"
          ></app-calendar-widget>
        </ng-scrollbar>
      </ng-container>
    </div>
  </div>
</div>

<app-product-status-popup
  [name]="'status-' + name"
  [mainTitle]="'modalsTexts.productAvailability' | translate"
  [navigationBarTitle]="'modalsTexts.productAvailability' | translate"
  [product]="product"
  [defaultButtonText]="'send' | translate"
  [buttonType]="'primary-button'"
  [showEmail]="true"
  [showPhone]="true"
  (closeParentEventEmitter)="closeModal()"
  (successEmitter)="emitSuccess($event)"
>
</app-product-status-popup>
