import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationStart } from "@angular/router";
import { UrlTranslateService } from './helpers/url-translate.service';
import { ViewportScroller } from '@angular/common';
import { WindowRefService } from './window-service/window-ref.service';


interface ScrollPositionObject
{
  position: [number, number];
  url: string;
}

interface ScrollPosition
{
  positions: ScrollPositionObject[],
  positionToRestore: [number, number]
}


@Injectable({
  providedIn: 'root'
})

export class ScrollPositionService
{
  private ignoreNext: boolean = false;
  private scrollRestorationList: string[];
  private scrollPosition: BehaviorSubject<ScrollPosition> = new BehaviorSubject({ positions: [], positionToRestore: [0, 0] })

  constructor(private urlTranslateService: UrlTranslateService,
    private viewportScroller: ViewportScroller,
    private windowRef: WindowRefService)
  {
    this.urlTranslateService.routingTable.shop.listingFriendly = (window as any).friendlyList;
    this.scrollRestorationList = [
      this.urlTranslateService.routingTable.shop.listing,
      this.urlTranslateService.routingTable.shop.product,
      this.urlTranslateService.routingTable.blog,
      ...this.urlTranslateService.routingTable.shop.listingFriendly
    ];
  }

  public ignoreNextRestore(): void
  {
    this.ignoreNext = true;
  }

  public resetPosition(url: string)
  {
    if (!this.scrollRestorationList.some(o => url.startsWith('/' + o)) && !(url == '/'))
    {
      this.viewportScroller.scrollToPosition([0, 0]);
    }
  }

  public restorePosition(): void
  {
    if (this.ignoreNext)
    {
      this.ignoreNext = false;
      return;
    }

    this.viewportScroller.scrollToPosition(this.scrollPosition.value.positionToRestore);
    setTimeout(() =>
    {
      this.viewportScroller.scrollToPosition(this.scrollPosition.value.positionToRestore);
    }, 0);
  }

  public popPositionAndWait(): void
  {
    const currentPositions = { ...this.scrollPosition.value };

    if (currentPositions.positions.length > 0)
    {
      currentPositions.positionToRestore = currentPositions.positions[currentPositions.positions.length - 1].position;
      currentPositions.positions.pop()
    }
    else
    {
      currentPositions.positionToRestore = [0, 0];
    }

    this.scrollPosition.next(currentPositions);
  }

  public addPosition(currentUrl: string): void
  {
    const scrollPosition = this.viewportScroller.getScrollPosition();
    const currentPositions = { ...this.scrollPosition.value };

    currentPositions.positionToRestore = [0, 0];
    currentPositions.positions.push({ position: scrollPosition, url: currentUrl })

    this.scrollPosition.next(currentPositions);
  }

  public getScrollPosition(): [number, number]
  {
    return this.scrollPosition.value.positionToRestore;
  }

  public teleportTo(y: number): void
  {
    this.windowRef.getNativeWindowToScroll().scrollTo({ top: y, left: 0, behavior: "auto" });
  }

  public scrollTo(y: number): void
  {
    this.windowRef.getNativeWindowToScroll().scrollTo({ top: y, left: 0, behavior: "smooth" });
  }
}
