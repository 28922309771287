import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscountComponent
{
  @Input() public isPromotion: boolean = false;
  @Input() public promotionPrice: string;
  @Input() public isFrom: boolean = false;
}
