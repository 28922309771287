<main class="main-content">

  <app-breadcrumbs [doubleHeight]="true"></app-breadcrumbs>

  <ng-container *ngIf="product$ | async as product; else emptyProduct">
    <div class="product-wrapper" id="productWrapper">
      <h1 *ngIf="product.name" class="title">{{ product.name }}</h1>

      <div class="picture-wrapper">
        <app-chips [items]="product.tags"></app-chips>
        <app-product-picture
          [width]="[450, 800, 830]"
          [product]="product"
          [swiperHeight]="[450, 63, 87]"
          [swiperWidth]="[450, 768, 80, 112]">
        </app-product-picture>
      </div>

      <app-product-calculator [product]="product" (summaryPrice)="onSummaryPriceChange($event)"></app-product-calculator>

      <div style="clear: both"></div>

      <app-product-parameters id="descriptionProduct" [productParameters]="product.parameters"></app-product-parameters>

      <app-tile-box [product]="product" [isProduct]="true" [columns]="2" [showMore]="true" id="collectionProduct"></app-tile-box>

      <app-product-slider
        cardSize="small"
        class="slider-template-small-products similar-products"
        [ngClass]="{ wykladzina: product.isCovering, hidden: hasSimilarProducts }"
        [products]="{ link: 'cg={customer_guid}&sti={product_family_code}&c=16', productFamilyCode: product.familyCode }"
        [height]="[242, 242, 242]"
        [width]="[180, 180, 180]"
        [spaceBetween]="[12, 50, 32, 32]"
        [title]="'favourite.showSimilarProducts' | translate"
        (sliderIsEmpty)="hasSimilarProducts = $event"
        (routeActive)="resetProduct()">
      </app-product-slider>

      <app-product-rating id="reviews" [product]="product"></app-product-rating>

      <app-raty #payments *ngIf="translateService.currentLang === 'pl' || canBuyWithInstallment" id="payments"></app-raty>

      <section class="complementary-section second-product-slider" id="complementary" *ngIf="product.accessoryProducts && product.accessoryProducts.length">

        <h2 class="additional-title">
          {{ "productCalculator.addAccessory" | translate }}
        </h2>

        <swiper [config]="swiperOpt" [ngClass]="{ loaded: swiperIsLoaded, wykladziny: accesoriesAreCoverings }" class="slider-main" #swiperComponent>
          <ng-template *ngFor="let acp of product.accessoryProducts; let i = index;" swiperSlide>
            <div class="additional-wrapper" [ngClass]="{ wykladziny: acp.isCovering }">

              <a [routerLink]="getRouteLink(acp)" (click)="routerGuard($event, acp)">
                <app-single-product
                  [product]="acp"
                  [height]="[230, 230, 348]"
                  [width]="[208, 235, 269]"
                  [fillCrop]="true"
                  [productFamily]="acp"
                  [simplePrice]="true"
                  [lazy]="false">
                </app-single-product>
              </a>

              <div class="flex-wrapper-checkbox" (click)="addToCart(acp, isSelected(acp.iD))">
                <input type="checkbox" class="primary-checkbox" [checked]="isSelected(acp.iD)"/>
                <span class="checkmark"></span>
                <p class="compare-checkbox-label" [ngClass]="{ active: isSelected(acp.iD) }">
                  {{ getCaption(isSelected(acp.iD)) }}
                </p>
              </div>

            </div>
          </ng-template>
        </swiper>

      </section>

      <ng-container *ngIf="product.isCovering && banner">
        <div class="virtual-travel">
          <!--todo dokonczyc banner jak bedzie zdjecie-->
          <div class="banner-squere">
            <p>
              <span class="tablet">{{ banner.captionShort }}</span
              ><span class="desktop">{{ banner.caption }}</span>
            </p>
            <p>
              <a
                class="link-to-more text-link"
                [routerLink]="
                  '/' + urlTranslateService.routingTable.shop.listing
                "
                [queryParams]="getLinkToProduct(banner.callToActionLink)"
                >{{ banner.callToActionText }}</a
              >
            </p>
          </div>
          <div class="image-wrapper">
            <img
              class="virtual-img"
              [src]="
                getImageUrlStatic(
                  banner.image,
                  [760, 976, 976],
                  [328, 400, 400]
                )
              "
            />
          </div>
        </div>
      </ng-container>
    </div>
    <app-product-slider
      *ngIf="product.collectionFilter"
      cardSize="big"
      class="slider-template-small-products last-slider"
      [title]="'maybeYouLike' | translate"
      [spaceBetween]="[12, 50, 32, 32]"
      [products]="{ link: product.collectionFilter + '&c=16' }"
      [secondaryTitle]="'findMoreFromOurCollection' | translate"
      [secondaryTitleHref]="product.collectionName"
      (routeActive)="resetProduct()"
    ></app-product-slider>
  </ng-container>

  <ng-template #emptyProduct>
    <div class="product-wrapper-placeholder">
      <div class="product-wrapper">
        <div class="upper-part-placeholder">
          <div class="right-side">
          <div class="title"></div>
          <div class="chips-placeholder"></div>
          <div class="image-placeholder">
            <div class="slider-placeholder">
              <div class="small-squere"></div>
              <div class="small-squere"></div>
              <div class="small-squere"></div>
              <div class="small-squere"></div>
              <div class="small-squere"></div>
              <div class="small-squere"></div>
            </div>
            <div class="main-image-placeholder">
              <img [src]="'/assets/images/empty-pic.svg'" />
            </div>
          </div>
          </div>
          <div class="calculator-wrapper">
            <div class="outlet-placeholder">
                <div class="outlet-placeholder-inside">
                </div>
            </div>
            <div class="title-row"></div>
            <div class="description-row"></div>
            <div class="rows-wrapper">
              <div class="single-row">
                <div class="row"></div>
                <div class="row2"></div>
              </div>
              <div class="separator-gray no-margin"></div>
              <div class="single-row">
                <div class="row"></div>
                <div class="row2"></div>
              </div>
              <div class="separator-gray no-margin"></div>
              <div class="single-row">
                <div class="row"></div>
                <div class="row2"></div>
              </div>
              <div class="separator-gray no-margin"></div>
              <div class="single-row">
                <div class="row"></div>
                <div class="row2"></div>
              </div>
              <div class="separator-gray no-margin"></div>
              <div class="single-row">
                <div class="row"></div>
                <div class="row2"></div>
              </div>
              <div class="separator-gray no-margin"></div>
              <div class="single-row">
                <div class="row"></div>
                <div class="row2"></div>
              </div>
              <div class="separator-gray no-margin"></div>
            </div>
            <div class="big-squere-placeholder"></div>
          </div>
        </div>
        <div class="bottom-part-placeholder">
          <div class="rows-wrapper">
            <div class="single-row">
              <div class="row"></div>
              <div class="row2"></div>
            </div>
            <div class="separator-gray no-margin"></div>
            <div class="single-row">
              <div class="row"></div>
              <div class="row2"></div>
            </div>
            <div class="separator-gray no-margin"></div>
            <div class="single-row">
              <div class="row"></div>
              <div class="row2"></div>
            </div>
            <div class="separator-gray no-margin"></div>
            <div class="single-row">
              <div class="row"></div>
              <div class="row2"></div>
            </div>
            <div class="separator-gray no-margin"></div>
          </div>
          <div class="rows-wrapper">
            <div class="single-row">
              <div class="row"></div>
              <div class="row2"></div>
            </div>
            <div class="separator-gray no-margin"></div>
            <div class="single-row">
              <div class="row"></div>
              <div class="row2"></div>
            </div>
            <div class="separator-gray no-margin"></div>
            <div class="single-row">
              <div class="row"></div>
              <div class="row2"></div>
            </div>
            <div class="separator-gray no-margin"></div>
            <div class="single-row">
              <div class="row"></div>
              <div class="row2"></div>
            </div>
            <div class="separator-gray no-margin"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <router-outlet></router-outlet>

</main>