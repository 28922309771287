import { Component, Input } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sd-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']

})
export class ErrorsComponent
{
  private errorMessages = {
    'required': () => this.getTranslation(this.translate.instant('fieldReq')),
    'minlength': (params) => this.getTranslation(this.translate.instant('error.minLength'), [params.requiredLength]),
    'maxlength': (params) => this.getTranslation(this.translate.instant('error.maxLength'), [params.requiredLength]),
    'pattern': (params) => this.getTranslation(this.translate.instant('error.pattern'), [params.requiredPattern]),
    'years': (params) => this.getTranslation(this.translate.instant('error.years'), [params.message]),
    'countryCity': (params) => this.getTranslation(this.translate.instant('error.countryCity'), [params.message]),
    'uniqueName': (params) => this.getTranslation(this.translate.instant('error.uniqueName'), [params.message]),
    'telephoneNumbers': (params) => this.getTranslation(this.translate.instant('error.telephoneNumbers'), [params.message]),
    'telephoneNumber': (params) => this.getTranslation(this.translate.instant('error.telephoneNumber'), [params.message]),
    'invalidTelephone': () => this.getTranslation(this.translate.instant('error.invalidTelephone')),
    'invalidBankNumber': () => this.getTranslation(this.translate.instant('error.invalidBankNumber')),
    'invalidVat': () => this.getTranslation(this.translate.instant('error.invalidVat')),
    'email': () => this.getTranslation(this.translate.instant('error.invalidEmail')),
    'emailRepeated': () => this.getTranslation(this.translate.instant('error.emailTaken')),
    'notEqual': () => this.getTranslation(this.translate.instant('error.valuesNotEqual')),
    'default': () => this.getTranslation(this.translate.instant('error.default')),
  };

  @Input() private control: AbstractControlDirective | AbstractControl;
  @Input() private context: string;
  @Input() public show: boolean = true;
  @Input() public noImage: boolean = false;

  constructor(private translate: TranslateService)
  {
  }

  public shouldShowErrors(): boolean
  {
    return this.show && this.control && this.control.errors && (this.control.dirty && this.control.touched);
  }

  public shouldShowAccepted(): boolean
  {
    return this.show && this.control && !this.control.errors && (this.control.dirty && this.control.touched);
  }

  public get error(): string
  {
    const key = Object.keys(this.control.errors)[0];
    return this.getMessage(key, this.control.errors[key]);
  }

  private getMessage(type: string, params: any)
  {
    if (!this.errorMessages[type])
    {
      type = 'default';
    }
    return this.errorMessages[type](params);
  }

  private getTranslation(translation: string, params: any[] = [])
  {
    if (params.length === 0)
    {
      return translation;
    }

    params.forEach((param, index) =>
    {
      translation = translation.replace(`{p${index + 1}}`, param);
    });

    return translation;
  }
}
