<h2 class="font-style-semi separator1 title">{{ 'installment.buy' |translate }}</h2>
<div class="font-style-light color-body-text description">{{ 'installment.planning' |translate }}</div>
<div class="separator-gray inactive no-margin"></div>
<div id="installment-calc"></div>

<div id="arte-installment-calc" class="font-style-light color-body-text" [hidden]="translate.currentLang !== 'pl'">
    <div class="left-column">
        <div class="payUlogo"></div>
        <div class="inputs-row">
            <label for="payu-credit-amount-input">{{ 'installment.creditAmount' |translate }}</label>
            <div class="field">
                <input id="payu-credit-amount-input" #creditAmountInput name="payu-credit-amount-input" class="input"
                    (change)="refreshCreditAmount()">
                <span class="payu-currency payu-val"></span>
            </div>
        </div>
        <div class="inputs-row">
            <label for="payu-installment-amout-input" name="payu-installment-amout-input">{{
                'installment.installmentAmout' |translate }}</label>
            <div class="field">
                <div class="minus" [ngClass]="{ disabled: installmentAmountValue <= 12 }" (click)="subtractionValue()">
                    <img src="/assets/icons/minus.svg" />
                </div>
                <input class="input text-center" #installmentAmoutInput (change)="refreshInstallmentAmount()"
                    id="payu-installment-amout-input" />
                <div class="plus" [ngClass]="{ disabled: installmentAmountValue >= 50 }" (click)="additionValue()">
                    <img src="/assets/icons/plus.svg" />
                </div>
            </div>
        </div>
        <div id="payu-credit-amount-slider"></div>
        <div id="payu-installment-amount-slider"></div>
    </div>

    <div class="right-column">
        <div class="flat-values">
            <div class="single-value">
                <p>{{ 'installment.totalCredit' |translate }}</p>
                <strong>
                    <span id="payu-total-credit"></span>
                    <span class="payu-currency payu-val"></span>
                </strong>
            </div>

            <div class="single-value">
                <p>{{ 'installment.creditCost' |translate }}</p>
                <strong>
                    <span id="payu-credit-cost"></span>
                    <span class="payu-currency payu-val"></span>
                </strong>
            </div>

            <div class="single-value">
                <p>{{ 'installment.rrso' |translate }}</p>
                <strong>
                    <span id="payu-calculator-rrso"></span>
                    <span class="payu-percentage">%</span>
                </strong>
            </div>

            <div class="single-value">
                <p>{{ 'installment.oneInstallment' |translate }}</p>
                <strong>
                    <span id="payu-one-installment"></span>
                    <span class="payu-currency payu-val"></span>
                </strong>
            </div>

            <div class="single-value">
                <p>{{ 'installment.interestRateValue' |translate }}</p>
                <strong>
                    <span id="payu-interest-rate-value"></span>
                    <span class="payu-percentage">%</span>
                </strong>
            </div>

            <div class="single-value"></div>

            <div class="single-value"><a
                    href="https://poland.payu.com/wp-content/uploads/sites/14/2021/03/Przyk%C5%82ady-reprezentatywne-03.2021.pdf"
                    target="_blank">{{ 'installment.representativeExample' |translate }}</a></div>
        </div>
    </div>
</div>