import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UrlHelperService } from '@service/helpers/url-helper.service';
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { TileContent } from 'app/model/interfaces';
import { transformColorStatic } from 'app/utils/utilsFunctions';

@Component({
  selector: 'app-tile-banner',
  templateUrl: './tile-banner.component.html',
  styleUrls: ['./tile-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileBannerComponent
{
  public transformColor = transformColorStatic;

  @Input() public height: number[];
  @Input() public width: number[];
  @Input() public banner: TileContent;
  @Input() public lazy: boolean;

  public constructor(
    private readonly urlHelper: UrlHelperService,
    public windowRef: WindowRefService,
    public urlTranslateService: UrlTranslateService
  )
  {
  }

  public isDoubleWidth(banner: TileContent): boolean
  {
    return (banner.position.right - banner.position.left) >= 1;
  }

  public getLinkToProduct(link: any): any
  {
    return this.urlHelper.parseQueryStringToObject(link.split(`/${this.urlTranslateService.routingTable.shop.listing}?`)[1]);
  }
}
