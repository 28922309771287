import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ShopProductFamily } from '@dto';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '@service/config/config.service';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { Article, ArticleContent } from '@service/article/article.service';
import { routingTable } from 'environments/routing-table';
import { BlockService } from '@shared/block/shared/block.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService
{
  private siteName: string;

  constructor(
    @Inject(DOCUMENT) private dom,
    private titleService: Title,
    private translateService: TranslateService,
    private meta: Meta,
    private configService: ConfigService,
    private windowRef: WindowRefService,
    public urlTranslateService: UrlTranslateService,
    private blockService: BlockService,
  )
  {
  }

  public clear(): void
  {
    this.setCanonical(undefined);
    this.setImage(undefined, undefined);
    this.setDescription(undefined);
    this.setRichResults(undefined);
    this.setKeywords(undefined);
  }

  public getFilterQuery(nextLink: string = null): string
  {
    const friendlyUrls = this.blockService.getDataSync('friendlyUrls');
    let urlParts: any[];

    if (!nextLink)
    {
      const url = new URL(window.location.href);
      urlParts = url.pathname.split('/').filter(o => o != '');
    }
    else
    {
      urlParts = nextLink.split('/').filter(o => o != '');
    }

    const pageNumber = Number(urlParts[urlParts.length - 1]);


    if (!isNaN(pageNumber))
    {
      urlParts.pop();
    }

    let mappedFriendlyUrl = friendlyUrls ? Object.keys(friendlyUrls).find(key => friendlyUrls[key] === '/' + urlParts.join('/')) : null;

    if (mappedFriendlyUrl)
    {
      let url = mappedFriendlyUrl;
      //let url = `${this.urlTranslateService.routingTable.shop.listing}?${mappedFriendlyUrl}`;
      return isNaN(pageNumber) ? url : url += `&p=${pageNumber}`;
    }
    else
    {
      return null;
    }
  }

  public setProduct(product: ShopProductFamily): void
  {
    if (product)
    {
      //#region Setup
      let description = product.collectionDescription;

      if (product.hasDescription)
      {
        description = product.description;
      }
      //#endregion

      this.setBasics();
      this.setRichResults(product.richResults);
      this.setTitle(product.name + ' - ' + this.translateService.instant("pageTitleSufix"));
      this.setDescription(description);
      this.setCanonical(this.windowRef.getNativeWindow().location.host + `/${this.urlTranslateService.routingTable.shop.product}/` + product.url);

      if (product.images && product.images.length)
      {
        const image = this.configService.ImageUrl + '/productimages/' + product.familyCode + '?index=0&dpi=100&format=jpg&height=630&borderWidth=12&borderSide=inside';
        this.setImage(image, product.name);
      }
    }
    else
    {
      this.setCanonical(undefined);
      this.setRichResults(undefined);
    }
  }

  public setArticle(article: Article, articleContent: ArticleContent): void
  {
    if (article.directPath)
    {
      this.setCanonical(this.windowRef.getNativeWindow().location.host + "/" + article.code);
    }
    else if (article.blogPath)
    {
      this.setCanonical(this.windowRef.getNativeWindow().location.host + "/" + routingTable.blog + "/" + article.code);
    }

    if (articleContent.metaTitle)
    {
      this.setTitle(articleContent.metaTitle);
    }

    if (articleContent.mainImage)
    {
      this.setImage(articleContent.mainImage.url, "Zdjęcie " + articleContent.title);
    }

    if (articleContent.metaDescription)
    {
      this.setDescription(articleContent.metaDescription);
    }

    if (articleContent.ldJson)
    {
      this.setRichResults(articleContent.ldJson);
    }

    if (articleContent.metaKeywords)
    {
      this.setKeywords(articleContent.metaKeywords);
    }
  }

  //#region Basics
  public setBasics(): void
  {
    if (this.siteName == undefined || this.siteName == '')
    {
      this.siteName = this.translateService.instant('fullPageName');
      this.meta.updateTag({ property: 'og:site_name', content: this.siteName });
    }

    this.meta.updateTag({ property: 'og:locale', content: "pl_PL" });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:image:type', content: 'image/jpeg' });
    this.meta.updateTag({ property: 'fb:app_id', content: environment.facebookAppId });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
  }
  //#endregion

  //#region Image
  public setImage(url: string, alt: string)
  {
    if (url == undefined || url == '')
    {
      this.meta.removeTag("property='og:image'");
      this.meta.removeTag("name='twitter:image'");
    }
    else
    {
      this.meta.updateTag({ property: 'og:image', content: url });
      this.meta.updateTag({ name: 'twitter:image', content: url });
    }

    if (alt == undefined || alt == '')
    {
      this.meta.removeTag("property='og:image:alt'");
      this.meta.removeTag("name='twitter:image:alt'");
    }
    else
    {
      this.meta.updateTag({ property: 'og:image:alt', content: alt });
      this.meta.updateTag({ name: 'twitter:image:alt', content: alt });
    }
  }

  public setStandardImage()
  {
    this.meta.updateTag({ property: 'og:image', content: "https://images.arte.pl/images/Banners/AR5_9884_7.jpg?cropType=Fill_Crop&zoom=22&cropYOffsetPercent=62&cropXOffsetPercent=95&width=1200&height=630" });
    this.meta.updateTag({ name: 'twitter:image', content: "https://images.arte.pl/images/Banners/AR5_9884_7.jpg?cropType=Fill_Crop&zoom=22&cropYOffsetPercent=62&cropXOffsetPercent=95&width=1200&height=630" });
    this.meta.updateTag({ property: 'og:image:alt', content: "Sklepy Arte.pl" });
    this.meta.updateTag({ name: 'twitter:image:alt', content: "Sklepy Arte.pl" });
  }
  //#endregion

  //#region LD+JSON
  public setRichResults(richResultsObject: any): void
  {
    let richResults = undefined;

    if (typeof richResultsObject === 'string')
    {
      richResults = richResultsObject;
    }
    else
    {
      richResults = JSON.stringify(richResultsObject);
    }

    let existingScript = this.getRichResults();

    if (richResults)
    {
      if (!existingScript)
      {
        existingScript = this.windowRef.getDocument().createElement("script");
        existingScript.setAttribute("type", "application/ld+json");
        this.windowRef.getDocument().head.appendChild(existingScript);
      }

      existingScript.textContent = richResults;
    }
    else
    {
      if (existingScript)
      {
        this.windowRef.getDocument().head.removeChild(existingScript);
      }
    }
  }

  public getRichResults(): HTMLElement
  {
    const links: any = Array.prototype.slice.call(this.dom.getElementsByTagName('script'));
    const richResultScript = links.filter(s => s.type == "application/ld+json");

    if (richResultScript.length > 0)
    {
      return richResultScript[0];
    }
    else
    {
      return null;
    }
  }
  //#endregion

  //#region Title
  public setTitle(title: string): void
  {
    this.titleService.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
  }
  //#endregion

  //#region Description
  public setDescription(description: string): void
  {
    if (description == undefined || description == '')
    {
      this.meta.removeTag('name="description"');
      this.meta.removeTag('name="twitter:description"');
      this.meta.removeTag('property="og:description"');
    }
    else
    {
      this.meta.updateTag({ name: 'description', content: description });
      this.meta.updateTag({ name: 'twitter:description', content: description });
      this.meta.updateTag({ property: 'og:description', content: description });
    }
  }
  public setStandardDescription(): void
  {
    this.setDescription("Arte.pl – Oferujemy dywany, wykładziny, chodniki i wycieraczki takich producentów jak Agnella, Dywilan, Osta, Arte Espina oraz wielu innych. Salony w Warszawie, Krakowie, Wrocławiu, Katowicach i Częstochowie. Zapraszamy!");
  }
  //#endregion

  //#region Keywords
  public setKeywords(keywords: string): void
  {
    if (keywords == undefined || keywords == '')
    {
      this.meta.removeTag('name="keywords"');
    }
    else
    {
      this.meta.updateTag({ name: 'keywords', content: keywords });
    }
  }
  //#endregion

  //#region Canonical
  public setCanonical(url?: string)
  {
    let canonicalLink = this.getCanonical();

    if (url == undefined || url == '')
    {
      if (canonicalLink)
      {
        this.dom.head.removeChild(canonicalLink);
      }

      this.meta.removeTag('property="og:url"');
    }
    else
    {
      if (!canonicalLink)
      {
        canonicalLink = this.dom.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(canonicalLink);
      }

      if (!url.startsWith("https://"))
      {
        url = "https://" + url;
      }

      canonicalLink.setAttribute('href', url);

      //#region TODO - za bardzo miga
      if (this.windowRef.getNativeWindow().location.host.includes("development") || this.windowRef.getNativeWindow().location.host.includes("localhost"))
      {
        //const dsds = url.split(this.windowRef.getNativeWindow().location.host).pop();
        //this.location.replaceState(dsds);
      }
      //#endregion

      this.meta.updateTag({ property: 'og:url', content: url });
    }
  }

  public getCanonical(): HTMLLinkElement
  {
    const links: any = this.dom.getElementsByTagName('link');

    for (let i = 0; i < links.length; ++i)
    {
      if (links[i].rel == 'canonical')
      {
        return links[i];
      }
    }

    return undefined;
  }
  //#endregion

  //#region Nofollow
  public setNofollowByUrl(url: string)
  {
    if (document.body.classList.contains('kiosk-mode'))
    {
      this.setNofollow(true);
    }
    else if (url.startsWith(`/${this.urlTranslateService.routingTable.shop.listing}?`)) //&& url.search("cg=") >= 0)
    {
      this.setNofollow(true);
    }
    else if (url.startsWith(`/${this.urlTranslateService.routingTable.customer.base}`) ||
      url.startsWith(`/${this.urlTranslateService.routingTable.checkout.base}`) ||
      url.startsWith(`/${this.urlTranslateService.routingTable.shop.compare}`))
    {
      this.setNofollow(true);
    }
    else
    {
      this.setNofollow(false);
    }
  }

  public setNofollow(value: boolean): void
  {
    if (value)
    {
      this.meta.addTag({ name: "robots", content: "noindex" });
    }
    else
    {
      this.meta.removeTag("name='robots'");
    }
  }
  //#endregion
}