import
{
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomerClientService } from "@service/client/customer-client.service";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { UrlTranslateService } from '@service/helpers/url-translate.service';

@Component({
  selector: "app-account-popup",
  templateUrl: "./account-popup.component.html",
  styleUrls: ["./account-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPopupComponent implements OnInit, OnDestroy
{
  private destroy$: Subject<void> = new Subject();
  public userName: string;
  public shouldShow: boolean = false;
  public userIsLogged: boolean;

  constructor(
    private cs: CustomerClientService,
    private translate: TranslateService,
    private changeDet: ChangeDetectorRef,
    public urlTranslateService: UrlTranslateService
  ) { }

  public ngOnDestroy(): void
  {
    if (!this.changeDet["destroyed"])
    {
      this.destroy$.next();
      this.destroy$.complete();
    }
  }

  public ngOnInit(): void
  {
    this.cs.customer$
      .pipe(
        takeUntil(this.destroy$),
        filter((v) => !!v)
      )
      .subscribe((res) =>
      {
        this.userIsLogged = res && res.isRegistered;

        if (res && res.defaultContactAddress && res.defaultContactAddress.firstName)
        {
          this.userName = res.defaultContactAddress.firstName;
        }
        else
        {
          this.userName = this.translate.instant("userName");
        }

        if (!this.changeDet["destroyed"])
        {
          this.changeDet.detectChanges();
        }
      });
  }

  public show(): void
  {
    this.shouldShow = true;
    this.changeDet.detectChanges();
  }

  public hide(): void
  {
    this.shouldShow = false;
    this.changeDet.detectChanges();
  }

  public isUserLogged(): boolean
  {
    setTimeout(() =>
    {
      if (!this.changeDet["destroyed"])
      {
        this.changeDet.detectChanges();
      }
    }, 64);
    return this.cs.isUserLogged;
  }

  public logout(): void
  {
    this.cs.logout();
    this.hide();
  }
}
