<div class="wrapper breadcrumbs" *ngIf="breadcrumbs$ | async as breadcrumbs; else placeHolder" [ngClass]="{ placeholder: breadcrumbs == null }">
  <ul *ngIf="breadcrumbs && breadcrumbs.length">
    <a class="success-link" (click)="goBack()"> ‹ {{ "back" | translate }}</a>
    <li *ngFor="let urlState of breadcrumbs; let i = index">
      <a [ngClass]="{ 'last-bolder': i == breadcrumbs.length - 1 }" [routerLink]="urlState.url" [queryParams]="urlState.queryParams">
        {{ urlState.name | translate }}
      </a>
      <div class="break" *ngIf="i < breadcrumbs.length - 1"></div>
    </li>
  </ul>
</div>
<ng-template #placeHolder>
  <div class="small-squere" [ngClass]="{ doubleHeight: doubleHeight }" style="height: placeHolderHeight;"></div>
</ng-template>