import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appExpand]'
})
export class ExpandDirective implements AfterViewInit
{
  @Input('appExpand') private section: HTMLElement;
  @Input() private isCollapsible: boolean = false;
  @Input() private initialHeight: number = null;
  @Input() private elements?: number;
  @Input() private perPage?: number;

  @Output() private toggleSection: EventEmitter<boolean> = new EventEmitter<boolean>();

  private isExpanded: boolean = false;
  private unit: string = "px";

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) { }

  @HostListener("click", ["$event"])
  onClick()
  {
    this.isExpanded = this.isCollapsible ? !this.isExpanded : true;

    if (this.perPage && this.elements > 1)
    {
      this.multiplePage();
    }
    else
    {
      this.singlePage();
    }
  }

  private singlePage(): void
  {
    if (this.section.style.maxHeight === this.initialHeight + this.unit)
    {
      this.section.style.maxHeight = this.section.children[0].clientHeight + this.unit;
    }
    else
    {
      this.section.style.maxHeight = this.initialHeight !== null ? this.initialHeight + this.unit : null;
    }

    this.toggleSection.emit(this.isExpanded);
  }

  private multiplePage(): void
  {
    const height = this.section.clientHeight + (this.section.children[0].clientHeight / this.elements) * this.perPage;

    if (height > this.section.children[0].clientHeight)
    {
      this.section.style.maxHeight = this.section.children[0].clientHeight + this.unit;
    }
    else
    {
      this.section.style.maxHeight = height + this.unit;
    }


    if (height >= this.section.children[0].clientHeight)
    {
      this.toggleSection.emit(true);
    }
  }

  ngAfterViewInit()
  {
    this.renderer.addClass(this.section, "show-more-block");
    this.renderer.addClass(this.elementRef.nativeElement, "show-more-wrapper");

    if (this.initialHeight !== null)
    {
      this.section.style.maxHeight = this.initialHeight + this.unit;
    }
    else if (this.perPage && this.elements)
    {
      this.section.style.maxHeight = (this.section.children[0].clientHeight / this.elements) * this.perPage + this.unit;
    }
  }
}