<ng-container *ngIf="unknownDate">
  <div class="font-style-semi main-title">{{ 'tooltip.unknownDate' |translate }}</div>
  <ul class="special-bullet-list">
    <li class="font-style-light color-body-text">{{ 'tooltip.phoneRequest' |translate }}</li>
    <li class="font-style-light color-body-text">{{ 'tooltip.phoneRequest2' |translate }}</li>
  </ul>
  <div class="button-wrapper">
    <button class="button primary-button" (click)="goToProductStatusPopup($event)">{{ 'tooltip.callMeWhenAvailable' |translate }}</button>
  </div>
</ng-container>
<ng-container *ngIf="!unknownDate">
  <div class="font-style-semi main-title separator1" [ngClass]="getClassNameByResolution('heading5','heading3')">{{ 'tooltip.shippingDate'
    |translate }}
  </div>
  <p class="color-body-text font-style-light description-under-title">{{ 'tooltip.forOrdersBeforeDate' |translate }} <span
    class="font-style-semi"> {{dateNowISO|date:'dd.MM.yyyy'}} r.</span> ({{ 'tooltip.today' |translate }})
    {{ 'tooltip.predictedShippingDate' |translate }} <span class="font-style-semi">{{getShippingTime(product)|date:'dd.MM.yyyy'}} r.</span>
  </p>
  <app-calendar-widget [dates]="getDates(product)"></app-calendar-widget>
</ng-container>
