<div class="newsletter-wrapper">
  <div class="newsletter-description small-text">{{ 'newsletter.title' |translate }}</div>
  <div class="heading5 promotion">{{ 'newsletter.5%discount' |translate }}</div>
  <div class="body-text">{{ 'newsletter.subscribeNews' |translate }}</div>
  <button class="button primary-button" (click)="openPopup($event)"
          [ngClass]="{'disabled-same-colour':isSigned}">{{isInNewsletter()}}</button>
  <app-media-links></app-media-links>
  <div class="small-text phones">{{ 'helpline' |translate }} <a [registerClickEvent] href="tel:801090905">801 09 09 05</a> / <a
    [registerClickEvent] href="tel:221002727">22 100 27 27</a>
  </div>
</div>

