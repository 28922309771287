import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult } from '@angular/router';
import { BreadcrumbsLoggerService } from '@service/breadcrumbs/breadcrumbs-logger.service';
import { CompareComponent } from '@shop/compare/compare.component';
import { MainComponent } from '@shop/main/main.component';
import { ShopComponent } from '@shop/shop.component';
import { routingTable } from 'environments/routing-table';
import { PayUScriptResolver } from '../resolver/payu-script-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: ShopComponent,
    children: [
      { path: '', component: MainComponent },
      {
        matcher: (url) =>
        {
          if (url[0].path === routingTable.shop.listing)
          {
            return { consumed: url };
          }
          else if (((window as any).friendlyList as string[]).some(o => url[0].path == o))
          {
            return { consumed: url };
          }
          else
          {
            return null;
          }
        },
        loadChildren: () => import('app/module/shop/listing/listing.module').then(m => m.ListingModule),
        canActivate: [BreadcrumbsLoggerService]
      },
      {
        path: routingTable.shop.compare,
        component: CompareComponent,
        canActivate: [BreadcrumbsLoggerService]
      },
      {
        path: routingTable.shop.product,
        loadChildren: () => import('app/module/shop/product/product.module').then(m => m.ProductModule),
        canActivate: [BreadcrumbsLoggerService],
        resolve: { tempTranslation: PayUScriptResolver }
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule
{
}
