import { NgModule } from "@angular/core";
import { DirectivesModule } from "@shared/directives/directives.module";
import { ExtraModule } from "@shared/extraModuleShared/extraModule.module";
import { SharedModule } from "../../sharedModules/shared/shared.module";
import { TooltipModule } from "../../sharedModules/tooltip/tooltip.module";
import { FreeSamplePopupComponent } from "@shop/product/product-calculator/free-sample-popup/free-sample-popup.component";
import { ProductCalculatorComponent } from "@shop/product/product-calculator/product-calculator.component";
import { ProgressBarComponent } from "@shop/product/product-rating/progress-bar/progress-bar.component";
import { ProductRoutingModule } from "@shop/product/product-routing.module";
import { ProductComponent } from "@shop/product/product.component";
import { SwiperModule } from "swiper/angular";
import { ProductParametersComponent } from "./product-parameters/product-parameters.component";
import { ProductPictureComponent } from "./product-picture/product-picture.component";
import { ProductRatingComponent } from "./product-rating/product-rating.component";
import { ProductSizeCalculatorComponent } from "./product-size-calculator/product-size-calculator.component";
import { RatyComponent } from "./raty/raty.component";
import { ChipsComponent } from "./chips/chips.component";
import { ChipsItemComponent } from "./chips/chips-item/chips-item.component";

@NgModule({
  declarations: [
    ProductComponent,
    ProductPictureComponent,
    RatyComponent,
    ProductParametersComponent,
    ProductRatingComponent,
    ProductSizeCalculatorComponent,
    ProductCalculatorComponent,
    ProgressBarComponent,
    FreeSamplePopupComponent,
    ChipsComponent,
    ChipsItemComponent,
  ],
  imports: [
    SharedModule,
    SwiperModule,
    ProductRoutingModule,
    TooltipModule,
    ExtraModule,
    DirectivesModule,
  ],
})
export class ProductModule { }
