import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { of, Subject } from 'rxjs';

@Component({
  selector: 'app-opineo',
  templateUrl: './opineo.component.html',
  styleUrls: ['./opineo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpineoComponent implements OnChanges
{
  @Input() showMore: boolean = false;
  @Input() public noClick: boolean;
  @Input() public noHeader: boolean = false;

  @Input() set reviews(value: any)
  {
    this.tmpReviews = [];
    this.allReviews = value;
  }

  public allReviews: any[] = [];
  public tmpReviews: any[] = [];


  isExpanded: boolean = false;



  constructor(
    private windowRef: WindowRefService,
    private changeDetector: ChangeDetectorRef
  )
  {

  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (changes.reviews.currentValue) 
    {
      this.allReviews.slice(0, 4).forEach(item =>
      {
        this.tmpReviews.push(item);
      });
    }
  }

  toggleSection(status: boolean): void 
  {
    this.isExpanded = status;
    this.changeDetector.detectChanges();
  }

  public goToOpineo($event): void
  {
    this.windowRef.getNativeWindow().open('https://www.opineo.pl/opinie/arte-pl', '_blank');
    $event.stopPropagation();
    $event.preventDefault();
  }
}
