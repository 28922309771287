import { ViewportScroller } from "@angular/common"
import
{
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ShopProductFamily } from "@dto";
import { TranslateService } from "@ngx-translate/core";
import { Resolutions } from "@resolutions";
import { CartService } from "@service/cart/cart.service";
import { AnalyticsService } from "@service/analytics/abstract/analytics.service";
import { UrlHelperService } from "@service/helpers/url-helper.service";
import { NavbarService } from "@service/navbar/navbar.service";
import { SearchRepositoryService } from "@service/search-repository/search-repository.service";
import { SeoService } from "@service/seo/seo.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { BlockService } from "@shared/block/shared/block.service";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { getImageUrlStatic } from "../../../utils/utilsFunctions";
import SwiperCore, { Pagination, Navigation, SwiperOptions } from "swiper";
import { ScrollPositionService } from "@service/scrollPosition.service";
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { SimpleBanner } from "app/model/interfaces";
import { BreadcrumbsService } from "@service/breadcrumbs/breadcrumbs.service";
import { Breadcrumbs } from "app/model/Breadcrumbs.model";
import { SwiperConfigService } from "@service/swiper-config.service";

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComponent implements OnInit, OnDestroy, AfterViewChecked
{
  public getImageUrlStatic = getImageUrlStatic;
  public product$: BehaviorSubject<ShopProductFamily> = new BehaviorSubject<ShopProductFamily>(null);
  private productCurrent: ShopProductFamily;
  private productName: string;
  public banner: SimpleBanner;
  public hasSimilarProducts: boolean;
  public recommendationID: string;
  public canRoute: boolean = false;
  public accesoriesAreCoverings: boolean = false;
  public swiperIsLoaded: boolean = false;

  public swiperOpt: SwiperOptions;
  @ViewChild("swiperComponent") private swiperComponent;
  @ViewChild("payments") private payments;
  @HostBinding("class.no-collection") public collectionCss: boolean = false;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private searchRepo: SearchRepositoryService,
    private changeDet: ChangeDetectorRef,
    private windowRef: WindowRefService,
    private blockService: BlockService,
    private seoService: SeoService,
    private cartService: CartService,
    private router: Router,
    public translateService: TranslateService,
    private renderer2: Renderer2,
    private navbarService: NavbarService,
    private elementRef: ElementRef,
    private urlHelper: UrlHelperService,
    private activeRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private scrollPositionService: ScrollPositionService,
    private viewportScroller: ViewportScroller,
    public urlTranslateService: UrlTranslateService,
    private breadcrumbsService: BreadcrumbsService,
    private swiperCo: SwiperConfigService
  )
  {
    this.swiperOpt = this.swiperCo.getConfig(
      [0, Resolutions.resolutionTablet, Resolutions.resolutionDesktop, Resolutions.resolutionHalfHd, Resolutions.resolutionFullHd],
      [16, 28, 48, 132, 132],
      [16, 16, 32, 32, 32],
      [16, 24, 48, 0, 0],
      [1, 2, 3, 3, 3],
      [1, 1, 1, 1, 1]
    );

    this.swiperOpt.navigation = true;
    this.swiperOpt.watchOverflow = false;

  }

  public ngOnInit(): void
  {
    this.product$.pipe(takeUntil(this.destroy$), filter((v) => !!v))
      .subscribe((product) =>
      {
        if (product)
        {
          if (this.productCurrent && this.productCurrent.familyCode == product.familyCode)
          {
            return;
          }
          else
          {
            this.productCurrent = product;
          }

          this.seoService.setProduct(product);
          this.collectionCss = product.collectionFilter == null;
          this.analyticsService.registerProductPageView(product);

          if (product.isCovering)
          {
            this.renderer2.addClass(this.elementRef.nativeElement, "wykladzina");
            this.renderer2.removeClass(this.elementRef.nativeElement, "wycieraczka");
          }

          if (product.isWiper)
          {
            this.renderer2.addClass(this.elementRef.nativeElement, "wycieraczka");
            this.renderer2.removeClass(this.elementRef.nativeElement, "wykladzina");
          }

          if (this.swiperComponent?.swiperRef)
          {
            this.swiperComponent.swiperRef.update();
            this.swiperIsLoaded = true;
          }


          let bcArr: Breadcrumbs[] = [];

          bcArr.push({
            name: this.translateService.instant("mainPage"),
            url: "/",
          });

          if (product.breadcrumbs)
          {

            product.breadcrumbs.forEach(element =>
            {
              bcArr.push({
                name: element.name,
                url: '/produkty',
                queryParams: this.urlHelper.parseQueryStringToObject(element.url)
              });
            });
          }

          this.accesoriesAreCoverings = product.accessoryProducts?.every(o => o.isCovering);
          this.breadcrumbsService.updateBreadcrumb(bcArr);
        }
        else
        {
          this.seoService.setProduct(undefined);
        }

        if (this.changeDet && !this.changeDet["destroyed"])
        {
          this.changeDet.detectChanges();
        }
      });

    this.cartService.cart$.pipe(takeUntil(this.destroy$)).subscribe((res) =>
    {
      if (!this.changeDet["destroyed"])
      {
        this.changeDet.detectChanges();
      }
    });

    this.banner = (this.blockService.getDataSync("coveringBanner") as SimpleBanner);

    this.activeRoute.params.subscribe((params) =>
    {
      if (params['product'])
      {
        this.product$.next(null);
        this.navbarService.links = null;
        this.productName = params['product'];
        this.recommendationID = this.activeRoute.snapshot.queryParamMap.get("recommendationID");
        this.seoService.setTitle(this.productName.replace(/-/g, " ") + " - " + this.translateService.instant("pageTitleSufix"));

        this.searchRepo.getProduct(this.productName, this.recommendationID).pipe(takeUntil(this.destroy$)).subscribe(
          {
            next: (res) =>
            {
              this.product$.next(res);
              this.changeDet.detectChanges();
              this.scrollPositionService.restorePosition();
            },
            error: (error) =>
            {
              this.windowRef.pushToHistory(environment.baseUrl);
              this.router.navigate(["error"]);
            }
          });
      }
    });
  }

  ngAfterViewChecked()
  {
    if (!this.navbarService.links && this.product$.value)
    {
      this.navbarService.highlightElementInViewport();
    }
  }

  @HostListener("window:popstate", ["$event"])
  backButtonHandler()
  {
    this.changeDet.detectChanges();
  }

  public ngOnDestroy(): void
  {
    this.changeDet.detectChanges();
    this.destroy$.next();
    this.destroy$.complete();
  }

  get canBuyWithInstallment(): boolean
  {
    return environment.canBuyWithInstallment;
  }

  //#region  Static methods
  public getLinkToProduct(link: string): any
  {
    return this.urlHelper.parseQueryStringToObject(link.split(`/${this.urlTranslateService.routingTable.shop.listing}?`)[1]);
  }
  public getCaption(b: boolean): string
  {
    return b ? this.translateService.instant("cart.inCart") : this.translateService.instant("cart.addToCart");
  }
  //#endregion

  @HostListener("window:scroll", ["$event"])
  private onScroll(): void
  {
    this.navbarService.highlightElementInViewport();
  }

  public onSummaryPriceChange(price)
  {
    if (this.payments)
    {
      this.payments.setCreditAmount(price);
    }
  }

  public isSelected(productId: any): boolean
  {
    return Boolean(this.cartService.getCartItemByProductID(productId));
  }

  public addToCart(product, b): void
  {
    if (b)
    {
      const cartIndexOfProduct = this.cartService.getCartItemByProductID(product.iD).iD;
      this.cartService.removeElementFromCart("null", cartIndexOfProduct).subscribe();
    }
    else
    {
      this.cartService.addElementToCart("null", product, 1).subscribe();
    }
  }



  @HostListener("window:resize", ["$event"])
  private onWindowResize(): void
  {
    if (this.swiperComponent?.swiperRef)
    {
      this.swiperIsLoaded = true;
      this.changeDet.detectChanges();
    }
    else
    {
      this.changeDet.detectChanges();
    }
  }

  public routerGuard($event, acp?: any): void
  {
    if (Math.abs(this.swiperComponent.swiperRef.touches.startX - this.swiperComponent.swiperRef.touches.currentX) > 50)
    {
      $event.stopPropagation();
      $event.preventDefault();
    }
    else
    {
      this.canRoute = true;
      this.router.navigateByUrl(`/${this.urlTranslateService.routingTable.shop.product}/` + acp.url);
      this.canRoute = false;
    }

    this.changeDet.detectChanges();
  }

  public resetProduct(): void
  {
    this.product$.next(null);
    this.changeDet.detectChanges();
  }

  public getRouteLink(acp): any
  {
    return this.canRoute ? [`/${this.urlTranslateService.routingTable.shop.product}`, acp.url] : null;
  }

  @HostListener("mouseup", ["$event"])
  public onClick($event): void
  {
    if ($event.which === 2)
    {
      this.canRoute = true;
      this.changeDet.detectChanges();
    }
    setTimeout(() =>
    {
      this.canRoute = false;
      this.changeDet.detectChanges();
    }, 100);
  }

  //#region Exit
  public goToProductPage(product): void
  {
    if (product)
    {
      this.router.navigateByUrl(`/${this.urlTranslateService.routingTable.shop.product}` + product.url);
    }
  }
  //#endregion
}
