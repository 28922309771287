import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@shared/directives/directives.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { ActiveFiltersComponent } from './active-filters/active-filters.component';
import { FiltersComponent } from './filters.component';
import { IntervalFilterComponent } from './interval-filter/interval-filter.component';
import { SelectFilterComponent } from './select-filter/select-filter.component';

@NgModule({
  declarations: [FiltersComponent, ActiveFiltersComponent, IntervalFilterComponent, SelectFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TooltipModule,
    DirectivesModule
  ],
  exports: [
    FiltersComponent, ActiveFiltersComponent, IntervalFilterComponent, SelectFilterComponent
  ]
})
export class FiltersModule
{
}
