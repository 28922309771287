import
{
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Cart, Wishlist } from "@dto";
import { Resolutions } from "@resolutions";
import { CartService } from "@service/cart/cart.service";
import { CheckoutService } from "@service/checkout/checkout.service";
import { NavbarService } from "@service/navbar/navbar.service";
import { SearchRepositoryService } from "@service/search-repository/search-repository.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { WishlistService } from "@service/wishlist/wishlist.service";
import { BlockService } from "app/module/sharedModules/block/shared/block.service";
import { SearchComponent } from "app/module/sharedModules/shared/search/search.component";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { LinkElement } from "app/model/interfaces";
import { ScrollPositionService } from "@service/scrollPosition.service";
import { ConfigService } from "@service/config/config.service";
import { ShopModeService } from "@service/helpers/shop-mode.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy
{
  @HostBinding('class.fixed') fixed = false;
  @HostBinding('class.main-view') mainView = false;
  @HostBinding('class.cart-navbar') cartNavbar = false;
  @HostBinding('class.not-main-view') isNotMainView = false;

  @Input() public isFinalStepOfCheckout: boolean = false;
  public isCartPage: boolean = false;
  public searchExpanded: boolean = false;
  public isProductPage: boolean = false;
  public cartItemsNumber: number;
  public favItemsNumber: number;
  public blink: boolean;
  private destroy$: Subject<void> = new Subject();
  public searchIsActive: boolean = false;
  public justRoutedToContact: boolean = false;
  public justRoutedToAccount: boolean = false;

  @Output() public readonly mobileMenuOpened: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(SearchComponent, { static: true }) private searchComponent: SearchComponent;
  @ViewChild("cartPopup1") private cartPopup1: any;
  @ViewChild("cartPopup2", { static: true }) private cartPopup2: any;
  @ViewChild("contactPopup") private contactPopup: any;
  @ViewChild("accountPopup1") private accountPopup1: any;
  @ViewChild("accountPopup2") private accountPopup2: any;
  @ViewChild("favPopup1") private favPopup1: any;
  @ViewChild("favPopup2", { static: true }) private favPopup2: any;

  public isMoreThanSecondAfterRoutingActive: boolean = false;
  public headerLinks: LinkElement[];
  public isServer: boolean = false;
  public activeStep: string = "first";
  public activePopup: { timeoutId: any, name: string } = { timeoutId: null, name: null };
  public links: any;
  public isMobile: boolean = false;
  public siteName: string;
  public logoUrl: string = "/assets/icons/arte.svg";
  public isRuggo: boolean = false;

  constructor(
    public windowRef: WindowRefService,
    private checkoutService: CheckoutService,
    private cartService: CartService,
    private blockService: BlockService,
    private changeDet: ChangeDetectorRef,
    private searchRepoService: SearchRepositoryService,
    private wishlistService: WishlistService,
    private navbarService: NavbarService,
    private router: Router,
    public translateService: TranslateService,
    private scrollPositionService: ScrollPositionService,
    public urlTranslateService: UrlTranslateService,
    public shopModeService: ShopModeService
  )
  {
    this.refreshData();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) =>
    {
      if (event instanceof NavigationEnd)
      {
        this.refreshData();
      }
    });
  }

  public refreshData(): void
  {
    this.isProductPage = this.router.url.includes(`${this.urlTranslateService.routingTable.shop.product}/`);
    this.isCartPage = this.router.url.includes(`/${this.urlTranslateService.routingTable.checkout.base}`);
    this.siteName = this.translateService.instant('fullPageName');
    this.headerLinks = this.blockService.getDataSync('header').links;
    this.isNotMainView = !(this.router.url == "/" || this.router.url.includes("/?"));

    if (this.shopModeService.isRuggoMode())
    {
      this.isRuggo = true;
      this.logoUrl = "/assets/icons/ruggo/ruggo.svg";
    }

    this.cartNavbar = this.isCartPage;
    this.mainView = !this.isCartPage;
  }

  public ngAfterViewInit(): void
  {
    setTimeout(() =>
    {
      this.isMoreThanSecondAfterRoutingActive = true;
      if (this.changeDet && !this.changeDet["destroyed"])
      {
        this.changeDet.detectChanges();
      }
    }, 500);

    this.navbarService.navbarDom$.subscribe((res) =>
    {
      this.links = this.navbarService.links;

      if (this.changeDet && !this.changeDet["destroyed"])
      {
        this.changeDet.detectChanges();
      }
    });
  }

  public changeContactRoutedVar(b: boolean): void
  {
    this.justRoutedToContact = b;
    if (this.changeDet && !this.changeDet["destroyed"])
    {
      this.changeDet.detectChanges();
    }
  }

  public changeAccountRoutedVar(b: boolean): void
  {
    this.justRoutedToAccount = b;
    if (this.changeDet && !this.changeDet["destroyed"])
    {
      this.changeDet.detectChanges();
    }
  }

  get canBuyWithInstallment(): boolean
  {
    return environment.canBuyWithInstallment;
  }

  isMobileCheck()
  {
    if (this.isMobile !== this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
    {
      this.isMobile = this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop;
      this.changeDet.detectChanges();
    }
  }

  public scrollTopIfMainPage(): void
  {
    if (this.router.url == "/")
    {
      this.scrollPositionService.scrollTo(0);
    }
  }

  public ngOnInit(): void
  {
    this.isMobileCheck();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) =>
    {
      if (event instanceof NavigationEnd)
      {
        this.isProductPage = this.router.url.includes(`${this.urlTranslateService.routingTable.shop.product}/`);
        this.isCartPage = this.router.url.includes(`/${this.urlTranslateService.routingTable.checkout.base}`);
        this.setActiveStep();

        if (this.changeDet && !this.changeDet["destroyed"])
        {
          this.changeDet.detectChanges();
        }
      }
    });

    this.searchRepoService.searchVisible$.pipe(takeUntil(this.destroy$)).subscribe((res) =>
    {
      this.searchIsActive = res;
      if (this.changeDet && !this.changeDet["destroyed"])
      {
        this.changeDet.detectChanges();
      }
    });

    this.cartService.cart$.pipe(filter((v) => !!v), takeUntil(this.destroy$))
      .subscribe((c: Cart) =>
      {
        this.cartItemsNumber = c.items.length;
        if (this.changeDet && !this.changeDet["destroyed"])
        {
          this.changeDet.detectChanges();
        }
      });

    this.wishlistService.wishlist$.pipe(filter((v) => !!v), takeUntil(this.destroy$))
      .subscribe((wishlist: Wishlist) =>
      {
        if (
          wishlist.products.length > this.favItemsNumber &&
          this.favItemsNumber != undefined &&
          wishlist.products.length > 0
        )
        {
          this.blink = true;
          if (this.changeDet && !this.changeDet["destroyed"])
          {
            this.changeDet.detectChanges();
            setTimeout(() =>
            {
              this.blink = false;
              this.changeDet.detectChanges();
            }, 1000);
          }
        }
        this.favItemsNumber = wishlist.products.length;
        if (this.changeDet && !this.changeDet["destroyed"])
        {
          this.changeDet.detectChanges();
        }
      });
  }

  public ngOnDestroy(): void
  {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setExpandSearch(b: boolean): void
  {
    this.searchExpanded = b;
    this.searchComponent.setSearchInputAsFocused();
    if (this.changeDet && !this.changeDet["destroyed"])
    {
      this.changeDet.detectChanges();
    }
  }

  public scrollToElement(id: string): void
  {
    const element = this.windowRef.getDocument().getElementById(id);
    if (element)
    {
      this.windowRef.getNativeWindowToScroll().scrollTo({
        top:
          id == "productWrapper"
            ? element.offsetTop - 100
            : element.offsetTop + 100,
        behavior: "smooth",
      });
    }
  }

  public openMobileMenu(focusOnSearchInput: boolean = false)
  {
    this.mobileMenuOpened.emit(focusOnSearchInput);
  }

  public isStepActive(s: string): boolean
  {
    let isStepActive = this.checkoutService.currentStep === s;
    if (
      this.windowRef.getNativeWindow().location.pathname.indexOf(`/${this.urlTranslateService.routingTable.checkout.base}`) >= 0
    )
    {
      setTimeout(() =>
      {
        if (this.changeDet && !this.changeDet["destroyed"])
        {
          this.changeDet.detectChanges();
        }
      }, 64);
    }
    return isStepActive;
  }

  @HostListener("window:scroll", ["$event"])
  private onScroll(): void
  {
    if (this.isCartPage)
    {
      if (this.fixed)
      {
        this.fixed = false;
        this.changeDet.detectChanges();
      }
    }
    else
    {
      if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
      {
        if (!this.fixed)
        {
          this.fixed = true;
          this.changeDet.detectChanges();
        }
      }
      else
      {
        if (!this.fixed && this.windowRef.getNativeWindow().scrollY > 61)
        {
          this.fixed = true;
          this.changeDet.detectChanges();
        }
        else if (this.fixed && this.windowRef.getNativeWindow().scrollY <= 61)
        {
          this.fixed = false;
          this.changeDet.detectChanges();
        }
      }
    }

    if (this.windowRef.getNativeWindow().scrollY <= 61)
    {
      if (this.searchExpanded)
      {
        this.searchExpanded = false;
        this.changeDet.detectChanges();
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  private onWindowResize($event): void
  {
    this.isMobileCheck();

    if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
    {
      this.searchExpanded = false;
    }

    this.onScroll();
  }

  public goToCheckout($event): void
  {
    if (
      $event.target.className.includes("cart") ||
      $event.target.className.includes("ai-cart") ||
      $event.target.className.includes("cart-items-number")
    )
    {
      this.setPopupVisibility('cartPopup2', false);
      this.router.navigateByUrl(`/${this.urlTranslateService.routingTable.checkout.base}`);
    }
  }

  public goToAccount($event): void
  {
    if ($event.target.className.includes('account-icon') || $event.target.className.includes('ai-account'))
    {
      this.router.navigateByUrl(`/${this.urlTranslateService.urlMapping.customer.account.orders}`);
    }
  }

  onMyAccountClick($event)
  {
    if ($event.pointerType === 'touch')
    {
      this.setPopupVisibility('accountPopup1', !this.accountPopup1.shouldShow);
    } else
    {
      this.closePopup('accountPopup1');
      this.changeAccountRoutedVar(true);
      this.router.navigateByUrl(`/${this.urlTranslateService.urlMapping.customer.account.orders}`);
    }
  }

  onMyAccountMouseover($event)
  {
    if (!($event.sourceCapabilities && $event.sourceCapabilities.firesTouchEvents))
    {
      this.setPopupVisibility('accountPopup1', true)
    }
  }

  public goToFavourite($event, componentName: string): void
  {
    if (
      $event.target.className.includes("favourite") ||
      $event.target.className.includes("ai-heart") ||
      $event.target.className === "cart-items-number"
    )
    {
      $event.stopPropagation();
      $event.preventDefault();
      this.closePopup(componentName);
      this.router.navigate([`/${this.urlTranslateService.urlMapping.customer.account.favourite}`]);
    }
  }

  public goToStep(s: string): void
  {
    if (
      s === `/${this.urlTranslateService.routingTable.checkout.base}` ||
      (s === `/${this.urlTranslateService.urlMapping.checkout.account}` && this.isStepActive(`/${this.urlTranslateService.urlMapping.checkout.account}`)) ||
      this.isStepActive(`/${this.urlTranslateService.urlMapping.checkout.summary}`)
    )
    {
      this.router.navigateByUrl(s);
    }
  }

  public clearTimeout()
  {
    if (this.activePopup && this.activePopup.timeoutId)
    {
      clearTimeout(this.activePopup.timeoutId);
    }
  }

  public setPopupVisibility(elem: string, value: boolean)
  {
    if (value)
    {
      this.clearTimeout();
      if (this.activePopup && this.activePopup.name && this.activePopup.name !== elem)
      {
        this[this.activePopup.name].hide();
      }
      this[elem].show();
      this.activePopup.name = elem;
    } else
    {
      this.activePopup.timeoutId = setTimeout(() =>
      {
        this.closePopup(elem);
      }, 200);
    }
  }

  private closePopup(elem: string)
  {
    this[elem].hide();
    this.activePopup = { timeoutId: null, name: null };
  }

  private setActiveStep(): void
  {
    this.activeStep = "first";
    if (this.router.url.indexOf(`/${this.urlTranslateService.urlMapping.checkout.account}`) >= 0)
    {
      this.activeStep = "second";
    }
    if (this.router.url.indexOf(`/${this.urlTranslateService.urlMapping.checkout.login}`) >= 0)
    {
      this.activeStep = "second";
    }
    if (this.router.url.indexOf(`/${this.urlTranslateService.urlMapping.checkout.summary}`) >= 0)
    {
      this.activeStep = "third";
    }
    if (this.changeDet && !this.changeDet["destroyed"])
    {
      this.changeDet.detectChanges();
    }
  }

  public stopBlink(): void
  {
    this.blink = false;
    if (this.changeDet && !this.changeDet["destroyed"])
    {
      this.changeDet.detectChanges();
    }
  }
}
