import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Cart, CartItem } from '@dto';
import { CartService } from '@service/cart/cart.service';
import { SearchRepositoryService } from '@service/search-repository/search-repository.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { getCamelCaseVariableName } from 'app/utils/utilsFunctions';

@Component({
  selector: 'app-cart-popup',
  templateUrl: './cart-popup.component.html',
  styleUrls: ['./cart-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartPopupComponent implements OnDestroy
{
  public cart$: Observable<Cart>;
  private destroy$: Subject<void> = new Subject();
  public shouldShow: boolean = false;

  constructor(
    private router: Router,
    private cartService: CartService,
    private changeDet: ChangeDetectorRef,
    private searchService: SearchRepositoryService,
    public urlTranslateService: UrlTranslateService,
  )
  {
    this.cart$ = this.cartService.cart$.pipe(filter(v => !!v), takeUntil(this.destroy$), map(res => { return getCamelCaseVariableName(res, true); }));
  }

  public show(): void
  {
    this.shouldShow = true;
    this.changeDet.detectChanges();
  }

  public hide(): void
  {
    this.shouldShow = false;
    this.changeDet.detectChanges();
  }

  public goToCheckout(): void
  {
    this.router.navigateByUrl(`/${this.urlTranslateService.routingTable.checkout.base}`);
  }

  public ngOnDestroy(): void
  {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public removeElementFromCart(product: CartItem): void
  {
    this.cartService.removeElementFromCart('null', product.iD).subscribe((() => setTimeout(() => this.changeDet.detectChanges(), 64)));
  }

  public goToProductPage(item: CartItem): void
  {
    this.router.navigate([this.urlTranslateService.routingTable.shop.product, item.product.url]);
  }

  public goToBestsellers($event): void
  {
    this.router.navigateByUrl(this.searchService.getBestsellerLink());
    this.shouldShow = false;
  }
}
