<div class="main-content compare-wrapper" *ngIf="compareProducts$ | async as compareProducts; else emptyState">
  <app-breadcrumbs></app-breadcrumbs>

  <h1 class="main-title">{{ "compareProducts" | translate }}</h1>

  <h2 class="main-title center no-products-title" [hidden]="compareProducts.length !== 0">
    {{ "favourite.noProductsToCompare" | translate }}
  </h2>

  <div [hidden]="compareProducts.length === 0" class="compare-slider">

    <div class="product-compare-wrapper" [ngClass]="{ dywan: compareProducts[0]?.isCarpet || compareProducts[0]?.isWalkway }">

      <swiper [config]="swiperOpt" class="swiper swiper-container-style" #swiperComponent>
        <ng-template *ngFor="let product of compareProducts; let i = index" swiperSlide>
          <div class="compared-product">
            <div class="image-wrapper">
              <div class="close-button-wrapper" (click)="removeFromCompared(product)" >
                 <i class="ai ai-cancel"></i>
              </div>
              <a [routerLink]="[ '/' + urlTranslateService.routingTable.shop.product, product.url ]" >
                <app-img [src]="product.familyCode"
                  [index]="product.mainImageIndex ? product.mainImageIndex.toString() : '0' "
                  [width]="[420, 420, product.isCovering ? 420 : 480]"
                  [height]="[116, 283, 320]"
                  [options]="{ product: true, fillCrop: product.isCovering }" >
                </app-img>
              </a>
              <div class="flex-row show-in-desktop">
                <ng-container *ngFor="let image of product.images; let j = index">
                  <div *ngIf="j < 4"
                    class="image-wrapper"
                    (click)="changeMainImage(j)"
                    [ngClass]="{
                      selected: product.mainImageIndex == image.index,
                      last: j == 3,
                      wykladzina: product.isCovering }">
                    <app-img
                      [src]="product.familyCode"
                      [index]="product.images[j].index"
                      [height]="[54, 54, 54]"
                      [options]="{ product: true }">
                    </app-img>
                  </div>
                </ng-container>
              </div>
            </div>

            <a [routerLink]=" '/' + urlTranslateService.routingTable.shop.product + '/' + product.url ">
              <p class="product-name">
                {{ product.name }}
                <span class="product-size">
                  <span [innerHtml]="'\n'"></span>
                  {{ product["chosenOptionSize"] }}
                </span>
              </p>
            </a>

            <div class="product-info-wrapper">
              <span class="promotion-price">
                {{ product["chosenPrice"] | valueWithComma }}
                <span [innerHtml]="product.products[0].priceUnit"></span>
              </span>
              <div class="dropdown-wrapper">
                <p class="success-link" (click)="toggleSortBottomMenu(product)">
                  {{ "favourite.changeSize" | translate }}
                </p>
                <app-dropdown
                  [options]="getPrepared(product.products)"
                  [optionFieldName]="'option'"
                  [additionalInfo]="'promotionPrice'"
                  [dropdownPrice]="true"
                  [activeOption]="0"
                  [setToRightBold]="true"
                  [unit]="product.products[0].priceUnit"
                  (selectedItem)="chosenOptionFunc(product, $event)">
                </app-dropdown>
              </div>
              <div class="button-wrapper">
                <div class="button primary-button mobile" (click)="addProductToCart(product)" [id]="'add-button-' + product.chosenId">
                  <ng-container *ngIf="!isLoading || (isLoading && !isChosenProduct(product.chosenId))">
                    {{ "favourite.addToCart" | translate }}
                  </ng-container>
                  <span></span>
                </div>
                <div class="button primary-button desktop">
                  {{ "favourite.addToCart2" | translate }}
                </div>
              </div>
              <p class="delivery-time-description">
                <span class="desktop">
                  {{ "favourite.forSize" | translate }}:
                </span>
                <span>
                  {{ "favourite.shipment" | translate }}
                  {{ product?.chosenOption }}
                </span>
              </p>
            </div>

            <div class="product-detail-wrapper" [ngClass]="{ isWykladzina: isWykladzina }">
              <div class="product-detail" *ngFor="let parameter of parametersArray; let j = index">
                <span class="parameter-value" [ngClass]="{ grey: greyColorWhenSameValues(compareProducts, parameter) }" [innerHtml]="getValueOfParameter(parameter, product)"></span>
              </div>
              <p class="collection-description">
                {{ product.collectionDescription }}
              </p>
              <div class="button-wrapper bottom">
                <div class="button primary-button mobile" (click)="addProductToCart(product)">
                  {{ "favourite.addToCart" | translate }}
                </div>
                <button class="button tertiary-button show-in-desktop" (click)="goToSimilarProducts(product)">
                  {{ "favourite.showSimilarProducts" | translate }}
                </button>
              </div>
            </div>

          </div>
        </ng-template>
      </swiper>

    </div>

    <div class="absolute-product-bar">
      <div *ngFor="let parameterName of parametersArray; let i = index" [style.top]="getStyleHeight(i)">
        {{ parameterName }}
      </div>
    </div>
  </div>

  <div *ngIf="compareProducts.length === 0">
    <p class="heading3 center">Dodaj produkty do porównania</p>
  </div>
</div>

<app-gallery-widget
  *ngIf="selectedProduct"
  [product]="selectedProduct"
  #gallery>
</app-gallery-widget>

<div class="sort-popup" [ngClass]="{ active: showSortPopup }" *ngIf="chosenSortPopupOptionsProduct">
  <div (click)="closeSortPopup()" class="close-button">
    <i class="ai ai-cancel"></i>
  </div>
  <ng-scrollbar class="options-list" autoHeightDisabled="false">
    <ul>
      <li *ngFor="let product of chosenSortPopupOptionsProduct.products" (click)="chosenOptionFunc(chosenSortPopupOptionsProduct, product)">
        <p>{{ product.option }}</p>
        <span class="promotion-price">
          {{ product.promotionPrice | valueWithComma }}
          <span [innerHtml]="product.priceUnit"></span>
        </span>
      </li>
    </ul>
  </ng-scrollbar>
</div>

<app-product-add-item-popup [product]="chosenProduct" name="add-item-2"></app-product-add-item-popup>

<ng-template #emptyState>
  <div class="main-content compare-wrapper">
    <div class="empty-state-main">
      <img [src]="'/assets/icons/preloader.gif'" />
    </div>
  </div>
</ng-template>
