import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-error',
  templateUrl: './technical-error.component.html',
  styleUrls: ['./technical-error.component.scss']
})
export class TechnicalErrorComponent implements OnInit
{

  constructor() { }

  ngOnInit(): void
  {
  }

}
