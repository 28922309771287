import { Component } from '@angular/core';
import { ContactPopupAbstractComponent } from '../contact-popup-abstract/contact-popup-abstract.component';

@Component({
  selector: 'app-review-popup',
  templateUrl: '../contact-popup-abstract/contact-popup-abstract.component.html',
  styleUrls: ['../contact-popup-abstract/contact-popup-abstract.component.scss', './review-popup.component.scss']
})
export class ReviewPopupComponent extends ContactPopupAbstractComponent
{
}
