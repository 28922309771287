import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { AuthService } from '@service/session/auth/auth.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpService
{

  constructor(private httpService: HttpService, private authService: AuthService)
  {
  }

  public get<O>(path: string, params?: Params): Observable<O>
  {
    return this.createHeaders().pipe(switchMap(header => this.httpService.get<O>(path, params, header)));
  }

  public delete<O>(path: string, params?: Params): Observable<O>
  {
    return this.createHeaders().pipe(switchMap(header => this.httpService.delete<O>(path, params, header)));
  }

  public post<I, O>(path: string, body?: I, params?: Params): Observable<O>
  {
    return this.createHeaders(params).pipe(switchMap(header => this.httpService.post<I, O>(path, body, params, header)));
  }

  public put<I, O>(path: string, body?: I, params?: Params): Observable<O>
  {
    return this.createHeaders().pipe(switchMap(header => this.httpService.put<I, O>(path, body, params, header)));
  }

  private createHeaders(params?: Params): Observable<HttpHeaders>
  {
    if (params && params.hasOwnProperty('guest'))
    {
      return of(new HttpHeaders()
        .append('Content-type', 'text/plain')
        .append('Authorization', `Bearer ${params.token}`));
    }

    return this.authService.token$.pipe(take(1), map(token =>
    {
      if (token)
      {
        return new HttpHeaders().append('Content-type', 'text/plain').append('Authorization', `Bearer ${token}`);
      } else
      {
        return new HttpHeaders().append('Content-type', 'text/plain');
      }
    }));

  }

}
