import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService
{
  public changeChatState = new Subject<{ state: string, message: string }>();

  constructor() { }

  public disable(): void
  {
    this.changeChatState.next({ state: "disable", message: '' });
  }
  public enable(): void
  {
    this.changeChatState.next({ state: "enable", message: '' });
  }


  public open(message: string = null): void
  {
    this.changeChatState.next({ state: "show", message: message });
  }
  public hide(): void
  {
    this.changeChatState.next({ state: "hide", message: '' });
  }
}
