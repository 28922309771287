import { Injectable, OnDestroy } from '@angular/core';
import { ShopProductFamily } from '@dto';
import { StorageService } from '@service/storage/storage.service';
import { WishlistService } from '@service/wishlist/wishlist.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { getCamelCaseVariableName } from '../../../utils/utilsFunctions';

@Injectable({
  providedIn: 'root'
})
export class CompareService implements OnDestroy
{
  private compareSubject: BehaviorSubject<ShopProductFamily[]> = new BehaviorSubject<ShopProductFamily[]>(null);
  public readonly compare$: Observable<ShopProductFamily[]> = this.compareSubject.asObservable();

  constructor(private storageService: StorageService, private wishlistService: WishlistService)
  {
    this.wishlistService.wishlist$.pipe(
      filter(v => !!v),
      mergeMap(wishlist =>
      {
        return this.storageService.get('compare')
          .pipe(
            filter(v => !!v),
            map((compareStorageValue: any[]) =>
            {
              const products: ShopProductFamily[] = compareStorageValue.map((el) => getCamelCaseVariableName(el, true));
              return this.removeElementNotFromList(wishlist.products, products);
            })
          )
      })
    ).subscribe(res =>
    {
      this.saveElementToCompare(res);
    })
  }

  public ngOnDestroy(): void
  {
    this.compareSubject.complete();
  }

  public saveElementToCompare(selectedItems: ShopProductFamily[]): void
  {
    this.compareSubject.next(selectedItems);
    this.storageService.set('compare', selectedItems);
  }

  private removeElementNotFromList(products: ShopProductFamily[], selectedItems: ShopProductFamily[]): ShopProductFamily[]
  {
    let sharedElements = [];
    selectedItems.forEach(el =>
    {
      let temp = products.filter(comparedElement => comparedElement.name == el.name);
      if (temp.length > 0)
      {
        sharedElements.push(temp[0]);
      }
    });
    return sharedElements;
  }
}
