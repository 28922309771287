import { Component, OnChanges, AfterContentInit, Input, SimpleChanges, ViewChild, Output, EventEmitter, ViewContainerRef, NgZone, EmbeddedViewRef } from '@angular/core';
import { ShopProductFamily } from "@dto";
import { getImageUrl } from 'app/utils/utilsFunctions';
import { SwiperOptions } from "swiper";
import { SwiperEvents } from 'swiper/types';
import { GetImageUrlPipe } from '@shared/extraModuleShared/img/get-image-url.pipe'
//import PhotoSwipeLightbox from "photoswipe/lightbox";
import { MainImageZoomThumbsComponent } from "@shared/shared/main-image-zoom-thumbs/main-image-zoom-thumbs.component";
import { ChatService } from "@service/chat/chat-service.service";
import { Resolutions } from '@resolutions';
import { RollbarErrorHandler } from '@service/rollbar/rollbar';

@Component({
  selector: 'app-main-image',
  templateUrl: './main-image.component.html',
  styleUrls: ['./main-image.component.scss']
})
export class MainImageComponent implements AfterContentInit, OnChanges
{
  public getImageUrl = getImageUrl;
  @Input() public imgArray: any[];
  @Input() public product: ShopProductFamily;
  @Input() public height: number[];
  @Input() public width: number[];
  @Input() public mainImageIndex: number = 0;
  @Output() public slideChange: EventEmitter<number> = new EventEmitter();

  @ViewChild("swiperComponent") private swiperComponent;

  public mainSliderConfig: SwiperOptions = {
    direction: "horizontal",
    slidesPerView: 1,
    autoplay: false,
    centeredSlides: true,
    freeMode: false,
    speed: 500,
    spaceBetween: 16,
    navigation: true,
    pagination: {
      clickable: true,
      dynamicBullets: true,
    }
  };
  public preventThumbsChange: boolean = false;
  private lightbox;
  private lightboxInit: boolean = false;

  constructor(
    public getImageUrlPipe: GetImageUrlPipe,
    private viewContainerRef: ViewContainerRef,
    private chatService: ChatService,
    private ngZone: NgZone,
    private rollbarService: RollbarErrorHandler,
  ) { }

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes.mainImageIndex && this.swiperComponent)
    {
      this.preventThumbsChange = true;
      this.swiperComponent.swiperRef?.slideTo(changes.mainImageIndex.currentValue);
    }
    if (changes.imgArray && changes.imgArray.currentValue && changes.imgArray.currentValue.length === 1)
    {
      this.mainSliderConfig.navigation = false;
    }
  }

  public onSlideChange(eventParams: Parameters<SwiperEvents['slideChange']>)
  {
    if (!this.preventThumbsChange)
    {
      this.slideChange.emit(eventParams[0].activeIndex || 0);
    }
    this.preventThumbsChange = false;
  }

  public initPhotoSwipe(event?)
  {
    if (event && event.preventDefault)
    {
      event.preventDefault();
    }


    if (this.lightboxInit)
    {
      return;
    }

    this.lightboxInit = true;

    import("photoswipe/lightbox").then(PhotoSwipeLightbox => 
    {
      import('photoswipe').then(PhotoSwipe =>
      {
        const mainImageZoomThumbsComponentRef = this.viewContainerRef.createComponent(MainImageZoomThumbsComponent);
        this.lightbox = new PhotoSwipeLightbox.default({
          gallery: '#main-product-gallery',
          mainClass: 'pswp--custom-icon-colors',
          children: 'a',
          bgOpacity: 1,
          arrowPrevSVG: '<i class="ai ai-back"></i>',
          arrowNextSVG: '<i class="ai ai-next"></i>',
          pswpModule: PhotoSwipe.default,
          paddingFn: (viewportSize) =>
          {
            return {
              top: 60,
              bottom: viewportSize.x >= Resolutions.resolutionTablet ? 180 : 60,
              left: 0,
              right: 0
            };
          }
        });

        this.lightbox.on('beforeOpen', () =>
        {
          this.chatService.disable();

          window.history.pushState(null, null, window.location.href);
          window.addEventListener('popstate', this.closeLightbox);
        });

        this.lightbox.on('destroy', () =>
        {
          this.chatService.enable();
          window.removeEventListener('popstate', this.closeLightbox);
        });

        this.lightbox.on('uiRegister', () =>
        {
          this.lightbox.pswp.ui.registerElement({
            name: 'thumbnails',
            className: 'pswp__thumbnails',
            appendTo: 'root',
            onInit: (el, pswp) =>
            {
              mainImageZoomThumbsComponentRef.setInput('product', this.product);
              mainImageZoomThumbsComponentRef.instance.activeIndexChange.subscribe((index) =>
              {
                pswp.goTo(index);
              });
              const galleryRoot = (mainImageZoomThumbsComponentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
              el.appendChild(galleryRoot);
            }
          });
        });
        this.lightbox.on('change', () =>
        {
          mainImageZoomThumbsComponentRef.setInput('activeIndex', this.lightbox.pswp.currIndex);
          this.swiperComponent.swiperRef?.slideTo(this.lightbox.pswp.currIndex, 1);
        });

        this.lightbox.init();

        if (event)
        {
          this.lightbox.loadAndOpen(this.mainImageIndex, {
            gallery: document.querySelector('#main-product-gallery')
          });
        }
      }).catch(err => { this.rollbarService.handleError(err); this.lightboxInit = false; });
    }).catch(err => { this.rollbarService.handleError(err); this.lightboxInit = false; });
  }

  private closeLightbox = () =>
  {
    if (this.lightbox && this.lightbox.pswp)
    {
      this.lightbox.pswp.close();
    }
  };

  public ngAfterContentInit(): void
  {
    this.ngZone.runOutsideAngular(() =>
    {
      setTimeout(() =>
      {
        this.initPhotoSwipe();
      }, 2000);
    });
  }
}
