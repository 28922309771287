import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { NavbarComponent } from './navbar/navbar.component';
import { OverlayComponent } from './overlay/overlay.component';

@NgModule({
  declarations: [NavbarComponent,
    OverlayComponent],
  imports: [
    SharedModule,
    RouterModule
  ],
  exports: [NavbarComponent, OverlayComponent]

})
export class Navbar_Overlay_Module
{
}
