import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent
{
  @Input() public activeElements: number = 0;
  @Input() public showRating: boolean = true;
  @Input() public isModal: boolean = false;
  @Output() public readonly setRating: EventEmitter<number> = new EventEmitter<number>();
  public activeModalElements: number = 0;
  public hoverStarsToHere: number = 0;

  constructor(private changeDet: ChangeDetectorRef)
  {
  }

  public setRatingFunc(rating: number): void
  {
    // number from 0
    this.setRating.emit(rating + 1);
  }

  public setAllStarToHere(i): void
  {
    if (this.isModal)
    {
      this.activeModalElements = i + 1;
      this.setRatingFunc(this.activeModalElements - 1);
      this.changeDet.detectChanges();
    }
  }

  public hoverStarsToHereFunc(i: number): void
  {
    if (this.isModal)
    {
      this.hoverStarsToHere = i;
      this.changeDet.detectChanges();
    }
    else
    {
      this.hoverStarsToHere = 0;
      this.changeDet.detectChanges();
    }
  }
}
