import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

@Pipe({
  name: 'observableWithLoading'
})
export class obsWithStatus implements PipeTransform
{
  public transform(val): any
  {
    return isObservable(val)
      ? val.pipe(
        map(value => ({ loading: false, value })),
        startWith({ loading: true }),
        catchError(error => of({ loading: false, error }))
      )
      : val;
  }
}
