import
{
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import
{
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import
{
  Customer,
  CustomerContactRequestVO,
  CustomerProductReviewDTO,
  CustomerUpdateDTO,
  ShopProduct,
  ShopProductFamily,
} from "@dto";
import { TranslateService } from "@ngx-translate/core";
import { Resolutions } from "@resolutions";
import { CartService } from "@service/cart/cart.service";
import { CustomerClientService } from "@service/client/customer-client.service";
import { NavbarService, NotificationBar } from "@service/navbar/navbar.service";
import { StorageService } from "@service/storage/storage.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import
{
  allRequiredPoliciesAreAccepted,
  getCollapsingContent,
  getSimpleContent,
  isCollapsingContentShown,
  isSimpleContentShown,
  parseContent,
  setAgreementTextExpand,
  toggleCheckbox,
} from "app/utils/utilsFunctionsContent";
import { CustomValidators } from "../../../../../utils/custom.validators";
import { Subject } from "rxjs";
import { filter, take, takeUntil } from "rxjs/operators";
import
{
  animateButton,
  getImageUrl,
  removeAnimateButton,
  showAfterSecond,
} from "../../../../../utils/utilsFunctions";
import { BlockService } from "../../../block/shared/block.service";
import { TooltipPopupComponent } from "../../../tooltip/tooltip-popup/tooltip-popup.component";
import { PopupService } from "../../../../service/popup/popup.service";
import { Consent, ConsentType, MessageItem } from "app/model/interfaces";

enum ControlNames
{
  RATING = "rating",
  EMAIL = "contactEmail",
  NAME = "name",
  PHONE = "phone",
  WHEN = "when",
  MESSAGE = "message",
  TIME = "time",
}

@Component({
  selector: "app-contact-popup-abstract",
  templateUrl: "./contact-popup-abstract.component.html",
  styleUrls: ["./contact-popup-abstract.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

// todo: rozbic na mniejsze i powtarzajace się tu jest tego za duzo
export class ContactPopupAbstractComponent implements AfterViewInit, OnInit, OnDestroy
{
  public isCollapsingContentShown = isCollapsingContentShown;
  public isSimpleContentShown = isSimpleContentShown;
  public getSimpleContent = getSimpleContent;
  public getCollapsingContent = getCollapsingContent;
  public setAgreementTextExpand = setAgreementTextExpand;
  public toggleCheckbox(agreements, code, $event, changeDetector)
  {
    toggleCheckbox(agreements, code, $event, changeDetector);
    this.checkAndRemoveButtonErrorState();
  }

  @ViewChild("addButton") public addButton: ElementRef;
  public allCheckboxIsSetAsActive: boolean = false;
  public isSubmitted: boolean = false;
  public readonly ControlNames = ControlNames;
  public whenAsap = "Jak najszybciej";
  public whenAfterTime = "Po godzinie: ";
  public customer: Customer;
  public showForm = false;
  public form: UntypedFormGroup;
  public formMessage = null;
  public error: MessageItem;
  public isLoading = false;
  public submission = false;
  public buttonText: string;
  public isForNewsletter: boolean = false;
  public formMessageColor: string = "green";
  public selectedShop: string;
  @Input() protected name: string = "";
  @Input() public mainTitle: string;
  @Input() public secondaryTitle: string;
  @Input() public phoneCaption: string;
  @Input() public productName: string;
  @Input() public productCode: string;
  @Input() public showLink: boolean = false;
  @Input() public showPhone: boolean = false;
  @Input() public phoneRequired: boolean = false;
  @Input() public showName: boolean = false;
  @Input() public showEmail: boolean = false;
  @Input() public showMessage: boolean = false;
  @Input() public showWhen: boolean = false;
  @Input() public showRating: boolean = false;
  @Input() public showOptionalDataCaption: boolean = false;
  @Input() public pictureExtension: string;
  @Input() public pictureSrc: string;
  @Input() public pictureAltName: string;
  @Input() public withImage: boolean = false;
  @Input() public isNewsletter: boolean = false;
  @Input() public isProductStatusPopup: boolean = false;
  @Input() public product: ShopProductFamily;
  @Input() public buttonType: string = "secondary-button";
  @Input() public dropdownOptions: any[] = [];
  @Input() public verticalSeparatorGrey: boolean = false;
  @Input() public tooltip: boolean = false;
  @Input() public navigationBarTitle: string;
  @Input() public isContactFormPopup: boolean = false;
  @Input() public isReviewPopup: boolean = false;
  @Input() public isAdvisorPopup: boolean = false;
  @Input() public alwaysShow: boolean = false;
  @Input() public emailPlaceholder: string;
  @Input() public messagePlaceholder: string;
  @Input() public namePlaceholder: string;
  @Input() public phonePlaceholder: string;
  @Input() public defaultButtonText;
  @Input() public buttonTextSuccess;
  @Output() private readonly closeHover = new EventEmitter<void>();
  @Output()
  public readonly ratingSuccessMsg: EventEmitter<any> = new EventEmitter();
  @Output()
  public readonly successEmitter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(TooltipPopupComponent) private tooltipPopup: TooltipPopupComponent;
  private destroy$: Subject<void> = new Subject();
  private window: Window | null;
  public productVariantsSizeSelected: boolean;
  public ratingError: boolean = true;
  public agreements: Consent[];
  public storedPickupPlace: any;
  public loaded = [];

  constructor(
    public renderer: Renderer2,
    public cartService: CartService,
    public windowRef: WindowRefService,
    public storageService: StorageService,
    public elementRef: ElementRef,
    public changeDetector: ChangeDetectorRef,
    private navbarService: NavbarService,
    public translate: TranslateService,
    private blockService: BlockService,
    private formBuilder: UntypedFormBuilder,
    private customerClientService: CustomerClientService,
    protected popupService: PopupService,
  )
  {
    this.window = this.windowRef.getNativeWindow();
    this.emailPlaceholder = this.translate.instant("emailAddress");
    this.messagePlaceholder = this.translate.instant("message");
    this.namePlaceholder = this.translate.instant("fullName");
    this.phonePlaceholder = this.translate.instant("typePhoneNumber");
    this.defaultButtonText = this.translate.instant("sendMessage");
    this.buttonTextSuccess = this.translate.instant("sent2");
  }

  public ngOnInit(): void
  {
    this.popupService.reserveName(this.name);

    this.initForm();
    this.buttonText = this.defaultButtonText;
    this.translatePage();
    this.form.valueChanges.subscribe(() =>
    {
      this.checkAndRemoveButtonErrorState();
    });
  }

  public ngAfterViewInit(): void
  {
  }

  private checkAndRemoveButtonErrorState()
  {
    if (this.addButton && this.agreements && allRequiredPoliciesAreAccepted(this.agreements) && this.form.valid)
    {
      removeAnimateButton("error", this.addButton, this.renderer);
    }
  }

  private getDataForAgreements(customer): void
  {

    let res = this.blockService.getDataSync("consents");
    if (this.isNewsletter)
    {
      this.agreements = res.items.filter(o => o.code == ConsentType.Newsletter)[0].consents;
    }
    else
    {
      this.agreements = res.items.filter(o => o.code == ConsentType.Contact)[0].consents;
    }

    this.agreements.forEach((agr) =>
    {
      agr.expand = false;

      let temp = customer.consents.filter((el) => el.code == agr.code);
      if (temp.length > 0)
      {
        agr.accepted = temp[0].active;
      }
    });

    if (!this.isNewsletter)
    {
      this.agreements = this.agreements.filter(
        (agr) => !(agr.global == true && agr.accepted)
      );
    }
    this.agreements.filter((agr) => (agr.accepted = false));
    this.agreements.filter((agr) => agr.global == false).forEach((agr) => (agr.accepted = false));
    this.agreements.forEach((agreement) => (parseContent(agreement, agreement.text)));
    this.allCheckboxIsSetAsActive = this.agreements.filter((agr) => agr.accepted == true).length == this.agreements.length;
    this.agreements.filter((agr) => agr.defaultChecked == true).forEach((agr) => (agr.accepted = true));

    if (!this.changeDetector["destroyed"])
    {
      this.changeDetector.detectChanges();
    }

  }

  public ngOnDestroy(): void
  {
    this.popupService.clearName(this.name);
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit(): void
  {
    this.isLoading = true;
    this.isSubmitted = true;
    animateButton(
      "loading",
      this.addButton,
      this.renderer,
      this.changeDetector
    );
    this.formMessageColor = "green";
    this.submission = true;
    if (!allRequiredPoliciesAreAccepted(this.agreements) || this.form.invalid)
    {
      removeAnimateButton("loading", this.addButton, this.renderer);
      animateButton(
        "error",
        this.addButton,
        this.renderer,
        this.changeDetector
      );
      this.isLoading = false;
      this.markAsTouchedAndDirtyForms();
      this.changeDetector.detectChanges();
      return;
    }

    this.formMessage = null;
    //todo zapisanie do newslettera przez popup poprawić
    if (this.isNewsletter)
    {
      this.saveForNewsletter();
    } else if (this.isReviewPopup)
    {
      this.saveForReview();
    } else
    {
      this.saveForContactRequest();
    }
  }

  private markAsTouchedAndDirtyForms(): void
  {
    if (!this.form.valid)
    {
      this.markAsTouchedAndDirty(this.form.controls);
    }
  }

  private markAsTouchedAndDirty(formControls: object): void
  {
    Object.keys(formControls).forEach((el) =>
    {
      if (formControls[el].value || formControls[el].errors)
      {
        if (el == ControlNames.RATING && formControls[el].value == "")
        {
          this.ratingError = true;
        }
        formControls[el].markAsDirty();
        formControls[el].markAsTouched();
      }
    });
    this.changeDetector.detectChanges();
  }

  public clear(): void
  {
    this.submission = false;
    this.isSubmitted = false;
    this.isLoading = false;
    this.form.reset();
    Object.keys(this.form.controls).forEach((key) =>
    {
      this.form.controls[key].setErrors(null);
    });
    this.changeDetector.detectChanges();
  }

  public open(email: string): void
  {
    if (email)
    {
      this.form.get(ControlNames.EMAIL).setValue(email);
      this.isForNewsletter = true;
    }
    this.showForm = true;
    this.formMessage = null;
  }

  public openModal(
    productVariant?: any,
    imageThumb?: string,
    disableReload?: boolean
  ): void
  {
    this.customerClientService.customer$
      .pipe(
        filter((v) => !!v),
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe((newCustomer: Customer) =>
      {
        this.customer = newCustomer;
        this.fillUpFormContact();
        this.getDataForAgreements(newCustomer);
      });
    this.storageService.get("selectedPickupPlace").subscribe((res) =>
    {
      this.storedPickupPlace = res;
    });

    this.popupService.openPopup(this.name, !!disableReload);

    if (imageThumb)
    {
      this.pictureSrc = imageThumb;
    }
    this.showForm = true;
    if (this.form.controls[this.ControlNames.WHEN])
    {
      this.form.controls[ControlNames.WHEN].setValue(this.whenAsap);
    }

    if (this.tooltip)
    {
      this.product = productVariant;
      if (
        this.windowRef
          .getDocumentBody()
          .querySelector(".contact-popup-abstract")
      )
      {
        this.renderer.addClass(
          this.windowRef
            .getDocumentBody()
            .querySelector(".contact-popup-abstract"),
          "first-stage"
        );
      }
    }
    if (this.isReviewPopup)
    {
      this.product = productVariant;
    }
    this.windowRef.blockScroll();
    showAfterSecond(100, this.loaded, this.changeDetector);
    this.changeDetector.detectChanges();
  }

  public closeModal(
    event?: any,
    disableReload?: boolean,
    isWrapper?: boolean
  ): void
  {
    if (event && isWrapper && !event.target.attributes["data-wrapper"]) return;
    this.clear();

    this.popupService.closePopup(this.name, !!disableReload);

    if (this.tooltip)
    {
      if (
        event &&
        event.path.length &&
        event.path[1].className != "close-button" &&
        event.path[1].className != "modal-wrapper" &&
        event.path[1].className != "img-wrapper cancel" &&
        event.path[1].className != "arrow-back"
      )
      {
        return;
      }
      if (
        this.windowRef
          .getDocumentBody()
          .querySelector(".contact-popup-abstract")
      )
      {
        if (!event && this.tooltip)
        {
          this.tooltipPopup.closeModal();
          this.tooltip = false;
          this.renderer.removeClass(
            this.windowRef
              .getDocumentBody()
              .querySelector(".contact-popup-abstract"),
            "first-stage"
          );
          return;
        }
        this.tooltip = false;
        this.tooltipPopup.closeModal();
        return;
      }
    }
    this.form.reset();
    this.showForm = false;
    this.cleanProductVariantFromSelectedData();
    this.error = null;
    this.closeHover.emit();
    this.formMessage = null;
    this.windowRef.unBlockScroll();
    this.loaded["loaded"] = false;
    this.changeDetector.detectChanges();
  }

  public getClassNameByResolution(
    className1: string,
    className2: string
  ): string
  {
    if (
      this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop
    )
    {
      return className1;
    }
    return className2;
  }

  public getWhenValue(): number
  {
    return this.form.controls[ControlNames.WHEN].value;
  }

  public setWhenValue(when: string): void
  {
    this.form.controls[ControlNames.WHEN].setValue(when);
  }

  public setTimeValue(time: string): void
  {
    this.form.controls[ControlNames.TIME].setValue(time);
  }

  public onSelectElement(product: ShopProductFamily): void
  {
    this.productVariantsSizeSelected =
      product.products.filter((variant) => variant["selectedModal"] == true)
        .length > 0;
    this.changeDetector.detectChanges();
  }

  private cleanProductVariantFromSelectedData(): void
  {
    this.productVariantsSizeSelected = false;
    if (
      this.product &&
      this.product.products &&
      this.product.products.length > 1
    )
    {
      this.product.products.forEach(
        (variant) => (variant["selectedModal"] = false)
      );
    }
  }

  private prepareDataForWhenControl(): string
  {
    return this.form.controls[ControlNames.WHEN].value === this.whenAsap
      ? this.form.controls[ControlNames.WHEN].value
      : this.form.controls[ControlNames.WHEN].value +
      " " +
      this.form.controls[ControlNames.TIME].value;
  }

  private addControl(
    controlName,
    controlValidator = [Validators.required]
  ): void
  {
    this.form.addControl(controlName, new UntypedFormControl("", controlValidator));
  }

  private fillUpFormContact(): void
  {
    if (this.customer && this.form.controls)
    {
      if (this.customer.defaultDeliveryAddress)
      {
        if (this.form.get(ControlNames.NAME))
        {
          this.form.controls[ControlNames.NAME].setValue(
            this.customer.defaultDeliveryAddress.firstName &&
              this.customer.defaultDeliveryAddress.secondName
              ? this.customer.defaultDeliveryAddress.firstName +
              " " +
              this.customer.defaultDeliveryAddress.secondName
              : ""
          );
        }
        if (this.form.get(ControlNames.PHONE))
        {
          this.form.controls[ControlNames.PHONE].setValue(
            this.customer.defaultDeliveryAddress
              ? this.customer.defaultDeliveryAddress.phone
              : null
          );
        }
      }

      if (this.form.get(ControlNames.EMAIL))
      {
        this.form.controls[ControlNames.EMAIL].setValue(this.customer.email);
      }
    }
  }

  private addNotification(alertStatus: string, errorText: string): void
  {
    const notificationBar: NotificationBar = {
      alertStatus: alertStatus,
      errorText: errorText,
    };
    this.navbarService.updateNotificationSubject(notificationBar);
    setTimeout(() => this.navbarService.updateNotificationSubject(null), 20000);
  }

  private saveForNewsletter(): void
  {
    const dto: CustomerUpdateDTO = {
      email: this.form.controls[ControlNames.EMAIL] ? this.form.controls[ControlNames.EMAIL].value : null,
      addConsents: []
    };

    this.agreements.forEach((agr) =>
    {
      if (agr.accepted)
      {
        dto.addConsents.push(agr.code);
      }
    });
    this.customerClientService.update(dto).subscribe(
      (result) =>
      {
        this.isLoading = false;
        removeAnimateButton("loading", this.addButton, this.renderer);
        removeAnimateButton("error", this.addButton, this.renderer);
        animateButton(
          "success",
          this.addButton,
          this.renderer,
          this.changeDetector
        );
        this.isSubmitted = false;
        this.isLoading = false;
        this.error = null;
        this.formMessage = this.translate.instant("error.newsletterSuccess");
        this.addNotification("Success", this.formMessage);
        this.form.reset();
        this.closeModal(true);
      },
      (error) =>
      {
        this.isLoading = false;
        removeAnimateButton("success", this.addButton, this.renderer);
        removeAnimateButton("loading", this.addButton, this.renderer);
        animateButton("error", this.addButton, this.renderer, this.changeDetector);
        this.formMessageColor = "red";
        this.formMessage = this.translate.instant("error.newsletterUnsuccessful");
        this.changeDetector.detectChanges();
      }
    );
  }

  private saveForContactRequest(): void
  {
    const requestDTO: CustomerContactRequestVO = {
      contactFormCode: this.mainTitle,
      customerEmail: !this.form.controls[ControlNames.EMAIL] ? null : this.form.controls[ControlNames.EMAIL].value,
      customerMessage: !this.form.controls[ControlNames.MESSAGE] ? null : this.form.controls[ControlNames.MESSAGE].value,
      customerName: !this.form.controls[ControlNames.NAME] ? null : this.form.controls[ControlNames.NAME].value,
      customerPhone: !this.form.controls[ControlNames.PHONE] ? null : this.form.controls[ControlNames.PHONE].value,
      whenToContact: !this.form.controls[ControlNames.WHEN] ? null : this.prepareDataForWhenControl()
    };

    if (!this.productCode)
    {
      let selectedItemCode: string = "";

      if (this.product && this.product.products)
      {
        this.getAllSelectedItems(this.product.products).map((product) => product.code + ",").forEach((el) => (selectedItemCode += el));
      }

      requestDTO.productCode = selectedItemCode.slice(0, selectedItemCode.length - 1);
    }
    else
    {
      requestDTO.productCode = this.productCode;
    }

    if (this.selectedShop)
    {
      requestDTO.customerMessage = this.selectedShop["code"] + " " + this.selectedShop["name"];
    }

    if (this.productName || (this.product && this.product.name))
    {
      requestDTO.productName = this.productName ? this.productName : this.product.name;
    }

    this.customerClientService.sendContactRequest(requestDTO).subscribe(
      (result) =>
      {
        this.successEmitter.emit({
          type: "Success",
          message: this.translate.instant("error.formSend"),
        });
        this.isLoading = false;
        this.error = null;
        this.isSubmitted = false;

        this.formMessage = this.translate.instant("error.thanksForMessage");
        this.addNotification("Success", this.formMessage);

        removeAnimateButton("error", this.addButton, this.renderer);
        removeAnimateButton("loading", this.addButton, this.renderer);
        animateButton(
          "success",
          this.addButton,
          this.renderer,
          this.changeDetector
        );
        this.changeDetector.detectChanges();
        this.form.reset();
        this.closeModal(true);
        const dto: CustomerUpdateDTO = {
          addConsents: []
        }
        this.agreements.forEach((agr) =>
        {
          if (agr.accepted)
          {
            dto.addConsents.push(agr.code);
          }
        });
        this.customerClientService.update(dto).subscribe();
      },
      (error) =>
      {
        this.isLoading = false;
        this.error = error;
        this.submission = false;
        this.formMessageColor = "red";
        this.formMessage = this.translate.instant("error.messageSendUnsuccessful");

        removeAnimateButton("success", this.addButton, this.renderer);
        removeAnimateButton("loading", this.addButton, this.renderer);
        animateButton("error", this.addButton, this.renderer, this.changeDetector);
      }
    );
  }

  private getAllSelectedItems(products: ShopProduct[]): ShopProduct[]
  {
    return products.filter((el) => el["selectedModal"] == true);
  }

  private saveForReview(): void
  {
    if (!this.form.valid)
    {
      return;
    }

    const dto: CustomerProductReviewDTO = {
      rating: this.form.controls[ControlNames.RATING].value,
      anonymousUsername: this.form.controls[ControlNames.NAME].value,
      description: this.form.controls[ControlNames.MESSAGE].value ? this.form.controls[ControlNames.MESSAGE].value : "",
      productFamilyCode: this.product.familyCode,
      addConsents: []
    };

    this.agreements.forEach((agr) =>
    {
      if (agr.accepted)
      {
        dto.addConsents.push(agr.code);
      }
    });

    this.customerClientService
      .customerProductReviewAdd(dto)
      .subscribe((success: any) =>
      {
        this.onSuccess(success);
        this.successEmitter.emit();
      }, this.onError.bind(this));
  }

  protected onSuccess(success: boolean): void
  {
    this.error = null;
    this.isLoading = false;
    this.isSubmitted = false;
    this.ratingSuccessMsg.emit(true);
    removeAnimateButton("error", this.addButton, this.renderer);
    removeAnimateButton("loading", this.addButton, this.renderer);
    animateButton(
      "success",
      this.addButton,
      this.renderer,
      this.changeDetector
    );

    if (success)
    {
      this.form.reset();
      this.form.controls[ControlNames.MESSAGE].markAsUntouched();
      this.form.controls[ControlNames.MESSAGE].markAsPending();
      this.form.controls[ControlNames.MESSAGE].markAsPristine();
      this.changeDetector.detectChanges();
      this.closeModal(true);
      return;
    }
  }

  protected onError(error: any): void
  {
    this.isLoading = false;
    this.error = error;
    removeAnimateButton("success", this.addButton, this.renderer);
    removeAnimateButton("loading", this.addButton, this.renderer);
    animateButton("error", this.addButton, this.renderer, this.changeDetector);
    return;
  }

  public setRatingNumber($event): void
  {
    this.form.controls[ControlNames.RATING].setValue($event);
    this.ratingError = false;
    this.changeDetector.detectChanges();
  }

  private initForm(): void
  {
    this.form = this.formBuilder.group({});
    if (this.showPhone)
    {
      this.addControl(
        ControlNames.PHONE,
        this.phoneRequired ? [Validators.required] : null
      );
    }

    if (this.showName)
    {
      this.addControl(ControlNames.NAME);
    }

    if (this.showEmail)
    {
      this.addControl(ControlNames.EMAIL, [
        Validators.required,
        CustomValidators.email,
      ]);
    }

    if (this.showMessage)
    {
      this.addControl(ControlNames.MESSAGE, null);
    }
    if (this.showRating)
    {
      this.addControl(ControlNames.RATING);
    }

    if (this.showWhen)
    {
      this.form.addControl(ControlNames.WHEN, new UntypedFormControl(this.whenAsap));
      this.form.addControl(ControlNames.TIME, new UntypedFormControl());
    }

    if (this.showLink)
    {
      this.addControl("link");
    }

    this.form.addControl(
      ConsentType.ContactRequestProcessing,
      new UntypedFormControl(false)
    );
    this.form.addControl(
      ConsentType.PrivacyPolicy,
      new UntypedFormControl(false)
    );
    this.form = new UntypedFormGroup(this.form.controls);
    this.form.statusChanges.subscribe(() =>
      this.changeDetector.detectChanges()
    );
  }

  @HostListener("window:keyup", ["$event"])
  private onKeyUp(event): void
  {
    if (this.showForm && event.key === "Escape")
    {
      this.closeModal(null);
    } else if (this.showForm && event.keyCode === 13)
    {
      this.onSubmit();
    }
  }

  @HostListener("window:popstate", ["$event"])
  private backButtonHandler(): void
  {
    if (
      !this.popupService.isActiveParam("m", this.name) &&
      this.popupService.isActivePopup(this.name)
    )
    {
      this.closeModal(null, true);
    }
  }

  protected removeAnimateButton(s: string): void
  {
    this.renderer.removeClass(this.addButton.nativeElement, s);
    this.changeDetector.detectChanges();
  }

  public showTooltipPopup($event): void
  {
    // todo: event tylko jako event czy jako shopProductPack
    if ($event instanceof Event)
    {
      this.tooltipPopup.openModal();
      $event.stopPropagation();
    } else
    {
      this.tooltipPopup.openModal(
        $event.productVariant,
        getImageUrl(
          $event.product.familyCode,
          "0",
          null,
          [80, 80, 80],
          null
        )
      );
      this.tooltip = true;
    }
  }

  public clickOnDisabled(): void
  {
    const rightSide = this.elementRef.nativeElement.querySelector(
      ".right-side"
    );
    if (
      rightSide.className.includes("disabled") &&
      this.selectedShop == null &&
      this.storedPickupPlace == null
    )
    {
      this.renderer.addClass(
        this.elementRef.nativeElement.querySelector("app-dropdown"),
        "error"
      );
    }
  }

  private translatePage(): void
  {
    this.whenAsap = this.translate.instant("modalsTexts.rightNow");
    this.whenAfterTime = this.translate.instant("modalsTexts.afterHour");
    if (!this.defaultButtonText)    
    {
      this.defaultButtonText = this.translate.instant("sendMessage");
    }

    if (!this.buttonText)
    {
      this.buttonText = this.translate.instant("sendMessage");
    }
    if (!this.emailPlaceholder)
    {
      this.emailPlaceholder = this.translate.instant("login.inputEmail");
    }
    if (!this.messagePlaceholder)
    {
      this.messagePlaceholder = this.translate.instant("message");
    }
    if (!this.namePlaceholder)
    {
      this.namePlaceholder = this.translate.instant("fullName");
    }

    this.phonePlaceholder = this.translate.instant("typePhoneNumber");
  }

  public hasError(agreement: Consent): boolean
  {
    if (agreement.required == true && agreement.accepted == false)
    {
      return true;
    }
  }

  public setSelectedShop(selectedShopLocal: string): void
  {
    this.selectedShop = selectedShopLocal;
    this.storageService.set("selectedPickupPlace", selectedShopLocal);
    this.changeDetector.detectChanges();
  }
}
