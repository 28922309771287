import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService
{
  private overlaySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isOverlayVisible$: Observable<boolean> = this.overlaySubject.asObservable();

  public get status(): boolean
  {
    return this.overlaySubject.getValue();
  }

  public set(status: boolean): void
  {
    this.overlaySubject.next(status);
  }

  public toggle(): void
  {
    this.overlaySubject.next(!this.overlaySubject.getValue());
  }

  public show(): void
  {
    this.overlaySubject.next(true);
  }

  public hide(): void
  {
    this.overlaySubject.next(false);
  }
}
