import
{
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit
} from '@angular/core';
import { Resolutions } from '@resolutions';
import { UrlHelperService } from '@service/helpers/url-helper.service';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlockService } from '../../../sharedModules/block/shared/block.service';
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { TileContent, TilePosition, Tiles } from 'app/model/interfaces';

interface TileContentWrapper extends TileContent
{
  imageSize?: { width: number[], height: number[] } | undefined;
  linkQueryParams?: { [key: string]: string } | undefined;
  className?: string | undefined;
}

@Component({
  selector: 'app-tile-rwd-box',
  templateUrl: './tile-rwd-box.component.html',
  styleUrls: ['./tile-rwd-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileRwdBoxComponent implements OnInit
{
  public tiles$: Observable<TileContentWrapper[]>;
  public shouldShow: boolean;
  public isMoreThanMobileResolution: boolean;

  constructor(private blockService: BlockService,
    private urlHelper: UrlHelperService,
    private windowRefService: WindowRefService,
    private changeDet: ChangeDetectorRef,
    public urlTranslateService: UrlTranslateService,)
  {
  }

  public ngOnInit(): void
  {
    this.isMoreThanMobileResolution = this.windowRefService.getNativeWindowInnerWidth() >= Resolutions.resolutionDesktop;

    this.tiles$ = this.blockService.getData('tiles').pipe(map(res =>
    {
      this.shouldShow = true;

      const list = res.list.map((el: TileContent, index: number) =>
      {
        let newItem: TileContentWrapper = { ...el };
        newItem.className = this.prepareClass(el, index, res.list.length);
        newItem.linkQueryParams = this.urlHelper.parseQueryStringToObject(newItem.callToActionLink.split(`/${this.urlTranslateService.routingTable.shop.listing}?`)[1]);
        return newItem;
      });

      return list;
    }));
  }

  @HostListener('window:resize', ['$event'])
  public onWindowResize(): void
  {
    if (this.isMoreThanMobileResolution !== this.windowRefService.getNativeWindowInnerWidth() >= Resolutions.resolutionDesktop)
    {
      this.isMoreThanMobileResolution = this.windowRefService.getNativeWindowInnerWidth() >= Resolutions.resolutionDesktop;
      this.changeDet.detectChanges();
    }
  }

  //#region Static methods
  private isDoubleWidth(pos: TilePosition): boolean
  {
    return pos.right - pos.left === 1;
  }

  private isDoubleHeight(pos: TilePosition): boolean
  {
    return pos.bottom - pos.top === 1;
  }

  private prepareClass(tile: TileContent, index: number, numberOfElements: number): string
  {
    let className = '';
    if (tile.type === 'Text')
    {
      className += 'item-text';
    }
    if (this.isDoubleWidth(tile.position))
    {
      className += 'grid-item--width2';
    }
    if (this.isDoubleHeight(tile.position))
    {
      className += 'grid-item--height2';
    }
    if (this.shouldShow)
    {
      className += ' show';
    }
    if (index == numberOfElements - 1)
    {
      className += ' last-item';
    }
    return className;
  }
  //#endregion
}
