import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueWithComma'
})
export class ValueWithCommaPipe implements PipeTransform
{
    transform(value: any, showTrailingZeros: boolean = false): string
    {
        if (typeof value == 'string')
        {
            value = value.replace(/\s/g, "");
            value = value.replace(/,/g, ".");
            value = parseFloat(value);
        }

        if (Math.round(value) != value || showTrailingZeros)
        {
            let temp = parseFloat(value).toFixed(2).replace(/\./gi, ",");
            return temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        return value;
    }
}