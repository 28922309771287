import { Injectable } from "@angular/core";
import
{
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BreadcrumbsService } from "@service/breadcrumbs/breadcrumbs.service";
import { UrlHelperService } from "@service/helpers/url-helper.service";
import { of } from "rxjs";
import { Breadcrumbs } from "../../../model/Breadcrumbs.model";
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { SeoService } from "@service/seo/seo.service";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbsLoggerService implements CanActivate
{
  constructor(
    private breadcrumsService: BreadcrumbsService,
    private translate: TranslateService,
    private urlHelper: UrlHelperService,
    public urlTranslateService: UrlTranslateService
  ) { }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any
  {
    this.saveRoute(state);
    return of(true);
  }

  private saveRoute(routeSnapshot: RouterStateSnapshot): void
  {
    let bcArr: Breadcrumbs[] = [];
    const readonlyRouteArray = routeSnapshot.url.split("/");
    const routeArray = readonlyRouteArray.slice();

    for (let i = 0; i < readonlyRouteArray.length; i++)
    {
      readonlyRouteArray[i] = encodeURIComponent(readonlyRouteArray[i]);
      const url = routeArray.slice(i, i + 1).map((el) => "/" + encodeURIComponent(el))[0];
      bcArr.push({ url: url, name: readonlyRouteArray[i] === "" ? this.translate.instant("mainPage") : readonlyRouteArray[i], });
    }

    if (bcArr.length > 1 && bcArr[1].url == `/${this.urlTranslateService.routingTable.shop.product}`)
    {
      return;
    }
    else if (readonlyRouteArray.length > 1 && (window as any).friendlyList.some(o => readonlyRouteArray[1] == o))
    {
      return;
    }
    else if (bcArr[1].url.includes(`/${this.urlTranslateService.routingTable.shop.listing}`))
    {
      return;
    }
    else if (bcArr[1].url.includes(`/${this.urlTranslateService.routingTable.additional.contact}`))
    {
      bcArr[1].name = this.translate.instant("contact.title");
    }
    else if (bcArr[1].url.includes(`/${this.urlTranslateService.routingTable.order}`))
    {
      bcArr[1].name = this.translate.instant("order.title");
      bcArr[1].url = `/${this.urlTranslateService.routingTable.order}`;
      bcArr = bcArr.slice(0, 2);
    }
    else if (bcArr[1].url.includes(`/${this.urlTranslateService.routingTable.customer.base}`))
    {
      bcArr[1].name = this.translate.instant("myAccount");
      bcArr[1].url = `/${this.urlTranslateService.urlMapping.customer.account.orders}`;
      bcArr = bcArr.slice(0, 2);
    }
    else if (bcArr[1].url.includes(`/${this.urlTranslateService.routingTable.additional.pos}`))
    {
      bcArr[1].name = this.translate.instant("arteShops");

      if (bcArr.length > 2)
      {
        bcArr.pop();
      }
    }
    else if (bcArr[1].url.includes(`/${this.urlTranslateService.routingTable.shop.compare}`))
    {
      bcArr[1].name = this.translate.instant("compareProducts");
    }
    else if (bcArr[1].url.includes(`/${this.urlTranslateService.routingTable.additional.aboutUs}`))
    {
      bcArr[1].name = this.translate.instant("companyPage.title");
    }

    this.breadcrumsService.updateBreadcrumb([...bcArr]);
  }
}
