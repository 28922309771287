<div class="popup-wrapper" [hidden]="!shouldShow">
  <ng-container *ngIf="!userIsLogged">
    <div class="upper-section">
      <button
        class="button secondary-button font-style-semi"
        (click)="hide()"
        [routerLink]="'/' + urlTranslateService.urlMapping.customer.login"
      >
        {{ "login.logIn" | translate }}
      </button>
      <div class="register-link font-style-light">
        <span>{{ "login.noAccount" | translate }}</span>
        <span>
          <a
            class="color-success"
            [routerLink]="
              '/' + urlTranslateService.urlMapping.customer.register
            "
          >
            {{ "login.register" | translate }}</a
          ></span
        >
      </div>
    </div>
    <div class="separator-gray"></div>
  </ng-container>
  <ng-container *ngIf="userIsLogged">
    <p class="user-name">
      {{ "login.greeting" | translate }}
      {{ userName ? userName : "Użytkowniku" }}
    </p>
    <div class="separator-gray no-margin"></div>
  </ng-container>

  <div class="lower-section">
    <div class="font-style-light">
      <a
        [routerLink]="
          '/' + urlTranslateService.urlMapping.customer.account.orders
        "
        >{{ "account.myOrders" | translate }}</a
      >
    </div>
    <div class="font-style-light">
      <a
        [routerLink]="
          '/' + urlTranslateService.urlMapping.customer.account.myData
        "
        >{{ "account.myData" | translate }}</a
      >
    </div>
    <div class="font-style-light">
      <a
        [routerLink]="
          '/' + urlTranslateService.urlMapping.customer.account.myCoupons
        "
        >{{ "account.coupons" | translate }}</a
      >
    </div>
    <div class="font-style-light">
      <a
        [routerLink]="
          '/' + urlTranslateService.urlMapping.customer.account.reviews
        "
        >{{ "navbar.reviews" | translate }}</a
      >
    </div>
    <div class="font-style-light">
      <a
        [routerLink]="
          '/' + urlTranslateService.urlMapping.customer.account.favourite
        "
        >{{ "favourite.title" | translate }}</a
      >
    </div>
    <div class="font-style-light">
      <a
        [routerLink]="
          '/' + urlTranslateService.urlMapping.customer.account.lastWatched
        "
        >{{ "main.lastWatched" | translate }}</a
      >
    </div>
  </div>
  <ng-container *ngIf="userIsLogged">
    <button
      class="button secondary-button font-style-semi logout-button"
      (click)="logout()"
    >
      {{ "login.logOut" | translate }}
    </button>
  </ng-container>
</div>
