<div class="input-wrapper" [ngClass]="{ hidePlusAndMinus: hidePlusAndMinus }">
  <div *ngIf="showArrows" class="minus" (click)="subtractionValue(isDefaultValueOfValue())" [ngClass]="{ disabled: isDefaultValueOfValue() || disabled }">
    <img src="/assets/icons/minus.svg" />
  </div>
  <input class="input"  [(ngModel)]="realValue" #inputValue (blur)="changeByBlur()" (keyup)="changeByEnter($event)" [id]="inputValueName"/>
  <label [for]="inputValueName" (click)="inputValue.focus()">
    {{ placeholder }}
  </label>
  <div *ngIf="showArrows" class="plus" (click)="additionValue()">
    <img src="/assets/icons/plus.svg" />
  </div>
</div>
