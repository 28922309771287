import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AutofocusDirective } from '@shared/directives/autofocus.directive';
import { BlockModule } from '../block/block.module';
import { AutofillLabelupDirective } from './autofill-labelup.directive';
import { ClickOutsideDirective } from './click.directive';
import { InputClickDirective } from './input-click.directive';
//import { DeferLoadDirective } from './lazy.directive';
import { NumberFocusDirective } from './number.directive';
import { RegisterClickEventDirective } from './register-click-event.directive';
import { ExpandDirective } from './expand.directive';
import { ComponentFactoryDirective } from './component-factory.directive'

@NgModule({
  declarations: [
    AutofillLabelupDirective,
    InputClickDirective,
    ClickOutsideDirective,
    //DeferLoadDirective,
    NumberFocusDirective,
    AutofocusDirective,
    RegisterClickEventDirective,
    ExpandDirective,
    ComponentFactoryDirective
  ],
  imports: [
    CommonModule,
    TranslateModule,
    BlockModule
  ],
  exports: [
    AutofillLabelupDirective,
    InputClickDirective,
    //DeferLoadDirective,
    ClickOutsideDirective,
    NumberFocusDirective,
    RegisterClickEventDirective,
    AutofocusDirective,
    ExpandDirective,
    ComponentFactoryDirective
  ]
})
export class DirectivesModule
{
}
