import { AfterViewInit, Component, ElementRef, HostListener, Input } from '@angular/core';
import { ShopModeService } from '@service/helpers/shop-mode.service';
import { WindowRefService } from '@service/window-service/window-ref.service';

@Component({
  selector: 'app-simple-block',
  templateUrl: './simple-block.component.html',
  styles: []
})
export class SimpleBlockComponent implements AfterViewInit
{

  @Input() public content: string;

  constructor(private elementRef: ElementRef, private windowRef: WindowRefService, private shopModeService: ShopModeService)
  {

  }

  public ngAfterViewInit(): void
  {
    this.elementRef.nativeElement.insertAdjacentHTML('beforeEnd', this.content);
  }

  @HostListener('click', ['$event'])
  public onClick(event): void
  {
    const tagName: string = event && event.target && event.target.tagName ? event.target.tagName : '';
    if (tagName.toUpperCase() === 'A')
    {
      event.stopPropagation();
      event.preventDefault();
      this.windowRef.getNativeWindow().open(event.target.href, this.shopModeService.isKioskMode() ? '_self' : '_blank');
    }
  }

}
