import
{
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ShopProduct, ShopProductFamily } from "@dto";
import { Record, Variant } from "@idto";
import { TranslateService } from "@ngx-translate/core";
import { Resolutions } from "@resolutions";
import { CartService } from "@service/cart/cart.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { filter } from "rxjs/operators";
import { ProductStatusPopupComponent } from "../../tooltip/product-status-popup/product-status-popup.component";
import { TooltipPopupComponent } from "../../tooltip/tooltip-popup/tooltip-popup.component";
import { ShortTimePipe } from "./short-time.pipe";

export interface ShopProductPack
{
  productVariant: ShopProduct;
  product: ShopProductFamily;
}

@Component({
  selector: "app-product-variants",
  templateUrl: "./product-variants.component.html",
  styleUrls: ["./product-variants.component.scss"],
  providers: [
    ShortTimePipe
  ]
})
export class ProductVariantsComponent implements OnInit
{
  public activeTooltip: Number;
  public activePromotionTooltip: Number;

  get product(): ShopProductFamily | Record
  {
    return this._product;
  }

  @Input() set product(value)
  {
    this._product = value;
  }

  get selectedShop(): any
  {
    return this._selectedShop;
  }

  @Input() set selectedShop(value)
  {
    this._selectedShop = value;
    this.changeDet.detectChanges();
  }

  private _selectedShop;

  private readonly MAX_HOVER_VARIATIONS_NUMBER = 4;
  private _product;
  public productArchived = [
    {
      option: "80 x 120 cm",
      size: (80 * 120) / 10000,
    },
    {
      option: "120 x 160 cm",
      size: (120 * 160) / 10000,
    },
    {
      option: "160 x 230 cm",
      size: (160 * 230) / 10000,
    },
    {
      option: "200 x 300 cm",
      size: (200 * 300) / 10000,
    },
    {
      option: "240 x 340 cm",
      size: (240 * 340) / 10000,
    },
  ];
  @Input() public variantsModal: boolean = false;
  @Input() public placeholder: boolean;
  @Input() public headingTitle: string;
  @Input() public onlyTableView: boolean;
  @Input() public popupReservation: boolean;
  @Input() public deliveryTooltip: boolean = true;
  @Input() public doesntMatterIfUnique: boolean = false;
  @Input() public addPerfectScroll: boolean = false;
  @Input() public addVariantsHeader: boolean = true;
  @Output()
  private readonly selectElement: EventEmitter<ShopProduct> = new EventEmitter();
  @Output()
  private readonly tooltipInvoked: EventEmitter<ShopProductPack> = new EventEmitter<ShopProductPack>();
  @Output()
  private readonly statusProductTooltipInvoked: EventEmitter<ShopProductPack> = new EventEmitter<ShopProductPack>();
  @ViewChild(TooltipPopupComponent) private tooltipPopup: TooltipPopupComponent;
  @ViewChild(ProductStatusPopupComponent)
  private pspc: ProductStatusPopupComponent;
  public isMobile: boolean = false;

  constructor(
    private changeDet: ChangeDetectorRef,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private cartService: CartService,
    private windowRef: WindowRefService
  )
  {
    this.cartService.resetSelected$
      .pipe(filter((v) => !!v))
      .subscribe((res) => this.resetAllSelectedItems());
  }

  checkIsMobile()
  {
    this.isMobile = this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionTablet;
  }

  public isPromotionActive(productVariant: any): boolean
  {
    return productVariant.standardPrice !== productVariant.promotionPrice;
  }

  public setElementAsSelected(productVariant): void
  {
    if (this.variantsModal)
    {
      productVariant["selectedModal"] = !productVariant["selectedModal"];
    } else
    {
      productVariant["selected"] = !productVariant["selected"];
    }
    this.selectElement.emit(productVariant);
    this.changeDet.detectChanges();
  }

  public goToTooltipPopup($event, productVariant: ShopProduct): void
  {
    $event.stopPropagation();
    this.tooltipInvoked.emit({
      productVariant: productVariant,
      product: this._product,
    });
  }

  public goToProductStatusPopup(productVariant: ShopProduct): void
  {
    this.statusProductTooltipInvoked.emit({
      productVariant: productVariant,
      product: this._product,
    });
  }

  public canShowOnOnlyTableView(index: number): boolean
  {
    return this.onlyTableView
      ? index <= this.MAX_HOVER_VARIATIONS_NUMBER
      : true;
  }

  public changeTooltipState(index: Number, tooltipType: string = 'activeTooltip', event, forceClose?: boolean): void
  {
    if (event && event.type !== "click" && this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
    {
      return;
    }

    if (event)
    {
      event.stopPropagation();
    }

    if (this[tooltipType] == index || forceClose) this[tooltipType] = null;
    else this[tooltipType] = index;

    this.changeDet.detectChanges();
  }

  private resetAllSelectedItems(): void
  {
    if (this._product && this._product["products"])
    {
      if (this.variantsModal)
      {
        this._product["products"].forEach(
          (product) => (product["selectedModal"] = false)
        );
      } else
      {
        this._product["products"].forEach(
          (product) => (product["selected"] = false)
        );
      }
      if (!this.changeDet["destroyed"])
      {
        this.changeDet.detectChanges();
      }
    }
  }

  ngOnInit()
  {
    this.checkIsMobile();
  }
}
