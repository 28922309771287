import { ChangeDetectorRef, Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UrlHelperService } from '@service/helpers/url-helper.service';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { UrlTranslateService } from '@service/helpers/url-translate.service';
//import { __createBinding } from 'tslib';
import { Resolutions } from '@resolutions';
import { ResponsiveBanner } from '../interfaces';
import { transformColorStatic } from 'app/utils/utilsFunctions';

@Directive()
export class Banner
{
  public transformColor = transformColorStatic;

  private _banner: ResponsiveBanner;

  get banner(): ResponsiveBanner
  {
    return this._banner;
  }
  @Input() set banner(value: ResponsiveBanner)
  {
    this._banner = value;
    this.onEventResize(null);
  }

  public showCaptionClipboard: boolean;

  public constructor(
    private readonly urlHelper: UrlHelperService,
    private router: Router,
    private detectorRef: ChangeDetectorRef,
    public windowRef: WindowRefService,
    public urlTranslateService: UrlTranslateService
  )
  {
  }

  public goToLink(banner: ResponsiveBanner): void
  {
    if (banner.options.selected.callToActionClipboard)
    {
      const el = document.createElement('textarea');
      el.value = banner.options.selected.callToActionClipboard;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.showCaptionClipboard = true;
      this.detectorRef.detectChanges();

      setTimeout(() =>
      {
        this.showCaptionClipboard = false;
        this.detectorRef.detectChanges();
      }, 500);
    }

    if (banner.options.selected.callToActionLink)
    {
      this.router.navigateByUrl(banner.options.selected.callToActionLink);
    }
  }

  public getLinkToProduct(link: any): any
  {
    return this.urlHelper.parseQueryStringToObject(link.split(`/${this.urlTranslateService.routingTable.shop.listing}?`)[1]);
  }

  @HostListener('window:resize', ['$event'])
  private onEventResize($event): void
  {
    if (window.innerWidth < Resolutions.resolutionTablet)
    {
      this.banner.options.selected = this.banner.options.mobile;
    }
    else if (window.innerWidth < Resolutions.resolutionDesktop)
    {
      this.banner.options.selected = this.banner.options.tablet;
    }
    else
    {
      this.banner.options.selected = this.banner.options.desktop;
    }

    this.detectorRef.detectChanges();

    //if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionTablet)
    //{
    //}
  }
}
