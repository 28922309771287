import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Resolutions } from "@resolutions";

@Injectable({
  providedIn: 'root'
})
export class WindowRefService
{
  public renderer: Renderer2;
  //public ieVersion;
  public isServer: boolean = false;
  private isServerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isServer$: Observable<boolean> = this.isServerSubject.asObservable();
  private savedScroll: string;
  public loadingAppSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public scrollIsBlocked: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: object,
    private _renderer: RendererFactory2,
    @Inject(DOCUMENT) private document: Document)
  {
    this.renderer = _renderer.createRenderer(null, null);
    this.checkIfBrowserIsLoaded();
    this.isServer$.subscribe(res => this.isServer = res);
  }

  public getNativeWindow(): Window
  {
    return window;
  }

  public getNativeWindowToScroll(): Window
  {
    return {
      ...window, scrollTo: (options?: ScrollToOptions) =>
      {
        window.scrollTo(options);
      }
    } as any;
  }

  public pushToHistory(data?: string): void
  {
    if (this.isWindowLoaded() && this.getNativeWindow().history && this.getNativeWindow().history.pushState)
    {
      if (data)
      {
        this.getNativeWindow().history.pushState(null, null, data);
      } else
      {
        this.getNativeWindow().history.pushState(null, null, this.getDocument().URL + '#');
      }
    }
  }

  public isWindowLoaded(): boolean
  {
    return !!window;
  }

  public isMobile(): boolean
  {
    if (!isPlatformServer(this.platformId))
    {
      return navigator.maxTouchPoints > 0;
    }
  }

  public getNativeWindowOuterWidth(): number
  {
    return this.getNativeWindow() ? this.getNativeWindow().outerWidth : 0;
  }

  public getNativeWindowInnerWidth(): number
  {
    return this.getNativeWindow() ? this.getNativeWindow().innerWidth : 0;
  }

  public getNativeWindowInnerHeight(): number
  {
    return this.getNativeWindow() ? this.getNativeWindow().innerHeight : 0;
  }

  public getDocumentBody(): any
  {
    return this.document.body;
  }

  public getDocument(): any
  {
    return this.document;
  }

  public blockScroll(): void
  {
    if (this.scrollIsBlocked == true)
    {
      return;
    }

    const htmlElement = this.document.getElementsByTagName('html');
    this.savedScroll = this.getNativeWindow().scrollY + 'px';
    this.getDocumentBody().style.position = 'fixed';
    this.renderer.addClass(this.getDocumentBody(), 'fixed-class');
    this.renderer.addClass(htmlElement[0], 'scroll-blocked');
    this.getDocumentBody().style.top = `-${this.savedScroll}`;
    this.getDocumentBody().style.left = 0;
    this.getDocumentBody().style.right = 0;
    this.scrollIsBlocked = true;
  }

  public unBlockScroll(addAppbarFixed: boolean = true): void
  {
    if (this.scrollIsBlocked == false)
    {
      return;
    }

    const scrollY = this.getDocumentBody().style.top != ''
      ? this.getDocumentBody().style.top
      : '-' + this.savedScroll;
    const htmlElement = this.document.getElementsByTagName('html');
    this.getDocumentBody().style.position = '';
    this.getDocumentBody().style.top = '';
    this.renderer.removeClass(this.getDocumentBody(), 'fixed-class');
    this.renderer.removeClass(htmlElement[0], 'scroll-blocked');
    if (addAppbarFixed && this.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
    {
      this.renderer.addClass(this.getDocumentBody().querySelector('app-navbar'), 'fixed');
    }
    this.getNativeWindow().scrollTo(0, parseInt(scrollY || '0') * -1);
    this.scrollIsBlocked = false;
  }

  private checkIfBrowserIsLoaded(): void
  {
    this.isServer$.pipe(distinctUntilChanged(), debounceTime(500)).subscribe((res) =>
    {
      if (isPlatformServer(this.platformId) == false && res == true)
      {
        this.isServerSubject.next(false);
      }
    }
    );
  }

  public scrollToSection(relatedTarget, additionalOffset: number = 0)
  {
    if (!relatedTarget || !this.getNativeWindow())
    {
      return;
    }

    this.renderer.removeClass(relatedTarget, "animationBackground");
    let top = relatedTarget.offsetTop + additionalOffset;

    if (
      this.getNativeWindowInnerWidth() <
      Resolutions.resolutionDesktop
    )
    {
      const NAV_HEIGHT = 64;
      top = top - NAV_HEIGHT;
    }

    this.getNativeWindow().scroll({
      top: top,
      left: 0,
      behavior: "smooth",
    });
    this.renderer.addClass(relatedTarget, "animationBackground");
  }
}
