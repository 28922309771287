import
{
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Resolutions } from "@resolutions";
import { ShopModeService } from "@service/helpers/shop-mode.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { BlockService } from "@shared/block/shared/block.service";
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { AutoplayOptions } from "swiper/types/modules/autoplay";
import SwiperCore, { Pagination, Navigation, SwiperOptions, FreeMode, Grid } from "swiper";
import { SeoService } from "@service/seo/seo.service";
import { BannerList, BannerOptions, Banners, GeneralSeo, InstagramImages, ShopReview, SliderOptions, Sliders, Socalmedia } from "app/model/interfaces";
import { ScrollPositionService } from "@service/scrollPosition.service";
import { SwiperConfigService } from "@service/swiper-config.service";

SwiperCore.use([Pagination, Navigation, FreeMode, Grid]);

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit, OnDestroy
{
  public topSlider: Observable<any>; // todo:extension
  public mainPageServices: string[];
  private destroy$: Subject<void> = new Subject();
  public inspAranz: InstagramImages;
  public opineoReviews: ShopReview[] = [];
  //public offsetsBefore: number[] = [16, 24, 48, 128];
  //public offsetsAfter: number[] = [16, 24, 48, 128];
  public swiperOpt: SwiperOptions;

  @ViewChild("swiperComponent", { static: true })
  private swiperComponent;

  public productSliders: Sliders
  public lastWatchedSliderIsEmpty: boolean = true;
  public mediaLinks: Socalmedia;
  private loadingApp: boolean = true;
  private loadingAppSub: Subscription;
  lastWatchedMoreLinkHidden: boolean;

  constructor(
    private changeDet: ChangeDetectorRef,
    private seoService: SeoService,
    private windowRef: WindowRefService,
    private translate: TranslateService,
    private shopModeService: ShopModeService,
    private blockService: BlockService,
    private scrollPositionService: ScrollPositionService,
    private swiperCo: SwiperConfigService
  )
  {
    this.seoService.setTitle(this.translate.instant("fullPageName"));

    this.swiperOpt = this.swiperCo.getConfig(
      [0, Resolutions.resolutionTablet, Resolutions.resolutionDesktop, Resolutions.resolutionHalfHd],
      [16, 24, 48, 128],
      [16, 16, 32, 32],
      [16, 24, 48, 128],
      [1, 2, 4, 4],
      [2, 2, 2, 2]
    );
    delete this.swiperOpt.slidesPerGroup;
    this.swiperOpt.observeParents = true;
    this.swiperOpt.navigation = true;
  }


  //#region Init/Destory
  public ngOnInit(): void
  {
    this.productSliders = this.blockService.getDataSync('sliders');

    const instagramImages: InstagramImages = this.blockService.getDataSync('instagramImages');
    const generalSeo: GeneralSeo = this.blockService.getDataSync('generalSeo');

    this.mediaLinks = this.blockService.getDataSync("socalmedia");
    this.opineoReviews = this.blockService.getDataSync("reviews");
    this.mainPageServices = this.blockService.getDataSync("services").codes;

    this.seoService.setImage(generalSeo.defaultOGImage, "Sklepy Arte.pl");
    this.seoService.setCanonical(this.windowRef.getNativeWindow().location.host);

    this.inspAranz = instagramImages;

    //this.detectChanges();


    this.windowRef.loadingAppSubject.subscribe((res) =>
    {
      if (!res)
      {
        this.loadingApp = false;
        this.scrollPositionService.restorePosition();
      }
    });

    // this.windowRef.isServer$.pipe(takeUntil(this.destroy$)).subscribe((res) => (this.isServer = res));
  }

  public ngOnDestroy(): void
  {
    if (!this.changeDet["destroyed"])
    {
      this.destroy$.next();
      this.destroy$.complete();
      this.loadingAppSub.unsubscribe();
    }
  }
  //#endregion

  //#region Refresh GUI
  private detectChanges(): void
  {
    if (!this.changeDet["destroyed"])
    {
      this.changeDet.detectChanges();
    }
  }

  public isSliderEmpty($event: boolean): void
  {
    if (this.lastWatchedSliderIsEmpty !== $event)
    {
      this.lastWatchedSliderIsEmpty = $event;
      this.detectChanges();
      this.scrollPositionService.restorePosition();
    }
  }

  isShowMoreHidden(counter: number): void
  {
    const newValue = counter <= 1;

    if (this.lastWatchedMoreLinkHidden !== newValue)
    {
      this.lastWatchedMoreLinkHidden = newValue;
      this.detectChanges();
    }
  }
  //#endregion

  //#region Outside
  public openInNewCard(virtualTourLink: string, $event): void
  {
    $event.stopPropagation();
    $event.preventDefault();

    if (this.windowRef.isWindowLoaded())
    {
      this.windowRef.getNativeWindow().open(virtualTourLink, this.shopModeService.isKioskMode() ? "_self" : "_blank");
    }
  }
  //#endregion
}
