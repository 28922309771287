import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WindowRefService } from '@service/window-service/window-ref.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService
{

  constructor(private windowService: WindowRefService,
    @Inject(PLATFORM_ID) private platformId: Object)
  {
  }

  public getLocalStorage(): Storage
  {
    return this.windowService.getNativeWindow() ? this.windowService.getNativeWindow().localStorage : null;
  }

  public getSessionStorage(): Storage
  {
    return this.windowService.getNativeWindow() ? this.windowService.getNativeWindow().sessionStorage : null;
  }
}
