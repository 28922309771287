<div
*ngIf="itemsThumbs.length "
class="pswp__bottom-bar"
>
<div class="thumbnails-wrapper">
    <swiper
      [config]="swiperOpt"
      class="swiper"
      #swiperComponent
    >
      <ng-template
        *ngFor="let thumb of itemsThumbs; let i = index"
        swiperSlide
      >
        <div
          class="pswp__item__thumb"
          [ngClass]="{
            active: i === _activeIndex
          }"
        >
          <img
            [src]="thumb.src"
            [style.width.px]="thumb.w"
            [style.height.px]="thumb.h"
            (click)="goToImage(i)"
            (error)="onError(thumb)"
          />
        </div>
      </ng-template>
    </swiper>
    <div class="swiper-button-prev" (click)="previousSlide()"></div>
    <div class="swiper-button-next" (click)="nextSlide()"></div>
  </div>
</div>