import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input } from '@angular/core';
import { Resolutions } from '@resolutions';
import { WindowRefService } from '@service/window-service/window-ref.service';

@Component({
  selector: 'app-slider-base',
  templateUrl: './slider-base.component.html',
  styleUrls: ['./slider-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderBaseComponent
{
  public showSwiper: boolean = true;
  @Input() public pagination: number[] = [2, 2, 1, 1];
  protected currentResolutionStatus: number;
  public resolutionbreakpoints: number[] = [0, Resolutions.resolutionTablet, Resolutions.resolutionDesktop, Resolutions.resolutionHalfHd]

  public constructor(protected windowRef2: WindowRefService, protected changeDet2: ChangeDetectorRef)
  {

  }

  protected hideSwiper(): void
  {
    this.showSwiper = false;
  }
  protected initSwiper(): void
  {
    this.showSwiper = true;
    this.changeDet2.detectChanges();
  }

  @HostListener("window:resize", ["$event"])
  private onResize(): void
  {
    if (this.setResolutionStatus())
    {
      this.hideSwiper();
      setTimeout(() =>
      {
        this.initSwiper();
      }, 1);
    }
  }

  public setResolutionStatus(): boolean
  {
    const currentWidth = this.windowRef2.getNativeWindowInnerWidth();
    let nextResulutionStatus = undefined;

    for (let i = 1; i <= this.resolutionbreakpoints.length; i++)
    {
      if (i == this.resolutionbreakpoints.length || currentWidth < this.resolutionbreakpoints[i])
      {
        nextResulutionStatus = this.pagination[i - 1];
        break;
      }
    }

    if (nextResulutionStatus != this.currentResolutionStatus)
    {
      this.currentResolutionStatus = nextResulutionStatus;
      return true;
    }
    else
    {
      return false
    }
  }
}
