<div
  class="modal-wrapper"
  *ngIf="showForm || alwaysShow"
  [ngClass]="{
    loaded: loaded && loaded['loaded']
  }"
>
  <div class="wrapper" (click)="closeModal($event, null, true)" data-wrapper>
    <div
      class="under-wrapper"
      [ngClass]="{
        'with-image': withImage,
        'with-navigation-bar': navigationBarTitle && !isProductStatusPopup,
        loaded: loaded && loaded['loaded']
      }"
      #parent
      [parentRef]="parent"
    >
      <div class="navigation-bar">
        <span class="arrow-back ai ai-back" (click)="closeModal($event)"></span>
        <span class="heading5">{{ navigationBarTitle }}</span>
        <div class="img-wrapper cancel" (click)="closeModal($event)">
          <i class="ai ai-cancel"></i>
        </div>
      </div>
      <div class="separator-gray full-height separator-one-half"></div>
      <div class="content-with-form" (click)="clickOnDisabled()">
        <div class="left-side">
          <app-img
            *ngIf="pictureSrc"
            [src]="pictureSrc"
            [alt]="pictureAltName"
            [width]="[360, 360, 565]"
            [height]="[240, 240, 660]"
            [options]="{ prefix: true, fillCrop: true }"
          ></app-img>
        </div>
        <div (click)="closeModal($event)" class="close-button">
          <i class="ai ai-cancel"></i>
        </div>
        <div
          class="vertical-separator-grey"
          *ngIf="verticalSeparatorGrey"
        ></div>
        <div class="right-side">
          <div
            class="font-style-semi main-title"
            [ngClass]="getClassNameByResolution('heading5', 'heading3')"
          >
            {{ mainTitle }}
          </div>
          <p
            class="separator2 font-style-light"
            *ngIf="isContactFormPopup || isAdvisorPopup || isNewsletter"
          >
            {{ secondaryTitle }}
          </p>
          <div
            class="flex-row"
            *ngIf="isProductStatusPopup"
            [ngClass]="
              getClassNameByResolution('separator-one-half', 'separator2')
            "
          >
            <app-img
              *ngIf="pictureSrc"
              [src]="pictureSrc"
              [alt]="pictureAltName"
            ></app-img>
            <p class="font-style-light">
              {{ "modalsTexts.informWhenProduct" | translate }}
              <span class="font-style-semi">{{ product.name }}</span>
              {{ "modalsTexts.informWhenProduct2" | translate }}
            </p>
          </div>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div *ngIf="showName" class="show-name">
              <input
                class="input"
                appInputClick
                appAutofillLabelup
                type="text"
                [placeholder]="namePlaceholder"
                [formControlName]="ControlNames.NAME"
                [name]="ControlNames.NAME"
                [id]="ControlNames.NAME"
                #showNameInput
              />
              <label
                class="form-label"
                [for]="ControlNames.NAME"
                [ngClass]="{ 'is-Empty': showNameInput.value == '' }"
                >{{ namePlaceholder }}</label
              >
              <sd-errors
                [control]="form.controls[ControlNames.NAME]"
              ></sd-errors>
            </div>
            <div *ngIf="showEmail" class="show-email">
              <input
                class="input"
                appInputClick
                appAutofillLabelup
                type="email"
                [placeholder]="emailPlaceholder"
                [formControlName]="ControlNames.EMAIL"
                [name]="ControlNames.EMAIL"
                [id]="ControlNames.EMAIL"
                #showEmailInput
              />
              <label
                class="form-label"
                [for]="ControlNames.EMAIL"
                [ngClass]="{ 'is-Empty': showEmailInput.value == '' }"
                >{{ emailPlaceholder }}</label
              >
              <sd-errors
                [control]="form.controls[ControlNames.EMAIL]"
              ></sd-errors>
            </div>
            <section *ngIf="showRating" class="rating-wrapper">
              <div class="show-rating separator1">
                <p>{{ "opineo.review" | translate }}</p>
                <app-rating
                  (setRating)="setRatingNumber($event)"
                  [isModal]="true"
                ></app-rating>
              </div>
              <span class="error-line" *ngIf="isSubmitted && ratingError"
                >{{ "fieldReq" | translate }}</span
              >
            </section>
            <div
              class="phone-tooltip-status-agreement"
              *ngIf="isProductStatusPopup"
            >
              {{ "modalsTexts.textWhenGivePhone" | translate }}
            </div>
            <div *ngIf="showPhone" class="show-phone">
              <input
                appInputClick
                appAutofillLabelup
                class="input"
                type="text"
                [placeholder]="phonePlaceholder"
                [formControlName]="ControlNames.PHONE"
                [name]="ControlNames.PHONE"
                [id]="ControlNames.PHONE"
                #showPhoneInput
              />
              <label
                class="form-label"
                [for]="ControlNames.PHONE"
                [ngClass]="{ 'is-Empty': showPhoneInput.value == '' }"
                >{{ phonePlaceholder }}</label
              >
              <sd-errors
                [control]="form.controls[ControlNames.PHONE]"
              ></sd-errors>
            </div>
            <div class="phone-time separator-one-half" *ngIf="showWhen">
              <div
                class="radio-button-wrapper"
                (click)="setWhenValue(whenAsap)"
              >
                <label>
                  <input
                    class="radio-button"
                    type="radio"
                    [name]="ControlNames.WHEN"
                    [ngClass]="{ active: getWhenValue() === whenAsap }"
                    [formControlName]="ControlNames.WHEN"
                    [checked]="getWhenValue() === whenAsap"
                    [value]="whenAsap"
                  />
                  <span
                    class="body-text font-style-light"
                    [ngClass]="{
                      'font-style-semi': getWhenValue() === whenAsap
                    }"
                    >{{ "modalsTexts.rightNow" | translate }}</span
                  >
                </label>
              </div>
              <div class="radio-button-wrapper with-dropdown">
                <label
                  class="margin-right-2"
                  (click)="setWhenValue(whenAfterTime)"
                >
                  <input
                    class="radio-button"
                    type="radio"
                    [name]="ControlNames.WHEN"
                    [ngClass]="{ active: getWhenValue() === whenAfterTime }"
                    [formControlName]="ControlNames.WHEN"
                    [checked]="getWhenValue() === whenAfterTime"
                    [value]="whenAfterTime"
                  />
                  <span
                    class="body-text font-style-light"
                    [ngClass]="{
                      'font-style-semi': getWhenValue() === whenAfterTime
                    }"
                    >{{ "modalsTexts.afterHour" | translate }}</span
                  >
                </label>
                <app-dropdown
                  placeholder="12:00"
                  #timeInput
                  [activeOption]="'12:00'"
                  [options]="[
                    '9:00',
                    '10:00',
                    '11:00',
                    '12:00',
                    '13:00',
                    '14:00',
                    '15:00',
                    '16:00',
                    '17:00',
                    '18:00'
                  ]"
                  (selectedItem)="setTimeValue($event)"
                  (click)="setWhenValue(whenAfterTime)"
                  [disabled]="getWhenValue() === whenAsap"
                ></app-dropdown>
              </div>
            </div>

            <textarea
              *ngIf="showMessage"
              class="textarea separator2"
              [placeholder]="messagePlaceholder"
              [formControlName]="ControlNames.MESSAGE"
            ></textarea>
            <sd-errors
              class="textarea-error"
              [control]="form.controls[ControlNames.MESSAGE]"
              [show]="false"
            ></sd-errors>

            <div class="consent-wrapper">
              <div
                class="checkbox-wrapper separator1"
                *ngFor="let agreement of agreements"
              >
                <div
                  class="flex-wrapper-checkbox"
                  (click)="
                    toggleCheckbox(
                      agreements,
                      agreement.code,
                      $event,
                      changeDetector
                    )
                  "
                >
                  <input
                    type="checkbox"
                    class="primary-checkbox"
                    [checked]="agreement.accepted == true"
                  />
                  <span
                    class="checkmark"
                    [ngClass]="{ error: isSubmitted && hasError(agreement) }"
                  ></span>
                  <p
                    class="font-style-light checkbox-text"
                    [ngClass]="{ 'expanded ': agreement.expand }"
                  >
                    <app-simple-block
                      [content]="getSimpleContent(agreement)"
                      *ngIf="isSimpleContentShown(agreement)"
                    ></app-simple-block>
                    <app-collapsing-block
                      [content]="getCollapsingContent(agreement)"
                      *ngIf="isCollapsingContentShown(agreement)"
                      (onExpandClick)="
                        setAgreementTextExpand(
                          $event,
                          agreement,
                          changeDetector
                        )
                      "
                    ></app-collapsing-block>
                  </p>
                </div>
                <ng-container>
                  <span
                    class="error-line"
                    *ngIf="isSubmitted && hasError(agreement)"
                  >
                    {{ "fieldReq" | translate }}
                </span>
                </ng-container>
              </div>
              <div
                class="button-wrapper"
                [ngClass]="{
                  'product-status-popup': isProductStatusPopup
                }"
              >
                <ng-container *ngIf="!isProductStatusPopup">
                  <div
                    *ngIf="showOptionalDataCaption"
                    class="footer-caption font-style-light tiny-text"
                  >
                    <p>{{ "fieldOpt" | translate }}</p>
                  </div>
                  <button
                    type="button"
                    class="button"
                    [ngClass]="buttonType"
                    #addButton
                    (click)="onSubmit()"
                  >
                    <ng-container *ngIf="!isLoading">{{
                      buttonText
                    }}</ng-container>
                    <span></span>
                  </button>
                </ng-container>
                <ng-container *ngIf="isProductStatusPopup">
                  <div
                    class="success-link font-style-light"
                    (click)="closeModal($event)"
                  >
                    {{ "backToPage" | translate }}
                  </div>
                  <button
                    class="button"
                    [ngClass]="buttonType"
                    #addButton
                    (click)="onSubmit()"
                  >
                    <ng-container *ngIf="!isLoading">{{
                      buttonText
                    }}</ng-container>
                  </button>
                </ng-container>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
