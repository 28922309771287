import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarService } from '@service/calendar.service';

@Component({
  selector: 'app-calendar-widget',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarWidgetComponent implements OnInit
{
  // todo: performance
  @Input() public dates = [];

  public highlighted = [];
  public weeks = [];

  public months = [];
  public monthNames = [];
  public dayNamesMin = [];

  constructor(private translate: TranslateService,
    private calendarService: CalendarService)
  {
  }

  public ngOnInit(): void
  {
    const res2 = this.translate.instant([
      'calendar.mo',
      'calendar.tu',
      'calendar.we',
      'calendar.th',
      'calendar.fr',
      'calendar.sa',
      'calendar.su']);
    this.dayNamesMin.push(res2['calendar.mo']);
    this.dayNamesMin.push(res2['calendar.tu']);
    this.dayNamesMin.push(res2['calendar.we']);
    this.dayNamesMin.push(res2['calendar.th']);
    this.dayNamesMin.push(res2['calendar.fr']);
    this.dayNamesMin.push(res2['calendar.sa']);
    this.dayNamesMin.push(res2['calendar.su']);


    const res = this.translate.instant([
      'calendar.january',
      'calendar.february',
      'calendar.march',
      'calendar.april',
      'calendar.may',
      'calendar.june',
      'calendar.july',
      'calendar.august',
      'calendar.september',
      'calendar.october',
      'calendar.november',
      'calendar.december'
    ]);

    this.monthNames.push(res['calendar.january']);
    this.monthNames.push(res['calendar.february']);
    this.monthNames.push(res['calendar.march']);
    this.monthNames.push(res['calendar.april']);
    this.monthNames.push(res['calendar.may']);
    this.monthNames.push(res['calendar.june']);
    this.monthNames.push(res['calendar.july']);
    this.monthNames.push(res['calendar.august']);
    this.monthNames.push(res['calendar.september']);
    this.monthNames.push(res['calendar.october']);
    this.monthNames.push(res['calendar.november']);
    this.monthNames.push(res['calendar.december']);


    this.addHighlightedDate(new Date(), '#dadada', true);

    if (this.dates && this.dates.length)
    {
      this.dates.forEach(date =>
      {
        this.addHighlightedDate(date.date, date.color);
      });
    }
    this.months = this.findMonths();

  }

  public addHighlightedDate(date, color = '#FFFFFF', order?: boolean): boolean
  {
    this.highlighted.push({
      date: date,
      color: color,
      icon: order ? 'ai-order-date' : null
    });
    return true;
  }

  public removeHighlightedDate(date): boolean
  {
    this.highlighted.forEach(highlightedDate =>
    {
      if (highlightedDate.date === date)
      {
        this.highlighted.splice(this.highlighted.indexOf(highlightedDate), 1);
        return true;
      }
    });
    return false;
  }

  private findEarliestDate(dates)
  {
    const sortedDates = this.sortDates(dates);
    return sortedDates[0];
  }

  private findLatestDate(dates)
  {
    const sortedDates = this.sortDates(dates);
    return sortedDates[sortedDates.length - 1];
  }

  private sortDates(dates)
  {
    return dates.sort((a, b) =>
    {
      return a.date < b.date ? -1 : 1;
    });
  }

  private findWeeks()
  {
    return this.findWeeksByRange(this.findEarliestDate(this.highlighted), this.findLatestDate(this.highlighted));
  }

  private findMonths()
  {
    const months = this.findMonthsByRange(this.findEarliestDate(this.highlighted), this.findLatestDate(this.highlighted));
    return months.filter((month) =>
    {
      return !!this.highlighted.find((element) => element.date.getMonth() === month.month);
    });
  }

  private findMonthsByRange(starting, ending)
  {

    const months = [];

    let startingMonth = starting.date.getMonth();
    const startingYear = starting.date.getFullYear();

    const endingMonth = ending.date.getMonth();
    const endingYear = ending.date.getFullYear();

    const startingWeek = this.getWeek(starting.date);
    const firstWeekOfEndingMonth = this.getWeek(new Date(endingYear, endingMonth, 1));

    if (startingWeek === firstWeekOfEndingMonth)
    {
      startingMonth = endingMonth;
    }

    for (let yearIndex = startingYear; yearIndex <= endingYear; yearIndex++)
    {

      let _startingMonth = startingMonth;
      if (yearIndex !== startingYear)
      {
        _startingMonth = 0;
      }

      let _endingMonth = endingMonth;
      if (yearIndex !== endingYear)
      {
        _endingMonth = 11;
      }

      const monthsArray = this.calendarService.generateMatrix(yearIndex);

      for (let index = _startingMonth; index <= _endingMonth; index++)
      {

        let weeks = monthsArray[index + 1];
        weeks = this.weeksCalc(weeks);

        months.push({
          month: index,
          name: this.monthNames[index],
          weeks: monthsArray[index + 1]
        });

      }

    }

    return months;

  }

  private weeksCalc(weeks)
  {
    weeks.forEach(week =>
    {
      week.forEach(day =>
      {
        day.highlighted = false;
        day.color = null;
        day.highlightColor = null;
        day.icon = null;
      });
    });
    weeks.forEach(week =>
    {
      week.forEach(day =>
      {
        this.highlighted.forEach(highlighted =>
        {
          if (day.date.getDate() === highlighted.date.getDate() &&
            day.date.getMonth() === highlighted.date.getMonth() &&
            day.date.getFullYear() === highlighted.date.getFullYear())
          {
            day.highlighted = true;
            day.highlightColor = highlighted.color;
            day.icon = highlighted.icon ? highlighted.icon : highlighted.color === '#ffffff' ? 'ai-delivery' : 'ai-shipment';
          }
        });
      });
    });
    return weeks;
  }

  private findWeeksByRange(starting, ending): any[]
  {

    let weeks = [];

    const startingDay = this.calendarService.daysMatrix[starting.date.getMonth() + 1][starting.date.getDate()];
    const endingDay = this.calendarService.daysMatrix[ending.date.getMonth() + 1][ending.date.getDate()];

    let endingWeek = endingDay.weekOfYear;
    const startingWeek = startingDay.weekOfYear - 1;

    if (endingWeek - startingDay.weekOfYear < 3)
    {
      endingWeek = startingDay.weekOfYear + 3;
    }

    for (let index = startingWeek; index <= endingWeek; index++)
    {
      weeks.push(this.calendarService.weeksMatrix[index]);
    }
    weeks = this.weeksCalc(weeks);

    return weeks;

  }

  private getWeek = function (date)
  {
    const januaryFirst = new Date(date.getFullYear(), 0, 1);
    const weekStart = 0;
    return Math.floor((((+date - +januaryFirst) / 86400000) + januaryFirst.getDay() - weekStart) / 7);
  };

}
