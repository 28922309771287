import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BlockService } from "@shared/block/shared/block.service";
import { Socalmedia } from "app/model/interfaces";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class PayUScriptResolver implements Resolve<any> {
    resolve(): Observable<any>
    {
        return of(true);

        // if ((window as any).openpayu)
        // {
        //     return of(true);
        // }

        // return new Observable(observer =>
        // {
        //     (window as any).openpayu = {};
        //     (window as any).openpayu.options = { creditAmount: 1000, posId: "1518454", key: "b9" };

        //     const script = document.createElement('script');
        //     script.type = 'text/javascript';
        //     script.src = 'https://static.payu.com/res/v2/widget-calculator-installments.min.js';
        //     script.async = true;
        //     script.defer = true;
        //     script.onload = () =>
        //     {
        //         console.log("InitPayU");
        //         observer.next(true);
        //         observer.complete();
        //     };
        //     script.onerror = (error: any) =>
        //     {
        //         observer.error(error);
        //     };
        //     document.body.appendChild(script);
        // });
    }
}