import
{
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ShopProductFamily } from "@dto";
import { Resolutions } from "@resolutions";
import { RollbarErrorHandler } from "@service/rollbar/rollbar";
import { WindowRefService } from "@service/window-service/window-ref.service";
import
{
  getImageUrl,
  getImageUrlStatic,
} from "../../../../utils/utilsFunctions";
import { SwiperOptions } from "swiper";
import { PaginationOptions } from "swiper/types/modules/pagination";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { UrlTranslateService } from '@service/helpers/url-translate.service';
import { SwiperConfigService } from "@service/swiper-config.service";

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements AfterViewInit
{
  private _imgArray: any[] = [];
  get imgArray(): any[] { return this._imgArray; }

  private readonly desktopSlidesPerView = 6;
  public getImageUrl = getImageUrl;
  public getImageUrlStatic = getImageUrlStatic;
  public selectedIndex: number = 0;
  public sliderIndex: number = 0;
  public offsetsBefore: number[] = [16, 24, 48, 128];
  public offsetsAfter: number[] = [16, 24, 48, 128];

  pagination: PaginationOptions = {
    clickable: true,
    dynamicBullets: true,
  };

  public defaultConfigForGallery: SwiperOptions = {
    slidesPerView: "auto",
    direction: "horizontal",
    freeMode: false,
    speed: 200,
    spaceBetween: 24,
    initialSlide: 0,
    init: true,
    watchOverflow: false,
    preloadImages: false,
    centeredSlides: false,
    slidesOffsetAfter: 0,
    slidesPerGroup: 1,
    breakpoints: {
      [Resolutions.resolutionTablet]: {
        // when window width is >= 768px
        slidesPerView: "auto",
        direction: "horizontal",
        spaceBetween: 8,
      },
      [Resolutions.resolutionDesktop]: {
        //  when window width is >= 1280px
        direction: "vertical",
        spaceBetween: 12,
        slidesPerView: this.desktopSlidesPerView,
        speed: 800,
        freeMode: true,
      },
    },
  };

  @Input() set imgArray(value: any[])
  {
    this._imgArray = value;

    if (this.swiperComponent?.swiperRef)
    {
      this.swiperComponent.swiperRef.slideTo(0, 800);
      this.setAsMainImage(0);
      if (this.product)
      {
        this.defaultConfigForGallery.slidesOffsetAfter = this.imgArray.length > 8 ? -160 : -80;
      }
      this.swiperComponent.swiperRef.update();
    }

  }
  @Input() public sliderAfter: number[];
  @Input() public config: SwiperOptions;
  @Input() public showThumbs: boolean = false;
  @Input() public fillCrop: boolean = false;
  @Input() public singleSlideMovement: boolean = false;
  @Input() public product: ShopProductFamily;
  @Input() public height: number[];
  @Input() public width: number[];
  @Input() placeholder: boolean = false;

  @ViewChild("swiperComponent") private swiperComponent;
  @ViewChild("sliderWrapper") private sliderWrapper: ElementRef;

  @Output() private readonly selectMainImage: EventEmitter<number> = new EventEmitter<number>();

  public isImageLoaded: boolean;
  public showSlider: boolean = false;
  private slideNumber: number;

  constructor(private windowRef: WindowRefService,
    private rollbarService: RollbarErrorHandler,
    private changeDet: ChangeDetectorRef,
    public urlTranslateService: UrlTranslateService,
    private swiperCo: SwiperConfigService)
  {
    // this.defaultConfigForGallery = this.swiperCo.getConfig(
    //   [0, Resolutions.resolutionTablet, Resolutions.resolutionDesktop],
    //   [0, 0, 0]
    //   [24,8,12]
    // )
  }

  //#region Initi/Destroy
  public ngAfterViewInit(): void
  {
    if (this.imgArray.length)
    {
      this.showSlider = true;

      if (this.sliderAfter)
      {
        this.defaultConfigForGallery.breakpoints[Resolutions.resolutionTablet].slidesOffsetAfter = this.sliderAfter[0];
        this.defaultConfigForGallery.breakpoints[Resolutions.resolutionDesktop].slidesOffsetAfter = this.sliderAfter[1];
      }

      this.calculateNumberOfSlides();
      this.changeDet.detectChanges();
    }
  }
  //#endregion

  //#region Updates/Changes
  public updateSlider(): void
  {
    this.swiperComponent.swiperRef.update();
  }
  //#endregion

  //#region Screen changes (resize etc.)
  @HostListener('window:resize', ['$event'])
  public onWindowResize(): void
  {
    this.calculateNumberOfSlides();
  }
  private calculateNumberOfSlides(): void
  {
    if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop)
    {
      this.slideNumber = this._imgArray.length;
      let newSlidesPerGroup = (this.slideNumber > 8 && this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop) || this.slideNumber > 12 ? 3 : 1;
      newSlidesPerGroup = this.singleSlideMovement == true ? 1 : newSlidesPerGroup;

      if (this.defaultConfigForGallery.slidesPerGroup !== newSlidesPerGroup)
      {
        this.defaultConfigForGallery.slidesPerGroup = newSlidesPerGroup

        if (!this.changeDet["destroyed"])
        {
          this.changeDet.detectChanges();
        }
      }
    }
  }
  //#endregion

  //#region Slide navigation
  public nextSlide(): void
  {
    if (this.selectedIndex == this._imgArray.length - 1)
    {
      return;
    }

    this.setAsMainImage(this.selectedIndex + 1);
    this.changeDet.detectChanges();
  }

  public previousSlide(): void
  {
    if (this.selectedIndex == 0)
    {
      return;
    }

    this.setAsMainImage(this.selectedIndex - 1);
    this.changeDet.detectChanges();
  }

  public isFirstSlide(): boolean
  {
    return this.selectedIndex == 0;
  }

  public isLastSlide(): boolean
  {
    return this.selectedIndex == this._imgArray.length - 1;
  }

  public setAsMainImage(index: number): void
  {
    if (this._imgArray.length > index)
    {
      this.sliderIndex = index - 2

      if (this.sliderIndex < 0)
      {
        this.sliderIndex = 0;
      }
      if (this.sliderIndex > this._imgArray.length - 2)
      {
        this.sliderIndex = this._imgArray.length - 2;
      }

      this.swiperComponent.swiperRef.slideTo(this.sliderIndex);

      this.selectedIndex = index;
      this.selectMainImage.emit(index);
    }
  }

  public notAllImagesVisible(): boolean
  {
    if (!this.windowRef.isServer)
    {
      if (this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop && this.sliderWrapper)
      {
        return this._imgArray.length * 86 + 51 > this.sliderWrapper.nativeElement.clientWidth;
      }
      if (this.windowRef.getNativeWindowInnerWidth() >= Resolutions.resolutionDesktop)
      {
        return this._imgArray.length > this.desktopSlidesPerView;
      }
    }
  }
  //#endregion

  public onError(img): void
  {
    img['tempSrc'] = '/assets/icons/no-image.svg';
    img['noImage'] = true;
    this.changeDet.detectChanges();
  }

  public setImageAsLoaded()
  {
    this.isImageLoaded = true;
    this.changeDet.detectChanges();
  }

  //#region Product images
  public getImageAlt(img): string
  {
    if (this.product && this.product.name && img.name)
    {
      if (this.product)
      {
        return this.product.name + ' - ' + img.name + " - miniaturka";
      }
      else
      {
        return this.product.name + ' - ' + img.name;
      }
    }
    else if (img.Name)
    {
      return img.Name;
    }

    return "";
  }
  //#endregion
}
