import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { take } from 'rxjs';
import { environment } from "environments/environment";
import { ConfigService } from '@service/config/config.service';
import { CustomerClientService } from '@service/client/customer-client.service';
import { WindowRefService } from '@service/window-service/window-ref.service';
import { ChatService } from '@service/chat/chat-service.service';
import { ShopModeService } from '@service/helpers/shop-mode.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit
{
  private fullWindowWidth = 450;

  constructor(public intercom: Intercom,
    private ngZone: NgZone,
    private configService: ConfigService,
    private customerService: CustomerClientService,
    private windowRef: WindowRefService,
    private chatService: ChatService,
    public shopModeService: ShopModeService) { }
  private isChatOpen: boolean = false;
  private backBlocked: boolean = false;

  ngOnInit(): void
  {
    this.ngZone.onStable.pipe(take(1)).subscribe(() =>
    {
      if (environment.intercom && this.shopModeService.isKioskMode() == false)
      {
        setTimeout(() =>
        {
          if (this.customerService.customerSubject.value)
          {
            environment.intercomeDataSettings.user_id = this.customerService.customerSubject.value.guid;
          }

          this.intercom.boot(environment.intercomeDataSettings)
          this.intercom.onHide(this.onIntercomHide);
          this.intercom.onShow(this.onIntercomShow);

          this.chatService.changeChatState.subscribe((state) =>
          {
            if (state.state == "show" && this.isChatOpen == false)
            {
              this.intercom.show(state.message);
            }
            else if (state.state == "show" && this.isChatOpen == true)
            {
              this.intercom.showNewMessage(state.message);
            }
            else if (state.state == "hide" && this.isChatOpen)
            {
              this.intercom.hide();
            }
            else if (state.state == "disable")
            {
              if (this.intercom)
              {
                this.windowRef.getNativeWindow()["intercomSettings"] = {
                  hide_default_launcher: true
                };
                this.intercom.update();
              }
            }
            else if (state.state == "enable")
            {
              if (this.intercom)
              {
                this.windowRef.getNativeWindow()["intercomSettings"] = {
                  hide_default_launcher: false
                };
                this.intercom.update();
              }
            }
          });

        }, environment.IntercomDelay);
      }
    });
  }

  public disableIntercom()
  {
    if (this.intercom)
    {
      this.windowRef.getNativeWindow()["intercomSettings"] = {
        hide_default_launcher: true
      };
      this.intercom.update();
    }
  }

  private changeIntercomePosition(n: number): void
  {
    if (this.intercom)
    {
      this.windowRef.getNativeWindow()['intercomSettings'] = { vertical_padding: n };
    }
  }

  @HostListener("window:popstate", ["$event"])
  private backButtonHandler($event): void
  {
    // Hidden by back
    if (this.isChatOpen)
    {
      this.backBlocked = false;

      if (this.windowRef.getNativeWindowInnerWidth() <= this.fullWindowWidth)
      {
        this.isChatOpen = false;
        this.intercom.hide();
      }
    }
  }

  private onIntercomHide = () =>
  {
    // Hiden by other means then back
    if (this.isChatOpen == true)
    {
      this.isChatOpen = false;
      this.checkInterCome();
    }
  }
  private onIntercomShow = () =>
  {
    // Opened by any means
    if (this.isChatOpen == false)
    {
      this.isChatOpen = true;
      this.checkInterCome();
    }
  }

  public blockBack()
  {
    if (this.backBlocked == false)
    {
      history.pushState(null, document.title, location.href);
      this.backBlocked = true;
    }
  }
  public unblockBack()
  {
    if (this.backBlocked == true)
    {
      history.back();
      this.backBlocked = false;
    }
  }

  @HostListener("window:resize", ["$event"])
  private onResize(): void
  {
    this.checkInterCome();
  }


  private checkInterCome()
  {
    if (this.isChatOpen)
    {
      if (this.backBlocked)
      {
        if (this.windowRef.getNativeWindowInnerWidth() <= this.fullWindowWidth)
        {
          // nic
        }
        else
        {
          this.unblockBack();
        }
      }
      else
      {
        if (this.windowRef.getNativeWindowInnerWidth() <= this.fullWindowWidth)
        {
          this.blockBack();
        }
        else
        {
          // nic
        }
      }
    }
    else
    {
      if (this.backBlocked)
      {
        this.unblockBack();
      }
    }
  }
}
