<section class="opineo-wrapper">
  <h2 class="title" *ngIf="!noHeader">{{ 'opineo.clientsReviews' |translate }}</h2>
  <p class="recommendation" *ngIf="!noHeader"><span class="font-style-semi">99,9%</span> {{ 'opineo.fullRecommendation' |translate }}</p>
  <div class="block-wrapper" #expandSection>
    <div class="reviews-wrapper" *ngIf="allReviews.length>0">
      <div class="review" *ngFor="let review of allReviews; let i=index">
        <a [href]="'https://www.opineo.pl/opinie/arte-pl'" (click)="goToOpineo($event)" *ngIf="!noClick">
          <ng-container>
            <div class="header-wrapper">
              <p class="username">{{review.nick || review.anonymousUsername}}</p>
              <p class="tiny-text date" *ngIf="review.creationDate">{{review.creationDate | date:'dd.MM.yyyy'}} r.</p>
            </div>
            <app-rating *ngIf="review.rating" [activeElements]="review.rating" [showRating]="false"></app-rating>
            <p class="review-text font-style-light body-text">{{review.Description|| review.description}}</p>
          </ng-container>
        </a>
        <ng-container *ngIf="noClick">
          <div class="header-wrapper">
            <p class="username">{{review.nick || review.anonymousUsername}}</p>
            <p class="tiny-text date" *ngIf="review.creationDate">{{review.creationDate | date:'dd.MM.yyyy'}} r.</p>
          </div>
          <app-rating *ngIf="review.rating" [activeElements]="review.rating" [showRating]="false"></app-rating>
          <p class="review-text font-style-light body-text">{{ review.description}}</p>
        </ng-container>
      </div>
      <div
        *ngIf="showMore && allReviews.length > 4"
        [appExpand]="expandSection"
        [pages]="allReviews.length / 4"
        [elements]="allReviews.length"
        [perPage]="5"
        (toggleSection)="toggleSection($event)"
        [ngClass]="{ 'expanded': isExpanded }"
      >
        <button
          *ngIf="!isExpanded"
          class="button secondary-button show-more-opinions"
        >Pokaż więcej</button>
      </div>
    </div>
  </div>
  <a [href]="'https://www.opineo.pl/opinie/arte-pl'" style="display: inline-block;" (click)="goToOpineo($event)">
    <app-img
      *ngIf="!noHeader"
      [src]="'https://images.arte.pl/images/Other/Opineo.png'"
      [width]="[150, 150, 150]"
      [height]="[34, 34, 34]"
    ></app-img>
  </a>
</section>
