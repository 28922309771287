import
{
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import
{
  AutoQuantityType,
  Cart,
  ShopProduct,
  ShopProductFamily,
  Wishlist,
  DeliveryMethods
} from "@dto";
import { TranslateService } from "@ngx-translate/core";
import { Resolutions } from "@resolutions";
import { CartService } from "@service/cart/cart.service";
import { WindowRefService } from "@service/window-service/window-ref.service";
import { WishlistService } from "@service/wishlist/wishlist.service";
import { FreeSamplePopupComponent } from "@shop/product/product-calculator/free-sample-popup/free-sample-popup.component";
import { Subject, Observable } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import
{
  animateButton,
  getImageUrl,
  removeAnimateButton,
} from "../../../../utils/utilsFunctions";
import { BlockService } from "../../../sharedModules/block/shared/block.service";
import { ProductAvailabilityPopupComponent } from "../../../sharedModules/shared/modals/availability/product-availability-popup/product-availability-popup.component";
import { ProductAddItemPopupComponent } from "../../../sharedModules/shared/modals/product-add-item-popup/product-add-item-popup.component";
import { TooltipPopupComponent } from "../../../sharedModules/tooltip/tooltip-popup/tooltip-popup.component";
import { environment } from '../../../../../environments/environment';
import { MessageItem } from "app/model/interfaces";
import { ChatService } from "@service/chat/chat-service.service";
import { FamilyType } from "@idto";

@Component({
  selector: "app-product-calculator",
  templateUrl: "./product-calculator.component.html",
  styleUrls: ["./product-calculator.component.scss"],
})
export class ProductCalculatorComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  private _product: ShopProductFamily;

  @ViewChild(FreeSamplePopupComponent, { static: true }) private freeSamplePopup: FreeSamplePopupComponent;
  @ViewChild(ProductAvailabilityPopupComponent, { static: true }) private productAvailabilityPopup: ProductAvailabilityPopupComponent;
  @ViewChild(TooltipPopupComponent, { static: true }) private tooltipPopup: TooltipPopupComponent;
  @ViewChild(ProductAddItemPopupComponent, { static: true }) private productAddItemPopupComponent: ProductAddItemPopupComponent;

  @Input() public set product(value: ShopProductFamily)
  {
    this._product = value;

    if (this._product.familyType === FamilyType.DYWANY)
    {
      this.headerTitle = this.translateService.instant("productCalculator.pickCarpetSize");
    }
    if (this._product.familyType === FamilyType.CHODNIKI)
    {
      this.headerTitle = this.translateService.instant("productCalculator.pickCarpet2Size");
    }
    if (this._product.familyType === FamilyType.AKCESORIA_WYCIERACZKI)
    {
      this.headerTitle = this.translateService.instant("productCalculator.pickDoormatSize");
    }

    this.showSizeCalculator = this._product.isCovering || this._product.isWalkway;
    this.showSizeSelection = !this.showSizeCalculator;
  }
  public get product(): ShopProductFamily
  {
    return this._product;
  }
  @Output() private readonly summaryPrice: EventEmitter<number> = new EventEmitter();

  public showSizeSelection: boolean = false;
  public showSizeCalculator: boolean = false;
  public headerTitle: string;
  public isOutlet: boolean = false;
  public error: MessageItem;
  public installmentError: MessageItem;
  public isLoading: boolean;
  private selectedItems: Set<any> = new Set();
  public shopList: any[] = [];
  @ViewChild("addButton", { static: true }) private addButton: ElementRef;
  @ViewChild("calculatorWrapper", { static: true }) private calculatorWrapper: ElementRef;
  public isInFav: boolean = false;
  public isShopAvilabilityActive: boolean = false;
  private destroy$: Subject<void> = new Subject();
  private binding: any;
  private bindingAll: any;
  private NAV_HEIGHT: number = 144;
  public shouldWrapperBeStickyField: boolean = false;
  public summaryPriceValue: number;

  constructor(
    private windowRef: WindowRefService,
    private cartService: CartService,
    private changeDet: ChangeDetectorRef,
    public translateService: TranslateService,
    private blockService: BlockService,
    private chatService: ChatService,
    private renderer2: Renderer2,
    private wishlistService: WishlistService
  ) { }

  public ngOnDestroy(): void
  {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (changes.product.currentValue)
    {
      this.selectedItems = new Set<any>();
      this.changeDet.detectChanges();
    }

    this.shouldWrapperBeStickyField = this.shouldWrapperBeSticky();
  }

  public ngOnInit(): void
  {
    this.wishlistService.wishlist$.pipe(filter((v) => !!v), takeUntil(this.destroy$))
      .subscribe((res: Wishlist) =>
      {
        this.isInFav = this.isInFavFunc(this.product);
        if (this.changeDet && !this.changeDet["destroyed"])
        {
          this.changeDet.detectChanges();
        }
      });

    this.cartService.cart$.pipe(filter((v) => !!v), takeUntil(this.destroy$))
      .subscribe((res: Cart) =>
      {
        this.isShopAvilabilityActive = res.deliveryMethods.some((deliveryMethod) => deliveryMethod.code === DeliveryMethods.ODBIOR_OSOBISTY);
      });

    this.shouldWrapperBeStickyField = this.shouldWrapperBeSticky();
  }

  get canBuyWithInstallment(): boolean
  {
    return environment.canBuyWithInstallment;
  }

  public isLessThanDesktopResolution(): boolean
  {
    return (
      this.windowRef.getNativeWindowInnerWidth() < Resolutions.resolutionDesktop
    );
  }

  public goToProductStatusPopup($event, product: ShopProductFamily): void
  {
    $event.stopPropagation();
    this.productAvailabilityPopup.openModal(
      null,
      getImageUrl(product.familyCode, "0", null, [40, 40, 40], null)
    );
  }

  public goToFreeSamplePopup($event, product: ShopProductFamily): void
  {
    $event.stopPropagation();
    this.freeSamplePopup.openModal(
      null,
      getImageUrl(product.familyCode, "0", null, [72, 72, 72], null)
    );
  }

  public showTooltipPopup($event, product): void
  {
    // todo: event tylko jako event czy jako shopProductPack
    if ($event instanceof Event)
    {
      this.tooltipPopup.openModal();
    } else
    {
      if ($event.product)
      {
        this.tooltipPopup.openModal(
          $event.productVariant,
          getImageUrl($event.product.familyCode, "0", null, [80, 80, 80], null)
        );
      } else
      {
        if (product)
        {
          this.tooltipPopup.openModal(
            $event.productVariant,
            getImageUrl(product.familyCode, "0", null, [80, 80, 80], null)
          );
        }
      }
    }
  }

  public goToInstalmentsSection(): void
  {
    if (this.selectedItems.size === 0)
    {
      this.installmentError = { type: 'Warning', message: this.translateService.instant("error.selectProductToAddToInstallment") };
      return;
    }

    this.closeError("installmentError");
    const element = this.windowRef.getDocument().getElementById("payments");
    if (this.windowRef.isMobile())
    {
      this.windowRef
        .getNativeWindowToScroll()
        .scrollTo({ top: element.offsetTop + 50, behavior: "smooth" });
    } else
    {
      this.windowRef
        .getNativeWindowToScroll()
        .scrollTo({ top: element.offsetTop + 100, behavior: "smooth" });
    }
    this.onSummaryPriceChange(this.summaryPriceValue);
  }

  public showStatusProductTooltip(product: ShopProductFamily): void
  {
    if (product["product"])
    {
      this.tooltipPopup.openSecondStepModal(
        product["productVariant"],
        getImageUrl(
          product["product"].familyCode,
          "0",
          null,
          [40, 40, 40],
          null
        )
      );
    } else
    {
      this.tooltipPopup.openSecondStepModal(
        product,
        getImageUrl(product.familyCode, "0", null, [40, 40, 40], null)
      );
    }
  }

  public addToCart(): void
  {
    if (this.selectedItems.size == 0)
    {
      this.onError(false);
      return;
    }
    this.isLoading = true;
    animateButton("loading", this.addButton, this.renderer2, this.changeDet);
    if (Array.from(this.selectedItems)[0]["selectedSize"])
    {
      if (this.binding)
      {
        let bindingAutosizeOption = AutoQuantityType.Custom;

        if (this.bindingAll && this.product.isCovering)
        {
          bindingAutosizeOption = AutoQuantityType.AllSides;
        }
        else if (this.bindingAll && this.product.isWalkway)
        {
          bindingAutosizeOption = AutoQuantityType.TwoSides;
        }

        this.cartService.cartAddElementToCartWithBinding(
          "null",
          Array.from(this.selectedItems)[0],
          Array.from(this.selectedItems)[0]["selectedSize"],
          bindingAutosizeOption,
          Array.from(this.selectedItems)[0]["selectedBindingSize"]
        )
          .subscribe(this.onSuccess.bind(this), this.onError.bind(this));
      }
      else
      {
        this.cartService.addElementToCart("null", Array.from(this.selectedItems)[0], Array.from(this.selectedItems)[0]["selectedSize"])
          .subscribe({ next: this.onSuccess.bind(this), error: this.onError.bind(this) });
      }
    } else
    {
      if (
        this.checkIfSelectedItemIsNotUniqAndIsAlreadyAddedToCart(
          Array.from(this.selectedItems)[0]
        )
      )
      {
        this.error = this.blockService.getError("UniqueItem_CannotChangeQuantity");
        this.onError(this.error);
        return;
      }
      this.cartService
        .addElementToCart("null", Array.from(this.selectedItems)[0], 1)
        .subscribe(this.onSuccess.bind(this), this.onError.bind(this));
      for (let i = 1; i < this.selectedItems.size; i++)
      {
        this.cartService
          .addElementToCart("null", Array.from(this.selectedItems)[i], 1)
          .subscribe();
      }
    }
  }

  public addToFav(): void
  {
    if (this.isInFavFunc(this.product))
    {
      this.wishlistService.removeProductFromWishList(this.product.familyCode).subscribe();
    } else
    {
      this.wishlistService.addProductToWishList(this.product.familyCode).subscribe();
    }
  }

  private checkIfSelectedItemIsNotUniqAndIsAlreadyAddedToCart(productVariant: any): boolean
  {
    return !!this.cartService.getCartItemByProductID(productVariant.iD) && productVariant.isUnique == true;
  }

  public updateLocalCart(product: ShopProduct): void
  {
    if (!product)
    {
      return;
    }

    if (this.selectedItems.has(product) && !product["selectedSize"])
    {
      this.selectedItems.delete(product);
    }
    else
    {
      if (product["selectedSize"])
      {
        this.selectedItems.clear();
      }

      this.selectedItems.add(product);
      this.changeDet.detectChanges();
    }

    if (this.selectedItems.size)
    {
      this.installmentError = null;
      let summaryPrice = 0;

      this.selectedItems.forEach((value: ShopProduct) =>
      {
        summaryPrice = summaryPrice + value.promotionPrice;
      })

      this.summaryPriceValue = summaryPrice;
      this.summaryPrice.emit(this.summaryPriceValue);
    }
  }

  public onSummaryPriceChange(price)
  {
    this.summaryPrice.emit(price);
  }

  private onSuccess(success: boolean): void
  {
    this.error = null;
    this.isLoading = false;
    if (this.product.accessoryProducts)
    {
      this.productAddItemPopupComponent.openModal(
        Array.from(this.selectedItems),
        this.product.accessoryProducts
      );
    } else
    {
      this.productAddItemPopupComponent.openModal(
        Array.from(this.selectedItems),
        this.product.accessoryProducts,
        false,
        true
      );
    }
    this.changeDet.detectChanges();
    if (!Array.from(this.selectedItems)[0]["selectedSize"])
    {
      this.selectedItems.clear();
    }
    this.cartService.resetSelected();
    removeAnimateButton("error", this.addButton, this.renderer2);
    removeAnimateButton("loading", this.addButton, this.renderer2);
    animateButton("success", this.addButton, this.renderer2, this.changeDet);
    if (success)
    {
      return;
    }
  }

  private onError(error: any): void
  {
    this.isLoading = false;
    if (this.selectedItems.size == 0)
    {
      this.error = { type: 'Warning', message: this.translateService.instant("error.selectProductToAddToCart") };
      this.changeDet.detectChanges();
      removeAnimateButton("success", this.addButton, this.renderer2);
      removeAnimateButton("loading", this.addButton, this.renderer2);
    } else
    {
      this.error = error;
      this.changeDet.detectChanges();
      removeAnimateButton("success", this.addButton, this.renderer2);
      removeAnimateButton("loading", this.addButton, this.renderer2);
      animateButton("error", this.addButton, this.renderer2, this.changeDet);
    }
    return;
  }

  public ngAfterViewInit(): void
  {
    this.getProductShops();
  }

  public getProductShops(): void
  {
    this.blockService.getDataSync("shops").locations.forEach(el => this.shopList.push({ name: el.shortName, code: el.code, description: el.street, coordinates: el.coordinates }));
  }

  public showSuccessBar($event): void
  {
    this.error = $event;
  }

  public resetSelectedList(): void
  {
    this.closeError();
    this.selectedItems.clear();
    this.changeDet.detectChanges();
  }

  public isInFavFunc(product: ShopProductFamily): boolean
  {
    return product && !!this.wishlistService.getProduct(product);
  }

  public setBinding($event): void
  {
    this.binding = $event;
  }

  public setBindingAll($event): void
  {
    this.bindingAll = $event;
  }

  public showIntercom(): void
  {
    this.chatService.open();
  }

  public closeError(type: string = "error"): void
  {
    this[type] = null;
    this.changeDet.detectChanges();
  }

  shouldWrapperBeSticky()
  {
    //const NAV_HEIGHT = 144;
    return this.calculatorWrapper.nativeElement.clientHeight + this.NAV_HEIGHT < this.windowRef.getNativeWindow().innerHeight;
  }
}
