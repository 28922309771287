import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
//import { BlockComponent } from './block.component';
import { CollapsingBlockComponent } from './collapsing-block/collapsing-block.component';
import { SimpleBlockComponent } from './simple-block/simple-block.component';

@NgModule({
  declarations: [
    //BlockComponent,
    CollapsingBlockComponent,
    SimpleBlockComponent,
  ],
  providers: [],
  imports: [
    CommonModule,
    TranslateModule,
    NgScrollbarModule
  ],
  exports: [
    //BlockComponent,
    CollapsingBlockComponent,
    SimpleBlockComponent,
  ]
})
export class BlockModule
{
}
