<div class="rating-wrapper" (mouseleave)="hoverStarsToHereFunc(0)">
  <span class="rating" *ngFor="let elem of [].constructor(5);let i = index"
        (mouseenter)="hoverStarsToHereFunc(i)"
        [ngClass]="{'interactive': isModal, 'active-rating':(i<activeElements && !isModal) || (i<activeModalElements && isModal), 'hover-rating': i<=hoverStarsToHere}"
        (click)="setRatingFunc(i);setAllStarToHere(i)">
    <i class="ai ai-star"></i>
  </span>
  <span *ngIf="showRating && !isModal">{{activeElements.length?activeElements.length:activeElements}} /5</span>
  <span *ngIf="showRating && isModal">{{activeModalElements}} /5</span>
</div>
