import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ContactPopupAbstractComponent } from "app/module/sharedModules/shared/modals/contact-popup-abstract/contact-popup-abstract.component";

@Component({
  selector: "app-newsletter-popup",
  templateUrl:
    "../contact-popup-abstract/contact-popup-abstract.component.html",
  styleUrls: [
    "../contact-popup-abstract/contact-popup-abstract.component.scss",
    "./newsletter-popup.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterPopupComponent extends ContactPopupAbstractComponent { }
